/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, postFunc, putFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";

import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
  |--------------------------------------------------
  | TYPES
  |--------------------------------------------------
  */
const GET_TUT_BY_LANG_REQ = "auth/GET_TUT_BY_LANG_REQ";
const GET_TUT_BY_LANG_SCS = "auth/GET_TUT_BY_LANG_SCS";
const GET_TUT_BY_LANG_FLR = "auth/GET_TUT_BY_LANG_FLR";

const ALTER_TUTORIAL = "auth/ALTER_TUTORIAL";

const CREATE_TUTORIAL = "auth/CREATE_TUTORIAL";
//   const ALTER_TUT_SCS = "auth/ALTER_TUT_SCS";
//   const ALTER_TUT_FLR = "auth/ALTER_TUT_FLR";

/**
 * ACTIONS
 */

export const getTutorialByLanguage = (lang) => async (dispatch) => {
	dispatch({ type: GET_TUT_BY_LANG_REQ });
	const response = await getFunc(`tutorial/${lang}`);

	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_TUT_BY_LANG_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_TUT_BY_LANG_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const createTutorial = (body) => async (dispatch) => {
	const response = await postFunc(`tutorial`, body);

	if (response.status.errorCode === 200) {
		dispatch({ type: ALTER_TUTORIAL, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });

		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const alterTutorial = (body) => async (dispatch) => {
	const response = await putFunc(`tutorial`, body);

	if (response.status.errorCode === 200) {
		dispatch({ type: CREATE_TUTORIAL, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });

		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

/**
 * REDUCERS
 */
const INIT_STATE = {
	loading: false,
	data: [
		{
			id: "9012386c-9639-11ec-b909-0242ac120002",
			img: 0,
			header: "header0",
			description: "Description0",
			selected: false,
			position: 0,
		},
		{
			id: "94a81266-9639-11ec-b909-0242ac120002",
			img: 1,
			header: "header1",
			description: "Description1",
			selected: false,
			position: 0,
		},
		{
			id: "97a6ca0c-9639-11ec-b909-0242ac120002",
			img: 2,
			header: "header2",
			description: "Description2",
			selected: false,
			position: 0,
		},
		{
			id: "9b94013e-9639-11ec-b909-0242ac120002",
			img: 3,
			header: "header3",
			description: "Description3",
			selected: false,
			position: 0,
		},
		{
			id: "9f6e2906-9639-11ec-b909-0242ac120002",
			img: 4,
			header: "header4",
			description: "Description4",
			selected: false,
			position: 0,
		},
		{
			id: "a34af0a4-9639-11ec-b909-0242ac120002",
			img: 5,
			header: "header5",
			description: "Description5",
			selected: false,
			position: 0,
		},
		{
			id: "ac1fe8ce-9639-11ec-b909-0242ac120002",
			img: 6,
			header: "header6",
			description: "Description6",
			selected: false,
			position: 0,
		},
		{
			id: "ac1fe8ce-9639-11ec-b909-0242ac120003",
			img: 7,
			header: "header7",
			description: "Description7",
			selected: false,
			position: 0,
		},
	],
};

export default function reducer(state = INIT_STATE, action = {}) {
	switch (action.type) {
		case GET_TUT_BY_LANG_REQ:
			return {
				...state,
				loading: true,
				data: state.data,
			};
		case GET_TUT_BY_LANG_SCS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case GET_TUT_BY_LANG_FLR:
			return {
				...state,
				loading: false,
				data: state.data,
			};
		case CREATE_TUTORIAL:
			return {
				...state,
				loading: false,
				data: action.payload.map((item) => item.data),
			};
		case ALTER_TUTORIAL:
			return {
				...state,
				loading: false,
				data: action.payload.map((item) => item.data),
			};
		default:
			return state;
	}
}
