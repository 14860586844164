/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, postFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const GET_IMAGES_FROM_FOLDER_REQ = "auth/GET_IMAGES_FROM_FOLDER_REQ";
const GET_IMAGES_FROM_FOLDER_SCS = "auth/GET_IMAGES_FROM_FOLDER_SCS";
const GET_IMAGES_FROM_FOLDER_FLR = "auth/GET_IMAGES_FROM_FOLDER_FLR";

const CLEAR_IMAGES = "auth/CLEAR_IMAGES";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/
export const getImagesFromFolder = (id) => async (dispatch) => {
  dispatch({ type: GET_IMAGES_FROM_FOLDER_REQ });
  const response = await getFunc(`gallery/folders/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_IMAGES_FROM_FOLDER_SCS,
      payload: response.data,
    });
    NotificationManager.success(response.status.description);
  } else {
    dispatch({ type: GET_IMAGES_FROM_FOLDER_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const searchImages = (body) => async (dispatch) => {
  dispatch({ type: GET_IMAGES_FROM_FOLDER_REQ });
  const response = await postFunc(`gallery/folder/autocomplete`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_IMAGES_FROM_FOLDER_SCS,
      payload: response.data,
    });
    NotificationManager.success(response.status.description);
  } else {
    dispatch({ type: GET_IMAGES_FROM_FOLDER_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const clearImages = () => async (dispatch) => {
  dispatch({ type: CLEAR_IMAGES });
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/
const INIT_STATE = {
  data: null,
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_IMAGES_FROM_FOLDER_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_IMAGES_FROM_FOLDER_SCS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_IMAGES_FROM_FOLDER_FLR:
      return {
        ...state,
        loading: false,
        data: state.data,
      };
    case CLEAR_IMAGES:
      return {
        ...state,
        loading: false,
        data: null,
      };
    default:
      return state;
  }
}
