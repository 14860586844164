/* eslint-disable import/no-unresolved */
// React
import { lazy } from "react";

// Routes (Non splitted)
import PublicLayout from "Layouts/PublicLayout";
import PrivateLayout from "Layouts/PrivateLayout";
import OrdersLayout from "Layouts/OrdersLayout";
import FullWidthLayout from "Layouts/FullWidthLayout";
import ChatLayout from "Layouts/ChatLayout";

// Routes (Code splitting)
const Dashboard = lazy(() => import("Pages/dashboard/index.js"));
const Delivery = lazy(() => import("Pages/delivery/index.js"));
const Login = lazy(() => import("Pages/login/index.js"));
const ForgotPassword = lazy(() => import("Pages/forgot-password/index.js"));
const Verification = lazy(() => import("Pages/verification/index.js"));
const Organisations = lazy(() => import("Pages/organisations/index.js"));
const Setitings = lazy(() => import("Pages/settings/index.js"));
const Customization = lazy(() => import("Pages/customization/index.js"));
const Orders = lazy(() => import("Pages/orders/index.js"));
/* const Appointments = lazy(() =>
  import("Pages/appointments/screens/Calendars.js")
); */
const Appointments = lazy(() => import("Pages/appointments2/index.js"));
// const Services = lazy(() => import("Pages/services-category/index.js"));
const Services = lazy(() => import("Pages/services/index.js"));
// const SubCategory = lazy(() => import("Pages/services-sub-category/index.js"));
const Users = lazy(() => import("Pages/users/index.js"));
// const SubCategoryServices = lazy(() => import("Pages/services-sub-category/index.js"));
// const ItemServices = lazy(() => import("Pages/services-items/index.js"));
const AccommodationTypes = lazy(() =>
  import("Pages/accommodation-types/index.js")
);
const ResetPassword = lazy(() => import("Pages/reset-password/index.js"));
const Tenants = lazy(() => import("Pages/tenants/index.js"));
const PropertyManagement = lazy(() =>
  import("Pages/property-management/index.js")
);
const StayManagement = lazy(() => import("Pages/stay-management/index.js"));
const Reservations = lazy(() => import("Pages/reservations/index.js"));

const SuperAdmin = lazy(() => import("Pages/super-admin/index.js"));
const Campaign = lazy(() => import("Pages/campaign/index.js"));

const MenuPromotions = lazy(() => import("Pages/menu-promotions/index.js"));
const GuestAppPromotions = lazy(() =>
  import("Pages/guestapp-promotions/index.js")
);
const Guests = lazy(() => import("Pages/guests/index.js"));
const OrganisationsContactLess = lazy(() =>
  import("Pages/organisations-contact-less/index.js")
);
const ReservationsReview = lazy(() =>
  import("Pages/reservations-review/index.js")
);

const Chat = lazy(() => import("Pages/chat/index.js"));

const KeyAdministration = lazy(() =>
  import("Pages/key-administration/index.js")
);

const Statistics = lazy(() => import("Pages/stats/index.js"));
const Invoices = lazy(() => import("Pages/invoices/index.js"));
const Stickers = lazy(() => import("Pages/stickers/index.js"));
const MessagesContactless = lazy(() => import("Pages/messages-contactless/index.js"));
const MenuStatistics = lazy(() => import("Pages/menu-statistics/index.js"));
const Tutorial = lazy(() => import("Pages/tutorial/index.js"));

/**
|--------------------------------------------------
| PUBLIC ROUTES
|--------------------------------------------------
*/

export const publicRoutes = [
  {
    id: "login",
    title: "Log in",
    description: "Log in section",
    exact: true,
    path: "login",
    component: Login,
    layout: PublicLayout,
  },
  {
    id: "forgotPassword",
    title: "Forgot password",
    description: "Forgot password section",
    exact: true,
    path: "forgot-password",
    component: ForgotPassword,
    layout: PublicLayout,
  },
  {
    id: "resetPassword",
    title: "Reset password",
    description: "resetPassowrd section",
    exact: true,
    path: "reset-password",
    component: ResetPassword,
    layout: PublicLayout,
  },
];

/**
|--------------------------------------------------
| PRIVATE ROUTES
|--------------------------------------------------
*/

export const privateRoutes = [
  {
    id: "dashboard",
    title: "Dashboard",
    description: "Dashboard section",
    path: "",
    exact: true,
    component: Dashboard,
    layout: PrivateLayout,
  },
  {
    id: "verification",
    title: "Verification",
    description: "Verification section",
    path: "verification",
    exact: true,
    component: Verification,
    layout: PublicLayout,
  },
  {
    id: "bccd4ab0-7895-46db-b83a-45b0869c983d",
    title: "Organisations",
    description: "Organisations section",
    path: "organisations",
    exact: true,
    component: Organisations,
    layout: PrivateLayout,
  },
  {
    id: "settings",
    title: "Settings",
    description: "Settings section",
    path: "settings",
    exact: true,
    component: Setitings,
    layout: PrivateLayout,
  },
  {
    id: "customization",
    title: "Customization",
    description: "Customization section",
    path: "customization",
    exact: true,
    component: Customization,
    layout: PrivateLayout,
  },
  {
    id: "93a00f2f-3a1f-41b0-9136-5c7ca1fec971",
    title: "Services",
    description: "Services section",
    path: "services",
    exact: true,
    component: Services,
    layout: OrdersLayout,
  },
  {
    id: "delivery",
    title: "Delivery",
    description: "Delivery screen",
    path: "delivery",
    exact: true,
    component: Delivery,
    layout: PrivateLayout,
  },
  /*   {
    id: "SubCategoryServices",
    title: "Services",
    description: "Services section",
    path: "services/:groupName/:groupId",
    exact: true,
    component: SubCategoryServices,
    layout: PrivateLayout
  },
  {
    id: "ItemServices",
    title: "Services",
    description: "Item services section",
    path: "services/:groupName/:categoryName/:itemid",
    exact: true,
    component: ItemServices,
    layout: PrivateLayout
  }, */
  {
    id: "28cfa670-3872-4bd5-b8dd-5d20fe1885ec",
    title: "Users",
    description: "Users section",
    path: "users",
    exact: true,
    component: Users,
    layout: PrivateLayout,
  },
  {
    id: "e3461783-785a-46f1-9da6-b9375c64c405",
    title: "Orders",
    description: "Orders",
    path: "orders",
    exact: true,
    component: Orders,
    layout: OrdersLayout,
  },
  {
    id: "23b17823-56d6-4102-bfcd-5c899481e6b7",
    title: "Appointments",
    description: "Appointments",
    path: "appointments",
    exact: true,
    component: Appointments,
    layout: OrdersLayout,
  },
  {
    id: "2ba7a2ca-58dd-4347-8381-1e39da07c1ac",
    title: "Accommodation Types",
    description: "Accommodation types section",
    path: "accommodation-types",
    exact: true,
    component: AccommodationTypes,
    layout: PrivateLayout,
  },
  {
    id: "propertyManagement",
    title: "Property Management",
    description: "Managing Properties",
    path: "property",
    exact: true,
    component: PropertyManagement,
    layout: PrivateLayout,
  },
  {
    id: "stayManagement",
    title: "Stay Management",
    description: "Managing Staying",
    path: "stay",
    exact: true,
    component: StayManagement,
    layout: PrivateLayout,
  },
  {
    id: "11152e7f-06ba-4970-b7ef-240ab6304397",
    title: "Reservations",
    description: "Reservations section",
    path: "reservations",
    exact: true,
    component: Reservations,
    layout: OrdersLayout,
  },
  {
    id: "tenants",
    title: "Tenants",
    description: "Tenants section",
    path: "tenants",
    exact: true,
    component: Tenants,
  },
  {
    id: "superAdmin",
    title: "Super Admin",
    description: "Super Admin section",
    path: "super-admin",
    exact: true,
    component: SuperAdmin,
    layout: PrivateLayout,
  },
  {
    id: "d72defb4-314b-4cac-8246-42588aa8638f",
    title: "My Campaigns",
    description: "Campaign section",
    path: "campaigns",
    exact: true,
    component: Campaign,
    layout: PrivateLayout,
  },
  {
    id: "37e66419-2fe2-469a-91b2-da9eef608924",
    title: "Contactless App promotions",
    description: "Contactless App promotions section",
    path: "menu-promotions",
    exact: true,
    component: MenuPromotions,
    layout: FullWidthLayout,
  },
  {
    id: "eb72867b-dcb4-4d0d-9dbb-a9452dd8fe8e",
    title: "Guest App promotions",
    description: "GuestApp promotions section",
    path: "guestapp-promotions",
    exact: true,
    component: GuestAppPromotions,
    layout: FullWidthLayout,
  },
  {
    id: "guests",
    title: "Guests administration",
    description: "Guests administration section",
    path: "guests",
    exact: true,
    component: Guests,
    layout: PrivateLayout,
  },
  {
    id: "bb32ff3f-c365-43a5-9801-74d259077cb2",
    title: "Chat",
    description: "Chat with guests",
    path: "chat",
    exact: true,
    component: Chat,
    layout: ChatLayout,
  },
  {
    id: "bccd4ab0-7895-46db-b83a-45b0869c983d",
    title: "Organisations contact less",
    description: "Organisations contact less section",
    path: "org-contact-less",
    exact: true,
    component: OrganisationsContactLess,
    layout: PrivateLayout,
  },
  {
    id: "46744ab0-7895-46db-b83a-45b0869c436435",
    title: "Reservations review",
    description: "Reservations review section",
    path: "reservations-review",
    exact: true,
    component: ReservationsReview,
    layout: PrivateLayout,
  },
  {
    id: "key-administration",
    title: "Key Administration",
    description: "Key Administration section",
    path: "key-administration",
    exact: true,
    component: KeyAdministration,
    layout: PrivateLayout,
  },
  {
    id: "statistics",
    title: "Statistics",
    description: "Statistics section",
    path: "stats",
    component: Statistics,
    layout: PrivateLayout,
  },
  {
    id: "invoices",
    title: "Invoices",
    description: "Invoices section",
    path: "invoices",
    component: Invoices,
    layout: PrivateLayout,
  },
  {
    id: "stickers",
    title: "Stickers",
    description: "Stickers section",
    path: "stickers",
    component: Stickers,
    layout: PrivateLayout,
  },
  {
    id: "msgs-contactless",
    title: "Messages Contactless",
    description: "Messages contactless section",
    path: "msgs-contactless",
    component: MessagesContactless,
    layout: PrivateLayout,
  },
  {
    id: "menu-statistics",
    title: "Menu Statistics",
    description: "Menu statistics section",
    path: "menu-stats",
    component: MenuStatistics,
    layout: PrivateLayout,
  },
  {
    id: "tutorial",
    title: "Tutorial",
    description: "Tutorial section",
    path: "tutorial",
    component: Tutorial,
    layout: PrivateLayout,
  },
];
