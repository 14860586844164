/* eslint-disable no-nested-ternary */
import React from "react";
import { useTranslation } from "react-i18next";

// Material UI
import { Paper, makeStyles } from "@material-ui/core";

const style = makeStyles(theme => ({
  containerLight: {
    padding: "5px 20px",
    borderRadius: "10px",
    backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    backgroundSize: "100%",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundColor: "#fff",
    fontWeight: "600",
    flex: "1",
    cursor: "pointer",
    textAlign: "center"
  },
  containerDark: {
    padding: "5px 20px",
    borderRadius: "10px",
    color: "#fff",
    fontWeight: "600",
    flex: "1",
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    cursor: "pointer",
    textAlign: "center",
    width: "auto"
  },
  containerDisabled: {
    padding: "5px 20px",
    borderRadius: "10px",
    color: "#fff",
    fontWeight: "600",
    flex: "1",
    background: "#a9a9a9",
    textAlign: "center"
  }
}));

const ChipButton = ({
  text,
  onClick,
  light,
  dataCy,
  customStyle,
  disabled
}) => {
  const classes = style();
  const { t } = useTranslation();

  return (
    <Paper
      elevation={3}
      data-cy={dataCy}
      className={
        disabled
          ? classes.containerDisabled
          : light
          ? classes.containerLight
          : classes.containerDark
      }
      onClick={disabled !== true ? onClick : null}
      style={customStyle}
    >
      {t(text).toUpperCase()}
    </Paper>
  );
};

ChipButton.defaultProps = {
  light: false,
  text: "Button label",
  onClick: () => console.log("Happy Clicking! :)"),
  customStyle: null,
  disabled: false
};

export default ChipButton;
