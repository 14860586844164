/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { postFunc, getFunc } from "Services/mainApiServices";
import Base64 from "Util/base64";
import { NotificationManager } from "react-notifications";
import { getDeviceInfo } from "Util/getDeviceInfo";
import { createID } from "Util/functions";
import { getAllLists } from "./List";
import { saveDevice } from "./App";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const LOGIN_REQ = "auth/LOGIN_REQ";
const LOGIN_SCS = "auth/LOGIN_SCS";
const LOGIN_FLR = "auth/LOGIN_FLR";

const LOGIN_ORGS = "auth/LOGIN_ORGS";

const LOGOUT_USER = "auth/LOGOUT_USER";

const UPDATE_CHAT_DATA = "auth/UPDATE_CHAT_DATA";

const GET_ORGANISATIONS_FOR_LOGGED_USER =
  "auth/GET_ORGANISATIONS_FOR_LOGGED_USER";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/

const listToTree = list => {
  const map = {};
  let node;
  const roots = [];
  let i;
  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    // eslint-disable-next-line no-param-reassign
    list[i].childRoutes = []; // initialize the children
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent !== "0") {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parent]].childRoutes.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const getOrgsForLoggedUser = () => async dispatch => {
  const response = await getFunc(`organisations/getAllAssigned`);

  if (response?.status?.errorCode === 200) {
    localStorage.setItem("orgs", JSON.stringify(response.data));
    dispatch({
      type: GET_ORGANISATIONS_FOR_LOGGED_USER,
      payload: response.data
    });
  }
};

export const login = (body, history, realTimeAPI) => async dispatch => {
  dispatch({ type: LOGIN_REQ });

  const response = await postFunc("admins/login", body);

  if (response.status.errorCode === 200) {
    let decode = Base64.decode(response.access_token.trim().split(".")[1]);
    // eslint-disable-next-line no-control-regex
    decode = JSON.parse(decode.replace(/[^\x01-\x7F]/g, ""));
    if (decode && response.access_token) {
      localStorage.setItem("user", JSON.stringify(decode));
      localStorage.setItem("data", JSON.stringify(response.data));
      localStorage.setItem("jwt-token", JSON.stringify(response.access_token));
      localStorage.setItem("meni", JSON.stringify(response.meni));
      localStorage.setItem("currentOrg", JSON.stringify(response.org));
      localStorage.setItem(
        "org_type_id",
        JSON.stringify(response.org?.th_org?.org_type_id)
      );
      localStorage.setItem(
        "selectedOrg",
        JSON.stringify(decode.organisationID)
      );
      dispatch(getAllLists());

      const deviceInfo = getDeviceInfo();
      dispatch(saveDevice(deviceInfo));

      dispatch({ type: LOGIN_SCS, payload: { user: decode, data: response } });
      const lowestPriority = response.meni?.reduce(
        (min, p) => (p.priority < min.priority ? p : min),
        response.meni[0]
      );
      history.push(lowestPriority?.linkTo);
    }
  } else if (response.status.errorCode === 1) {
    dispatch({ type: LOGIN_ORGS, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
    dispatch({ type: LOGIN_FLR });
  }
};

export const renewToken = async () => {
  const response = await getFunc("admins/renew");

  if (response.status.errorCode === 200) {
    let decode = Base64.decode(response.access_token.trim().split(".")[1]);
    // eslint-disable-next-line no-control-regex
    decode = JSON.parse(decode.replace(/[^\x01-\x7F]/g, ""));
    if (decode && response.access_token) {
      localStorage.setItem("user", JSON.stringify(decode));
      localStorage.setItem("data", JSON.stringify(response.data));
      localStorage.setItem("jwt-token", JSON.stringify(response.access_token));
      localStorage.setItem("meni", JSON.stringify(response.meni));
      localStorage.setItem("currentOrg", JSON.stringify(response.org));
      localStorage.setItem(
        "org_type_id",
        JSON.stringify(response.org?.th_org?.org_type_id)
      );
    }
  }
};

export const logOut = (history, realTimeAPI) => dispatch => {
  dispatch({ type: LOGOUT_USER });
  localStorage.clear();
  history.push("/");
  realTimeAPI.disconnect();
};

export const changeOrganisation = (id, history) => async dispatch => {
  const response = await getFunc(`admins/changeOrganization/${id}`);

  if (response.status.errorCode === 200) {
    let decode = Base64.decode(response.access_token.trim().split(".")[1]);
    // eslint-disable-next-line no-control-regex
    decode = JSON.parse(decode.replace(/[^\x01-\x7F]/g, ""));
    if (decode && response.access_token) {
      localStorage.setItem("user", JSON.stringify(decode));
      localStorage.setItem("data", JSON.stringify(response.data));
      localStorage.setItem("jwt-token", JSON.stringify(response.access_token));
      localStorage.setItem("meni", JSON.stringify(response.meni));
      localStorage.setItem("currentOrg", JSON.stringify(response.org));
      localStorage.setItem(
        "org_type_id",
        JSON.stringify(response.org?.th_org?.org_type_id)
      );
      localStorage.setItem(
        "selectedOrg",
        JSON.stringify(decode.organisationID)
      );
      dispatch(getAllLists());
      dispatch({ type: LOGIN_SCS, payload: { user: decode, data: response } });
      const lowestPriority = response.meni.reduce(
        (min, p) => (p.priority < min.priority ? p : min),
        response.meni[0]
      );
      history.push(lowestPriority.linkTo);
      window.location.reload();
    }
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const updateChatData = data => async dispatch => {
  dispatch({
    type: UPDATE_CHAT_DATA,
    payload: data
  });
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/

const INIT_STATE = {
  user: JSON.parse(localStorage.getItem("user")),
  data: JSON.parse(localStorage.getItem("data")),
  orgs: JSON.parse(localStorage.getItem("orgs")),
  org: JSON.parse(localStorage.getItem("currentOrg")),
  loginOrgs: [],
  meni:
    localStorage.getItem("meni") === null
      ? []
      : listToTree(JSON.parse(localStorage.getItem("meni"))),
  org_type_id: JSON.parse(localStorage.getItem("org_type_id")),
  loading: false
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case LOGIN_REQ:
      return {
        ...state,
        loadnig: true,
        items: {}
      };
    case LOGIN_SCS:
      return {
        ...state,

        loading: false,
        user: action.payload.user,
        data: action.payload.data.data,
        org: action.payload.data.org,
        meni: listToTree(action.payload.data.meni),
        loginOrgs: []
      };
    case LOGIN_FLR:
      return {
        ...state,
        loading: false
      };
    case GET_ORGANISATIONS_FOR_LOGGED_USER:
      return {
        ...state,
        orgs: action.payload
      };
    case LOGIN_ORGS:
      return {
        ...state,
        loginOrgs: action.payload
      };
    case LOGOUT_USER:
      return {
        ...state,
        loginOrgs: [],
        meni: [],
        user: null,
        org: null,
        data: null,
        orgs: null
      };
    case UPDATE_CHAT_DATA:
      if (state.org.th_org.org_core_id === action.payload.org_core_id) {
        localStorage.setItem(
          "currentOrg",
          JSON.stringify({
            ...state.org,
            th_org: {
              ...state.org.th_org,
              chat_auth_token: action.payload.chat_auth_token,
              chat_user_id: action.payload.chat_user_id,
              chat_password: action.payload.chat_password
            }
          })
        );
        return {
          ...state,
          org: {
            ...state.org,
            th_org: {
              ...state.org.th_org,
              chat_auth_token: action.payload.chat_auth_token,
              chat_user_id: action.payload.chat_user_id,
              chat_password: action.payload.chat_password
            }
          }
        };
      }
      return { ...state };
    default:
      return state;
  }
}
