// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Brown Regular Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Brown Regular Regular.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face {\n\tfont-family: \"Brown Regular\";\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");\n}\n\n/*.compact-picker {\n  max-width: 210px !important;\n}*/\n\n.video-responsive {\n\toverflow: hidden;\n\tpadding-bottom: 56.25%;\n\tposition: relative;\n\theight: 0;\n}\n\n.video-responsive iframe {\n\tleft: 0;\n\ttop: 0;\n\theight: 100%;\n\twidth: 100%;\n\tposition: absolute;\n}\n", ""]);
// Exports
module.exports = exports;
