/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { postFunc, putFunc, deleteFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";
/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_ALL_TENANTS_REQ = "auth/GET_ALL_TENANTS_REQ";
const GET_ALL_TENANTS_SCS = "auth/GET_ALL_TENANTS_SCS";
const GET_ALL_TENANTS_FLR = "auth/GET_ALL_TENANTS_FLR";
const UPDATE_TENANT = "auth/UPDATE_TENANT";

/**
 * ACTIONS
 */
export const getAllTenants = body => async dispatch => {
  dispatch({ type: GET_ALL_TENANTS_REQ });
  const response = await postFunc("tenant/pagination", body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_TENANTS_SCS,
      payload: response.data,
      totalTenants: response.total
    });
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
      dispatch({ type: GET_ALL_TENANTS_FLR });
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const updateTenant = (body, id, closeDialog) => async dispatch => {
  const response = await putFunc(`tenant/${id}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: UPDATE_TENANT, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });
    closeDialog();
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const verifyTenant = (body, id) => async dispatch => {
  const response = await putFunc(`tenant/${id}/verifyTenant`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: UPDATE_TENANT, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const activateTenant = (id, setCheck) => async dispatch => {
  const response = await putFunc(`tenant/activateTenant/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: UPDATE_TENANT, payload: response.data });
    setCheck(response.data.status);
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const deactivateTenant = (id, setCheck) => async dispatch => {
  const response = await deleteFunc(`tenant/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: UPDATE_TENANT, payload: response.data });
    setCheck(response.data.status);
  } else {
    NotificationManager.error(response.status.description);
  }
};

/**
 * REDUCERS
 */
const INIT_STATE = {
  loading: false,
  allTenants: [],
  totalTenants: 0
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_TENANTS_REQ:
      return {
        ...state,
        loading: true,
        allTenants: state.data
      };
    case GET_ALL_TENANTS_SCS:
      return {
        ...state,
        loading: false,
        allTenants: action.payload,
        totalTenants: action.totalTenants
      };
    case GET_ALL_TENANTS_FLR:
      return {
        ...state,
        loading: false,
        allTenants: state.data
      };

    case UPDATE_TENANT:
      return {
        ...state,
        allTenants: state.allTenants.map(item => {
          if (item.tenant_id === action.payload.tenant_id) {
            return { ...action.payload };
          }
          return item;
        })
      };

    default:
      return state;
  }
}
