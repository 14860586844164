/**
 * IMPORTS
 */

import { getFunc, postFunc } from "Services/mainApiServices";

/**
 * TYPES
 */

const GET_THEME = "app/GET_THEME";

const GET_ACTIVE_LANGUAGES = "app/GET_ACTIVE_LANGUAGES";

const SAVE_DEVICE = "app/SAVE_DEVICE";

const SEND_SUBSCRIPTION = "app/SEND_SUBSCRIPTION";

/**
 * ACTIONS
 */

export const getTheme = () => async dispatch => {
  const response = await getFunc("tenants/templateDetails");

  if (response.status.errorCode === 200) {
    localStorage.setItem("theme", JSON.stringify(response.data));
    dispatch({ type: GET_THEME, payload: response.data });
  }
};

export const getActiveLanguages = () => async dispatch => {
  const response = await getFunc(`tenantLanguage/getAllActive`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ACTIVE_LANGUAGES,
      payload: response.data
    });
  }
};

export const saveDevice = info => async dispatch => {
  const response = await postFunc(`saveDevice`, {
    device: info,
    user_client_id: "af65a3dd-882e-49f2-9052-2a593a6eac0d"
  });

  if (response.status.errorCode === 200) {
    dispatch({
      type: SAVE_DEVICE
    });
  }
};

export const sendSubscriptionToServer = body => async dispatch => {
  const response = await postFunc(`saveSubscription`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: SEND_SUBSCRIPTION
    });
  }
};

/**
 * REDUCERS
 */

const INIT_STATE = {
  theme: JSON.parse(localStorage.getItem("theme")),
  activeLangs: []
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_THEME:
      return {
        ...state,
        theme: action.payload
      };
    case GET_ACTIVE_LANGUAGES:
      return {
        ...state,
        activeLangs: action.payload
      };
    default:
      return state;
  }
}
