// React
import React from "react";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles(theme => ({
  line: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    height: "2px",
    left: "0px",
    top: "113.04%",
    bottom: "-13.04%"
  }
}));

const Line = props => {
  const { marginBottom, lineWidth, variant, color, dataCy } = props;
  const classes = style();

  return (
    <div
      style={{
        width: `${lineWidth}`,
        marginBottom: `${marginBottom}`
      }}
      className={classes.line}
      variant={variant}
      color={color}
      data-cy={dataCy}
    />
  );
};

export default Line;
