/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, postFunc, putFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const GET_ALL_INT_REQ = "auth/GET_ALL_INT_REQ";
const GET_ALL_INT_SCS = "auth/GET_ALL_INT_SCS";
const GET_ALL_INT_FLR = "auth/GET_ALL_INT_FLR";

const GET_ONE_BY_PROV_REQ = "auth/GET_ONE_BY_PROV_REQ";
const GET_ONE_BY_PROV_SCS = "auth/GET_ONE_BY_PROV_SCS";
const GET_ONE_BY_PROV_FLR = "auth/GET_ONE_BY_PROV_FLR";

const CREATE_OR_ALTER_INTEGRATION = "auth/CREATE_OR_ALTER_INTEGRATION";

const ALTER_INTEGRATION_STATUS = "auth/ALTER_INTEGRATION_STATUS";

const GET_ALL_STATUSES_REQ = "auth/GET_ALL_STATUSES_REQ";
const GET_ALL_STATUSES_SCS = "auth/GET_ALL_STATUSES_SCS";
const GET_ALL_STATUSES_FLR = "auth/GET_ALL_STATUSES_FLR";

const GET_ALL_ORDER_STATES_REQ = "auth/GET_ALL_ORDER_STATES_REQ";
const GET_ALL_ORDER_STATES_SCS = "auth/GET_ALL_ORDER_STATES_SCS";
const GET_ALL_ORDER_STATES_FLR = "auth/GET_ALL_ORDER_STATES_FLR";

const ALTER_ORDERING_PROCESS_STATE = "auth/ALTER_ORDERING_PROCESS_STATE";

const CREATE_ORDERING_PROCESS_INT = "auth/CREATE_ORDERING_PROCESS_INT";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/

export const getAllStatusesByIntegrationType = (org, int) => async (
	dispatch
) => {
	dispatch({ type: GET_ALL_INT_REQ });
	const response = await getFunc(
		`integrations/getAllStatusesByIntegrationType/${org}/${int}`
	);

	if (response.status.errorCode === 200) {
		dispatch({ type: GET_ALL_INT_SCS, payload: response.data });
	} else {
		dispatch({ type: GET_ALL_INT_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const getOneIntegrationByProvider = (org, provider) => async (
	dispatch
) => {
	dispatch({ type: GET_ONE_BY_PROV_REQ });
	const response = await getFunc(
		`integrations/getOneByIntegartionProvider/${org}/${provider}`
	);

	if (response.status.errorCode === 200) {
		dispatch({ type: GET_ONE_BY_PROV_SCS, payload: response.data });
	} else {
		dispatch({ type: GET_ONE_BY_PROV_FLR });
		//NotificationManager.error(response.status.description);
	}
};

export const createOrUpdateIntegration = (body, successFunc) => async (
	dispatch
) => {
	const response = await postFunc("integrations", body);

	if (response.status.errorCode === 200) {
		dispatch({ type: CREATE_OR_ALTER_INTEGRATION, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const alterIntegrationStatus = (body, successFunc) => async (
	dispatch
) => {
	const response = await putFunc("integrations/changeStatus", body);

	if (response.status.errorCode === 200) {
		dispatch({ type: ALTER_INTEGRATION_STATUS, payload: response.data });
		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		}
	}
};

export const getAllStatusesForOrderingProcessIntegrations = (org) => async (
	dispatch
) => {
	dispatch({ type: GET_ALL_STATUSES_REQ });
	const response = await getFunc(
		`orderingProcessIntegration/getAllStatuses/${org}`
	);
	if (response.status.errorCode === 200) {
		dispatch({ type: GET_ALL_STATUSES_SCS, payload: response.data });
	} else {
		dispatch({ type: GET_ALL_STATUSES_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const getAllOrderingProcessStatesForAdministration = (org) => async (
	dispatch
) => {
	dispatch({ type: GET_ALL_ORDER_STATES_REQ });
	const response = await getFunc(
		`organisation/getAllOrderingProcessStatesForAdministration/${org}`
	);
	if (response.status.errorCode === 200) {
		dispatch({ type: GET_ALL_ORDER_STATES_SCS, payload: response.data });
	} else {
		dispatch({ type: GET_ALL_ORDER_STATES_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const changeOrderingProcessState = (body, org) => async (dispatch) => {
	const response = await putFunc(
		`organisations/changeOrderingProcessState/${org}`,
		body
	);
	if (response.status.errorCode === 200) {
		dispatch({ type: ALTER_ORDERING_PROCESS_STATE, payload: response.data });
		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		}
	}
};

export const createOrderingProcessIntegration = (body, org) => async (
	dispatch
) => {
	const response = await putFunc(`orderingProcessIntegration/${org}`, body);
	if (response.status.errorCode === 200) {
		dispatch({ type: CREATE_ORDERING_PROCESS_INT, payload: response.data });
		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		}
	}
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/

const INIT_STATE = {
	data: [],
	oneIntegration: {},
	statusList: [],
	orderingProcessStates: [],
	loading: false,
};

export default function reducer(state = INIT_STATE, action = {}) {
	switch (action.type) {
		case GET_ALL_INT_REQ:
			return {
				...state,
				loading: true,
				data: [],
			};
		case GET_ALL_INT_SCS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case GET_ALL_INT_FLR:
			return {
				...state,
				loading: false,
				data: state.data,
			};
		case GET_ONE_BY_PROV_REQ:
			return {
				...state,
				loading: true,
				oneIntegration: {},
			};
		case GET_ONE_BY_PROV_SCS:
			return {
				...state,
				loading: false,
				oneIntegration: action.payload,
			};
		case GET_ONE_BY_PROV_FLR:
			return {
				...state,
				loading: false,
				oneIntegration: state.oneIntegration,
			};
		case CREATE_OR_ALTER_INTEGRATION:
			return {
				...state,
				loading: false,
				oneIntegration: action.payload,
			};
		case ALTER_INTEGRATION_STATUS:
			return {
				...state,
				loading: false,
				oneIntegration: action.payload,
			};
		case GET_ALL_STATUSES_REQ:
			return {
				...state,
				loading: true,
				statusList: [],
			};
		case GET_ALL_STATUSES_SCS:
			return {
				...state,
				loading: false,
				statusList: action.payload,
			};
		case GET_ALL_STATUSES_FLR:
			return {
				...state,
				loading: false,
				statusList: state.statusList,
			};
		case GET_ALL_ORDER_STATES_REQ:
			return {
				...state,
				loading: true,
				orderingProcessStates: [],
			};
		case GET_ALL_ORDER_STATES_SCS:
			return {
				...state,
				loading: false,
				orderingProcessStates: action.payload,
			};
		case GET_ALL_ORDER_STATES_FLR:
			return {
				...state,
				loading: false,
				orderingProcessStates: state.orderingProcessStates,
			};
		case ALTER_ORDERING_PROCESS_STATE:
			return {
				...state,
				loading: false,
				orderingProcessStates: action.payload,
			};
		case CREATE_ORDERING_PROCESS_INT:
			return {
				...state,
				loading: false,
				statusList: action.payload,
			};
		default:
			return state;
	}
}
