import React from "react";
import { useTheme } from "@material-ui/core";

export const MenuPromotionsHelpImg = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  return (
    <svg
      id="be4c2388-7845-4faa-a392-000b3bb95634"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="200px"
      viewBox="0 0 724.72381 476.68454"
    >
      <path
        d="M945.86191,646.20558H305.0234a16.51867,16.51867,0,0,1-16.5-16.5V234.55177a12.10185,12.10185,0,0,1,12.0879-12.08837H950.06479a12.31112,12.31112,0,0,1,12.29712,12.29736V629.70558A16.51867,16.51867,0,0,1,945.86191,646.20558Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#f2f2f2"
      />
      <path
        d="M925.1421,625.37326H327.89735c-8.755,0-15.87757-6.66992-15.87757-14.86816v-344.105c0-6.08008,5.278-11.02686,11.76563-11.02686H929.05909c6.59522,0,11.96069,5.02832,11.96069,11.2085V610.5051C941.01978,618.70334,933.897,625.37326,925.1421,625.37326Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#fff"
      />
      <path
        d="M962.13217,236.60793H288.29184v-8.40039a16.57377,16.57377,0,0,1,16.56006-16.54981H945.57211a16.57369,16.57369,0,0,1,16.56006,16.54981Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#3f3d56"
      />
      <circle cx="80.7491" cy="12.5" r="4.28342" fill="#fff" />
      <circle cx="97.00791" cy="12.5" r="4.28342" fill="#fff" />
      <circle cx="113.26671" cy="12.5" r="4.28342" fill="#fff" />
      <rect
        x="253.83858"
        y="136.38163"
        width="324"
        height="41.02752"
        rx="20.51374"
        fill="#e6e6e6"
      />
      <rect
        x="253.83858"
        y="204.27042"
        width="324"
        height="41.02752"
        rx="20.51374"
        fill="#e6e6e6"
      />
      <rect
        x="253.83858"
        y="272.15922"
        width="324"
        height="41.02752"
        rx="20.51374"
        fill="#e6e6e6"
      />
      <circle cx="217.83858" cy="224.78419" r="21" fill="#e6e6e6" />
      <path
        d="M462.97469,434.99558l-6.37647-6.85809a1.53046,1.53046,0,0,0-2.2417-.00018l-6.37744,6.85827a1.00075,1.00075,0,1,0,1.46582,1.36286l5.03077-5.41211v13.28845a1,1,0,0,0,2,0V430.94242l5.03271,5.41583a1.00087,1.00087,0,0,0,1.46631-1.36267Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#fff"
      />
      <circle cx="217.83858" cy="292.67298" r="21" fill="#e6e6e6" />
      <path
        d="M462.97469,502.88438l-6.37647-6.8581a1.53047,1.53047,0,0,0-2.2417-.00018l-6.37744,6.85828a1.00075,1.00075,0,1,0,1.46582,1.36285l5.03077-5.41211v13.28845a1,1,0,0,0,2,0V498.83121l5.03271,5.41584a1.00087,1.00087,0,0,0,1.46631-1.36267Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#fff"
      />
      <circle cx="217.83858" cy="156.89539" r="21" fill={color} />
      <path
        d="M462.97469,367.10679l-6.37647-6.85809a1.53046,1.53046,0,0,0-2.2417-.00019l-6.37744,6.85828a1.00075,1.00075,0,1,0,1.46582,1.36285l5.03077-5.4121V376.346a1,1,0,0,0,2,0V363.05363l5.03271,5.41583a1.00087,1.00087,0,0,0,1.46631-1.36267Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#fff"
      />
      <path
        d="M435.7848,367.99223a10.05579,10.05579,0,0,0-10.98633,10.81926l-32.70725,14.395,15.60168,10.07083,28.66745-15.21368a10.11027,10.11027,0,0,0-.57555-20.07138Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#a0616a"
      />
      <circle cx="66.21514" cy="113.81662" r="24.56103" fill="#a0616a" />
      <polygon
        points="110.667 464.031 122.927 464.03 128.759 416.742 110.665 416.743 110.667 464.031"
        fill="#a0616a"
      />
      <path
        d="M345.1779,671.68572l24.1438-.001h.001A15.38605,15.38605,0,0,1,384.70915,687.071v.5l-39.53052.00147Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#2f2e41"
      />
      <polygon
        points="59.667 464.031 71.927 464.03 77.759 416.742 59.665 416.743 59.667 464.031"
        fill="#a0616a"
      />
      <path
        d="M294.1779,671.68572l24.1438-.001h.001A15.38605,15.38605,0,0,1,333.70915,687.071v.5l-39.53052.00147Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#2f2e41"
      />
      <path
        d="M294.91,664.49169a4.50138,4.50138,0,0,1-1.52441-3.36035l-.57691-177.20117,64.02222-2.46777.47046-.01758,10.52881,106.292,1.31933,67.71387a4.4851,4.4851,0,0,1-3.97314,4.55664l-15.54053,1.8291a4.498,4.498,0,0,1-5.0227-4.29492L341.8465,585.605l-13.43432-44.78418a1.49993,1.49993,0,0,0-2.85742.55371l-5.2295,117.76368a4.49288,4.49288,0,0,1-3.94433,4.23535l-17.9458,2.209a4.48882,4.48882,0,0,1-3.52515-1.09082Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#2f2e41"
      />
      <path
        d="M290.86482,490.21923l-18.968-77.0293c-5.9104-24.002,7.59106-48.16894,30.09717-53.87207a38.19591,38.19591,0,0,1,31.10815,5.54688,44.13237,44.13237,0,0,1,18.68018,30.792l12.39013,91.19141a4.49977,4.49977,0,0,1-4.34106,5.10449l-64.479,1.68945c-.0393.001-.07836.001-.11743.001A4.52648,4.52648,0,0,1,290.86482,490.21923Z"
        transform="translate(-237.63809 -211.65773)"
        fill={color}
      />
      <path
        d="M370.32575,419.05517l-48.08813-23.35742A16.1546,16.1546,0,1,1,338.68391,368.02l38.90869,27.78614,42.00171-16.72559a4.4838,4.4838,0,0,1,4.93164,1.08691l5.66455,5.98047a4.4992,4.4992,0,0,1-.79883,6.85645l-37.60229,24.66992a21.88163,21.88163,0,0,1-21.46363,1.38086Z"
        transform="translate(-237.63809 -211.65773)"
        fill={color}
      />
      <path
        d="M294.35448,348.49409a12.909,12.909,0,0,1-5.5959-1.15576c-.78324-.34456-1.5963-.62778-2.3781-.97258-6.9044-3.04508-11.45259-11.43906-11.61548-19.88649a30.30525,30.30525,0,0,1,9.35978-22.0498,33.70984,33.70984,0,0,1,20.44153-8.819c7.94994-.691,16.90182,1.61043,21.34416,9.16826a8.8451,8.8451,0,0,1,1.30879,6.8816,4.50415,4.50415,0,0,1-1.08694,1.90593c-1.98389,2.01487-3.96173.49989-5.98413.366-2.77978-.184-5.27651,2.38457-6.17387,5.39355s-.50235,6.32162.22221,9.39069l.01338.05705a5.14939,5.14939,0,0,1-4.56,6.27706c-3.05729.27579-6.41581.44756-7.37454-.00212-1.87739-.88058-4.39968-.68526-5.4924,1.26729a7.61014,7.61014,0,0,0-.69437,2.28861c-.97962,5.09485-.75447,4.7948-1.73409,9.88965Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#2f2e41"
      />
      <path
        d="M287.40824,531.09575a10.05576,10.05576,0,0,0-3.5733-14.99958l4.60642-35.4367-16.69083,8.1394-1.81545,32.40343a10.11028,10.11028,0,0,0,17.47316,9.89345Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#a0616a"
      />
      <path
        d="M289.32389,509.50825a4.60067,4.60067,0,0,1-.93749.2197l-16.84541,2.13a4.49991,4.49991,0,0,1-5.06431-4.46927l.133-117.62167a14.26532,14.26532,0,0,1,12.16015-14.02689h0a14.18887,14.18887,0,0,1,16.14969,15.40629l-4.894,50.68831,2.29392,63.26619A4.47425,4.47425,0,0,1,289.32389,509.50825Z"
        transform="translate(-237.63809 -211.65773)"
        fill={color}
      />
      <path
        d="M444.63809,688.34227h-206a1,1,0,0,1,0-2h206a1,1,0,1,1,0,2Z"
        transform="translate(-237.63809 -211.65773)"
        fill="#ccc"
      />
    </svg>
  );
};
