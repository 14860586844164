module.exports = {
  /// validacija:
  "Field must be between 2 and 100 characters long":
    "Polje mora sadržavati između 2 i 100 znakova",
  "Email is not in the correct format": "E-pošta nije u ispravnom formatu",
  "Field must be between 2 and 30 characters long":
    "Polje mora sadržavati između 2 i 30 znakova",
  "Not a valid UUID.": "Odaberi stavku sa liste",
  "Field must be between 2 and 40 characters long":
    "Polje mora sadržavati između 2 i 40 znakova",
  "Password to short.": "Zaporka je prekratka",
  "Password must contain at least one uppercase letter.":
    "Zaporka mora sadržavati bar jedno veliko slovo.",
  "Restriction must be in range of 1-200":
    "Ograničenje mora biti u intervalu od 1 do 200",
  "Stars must be in range of 1-200":
    "Zvijezde moraju biti u intervalu od 1 do 200",
  "Deadline must be in range of 1-1000 minutes":
    "Rok za otkazivanje mora biti u intervalu od 1 do 1000 minuta",
  "Field is required": "Polje je obavezno",
  "Field may not be null.": "Polje je obavezno.",
  "Field must be between 6 and 128 characters long":
    "Polje mora sadržavati između 6 i 128 znakova",
  "Field must be between 2 and 200 characters long":
    "Polje mora sadržavati između 2 i 200 znakova",
  // general
  "Welcome to React": "Dobro došli!",
  Username: "Korisničko ime",
  Password: "Zaporka",
  Submit: "Prijavite se",
  "Send e-mail": "Pošalji e-mail",
  ForgotPasswordHeader: "Zaboravili ste zaporku?",
  ForgotPasswordSubtitle:
    "Ne brinite! Ponovno postavljanje zaporke je jednostavno. Samo upišite email koji ste registrirali u TripleHost",
  RememberedPassword: "Sjećate se svoje zaporke?",
  "Try logging in": "Pokušajte se prijaviti",
  "Log out": "Odjava",
  Verification: "Verifikacija",
  Date: "Datum",
  "Total pending orders": "Narudžbe na čekanju",
  Organisation: "Organizacija",
  Refresh: "Osvježi",
  "Add item": "Dodaj",
  "Add item | Order": "Dodaj | Narudžba",
  "What do you want to add": "Što želite dodati?",
  "Edit item": "Uredi",
  "Edit item | Order": "Uredi | Narudžba",
  Decline: "Odbij",
  Accept: "Prihvati",
  Delete: "Izbriši",
  Save: "Spremi",
  Add: "Dodaj",
  "Add + Add another": "Dodaj + Dodaj još",
  Actions: "Akcije",
  Tenants: "Tenanti",
  Search: "Pretraži",
  rows: "redova",
  "No records to display": "Nema zapisa za prikaz",
  Domain: "Domena",
  GuestTable: "Gost",
  Service: "Usluga",
  service: "usluga/artikl",
  subcategory: "podkategorija",
  category: "kategorija",
  "No options": "Nema opcija",
  TimeReservation: "Vrijeme",
  Verify: "Potvrditi",
  confirmEmailText: "Poslali smo vam email s novom zaporkom za vaš račun.",
  "Back to login": "Povratak na login",
  Help: "Pomoć",
  "Add new organisation": "Dodaj novu organizaciju",
  "New sub-organisation": "Nova pod-organizacija",
  "There are no sub-organisations": "Nema pod-organizacija",
  "Not a valid number.": "Unos nije valjan.",
  "delete prices for selected dates": "izbriši cijene za odabrane datume",
  "Double room": "Dvokrevetna soba",
  "portal profile": "profil portala",
  "accomodation informations": "informacije o smještaju",
  pricing: "cijene",
  apply: "primijeni",
  "You cannot set prices on the past dates.":
    "Ne možete postavljati cijenu na datume u prošlosti.",
  Price: "Cijena",
  "Price can not be negative": "Iznos ne može biti niži od 0",
  "Item Description": "Unesi opis artikla ispod:",
  deleteServiceText:
    "Ovom akcijom ćete izbrisati odabrani artikl/uslugu. Jeste li sigurni da želite nastaviti?",
  deleteSubcategoryText:
    "Ovom akcijom ćete izbrisati odabranu podkategoriju. Jeste li sigurni da želite nastaviti?",
  deleteCategoryText:
    "Ovom akcijom ćete izbrisati odabranu kategoriju. Jeste li sigurni da želite nastaviti?",
  "Edit sub-category": "Uredi podkategoriju",
  Title: "Naslov",
  Description: "Opis",
  Details: "Detalji",
  DETAILS: "DETALJI",
  Picture: "Fotografija",
  Icon: "Ikona",
  AUDIENCE: "PUBLIKA",
  RUN: "POKRENI",
  Back: "Natrag",
  Next: "Sljedeći",
  Finish: "Završi",
  Name: "Ime",
  Lastname: "Prezime",
  Tag: "Tag",
  CAMPAIGN: "KAMPANJE",
  "Subscription status": "Status pretplate",
  YES: "DA",
  NO: "NE",
  Stays: "Odsjeo",
  Current: "Trenutno",
  "Who stayed x days ago": "Odsjeo prije x dana",
  Orders: "Narudžbe",
  "Bought something": "Kupio nešto",
  "Never bought anything": "Nikad nije kupio ništa",
  "Had more than x orders": "Imao više od x narudžbi",
  Campaigns: "Kampanje",
  "Has enabled notifications": "Dopustio obavijesti",
  "Never been sent a campaign": "Nikad nije poslana kampanja",
  "Were sent up to x campaigns": "Poslano x kampanja",
  UPLOAD: "UČITAJ",
  "Reset filter": "Očisti filter",
  "Campaign name": "Naziv kampanje",
  "Campaign preview": "Pregled kampanje",
  "campaign will include": "kampanja će uključiti",
  "guest/s": "gost/i",
  "How it will look on Android:": "Kako će izgledati na Android-u:",
  now: "sad",
  "Show price": "Prikaži cijenu",
  "Row name": "Naziv retka",
  Size: "Veličina",
  Tags: "Tagovi",
  Info: "Obavijest",
  "Delete service": "Izbriši artikl",
  "Delete sub-category": "Izbriši podkategoriju",
  "Delete category": "Izbriši kategoriju",
  "Random tag order": "Slučajni redoslijed tagova",
  "You have successfully deleted category":
    "Uspješno ste izbrisali kategoriju!",
  "You have successfully deleted sub-category":
    "Uspješno ste izbrisali podkategoriju!",
  "You have successfully deleted item": "Uspješno ste izbrisali artikl!",
  Remark: "Napomena",
  Quantity: "Količina",
  "Edit guest": "Uredi gosta",
  organisations_helptext: `
  <p>
          Ovdje možeš upravljati svojim <strong>organizacijama</strong>.
          Možeš dodati i dodijeliti im <strong>pod-organizaciju</strong>.<br><br>
          Bilo da kreiraš jednu ili drugu, moraš im postaviti <strong>grupu</strong>.
          Nakon toga, potrebno je ispuniti sve informacije, kao na prvom registriranju.
          <br><br> Možeš promijeniti informacije o organizaciji tako što ćeš kliknuti na ikonu za  
          <strong>postavke</strong> u gornjem desnom kutu. <br><br> 
          Ondje možeš promijeniti osnovne informacije, lokaciju, slike, dodjeljene dodatke i pravila.
          <br><br> Također, možeš definirati
          <strong>radne sate</strong>
          svoje organizacije. Gosti mogu praviti narudžbe samo u tim terminima.
</p>`,
  services_helptext: `
<p>
          Ovdje možeš dodati sve <strong>usluge</strong> i
          <strong>artikle</strong> koji će biti dostupni u Guest aplikaciji. 
          <br><br> Prvo je potrebno dodati <strong>kategoriju</strong>.
          Odaberi ikonu koja će najbolje predstavaljati kategoriju, ime, tip rezervacije 
          (rezervacija, narudžba, prilog) i predložak (kako će usluga ili artikl biti prikazan na Guest aplikaciji).
          <br><br> 
          Nakon toga, trebao bi dodati <strong>pot-kategoriju</strong>. Klikni na kreiranu kategoriju i potom klikni na gumb za dodavanje. 
          Postavi ime pot-kategoriji i onda joj možeš dodavati <strong>artikle</strong>. <br><br>
          Na primjer: Hrana (Kategorija) >> Pizza
          (Pot-kategorija) >> Margherita (Artikl) Za ovaj primjer Tip rezervacije i Predložak je Narudžba (Order).
          <br><br> Na primjer: Spa
          (Kategorija) >> Masaža (Pot-kategorija) >> Kraljevska masaža (Usluga) 
          Za ovaj primjer Tip rezervacije i Predložak je Rezervacija (Appointment).
</p>`,
  users_helptext: `
<p>
          Ovdje možeš vidjeti, kreirati i uređivati <strong>korisnike</strong> sustava. <br><br> 
          Možeš dodati korisnika <strong>određenoj organizaciji</strong> klikom na Uređivanje na kraju reda, i onda promijeniti organizaciju na modalu. 
          Potraži određenu organizaciju koju želiš dodijeliti odabranom korisniku. <br><br> 
          Također, možeš dodijeliti i <strong>role/uloge</strong> korisniku na isti način kao i kod dodjele organizacija.
</p>`,
  orders_helptext: `
<p>
          Na ovom ekranu možeš pratiti svoje <strong>narudžbe</strong>. <br><br> 
          Imaš tri stupca: Neriješeno, U tijeku i Završeno. <br><br> Možeš odabrati datum za koji će biti prikazane narudžbe. 
          Također, možeš pregledavati i po organizaciji.
          <br><br> Sve <strong>nadolazeće narudžbe</strong> će biti u stupcu <strong>Neriješeno</strong>, 
          čekajući da ih prihvatiš ili odbiješ.
          Iz tog stupca možeš ih prebaciti u stupce U tijeku ili Završeno tako što ćeš ih <strong>povući</strong> ili kliknuti gumb na dnu narudžbe.<br><br>          
          Jednom kada je narudžba završena, ne možeš ju prebacivati u druge stupce. <br><br> Također, možeš uređivati narudžbu ako je netočna: dodati joj novi artikl ili izbrisati odabrani.
</p>`,
  appointments_helptext: `
<p>
          Ovdje možeš upravljati <strong>rezervacijama</strong>. <br><br> 
          Nakon što gost napravi rezervaciju, možeš ju vidjeti među <strong>neriješenim</strong> rezervacijama. Također, možeš i sam/a kreirati rezervaciju! <br><br> 
          Da bi rezervaciju napravljenu od strane gosta prihvatio ili ukoliko kreiraš novu rezervaciju, prvo trebaš imati <strong>kalendar</strong>.
          Ako nemaš, klikni na <strong>Dodaj novi kalendar</strong>, 
          napiši osnovne informacije o njemu i postavi mu <strong>radne dane</strong> i pauze!
          <br><br> 
          Možeš dodati više kalendara ovisno o svojim potrebama. <br><br> Na primjer, Kalendar 1 - Radnik 1 i Kalendar 2 - Radnik 2.
</p>`,
  accomodation_helptext: `
<p>
          Na ovom ekranu možeš upravljati svojim <strong>tipovima smještaja</strong>.<br><br> 
          <strong>Dodaj</strong> nove i <strong>uređuj</strong> postojeće. <br><br> 
          Nakon dodavanja nove smještajne jedinice možeš joj dodijeliti <strong>sobe</strong>. <br><br> 
          Na primjer, Dvokrevetna soba (smještajna jedinica) >> 301, 302... (Sobe) <br><br> 
          Za smještajne jedinice možeš uređivati osnovne informacije, dodavati fotografije i dodjeljivati sadržaje.
</p>`,
  reservations_helptext: `
<p>
          Ovdje možeš upravljati <strong>rezervacijama</strong> napravljenim od strane gosta. <br><br> 
          Dodaj <strong>nositelja</strong> rezervacije i dodijeli rezervaciju određenoj sobi.
          Prije nego dodaš nositelja, prvo ga moraš kreirati/dodati u sustav, ako ne postoji.
          Nakon toga odaberi tip smještaja (ako već nije odabran) i odaberi sobu kojoj žeiš <strong>dodijeliti</strong> rezervaciju. <br><br> 
          Ako je sve dobro prošlo, rezervacija bi trebala biti vidljiva na <strong>rasporedu</strong> lijevo.
          Nakon što klikneš na rezervaciju, trebao bi vidjeti njene detalje uključujući i <strong>ključ za Guest aplikaciju</strong>
          koji je potreban da bi se gost prijavio u Guest aplikaciju. <br><br> 
          Dva su tipa rezervacija, <strong>preko sustava</strong> i <strong>preko portala</strong>. 
          Rezervacija preko sustava je dodana sa gumbom Dodaj novu rezervaciju, a preko portala je dodana preko booking portala.
</p>`,
  campaign_helptext: `
<p>
          Ovdje možeš vidjeti <strong>kampanje</strong> napravljene za tvoje goste. <br><br>  
          Kampanje ti pomažu da reklamiraš svoje usluge/artikle, a nakon što kampanju <strong>aktiviraš</strong>,
          gosti će dobiti <strong>obavijest</strong> na svojim mobilnim uređajima sa detaljima o tvojoj usluzi/artiklu. <br><br>  
          Kreiranje je jednostavno, kroz <strong>3 lagana koraka</strong>.
          Prvo dodaš detalje o kampanji i odabereš uslugu/artikl koji želiš promovirati. Potom, na drugom koraku, dodaješ <strong>goste</strong> do kojih želiš
          da stigne kampanja. Naposlijetku, na trećem koraku, možeš aktivirati kampanju klikom na gumb <strong>'Završeno'</strong>. <br><br>  
          Ako kampanja nije aktivna, možeš ju <strong>uređivati</strong> i odlučiti želiš li je aktivirati odmah ili kasnije.
</p>`,
  menupromotion_helptext: `
<p>
          Kreiraj redove na početnom zaslonu svog <strong>digitalnog jelovnika</strong> kako bi promovirao svoje usluge. <br><br>
          Klikom na 'Dodaj novi red', možeš unijeti detalje za red koji će se <strong>prikazivati</strong> tvojim gostima.
          <strong>Uredi</strong> svoje redove dodajući im naziv, veličinu (veličinu slike) i kategorije/potkategorije/usluge koje želiš istaknuti. <br><br>
          Označavanjem 'Slučajni redoslijed tagova' dodani tagovi će se izmijenjivati svaki put kad korisnik uđe na početni zaslon menija.<br><br>
          Možeš odlučiti želiš li radije prikazivati cijenu artikala/usluga ili ne, ili napraviti redoslijed prikazivanja redova na digitalnom jelovniku gosta.
          Ne zaboravi <strong>Spremiti promjene</strong> nakon što završiš!
</p>`,
  guestapppromotion_helptext: `
<p>
  Kreiraj redove na početnom zaslonu <strong>Guest aplikacije</strong> za promociju čega god želiš, usluga, događaja ili posebnih ponuda!<br><br>
  Klikom na 'Dodaj novi red', možeš unijeti detalje za red koji će se <strong>prikazivati</strong> korisnicima Guest aplikacije.
  <strong>Uredi</strong> svoje redove dodajući im naziv, veličinu (veličinu slike) i kategorije/potkategorije/usluge koje želiš istaknuti.<br><br>
  Označavanjem 'Slučajni redoslijed tagova' dodani tagovi će se izmijenjivati svaki put kad korisnik uđe na početni zaslon Guest Aplikacije.<br><br>
  Možeš odlučiti želiš li radije prikazivati cijenu artikala/usluga ili ne, ili napraviti redoslijed prikazivanja redova.
  Ne zaboravi <strong>Spremiti promjene</strong> nakon što završiš!
</p>`,
  reservationreview_helptext: `<p>Ovdje možeš <strong>pronaći neku rezervaciju</strong> i njene detalje, 
  <strong>kreirati</strong> novu rezervaciju ili <strong>importati više rezervacija</strong> putem datoteke!<br>
<br>
Na gornjem dijelu stranice, postoji različita polja za <strong>lakši pronalazak</strong>. Samo unesi željene parametre i klikni 'Pretraži'!<br>
<br>
Za kreiranje rezervacije, moraš <strong>unijeti datum prijave i datum odjave</strong>, <strong>dodijeliti goste</strong>, 
a zatim <strong>dodijeliti ih u smještaj</strong> po svojoj ili njihovoj želji! Klikni na 'Spremi' i nova rezervacija je kreirana!<br>
<br>
Import je još jednostavniji! Potrebno je samo kliknuti na <strong>gumb 'Import'</strong>, i odabrati datoteku sa rezervacijama. 
U kratkom vremenu, otvara se novi modal sa osnovnim detaljima o rezervacijama. Potom je potrebno dodijeliti rezervacijama ključ sobe!<br>
<br>
Također, <strong>možeš uređivati rezervacije</strong> na donjem dijelu stranice. Uređvati njihove informacije, uređivati ili dodijeliti ključ sobe i izbrisati rezervaciju!</p>`,
  chat_helptext: `<p>Ovo je mjesto za tvoj <strong>razgovor</strong> sa gostima!<br>
  <br>
  Jednostavno, klikni na 'plus' da <strong>pronađeš gosta</strong> s kojim želiš započeti razgovor. 
  Odaberi ga, napiši poruku, <strong>i pošalji!</strong> U chatu ćeš vidjeti datum i vrijeme komunikacije. 
  Ako je potrebno, možeš i izbrisati razgovor. <br>
  <br>
  Gost će dobiti <strong>obavijest</strong> na svom mobitelu preko Guest aplikacije za dolaznu poruku, a kad odgovori, 
  <strong>i ti ćeš ju dobiti!</strong> Klikom na obavijest, ponovno ćeš se naći na ovoj stranici, i pokraj imena gosta sa nepročitanom porukom bit će prikazan crveni krug!<br>
  <br>
  <strong>Sretno čavrljanje!</strong></p>`,
  "Basic Information": "Osnovne informacije",
  "Fill in informations about your property":
    "Napišite informacije o svom objektu",
  "Add all amenities": "Dodaj sav sadržaj",
  "Select amenities that your property has":
    "Odaberite sadržaje koje posjeduje vaš objekt",
  "Create Home screen rows": "Stvorite retke početnog zaslona",
  "Promote your services and items": "Promovirajte svoje usluge i artikle",
  "Import best images": "Uvezite najbolje slike",
  "Add images for your property": "Dodajte slike za svoj objekt",
  "Integrate with 3rd party applications":
    "Integrirajte s aplikacijama trećih strana",
  "Location is important": "Lokacija je važna",
  "Choosing location will help users find your property":
    "Odabir lokacije pomoći će korisnicima da pronađu vaš objekt",
  "Get order info": "Dodajte informacije o narudžbi",
  "Pick how would you like to receieve notifications":
    "Odaberite kako želite primati obavijesti",
  "Define your rules": "Definirajte svoja pravila",
  "Select set of rules for your property":
    "Odaberite skup pravila za svoj objekt",
  "Welcome and Info messages": "Poruke dobrodošlice i Info",
  "Write welcome and info messages to your Guests":
    "Napišite gostima dobrodošlicu i info poruke",
  "It's about your availability": "Radi se o vašoj dostupnosti",
  "Select a day and set work hours for it":
    "Odaberite dan i odredite radno vrijeme za njega",
  Basic: "Osnovno",
  Location: "Lokacija",
  Images: "Fotografije",
  Amenities: "Sadržaj",
  "Working hours": "Radni sati",
  Rules: "Pravila",
  Notification: "Obavijest",
  Integration: "Integracija",
  "Home screen": "Početni zaslon",
  Messages: "Poruke",
  "Where to?": "Gdje?",
  Address: "Adresa",
  Email: "E-pošta",
  Phone: "Telefon",
  Status: "Status",
  Availability: "Dostupnost",
  Inactive: "Neaktivno",
  Active: "Aktivno",
  Unavailable: "Nedostupno",
  Available: "Dostupno",
  "Save changes": "Spremi promjene",
  "Drag & Drop or Click to upload":
    "Povucite i ispustite ili kliknite za prijenos",
  Open: "Otvoreno",
  Close: "Zatvoreno",
  Mon: "Pon",
  Tue: "Uto",
  Wed: "Sri",
  Thu: "Čet",
  Fri: "Pet",
  Sat: "Sub",
  Sun: "Ned",
  "Check in from": "Prijava od",
  "Check in until": "Prijava do",
  "Check out from": "Odjava od",
  "Check out until": "Odjava do",
  "Web notifications": "Web obavijesti",
  "SMS notifications": "SMS obavijesti",
  "E-mail notifications": "Obavijesti e-pošte",
  "Check in": "Prijava",
  "Home screen rows": "Redci početnog zaslona",
  OrderHSR: "Redoslijed",
  "Add new row": "Dodaj novi redak",
  "Search...": "Traži...",
  Language: "Jezik",
  "Edit welcome message": "Uređivanje poruke dobrodošlice",
  "Edit info message": "Uređivanje info poruke",
  "Select Property Group": "Odaberi Grupu Objekta",
  "Property Group": "Grupa Objekta",
  Continue: "Nastavi",
  Organisations: "Organizacije",
  Services: "Usluge",
  Users: "Korisnici",
  Appointments: "Termini",
  "Accommodation Types": "Tipovi Smještaja",
  Reservations: "Rezervacije",
  "My Campaigns": "Moje Kampanje",
  "Menu Promotions": "Promocija Sadržaja",
  "Menu promotions": "Promocija sadržaja",
  "Contactless App Promotions": "Promocija Sadržaja",
  "Contactless App promotions": "Promocija sadržaja",
  Dashboard: "Početna stranica",
  "Administration language": "Jezik administracije",
  Category: "Kategorija",
  "Add new category": "Dodaj novu kategoriju",
  "There is no categories here. Add new by clicking on the plus icon.":
    "Nema nijedne kategorije. Dodaj ju klikom na ikonu u obliku plusa.",
  "Activate/deactivate": "Aktivno/neaktivno",
  "Edit this item": "Uredi ovaj artikl",
  "Sub-category": "Pod-kategorija",
  "Add new sub-category": "Dodaj novu pod-kategoriju",
  "There is no sub-categories for selected category. Add new by clicking on the plus icon.":
    "Nema nijedne pod-kategorije. Dodaj ju klikom na ikonu u obliku plusa.",
  Expand: "Raširi",
  Items: "Artikli",
  "Add new item": "Dodaj novi artikl",
  Collapse: "Sakrij",
  "Edit category": "Uredi kategoriju",
  Template: "Predložak",
  "External ID": "Vanjski ID",
  Position: "Pozicija",
  "Age restriction": "Dobno ograničenje",
  Visibility: "Vidljivost",
  "Guest app": "Guest app",
  "Contactless menu": "Beskontaktni jelovnik",
  "Public site": "Javna stranica",
  "Create category": "Kreiraj kategoriju",
  "Create sub-category": "Kreiraj pod-kategoriju",
  "Create item": "Kreiraj artikl",
  "Delivery time": "Vrijeme dostave",
  "Cancellation deadline (min)": "Rok otkazivanja (min)",
  Image: "Fotografija",
  "View reviews": "Pogledaj recenzije",
  Reviews: "Recenzije",
  "average rating": "prosječna ocjena",
  Guest: "Gost",
  Rating: "Ocjena",
  "users overview": "pregled korisnika",
  "First name": "Ime",
  "Last name": "Prezime",
  Roles: "Role",
  "Phone number": "Broj telefona",
  "Edit User": "Uredi Korisnika",
  "Add new User": "Dodaj novog Korisnika",
  Pending: "Na čekanju",
  "In progress": "U tijeku",
  Finished: "Završeno",
  "No pending appointments": "Nema rezervacija na čekanju",
  "No calendars to display": "Nema kalendara za prikaz",
  "Please add a new calendar": "Molimo dodajte novi kalendar",
  Total: "Ukupno",
  "Ready for delivery": "Spremno za dostavu",
  "New calendar": "Novi kalendar",
  "Add new calendar": "Dodaj novi kalendar",
  "Capacity of timeslot": "Kapacitet",
  "Timeslot interval": "Vremenski interval",
  Priority: "Prioritet",
  "Who has access to this calendar?": "Tko ima pristup ovom kalendaru?",
  "Create calendar": "Kreiraj kalendar",
  "Add new reservation": "Dodaj novu rezervaciju",
  "Payment method": "Način plaćanja",
  "Create New": "Kreiraj novog",
  "Add new guest": "Dodaj novog gosta",
  "Create guest": "Kreiraj gosta",
  "Check for new appointments": "Provjeri nove termine",
  "Edit working days and hours": "Uredi radne dane i sate",
  "Delete by clicking on working day or dragging on group of working days":
    "Izbriši klikom na radni dan ili povlačenjem skupine radnih dana",
  "Add working days by clicking on empty slot or dragging on group of empty slots":
    "Dodajte radne dane klikom na prazno mjesto ili povlačenjem grupe praznih mjesta",
  "Edit working days by clicking on working day or dragging on group of working days":
    "Uredite radne dane klikom na radni dan ili povlačenjem grupe radnih dana",
  "Enable delete": "Omogući brisanje",
  "Edit calendar": "Uredi kalendar",
  "Search appointments": "Pretraži termine",
  "Search reservation": "Traži rezervaciju",
  "Search this calendar": "Pretraži ovaj kalendar",
  "Transfer to calendar": "Premjesti na kalendar",
  All: "Svi/e",
  From: "Od",
  To: "Do",
  "Edit reservation": "Uredi rezervaciju",
  "Edit breaks": "Uredi pauze",
  "Delete by clicking on break event": "Izbriši klikom na pauzu",
  "Add break by dragging on empty time slots":
    "Dodaj pauzu povlačenjem na praznom vremenskom intervalu",
  "Working days": "Radni dani",
  "Working day": "Radni dan",
  BackCalendar: "BackCalendar",
  NextCalendar: "NextCalendar",
  "Breaks:": "Pauze:",
  "Reserv.:": "Rezerv.:",
  Calendars: "Kalendari",
  "COVER IMAGE": "NASLOVNA FOTOGRAFIJA",
  "Change reservation time": "Promijeni vrijeme rezervacije",
  "Choose time slot": "Odaberi vremenski period",
  Break: "Pauza",
  Reservation: "Rezervacija",
  "Add new accommodation": "Dodaj novi smještaj",
  Information: "Podaci",
  "Size (sqm)": "Veličina (m2)",
  "Max capacity": "Maks. kapacitet",
  Cancel: "Zatvori",
  "New accommodation unit": "Nova smještajna jedinica",
  Number: "Broj",
  Floor: "Kat",
  "Accommodation state": "Status smještaja",
  Pool: "Ukupno",
  "Total to pay": "Ukupno za platiti",
  "Date from": "Datum od",
  "Date to": "Datum do",
  Duration: "Trajanje",
  Guests: "Gosti",
  "Date of birth": "Datum rođenja",
  "Document ID": "Broj dokumenta",
  "Document type": "Vrsta dokumenta",
  Country: "Država",
  "Assign guest to the reservation": "Dodijeli gosta rezervaciji",
  Holder: "Nositelj",
  "Assign guest": "Dodijeli gosta",
  "Accommodation type": "Vrsta smještaja",
  Room: "Soba",
  Accommodation: "Smještaj",
  "Pricing by single day": "Cijene po danu",
  "Bulk edit": "Bulk edit",
  "campaigns overview": "pregled kampanja",
  Created: "Kreirano",
  Sent: "Poslano",
  Read: "Pročitano",
  "Edit this campaign": "Uredi ovu kampanju",
  "Add new campaign": "Dodaj novu kampanju",
  Enter: "Unesi još",
  "more characters to start the search": "znak/a da pokreneš pretraživanje",
  Audience: "Publika",
  "Search Campaigns": "Pretraži Kampanje",
  Overview: "Pregled",
  Settings: "Postavke",
  "My profile": "Moj profil",
  "Last logged in": "Zadnji put prijavljen/a",
  "Number of total logins": "Ukupni broj prijava",
  "Working place": "Radno mjesto",
  "App settings": "Postavke aplikacije",
  "Login message": "Login poruka",
  Billing: "Plaćanje",
  "Guest APP Overview": "Pregled Guest aplikacije",
  "Apply changes": "Primijeni promjene",
  "Primary color": "Primarna boja",
  "Secundary color": "Sekundarna boja",
  "Write login message": "Napiši login poruku",
  Notifications: "Obavijesti",
  "Read all": "Označi kao pročitano",
  "You have successfully updated the category":
    "Uspješno ste ažurirali kategoriju",
  "You have successfully created the category":
    "Uspješno ste napravili kategoriju",
  "You have successfully updated the subcategory":
    "Uspješno ste ažurirali potkategoriju",
  "You have successfully created the subcategory":
    "Uspješno ste napravili potkategoriju",
  "You have successfully updated the item": "Uspješno ste ažurirali artikal",
  "You have successfully created the item": "Uspješno ste napravili artikal",
  "You have successfully updated the extras": "Uspješno ste ažurirali dodatak",
  "You have successfully created the extras": "Uspješno ste napravili dodatak",
  "Create another": "Kreiraj još",
  "The delivery is finished!": "Dostava je završena!",
  "Chat is disabled": "Chat je onemogućen",
  "Add new conversation": "Dodaj novi razgovor",
  "Remove conversation": "Izbriši razgovor",
  "Type message here": "Upiši poruku ovdje",
  "Reservations review": "Pregled rezervacija",
  "Assigned key": "Dodjeljen ključ",
  "GuestApp state": "Status",
  "Room key": "Ključ sobe",
  "Find reservation": "Pretraži rezervaciju",
  "Add room key": "Dodaj ključ sobe",
  "Delete reservation": "Obriši rezervaciju",
  "With this action you'll delete chosen reservation. Are you sure you want to proceed?":
    "Sa ovom akcijom obrisat će te rezervaciju. Jeste li sigurni da želite nastaviti?",
  "Yes I am sure": "Da, siguran/a sam",
  Review: "Pregled",
  "Import reservations": "Uvezene rezervacije",
  "Reservations Review": "Pregled rezervacija",
  "Add new": "Dodaj rezervaciju",
  "Delete this item": "Izbriši rezervaciju",
  "Find key": "Pronađi ključ",
  Key: "Ključ",
  "Key list": "Lista ključeva",
  "Card preview": "Pregled kartice",
  "Delete key": "Izbriši ključ",
  "With this action you will delete chosen key. Are you sure you want to proceed?":
    "Ovom ćete akcijom izbrisati odabrani ključ. Jeste li sigurni da želite nastaviti?",
  "Generate new key/s": "Generiraj ključ/eve",
  "Number of keys": "Broj ključeva",
  Generate: "Generiraj",
  "Please scan the code and enjoy your stay!":
    "Skenirajte kod i uživajte u boravku!",
  "or visit": "ili posjetite",
  "and login with guest code": "i prijavite se s kodom",
  "No key": "Nema ključa",
  "Creating document please wait...": "Dokument se kreira molimo pričekajte...",
  "Downloading document": "Preuzimanje dokumenta",
  "Download document": "Preuzmi dokument",
  "Create document": "Kreiraj dokument",
  "Display this item on these sub-categories":
    "Prikaži ovaj artikala na ovim pod-kateogrijama",
  "Charge this sidedish": "Naplaćuj ovaj prilog",
  "Create reservation": "Kreiraj rezervaciju",
  "Currently staying in hotel": "Trenutno odsjeda u hotelu",
  "Customize homescreen rows": "Prilagodi redove početnog zaslona",
  "Something went wrong": "Došlo je do greške!",
  "Go to homepage": "Idi na početnu stranicu",
  Reports: "Izvještaji",
  CloseButton: "Zatvori",
  "Choose parameters you want to make report for":
    "Odaberi parametre za koje želiš napraviti izvještaj",
  Today: "Danas",
  "To the end of this week": "Do kraja tjedna",
  "Next week": "Sljedeći tjedan",
  "To the end of this month": "Do kraja mjeseca",
  "Next month": "Sljedeći mjesec",
  "Disable ordering": "Onemogući naručivanje",
  "Date and time from": "Datum i vrijeme početka",
  "Date and time to": "Datum i vrijeme kraja",
  "Room number": "Broj sobe",
  "No assigned guests": "Nema dodjeljenih gostiju",
  "Total (Total amount)": "Ukupno (Ukupni iznos)",
  // ACTIVITY STREAM
  "Guest activity": "Aktivnost gostiju",
  "/homeScreenRow/getAllForGuest/<stringlang>":
    "je pristupio/la početnom ekranu na aplikaciji.",
  "/category/guest/getAllActiveForLoggedUser/<stringlang>":
    "pregledava kategorije.",
  "/service/selectView/<stringlang>/<uuidorg_core_id>": "pregledava artikle.",
  "/service/singleView/<stringlang>/<uuidservice_id>": "pregledava artikal.",
  "/orders/roomBill": "je krerao/la novu narudžbu na račun sobe.",
  "/orders/getAllForGuest/<stringlang>":
    "je pristupio/la pregledu svojih narudžbi.",
  "/notifications/recieved": "je pročitao notifikacije.",
  "/stayReviews": "je ocjenio/ocjenila svoj boravak.",
  "/reservationGuests/<uuid_id>": "je postavio/la limit za potrošnju.",
  "/saveSubscription": "je dopustio/la primanje notifikacija.",
  "/guests/login": "se prijavio/la u aplikaciju. Sustav je evidentirao uređaj.",
  "Moving and copying items": "Premještanje i kopiranje artikala",
  "Do you want to move or copy selected items?":
    "Želite li premjestiti ili kopirati odabrane artikle?",
  "Steps": "Korak {{num1}} od {{num2}}",
  Move: "Premjesti",
  Copy: "Kopiraj",
  "Select subcategory, you want move items to":
    "Odaberite potkategoriju, u koju želite premjestiti artikle",
  "Select subcategory, you want copy items to":
    "Odaberite potkategoriju, u koju želite kopirati artikle",
    "Move selected items":"Premjestite {{length}} artikla",
    "Copy selected items":"Kopirajte {{length}} artikla",
  Confirm: "Prihvati",
  "QR codes and links":"QR kodovi i poveznice",
  "Other":"Ostalo",
  "Menu":"Meni",
  "public app":"javna aplikacija",
  "Invoices":"Računi",
  "Payment details":"Pojedinosti o plaćanju",
  "Amount":"Iznos",
  "There are no homescreen rows":"Nema redaka početne stranice",
  //integrations
  "Activate": "Aktiviraj",
  "Deactivate": "Deaktiviraj",
  "Api key": "Api ključ",
  "Base url": "Osnovni url",
  "Integration PMS": "PMS integracije",
  "Integration POS": "POS integracije",
  "CHOOSE": "ODABERITE",
  "External property ID": "Vanjski ključ organizacije",
  "Ordering process management": "Upravljanje procesom naručivanja",
  "Order status": "Stanje narudžbe",
  "Select status": "Odaberite stanje",
  "Define ordering processes of the organization": "Definirajte procese naručivanja organizacije",
  "connected": "povezan",
  "unconnected": "nepovezan",
  "inactive": "neaktivan",
  "Agree": "Da",
  "Disagree": "Ne",
  "Are you sure you want to delete calendar": "Jeste li sigurni da želite izbrisati kalendar",
  "Confirm deletion": "Potvrda brisanja",

  // Tutorial
  next: "dalje",
  back: "nazad",
  skip: "preskoči upute",
  tutTitle1: "Naručite poslugu u sobu",
  tutText1:
    "PRETRAŽI veliki izbor HRANE i PIĆA, dodaj što želiš u KOŠARICU i klikni RAČUN. Vaša narudžba će, u skorije vrijeme, biti dostavljena u Vašu sobu.",
  tutTitle2: "Napravite rezervaciju",
  tutText2:
    "Želiš napraviti REZERVACIJU u WELLNESS-u? Nije problem. Odaberi MASAŽU ili neki drugi tretman, provjeri dostupne TERMINE i odaberi jedan. Dodaj u košaricu i klikni RAČUN. To je to! Uspješno ste rezervirali USLUGU. Jedino što Vam preostaje je doći u zakazano vrijeme.",
  tutTitle3: "Rezerviraj sportsku aktivnost",
  tutText3:
    "Želiš li rezervirati TENISKI TEREN ili KAJAKE? Vrlo je jednostavno. Pretraži sportsku kategoriju i odaberi ono što želiš. Odaberi slobodan TERMIN i dodaj u KOŠARICU. To je to. Uspješno ste rezervirali SPORTSKU AKTIVNOST.",
  tutTitle4: "Pregledaj narudžbe",
  tutText4:
    "Nakon svake NARUDŽBE možeš pregledati sve naručene stavke na ekranu NARUDŽBE. Ako je naruždba završena, možeš napraviti RECENZIJU i pomoći sebi, drugim gostima i ovom smještaju da poboljša uslugu.",
  tutTitle5: "Informiraj se",
  tutText5:
    "Klikni na stavku TRAŽI, a onda na HOTELSKU MAPU. Ondje možeš pronaći raznovrsne i korisne informacije poput: KUĆNIH PRAVILA, PRIJEVOZA i KONTAKT informacija.",
  tutTitle6: "Ostani u toku",
  tutText6:
    "Na POČETNOJ STRANICI uvijek možeš vidjeti što je popularno u mjestu gdje odsjedate. Tu su informacije poput kada i gdje su sljedeći veliki događaji, poput KONCERATA, ZABAVA i drugih aktivnosti.",
  tutTitle7: "Postavi pitanje",
  tutText7:
    "Otvori stavku CHAT i slobodno postavi bilo koje pitanje. RECEPCIJA će vam odgovoriti u kratkom roku. Chat je dostupan 24/7.",
  tutTitle8: "Pogledaj informacije o boravku",
  tutText8:
    "Klikni na stavku PROFIL gdje možeš vidjeti informacije o boravku i svu POTROŠNJU u trenutnoj rezervaciji. Pogledaj sve svoje pretplate i koristi ih kao ulaznice za različita mjesta poput DORUČKA, BAZENA,...",
};
