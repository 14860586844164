/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, putFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { getCookie } from "Util/functions";
// import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_ORGS_NUMS_REQ = "orders/GET_ORGS_NUMS_REQ";
const GET_ORGS_NUMS_SCS = "orders/GET_ORGS_NUMS_SCS";
const GET_ORGS_NUMS_FLR = "orders/GET_ORGS_NUMS_FLR";

const GET_ORDERS_REQ = "orders/GET_ORDERS_REQ";
const GET_ORDERS_SCS = "orders/GET_ORDERS_SCS";
const GET_ORDERS_FLR = "orders/GET_ORDERS_FLR";

const GET_DELIVERY_ORDERS_REQ = "orders/GET_DELIVERY_ORDERS_REQ";
const GET_DELIVERY_ORDERS_SCS = "orders/GET_DELIVERY_ORDERS_SCS";
const GET_DELIVERY_ORDERS_FLR = "orders/GET_DELIVERY_ORDERS_FLR";

const FINISH_DELIVERY = "orders/FINISH_DELIVERY";

export function distinctItems(array) {
  return array?.map((i) => {
    return {
      ...i,
      items: i.items?.reduce((accumulator, current) => {
        if (
          !accumulator?.some(
            (i) =>
              i?.service?.id === current?.service?.id &&
              JSON.stringify(i.extras) === JSON.stringify(current.extras)
          )
        ) {
          accumulator.push(current);
        } else if (
          accumulator?.some((i) => i?.service?.id === current?.service?.id)
        ) {
          const temp = accumulator?.find(
            (i) =>
              i?.service?.id === current?.service?.id &&
              JSON.stringify(i.extras) === JSON.stringify(current.extras)
          );

          return accumulator.map((j) => {
            if (temp.order_item?.id === j.order_item?.id) {
              return {
                ...temp,
                order_item: {
                  ...temp.order_item,
                  quantity:
                    temp.order_item?.quantity + current.order_item?.quantity,
                },
              };
            } else return j;
          });
        }

        return accumulator;
      }, []),
    };
  });
}

/**
 * ACTIONS
 */

export const getOrgsNumsOrders = (date) => async (dispatch) => {
  dispatch({ type: GET_ORGS_NUMS_REQ });
  const response = await getFunc(
    `organisations/getNumberOfPendingOrdersAndItemsForOrderReservationType?date=${date}`
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ORGS_NUMS_SCS, payload: response.data });
  } else {
    dispatch({ type: GET_ORGS_NUMS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const getOrders = (date, org, auth) => async (dispatch) => {
  dispatch({ type: GET_ORDERS_REQ });
  const response = await getFunc(
    `orders/getAllByOrderReservationType/${getCookie(
      "lang",
      auth
    )}/${org}?date=${date}`
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ORDERS_SCS, payload: response.data });
  } else {
    dispatch({ type: GET_ORDERS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const getDeliveryOrders = (auth) => async (dispatch) => {
  dispatch({ type: GET_DELIVERY_ORDERS_REQ });
  const response = await getFunc(`orders/delivery/${getCookie("lang", auth)}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_DELIVERY_ORDERS_SCS, payload: response.data });
  } else {
    dispatch({ type: GET_DELIVERY_ORDERS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const finishDelivery = (id, successFunc) => async (dispatch) => {
  const response = await putFunc(`orders/delivery/changeStateToFinished/${id}`);

  console.log(`response`, response);

  if (response.status.errorCode === 200) {
    dispatch({ type: FINISH_DELIVERY, payload: response.data });
    successFunc(response.data);
  } else {
    NotificationManager.error(response.status.description);
  }
};

/**
 * REDUCERS
 */
const INIT_STATE = {
  loading: false,
  items: [],
  orgs: [],
  deliveryItems: [],
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ORGS_NUMS_REQ:
      return {
        ...state,
        orgs: [],
        loading: true,
      };
    case GET_ORGS_NUMS_SCS:
      return {
        ...state,
        orgs: action.payload,
        loading: false,
      };
    case GET_ORGS_NUMS_FLR:
      return {
        ...state,
        orgs: [...state.items.data],
        loading: false,
      };
    case GET_ORDERS_REQ:
      return {
        ...state,
        items: [],
        loading: true,
      };
    case GET_ORDERS_SCS:
      return {
        ...state,
        items: {
          ...action.payload,
          pending_orders: distinctItems(action.payload?.pending_orders),
          in_progress_orders: distinctItems(action.payload?.in_progress_orders),
          finished_orders: distinctItems(action.payload?.finished_orders),
        },
        loading: false,
      };
    case GET_ORDERS_FLR:
      return {
        ...state,
        orgs: [...state.items.data],
        loading: false,
      };
    case GET_DELIVERY_ORDERS_REQ:
      return {
        ...state,
        deliveryItems: [],
        loading: true,
      };
    case GET_DELIVERY_ORDERS_SCS:
      return {
        ...state,
        deliveryItems: action.payload,
        loading: false,
      };
    case GET_DELIVERY_ORDERS_FLR:
      return {
        ...state,
        deliveryItems: [...state.items.data],
        loading: false,
      };
    case FINISH_DELIVERY:
      return {
        ...state,
        deliveryItems: state.deliveryItems.filter(
          (item) => item.id !== action.payload.id
        ),
        loading: false,
      };

    default:
      return state;
  }
}
