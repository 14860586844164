import React from "react";
import { useTheme } from "@material-ui/core";

export const AccomodationTypesHelpImg = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  return (
    <svg
      width="100%"
      height="200px"
      viewBox="0 0 344 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M316.958 193.62C321.991 193.62 326.071 192.939 326.071 192.1C326.071 191.26 321.991 190.58 316.958 190.58C311.926 190.58 307.846 191.26 307.846 192.1C307.846 192.939 311.926 193.62 316.958 193.62Z"
        fill="#F2F2F2"
      />
      <path d="M327.59 46.9321H326.831V62.893H327.59V46.9321Z" fill="#F2F2F2" />
      <path d="M319.236 36.6719H314.68V194H319.236V36.6719Z" fill="#F2F2F2" />
      <path
        d="M337.818 49.0225H296.099C295.641 49.0225 295.189 48.9252 294.772 48.7368C294.355 48.5485 293.982 48.2735 293.679 47.9301C293.377 47.5868 293.15 47.1829 293.015 46.7452C292.88 46.3076 292.84 45.8462 292.897 45.3917L298.212 2.82955C298.311 2.04877 298.69 1.33073 299.28 0.80989C299.869 0.289048 300.628 0.00111331 301.415 0H332.502C333.288 0.0011132 334.047 0.289048 334.637 0.80989C335.226 1.33073 335.606 2.04877 335.704 2.82955L341.02 45.3917C341.077 45.8462 341.036 46.3076 340.901 46.7452C340.767 47.1829 340.54 47.5868 340.237 47.9301C339.934 48.2735 339.562 48.5485 339.145 48.7368C338.728 48.9252 338.275 49.0225 337.818 49.0225Z"
        fill="#F2F2F2"
      />
      <path
        d="M327.21 65.5537C328.678 65.5537 329.868 64.3627 329.868 62.8935C329.868 61.4244 328.678 60.2334 327.21 60.2334C325.742 60.2334 324.552 61.4244 324.552 62.8935C324.552 64.3627 325.742 65.5537 327.21 65.5537Z"
        fill="#F2F2F2"
      />
      <path
        d="M47.6741 151.766C45.7559 158.427 40.1548 163.089 34.3123 167.05C33.4965 167.603 32.6804 168.143 31.8641 168.671C31.8586 168.673 31.8529 168.679 31.8473 168.681C31.808 168.706 31.7687 168.732 31.7321 168.757C31.5636 168.866 31.3951 168.974 31.228 169.082L31.3198 169.123C31.3198 169.123 31.4099 169.171 31.3113 169.131C31.282 169.119 31.2512 169.108 31.2219 169.095C27.8278 167.725 24.3733 166.254 21.7722 163.687C19.0739 161.022 17.4971 156.842 18.9582 153.34C19.1502 152.882 19.3928 152.446 19.6814 152.041C19.7999 151.871 19.9276 151.709 20.0604 151.549C20.8664 150.565 21.9198 149.813 23.1123 149.371C24.3048 148.929 25.5935 148.813 26.8457 149.035C28.0979 149.256 29.2685 149.808 30.2371 150.633C31.2057 151.457 31.9374 152.525 32.357 153.727C32.7862 149.727 29.4908 146.376 26.3194 143.904C23.1466 141.433 19.5414 138.742 18.928 134.764C18.5855 132.551 19.3544 130.406 20.7552 128.684C20.7985 128.632 20.8419 128.58 20.8865 128.529C22.5916 126.524 24.9899 125.236 27.6022 124.923C32.4672 124.417 37.1929 126.994 40.7273 130.38C46.4158 135.827 49.8548 144.194 47.6741 151.766Z"
        fill="#F2F2F2"
      />
      <path
        d="M38.5298 143.055C39.4635 144.509 40.1804 146.091 40.6578 147.752C41.0615 149.237 41.2411 150.773 41.1908 152.311C41.0606 155.475 40.173 158.562 38.6028 161.312C37.4199 163.398 35.9784 165.325 34.3125 167.05C33.4967 167.603 32.6807 168.143 31.8644 168.671C31.8589 168.673 31.8532 168.678 31.8476 168.681C31.8083 168.706 31.7689 168.731 31.7323 168.757C31.5639 168.865 31.3953 168.974 31.2283 169.082C31.2283 169.082 31.4102 169.171 31.3116 169.131C31.2822 169.118 31.2515 169.107 31.2222 169.095C31.5264 166.599 31.2284 164.066 30.3531 161.709C29.4779 159.351 28.0508 157.239 26.1917 155.547C24.328 153.881 22.0974 152.68 19.6816 152.041C19.8002 151.871 19.9278 151.709 20.0607 151.549C20.9937 151.811 21.9017 152.155 22.7742 152.577C25.893 154.076 28.4467 156.54 30.0574 159.605C31.5027 162.388 32.1247 165.525 31.8505 168.65C31.9899 168.523 32.1296 168.394 32.265 168.266C34.8442 165.859 37.135 163.097 38.6854 159.909C40.0304 157.209 40.6915 154.219 40.6104 151.203C40.4838 147.915 39.1748 144.869 37.2794 142.217C35.202 139.388 32.7479 136.857 29.9853 134.694C27.2012 132.462 24.1547 130.579 20.9137 129.088C20.8406 129.054 20.7834 128.993 20.7539 128.918C20.7245 128.843 20.725 128.759 20.7555 128.684C20.7771 128.617 20.8243 128.561 20.8868 128.529C20.919 128.515 20.9538 128.508 20.9889 128.51C21.0239 128.511 21.0581 128.521 21.0889 128.537C21.4891 128.722 21.8865 128.909 22.2807 129.104C25.5639 130.728 28.6329 132.754 31.417 135.136C34.1062 137.43 36.638 140.052 38.5298 143.055Z"
        fill="white"
      />
      <path
        d="M19.9817 168.539H41.0027C41.2333 168.539 41.4613 168.588 41.6715 168.683C41.8816 168.777 42.0693 168.916 42.2219 169.089C42.3745 169.262 42.4886 169.466 42.5566 169.686C42.6246 169.907 42.645 170.139 42.6164 170.368L39.9379 191.814C39.8882 192.207 39.697 192.569 39.3999 192.832C39.1029 193.094 38.7205 193.239 38.3243 193.24H22.6601C22.2639 193.239 21.8815 193.094 21.5845 192.832C21.2874 192.569 21.0962 192.207 21.0465 191.814L18.368 170.368C18.3394 170.139 18.3598 169.907 18.4278 169.686C18.4958 169.466 18.6099 169.262 18.7625 169.089C18.9151 168.916 19.1027 168.777 19.3129 168.683C19.5231 168.588 19.7511 168.539 19.9817 168.539Z"
        fill="#F2F2F2"
      />
      <path
        d="M88.5204 193.405C89.9734 193.405 91.1512 192.226 91.1512 190.772C91.1512 189.318 89.9734 188.139 88.5204 188.139C87.0675 188.139 85.8896 189.318 85.8896 190.772C85.8896 192.226 87.0675 193.405 88.5204 193.405Z"
        fill="#CCCCCC"
      />
      <path
        d="M254.698 193.405C256.151 193.405 257.328 192.226 257.328 190.772C257.328 189.318 256.151 188.139 254.698 188.139C253.245 188.139 252.067 189.318 252.067 190.772C252.067 192.226 253.245 193.405 254.698 193.405Z"
        fill="#CCCCCC"
      />
      <path
        d="M136.751 154.128H95.5359C93.6178 154.126 91.7789 153.362 90.4226 152.005C89.0664 150.647 88.3034 148.807 88.3013 146.887V118.801C88.3034 116.882 89.0664 115.041 90.4226 113.684C91.7789 112.326 93.6178 111.563 95.5359 111.561H136.751C138.669 111.563 140.508 112.326 141.865 113.684C143.221 115.041 143.984 116.882 143.986 118.801V146.887C143.984 148.807 143.221 150.648 141.865 152.005C140.508 153.362 138.669 154.126 136.751 154.128Z"
        fill="#E6E6E6"
      />
      <path
        d="M192.217 154.348H151.001C149.083 154.346 147.244 153.582 145.888 152.225C144.532 150.867 143.769 149.027 143.767 147.107V119.021C143.769 117.101 144.532 115.261 145.888 113.903C147.244 112.546 149.083 111.782 151.001 111.78H192.217C194.135 111.782 195.974 112.546 197.33 113.903C198.686 115.261 199.449 117.101 199.451 119.021V147.107C199.449 149.027 198.686 150.867 197.33 152.225C195.974 153.582 194.135 154.346 192.217 154.348Z"
        fill="#E6E6E6"
      />
      <path
        d="M247.682 154.568H206.467C204.548 154.565 202.71 153.802 201.353 152.444C199.997 151.087 199.234 149.246 199.232 147.327V119.241C199.234 117.321 199.997 115.481 201.353 114.123C202.71 112.766 204.548 112.002 206.467 112H247.682C249.6 112.002 251.439 112.766 252.795 114.123C254.152 115.481 254.914 117.321 254.917 119.241V147.327C254.914 149.246 254.152 151.087 252.795 152.444C251.439 153.802 249.6 154.565 247.682 154.568Z"
        fill="#E6E6E6"
      />
      <path
        d="M263.467 152.592H81.9434V190.552H263.467V152.592Z"
        fill="#E6E6E6"
      />
      <path
        d="M88.5205 190.772H80.6282C76.639 190.772 73.3936 187.084 73.3936 182.55V142.938H88.5205V190.772Z"
        fill="#E6E6E6"
      />
      <path
        d="M262.59 190.771H254.698V136.794H269.825V181.494C269.825 186.61 266.579 190.771 262.59 190.771Z"
        fill="#E6E6E6"
      />
      <path
        d="M144.205 160.492H88.5205V148.643C107.082 137.986 125.644 137.986 144.205 148.643V160.492Z"
        fill="#CCCCCC"
      />
      <path
        d="M199.67 160.711H143.986V148.863C162.547 138.206 181.109 138.206 199.67 148.863V160.711Z"
        fill="#CCCCCC"
      />
      <path
        d="M255.136 160.931H199.451V149.082C218.013 138.426 236.574 138.426 255.136 149.082V160.931Z"
        fill="#CCCCCC"
      />
      <path
        d="M77.7784 153.031C84.922 153.031 90.713 147.235 90.713 140.085C90.713 132.936 84.922 127.14 77.7784 127.14C70.6348 127.14 64.8438 132.936 64.8438 140.085C64.8438 147.235 70.6348 153.031 77.7784 153.031Z"
        fill="#CCCCCC"
      />
      <path
        d="M267.194 152.592C274.337 152.592 280.129 146.796 280.129 139.647C280.129 132.497 274.337 126.701 267.194 126.701C260.05 126.701 254.259 132.497 254.259 139.647C254.259 146.796 260.05 152.592 267.194 152.592Z"
        fill="#CCCCCC"
      />
      <path
        d="M229.091 114.285C229.122 114.493 229.135 114.703 229.132 114.913L244.636 125.609L248.771 123.65L252.541 129.508L245.558 133.688C244.994 134.026 244.335 134.172 243.682 134.103C243.028 134.034 242.414 133.755 241.932 133.308L226.23 118.722C225.451 118.941 224.624 118.918 223.858 118.655C223.093 118.393 222.425 117.903 221.944 117.251C221.464 116.599 221.192 115.817 221.167 115.007C221.142 114.197 221.363 113.399 221.802 112.718C222.241 112.038 222.876 111.507 223.624 111.197C224.372 110.887 225.196 110.811 225.987 110.981C226.779 111.151 227.499 111.559 228.054 112.149C228.609 112.739 228.97 113.484 229.091 114.285V114.285Z"
        fill="#FFB8B8"
      />
      <path
        d="M244.935 124.689C245.004 124.452 245.122 124.234 245.282 124.048C245.442 123.862 245.641 123.712 245.864 123.61L253.66 120.035C254.713 119.349 255.995 119.109 257.224 119.367C258.453 119.625 259.53 120.361 260.219 121.413C260.907 122.464 261.151 123.746 260.896 124.978C260.641 126.209 259.909 127.289 258.86 127.98L252.415 133.684C252.231 133.847 252.015 133.968 251.78 134.04C251.545 134.111 251.297 134.131 251.054 134.098C250.811 134.065 250.577 133.98 250.37 133.848C250.162 133.717 249.986 133.542 249.852 133.336L245.144 126.097C245.01 125.891 244.922 125.659 244.886 125.416C244.85 125.173 244.867 124.925 244.935 124.689Z"
        fill={color}
      />
      <path
        d="M228.896 114.982L228.818 114.988L228.606 112.044C228.538 111.103 227.93 110.381 227.248 110.43L222.727 110.758C222.045 110.807 220.79 111.664 220.858 112.605L220.912 113.363L222.025 128.753C222.093 129.693 223.458 130.361 224.141 130.312L228.661 129.984C229.343 129.935 229.841 129.132 229.773 128.191L228.97 117.083L229.048 117.077L228.896 114.982Z"
        fill="#3F3D56"
      />
      <path
        d="M228.344 112.075L229.509 128.198C229.56 128.901 229.189 129.5 228.679 129.537L224.137 129.866C223.627 129.903 223.173 129.364 223.122 128.661L221.957 112.537C221.906 111.835 222.278 111.235 222.787 111.198L223.338 111.159C223.3 111.302 223.308 111.454 223.362 111.592C223.415 111.731 223.511 111.849 223.636 111.929C223.688 111.954 223.746 111.966 223.804 111.962L226.394 111.774C226.636 111.756 226.813 111.471 226.788 111.137C226.783 111.06 226.766 110.985 226.74 110.912L227.33 110.869C227.839 110.833 228.293 111.372 228.344 112.075Z"
        fill="white"
      />
      <path
        d="M164.619 149.306L163.121 144.895L179.407 137.012L181.618 143.522L164.619 149.306Z"
        fill="#FFB8B8"
      />
      <path
        d="M160.729 151.885L155.898 137.662L156.078 137.601C156.805 137.354 157.572 137.252 158.338 137.302C159.104 137.352 159.852 137.552 160.54 137.891C161.228 138.231 161.843 138.702 162.349 139.279C162.855 139.856 163.243 140.528 163.49 141.255L163.49 141.255L166.44 149.942L160.729 151.885Z"
        fill="#2F2E41"
      />
      <path
        d="M229.258 152.454C225.765 152.65 222.282 151.92 219.162 150.338L200.01 137.298L177.648 145.586C177.055 145.788 176.407 145.757 175.835 145.5C175.263 145.242 174.811 144.777 174.568 144.198L173.472 141.56C173.222 140.966 173.215 140.298 173.452 139.699C173.689 139.101 174.152 138.619 174.74 138.358L196.104 128.756C198.669 127.607 201.546 127.358 204.27 128.049L233.929 135.613L241.768 144.931L241.681 145.052C237.833 150.366 233.268 152.163 229.258 152.454Z"
        fill="#2F2E41"
      />
      <path
        d="M253.48 113.517C258.631 113.517 262.806 109.338 262.806 104.183C262.806 99.0284 258.631 94.8496 253.48 94.8496C248.33 94.8496 244.155 99.0284 244.155 104.183C244.155 109.338 248.33 113.517 253.48 113.517Z"
        fill="#FFB8B8"
      />
      <path
        d="M243.908 149.086L228.899 137.907L239.135 130.626C238.842 129.683 237.543 124.291 244.646 120.616C246.895 119.453 248.515 118.451 249.461 117.64C250.804 116.48 252.524 115.852 254.298 115.874C255.416 115.892 256.514 116.17 257.506 116.686C258.498 117.202 259.356 117.942 260.013 118.847C260.67 119.753 261.107 120.799 261.29 121.903C261.473 123.007 261.397 124.138 261.068 125.208C259.587 130.026 257.228 136.411 254.399 139.465C251.199 142.915 247.729 146.104 244.021 149.001L243.908 149.086Z"
        fill={color}
      />
      <path
        d="M166.999 153.215L165.501 148.804L181.787 140.921L183.999 147.431L166.999 153.215Z"
        fill="#FFB8B8"
      />
      <path
        d="M163.109 155.793L158.278 141.57L158.458 141.509C159.184 141.262 159.952 141.16 160.718 141.21C161.483 141.26 162.232 141.46 162.92 141.8C163.608 142.139 164.223 142.61 164.729 143.188C165.235 143.765 165.623 144.436 165.87 145.163L165.87 145.163L168.82 153.85L163.109 155.793Z"
        fill="#2F2E41"
      />
      <path
        d="M231.638 156.362C228.145 156.559 224.662 155.829 221.541 154.247L202.39 141.207L180.028 149.495C179.435 149.697 178.787 149.666 178.215 149.408C177.643 149.151 177.19 148.686 176.948 148.107L175.852 145.468C175.602 144.875 175.595 144.207 175.832 143.608C176.069 143.009 176.532 142.528 177.12 142.267L198.484 132.664C201.049 131.516 203.926 131.267 206.65 131.958L236.309 139.522L244.148 148.84L244.061 148.96C240.213 154.275 235.648 156.072 231.638 156.362Z"
        fill="#2F2E41"
      />
      <path
        d="M227.334 125.481C227.45 125.656 227.552 125.841 227.639 126.032L246.226 129.053L249.124 125.511L255.037 129.19L250.515 135.96C250.15 136.507 249.617 136.921 248.997 137.139C248.377 137.357 247.702 137.368 247.076 137.17L226.646 130.717C226.036 131.249 225.278 131.583 224.474 131.673C223.67 131.764 222.857 131.608 222.144 131.225C221.43 130.842 220.851 130.25 220.481 129.53C220.112 128.809 219.971 127.993 220.076 127.189C220.181 126.386 220.529 125.634 221.072 125.033C221.615 124.433 222.327 124.012 223.115 123.826C223.903 123.641 224.729 123.7 225.482 123.995C226.236 124.291 226.882 124.809 227.334 125.481Z"
        fill="#FFB8B8"
      />
      <path
        d="M246.102 128.094C246.063 127.851 246.076 127.603 246.141 127.366C246.207 127.129 246.322 126.909 246.48 126.721L251.996 120.149C252.654 119.078 253.71 118.312 254.931 118.018C256.153 117.725 257.44 117.928 258.512 118.584C259.584 119.239 260.353 120.293 260.649 121.515C260.946 122.737 260.746 124.027 260.095 125.101L256.71 133.018C256.614 133.244 256.469 133.446 256.288 133.611C256.106 133.776 255.891 133.9 255.657 133.975C255.423 134.049 255.175 134.072 254.932 134.042C254.688 134.012 254.453 133.93 254.244 133.802L246.894 129.277C246.684 129.148 246.505 128.976 246.369 128.772C246.232 128.568 246.141 128.336 246.102 128.094Z"
        fill={color}
      />
      <path
        d="M250.987 114.463C253.697 110.973 255.199 106.697 255.268 102.278C253.993 103.226 252.549 103.924 251.014 104.335C249.384 104.769 247.593 104.856 246.058 104.157C243.898 103.172 242.621 100.66 242.866 98.2977C243.111 95.9349 244.742 93.8242 246.867 92.7645C249.041 91.7468 251.521 91.5966 253.803 92.3443C255.009 91.3156 256.487 90.6569 258.058 90.447C259.63 90.2372 261.228 90.485 262.662 91.161C265.963 92.7825 267.789 96.5375 268.105 100.204C268.422 103.871 267.481 107.516 266.548 111.076L266.442 111.478C266.152 112.587 265.307 114.713 265.307 114.713C265.307 114.713 260.403 113.744 258.455 113.123C255.631 114.145 250.987 114.463 250.987 114.463Z"
        fill="#2F2E41"
      />
      <path
        d="M227.61 115.357L223.615 115.646C223.514 115.653 223.415 115.62 223.339 115.555C223.263 115.489 223.216 115.395 223.209 115.295C223.201 115.194 223.234 115.095 223.3 115.018C223.366 114.942 223.459 114.895 223.56 114.888L227.556 114.599C227.605 114.595 227.655 114.601 227.703 114.617C227.75 114.632 227.794 114.657 227.832 114.69C227.87 114.723 227.901 114.762 227.923 114.807C227.946 114.852 227.959 114.9 227.963 114.95C227.966 115 227.96 115.05 227.944 115.098C227.928 115.145 227.903 115.189 227.871 115.227C227.838 115.264 227.798 115.295 227.753 115.318C227.709 115.34 227.66 115.353 227.61 115.357Z"
        fill="#3F3D56"
      />
      <path
        d="M227.747 117.252L223.752 117.542C223.702 117.545 223.652 117.539 223.604 117.523C223.557 117.508 223.513 117.483 223.475 117.45C223.438 117.418 223.407 117.378 223.384 117.333C223.362 117.289 223.348 117.24 223.345 117.19C223.341 117.14 223.347 117.09 223.363 117.043C223.379 116.995 223.404 116.951 223.437 116.914C223.469 116.876 223.509 116.845 223.554 116.823C223.598 116.8 223.647 116.787 223.697 116.784L227.693 116.494C227.793 116.487 227.892 116.52 227.968 116.586C228.045 116.652 228.091 116.745 228.099 116.846C228.106 116.946 228.073 117.046 228.007 117.122C227.941 117.198 227.848 117.245 227.747 117.252Z"
        fill="#3F3D56"
      />
      <path
        d="M227.884 119.147L223.889 119.437C223.788 119.444 223.689 119.411 223.613 119.345C223.537 119.279 223.491 119.185 223.483 119.085C223.476 118.985 223.509 118.885 223.575 118.809C223.64 118.733 223.734 118.686 223.834 118.679L227.83 118.389C227.93 118.382 228.029 118.415 228.106 118.481C228.182 118.547 228.229 118.64 228.236 118.741C228.243 118.841 228.21 118.941 228.144 119.017C228.078 119.093 227.985 119.14 227.884 119.147Z"
        fill="#3F3D56"
      />
      <path
        d="M343.62 194H0.379691C0.278991 194 0.182414 193.96 0.111209 193.888C0.0400028 193.817 0 193.721 0 193.62C0 193.519 0.0400028 193.422 0.111209 193.351C0.182414 193.28 0.278991 193.24 0.379691 193.24H343.62C343.721 193.24 343.818 193.28 343.889 193.351C343.96 193.422 344 193.519 344 193.62C344 193.721 343.96 193.817 343.889 193.888C343.818 193.96 343.721 194 343.62 194Z"
        fill="#3F3D56"
      />
      <path
        d="M182.548 67.4533H144.199C143.293 67.4523 142.424 67.0916 141.784 66.4504C141.143 65.8093 140.783 64.9399 140.782 64.0332V30.9714C140.783 30.0647 141.143 29.1953 141.784 28.5542C142.424 27.913 143.293 27.5523 144.199 27.5513H182.548C183.454 27.5523 184.322 27.913 184.963 28.5542C185.604 29.1954 185.964 30.0647 185.965 30.9714V64.0332C185.964 64.9399 185.604 65.8092 184.963 66.4504C184.322 67.0916 183.454 67.4523 182.548 67.4533ZM144.199 28.3113C143.494 28.3121 142.819 28.5926 142.32 29.0913C141.822 29.59 141.542 30.2662 141.541 30.9714V64.0332C141.542 64.7384 141.822 65.4146 142.32 65.9133C142.819 66.412 143.494 66.6925 144.199 66.6933H182.548C183.252 66.6925 183.928 66.412 184.426 65.9133C184.925 65.4146 185.205 64.7384 185.206 64.0332V30.9714C185.205 30.2662 184.925 29.59 184.426 29.0913C183.928 28.5926 183.252 28.3121 182.548 28.3113H144.199Z"
        fill="#F2F2F2"
      />
      <path
        d="M176.941 62.3232H149.806C148.95 62.3222 148.13 61.9816 147.525 61.376C146.92 60.7704 146.58 59.9494 146.579 59.093V35.9118C146.58 35.0554 146.92 34.2344 147.525 33.6288C148.13 33.0232 148.95 32.6826 149.806 32.6816H176.941C177.797 32.6826 178.617 33.0232 179.222 33.6288C179.827 34.2344 180.167 35.0554 180.168 35.9118V59.093C180.167 59.9494 179.827 60.7704 179.222 61.376C178.617 61.9816 177.797 62.3222 176.941 62.3232Z"
        fill="#E6E6E6"
      />
      <path
        d="M163.563 22.6112C164.612 22.6112 165.462 21.7605 165.462 20.7111C165.462 19.6617 164.612 18.811 163.563 18.811C162.515 18.811 161.665 19.6617 161.665 20.7111C161.665 21.7605 162.515 22.6112 163.563 22.6112Z"
        fill="#F2F2F2"
      />
      <path
        d="M167.555 28.1269L163.564 21.4526L159.597 28.1257L158.944 27.7373L163.562 19.9697L168.206 27.7362L167.555 28.1269Z"
        fill="#F2F2F2"
      />
      <path
        d="M167.93 44.8424C167.929 44.2673 167.866 43.6939 167.742 43.1323H162.045C161.642 43.1323 161.256 43.2925 160.971 43.5776C160.686 43.8626 160.526 44.2493 160.526 44.6524V52.794C162.536 52.6513 164.417 51.7516 165.791 50.2762C167.165 48.8008 167.929 46.8592 167.93 44.8424Z"
        fill={color}
      />
      <path
        d="M159.956 53.2028C158.381 53.2034 156.837 52.758 155.504 51.918C154.17 51.0781 153.102 49.8778 152.421 48.4559C151.74 47.0339 151.474 45.4482 151.655 43.8817C151.836 42.3153 152.456 40.8321 153.444 39.6032C154.431 38.3744 155.745 37.45 157.235 36.9369C158.725 36.4238 160.329 36.3429 161.863 36.7034C163.397 37.0639 164.798 37.8512 165.903 38.9744C167.009 40.0976 167.775 41.5109 168.113 43.0512L168.213 43.5123H162.045C161.743 43.5126 161.453 43.6329 161.239 43.8466C161.026 44.0603 160.906 44.3501 160.906 44.6524V53.1481L160.487 53.1778C160.311 53.1905 160.135 53.2028 159.956 53.2028ZM159.956 37.242C157.942 37.242 156.011 38.0427 154.587 39.4681C153.163 40.8934 152.362 42.8266 152.362 44.8424C152.362 46.8581 153.163 48.7913 154.587 50.2167C156.011 51.642 157.942 52.4428 159.956 52.4428C160.02 52.4428 160.083 52.4409 160.146 52.4379V44.6524C160.147 44.1486 160.347 43.6656 160.703 43.3094C161.059 42.9532 161.541 42.7528 162.045 42.7523H167.255C166.795 41.1688 165.835 39.7766 164.52 38.7835C163.205 37.7904 161.604 37.2497 159.956 37.242Z"
        fill="white"
      />
      <path
        d="M171.157 55.6731H162.044C161.541 55.6725 161.059 55.4721 160.703 55.1159C160.347 54.7597 160.147 54.2768 160.146 53.773V52.4402L160.499 52.4151C161.586 52.34 162.644 52.031 163.601 51.5092C164.557 50.9874 165.39 50.265 166.043 49.3915C166.695 48.518 167.151 47.5139 167.381 46.4477C167.61 45.3816 167.606 44.2785 167.371 43.2137L167.27 42.7524H171.157C171.66 42.753 172.143 42.9534 172.499 43.3096C172.855 43.6658 173.055 44.1488 173.055 44.6525V53.773C173.055 54.2768 172.855 54.7597 172.499 55.1159C172.143 55.4721 171.66 55.6725 171.157 55.6731ZM160.905 53.1406V53.773C160.906 54.0753 161.026 54.365 161.239 54.5788C161.453 54.7925 161.742 54.9127 162.044 54.9131H171.157C171.459 54.9127 171.749 54.7925 171.962 54.5788C172.176 54.365 172.296 54.0753 172.296 53.773V44.6525C172.296 44.3503 172.176 44.0605 171.962 43.8468C171.749 43.633 171.459 43.5128 171.157 43.5125H168.2C168.272 43.9522 168.309 44.397 168.309 44.8425C168.302 46.8925 167.545 48.8689 166.181 50.3979C164.817 51.927 162.94 52.9027 160.905 53.1406Z"
        fill="white"
      />
    </svg>
  );
};
