/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// React, Redux
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// MUI
import { Popover, Box } from "@material-ui/core";

// Actions
import { getActiveLanguages } from "Modules/units/App";

// Atoms
import IconButton from "Components/atoms/buttons/IconButton";

// Molecules
import ListItem from "Components/molecules/ListItem";

import { usePersistedState, getNameFromList } from "Util/functions";

const ChangeLanguageNew = props => {
  const { color, setLang, getData } = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const [administrationLang, setAdministrationLang] = usePersistedState(
    "administrationLang",
    ""
  );

  const lists = useSelector(state => state.lists);
  const activeLangs = useSelector(state => state.app.activeLangs);

  useEffect(() => {
    dispatch(getActiveLanguages());
  }, []);

  useEffect(() => {
    if (administrationLang === "" || activeLangs[0]?.language) {
      setAdministrationLang(
        getNameFromList(lists, "Languages", activeLangs[0]?.language)
      );
    } else {
      setAdministrationLang("en");
    }
    return () => {};
  }, [activeLangs]);

  const changeLanguage = value => {
    setAdministrationLang(value);

    setAnchorEl(null);
    setLang(value);
    getData(value);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box component="span" onClick={handleClick} style={{ cursor: "pointer" }}>
        <IconButton
          icon="language"
          color={color}
          styleProp={{
            padding: "0px",
            marginLeft: "10px",
            marginBottom: "7px",
            verticalAlign: "middle"
          }}
        />
        {administrationLang}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {activeLangs?.map(item => (
          <ListItem
            key={item.id}
            primaryLabel={`${getNameFromList(
              lists,
              "Languages",
              item.language
            )}`}
            clickable
            onClick={() =>
              changeLanguage(getNameFromList(lists, "Languages", item.language))
            }
            selected={
              administrationLang ===
              getNameFromList(lists, "Languages", item.language)
            }
          />
        ))}
      </Popover>
    </>
  );
};

ChangeLanguageNew.defaultProps = {
  color: "primary"
};

export default ChangeLanguageNew;
