/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { postFunc, getFunc, putFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const CREATE_CAMPAIGN_DETAILS = "auth/CREATE_CAMPAIGN_DETAILS";

const ALTER_CAMPAIGN_DETAILS = "auth/ALTER_CAMPAIGN_DETAILS";

const GET_ONE_CAMPAIGN_DETAILS_REQ = "auth/GET_ONE_CAMPAIGN_DETAILS_REQ";
const GET_ONE_CAMPAIGN_DETAILS_SCS = "auth/GET_ONE_CAMPAIGN_DETAILS_SCS";
const GET_ONE_CAMPAIGN_DETAILS_FLR = "auth/GET_ONE_CAMPAIGN_DETAILS_FLR";

const GET_ALL_CAMPAIGN_REQ = "auth/GET_ALL_CAMPAIGN_REQ";
const GET_ALL_CAMPAIGN_SCS = "auth/GET_ALL_CAMPAIGN_SCS";
const GET_ALL_CAMPAIGN_FLR = "auth/GET_ALL_CAMPAIGN_FLR";

const GET_ALL_GUESTS_REQ = "auth/GET_ALL_GUESTS_REQ";
const GET_ALL_GUESTS_SCS = "auth/GET_ALL_GUESTS_SCS";
const GET_ALL_GUESTS_FLR = "auth/GET_ALL_GUESTS_FLR";

const CREATE_AUDIENCE = "auth/CREATE_AUDIENCE";

const ALTER_AUDIENCE = "auth/ALTER_AUDIENCE";

const GET_ONE_AUDIENCE_REQ = "auth/GET_ONE_AUDIENCE_REQ";
const GET_ONE_AUDIENCE_SCS = "auth/GET_ONE_AUDIENCE_SCS";
const GET_ONE_AUDIENCE_FLR = "auth/GET_ONE_AUDIENCE_FLR";

const GET_ONE_CAMPAIGN_PREVIEW_REQ = "auth/GET_ONE_CAMPAIGN_PREVIEW_REQ";
const GET_ONE_CAMPAIGN_PREVIEW_SCS = "auth/GET_ONE_CAMPAIGN_PREVIEW_SCS";
const GET_ONE_CAMPAIGN_PREVIEW_FLR = "auth/GET_ONE_CAMPAIGN_PREVIEW_FLR";

const ACTIVATE_CAMPAIGN_REQ = "auth/ACTIVATE_CAMPAIGN_REQ";
const ACTIVATE_CAMPAIGN_SCS = "auth/ACTIVATE_CAMPAIGN_SCS";
const ACTIVATE_CAMPAIGN_FLR = "auth/ACTIVATE_CAMPAIGN_FLR";

const EMPTY_STATE = "auth/EMPTY_STATE";

/**
 * ACTIONS
 */

export const clearValidation = () => async dispatch => {
  dispatch({ type: VALIDATION_CLEAR });
};

export const getAllCampaigns = (body, lang) => async dispatch => {
  dispatch({ type: GET_ALL_CAMPAIGN_REQ });
  const response = await postFunc(`campaigns/pagination/${lang}`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_CAMPAIGN_SCS,
      payload: response
    });
  } else {
    dispatch({ type: GET_ALL_CAMPAIGN_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const createCampaignDetails = body => async dispatch => {
  const response = await postFunc("campaigns/firstStep", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: CREATE_CAMPAIGN_DETAILS, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });
    localStorage.setItem("item_id", JSON.stringify(response.data.id));
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const getOneCampaignDetails = (id, lang) => async dispatch => {
  dispatch({ type: GET_ONE_CAMPAIGN_DETAILS_REQ });
  const response = await getFunc(`campaigns/firstStep/${lang}/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ONE_CAMPAIGN_DETAILS_SCS,
      payload: response.data
    });
  } else {
    dispatch({ type: GET_ONE_CAMPAIGN_DETAILS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const alterCampaignDetails = (
  body,
  id,
  handleNext
) => async dispatch => {
  const response = await putFunc(`campaigns/firstStep/${id}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: ALTER_CAMPAIGN_DETAILS, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });

    NotificationManager.success(response.status.description);
    handleNext();
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const getAllGuests = body => async dispatch => {
  dispatch({ type: GET_ALL_GUESTS_REQ });
  const response = await postFunc("guest/campaign/pagination", body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_GUESTS_SCS,
      payload: response
    });
  } else {
    dispatch({ type: GET_ALL_GUESTS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const createCampaignAudience = (body, handleNext) => async dispatch => {
  const response = await postFunc("campaigns/secondStep", body);

  if (response.status.errorCode === 200) {
    handleNext();
    dispatch({ type: CREATE_AUDIENCE, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });

    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const getOneCampaignAudience = id => async dispatch => {
  dispatch({ type: GET_ONE_AUDIENCE_REQ });
  const response = await getFunc(`campaigns/secondStep/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ONE_AUDIENCE_SCS,
      payload: response.data
    });
  } else {
    dispatch({ type: GET_ONE_AUDIENCE_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const alterCampaignAudience = (
  body,
  id,
  handleNext
) => async dispatch => {
  const response = await putFunc(`campaigns/secondStep/${id}`, body);

  if (response.status.errorCode === 200) {
    handleNext();
    dispatch({ type: ALTER_AUDIENCE, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });

    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const getCampaignPreview = (id, lang) => async dispatch => {
  dispatch({ type: GET_ONE_CAMPAIGN_PREVIEW_REQ });
  const response = await getFunc(`campaigns/preview/${lang}/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ONE_CAMPAIGN_PREVIEW_SCS,
      payload: response.data
    });
  } else {
    dispatch({ type: GET_ONE_CAMPAIGN_PREVIEW_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const campaignActivate = (id, handleReturnToStart) => async dispatch => {
  dispatch({ type: ACTIVATE_CAMPAIGN_REQ });
  const response = await getFunc(`campaigns/activation/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: ACTIVATE_CAMPAIGN_SCS,
      payload: response.data
    });
    handleReturnToStart();
    NotificationManager.success(response.status.description);
  } else {
    dispatch({ type: ACTIVATE_CAMPAIGN_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const clearState = () => async dispatch => {
  dispatch({ type: EMPTY_STATE });
};

/**
 * REDUCERS
 */
const INIT_STATE = {
  loading: false,
  data: [],
  detailsCreated: null,
  detailsOne: null,
  allGuests: null,
  audienceCreated: null,
  audienceOne: null,
  previewOne: null,
  activateCampaign: null
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_CAMPAIGN_REQ:
      return {
        ...state,
        loading: true,
        data: state.data
      };
    case GET_ALL_CAMPAIGN_SCS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case GET_ALL_CAMPAIGN_FLR:
      return {
        ...state,
        loading: false,
        data: state.data
      };

    case CREATE_CAMPAIGN_DETAILS:
      return {
        ...state,
        loading: false,
        detailsCreated: action.payload,
        data: {
          ...state.data,
          data: [...state.data.data, action.payload]
        }
      };

    case GET_ONE_CAMPAIGN_DETAILS_REQ:
      return {
        ...state,
        loading: true,
        detailsOne: state.detailsOne
      };
    case GET_ONE_CAMPAIGN_DETAILS_SCS:
      return {
        ...state,
        loading: false,
        detailsOne: action.payload
      };
    case GET_ONE_CAMPAIGN_DETAILS_FLR:
      return {
        ...state,
        loading: false,
        detailsOne: state.detailsOne
      };

    case ALTER_CAMPAIGN_DETAILS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,

          data: state.data.data.map(item => {
            if (item.id === action.payload.id) {
              return { ...action.payload };
            }
            return item;
          })
        }
      };

    case GET_ALL_GUESTS_REQ:
      return {
        ...state,
        loading: true,
        allGuests: state.allGuests
      };
    case GET_ALL_GUESTS_SCS:
      return {
        ...state,
        loading: false,
        allGuests: action.payload
      };
    case GET_ALL_GUESTS_FLR:
      return {
        ...state,
        loading: false,
        allGuests: state.allGuests
      };

    case CREATE_AUDIENCE:
      return {
        ...state,
        audienceCreated: action.payload
      };

    case GET_ONE_AUDIENCE_REQ:
      return {
        ...state,
        loading: true,
        audienceOne: state.audienceOne
      };
    case GET_ONE_AUDIENCE_SCS:
      return {
        ...state,
        loading: false,
        audienceOne: action.payload
      };
    case GET_ONE_AUDIENCE_FLR:
      return {
        ...state,
        loading: false,
        audienceOne: state.audienceOne
      };

    case GET_ONE_CAMPAIGN_PREVIEW_REQ:
      return {
        ...state,
        loading: true,
        previewOne: state.previewOne
      };
    case GET_ONE_CAMPAIGN_PREVIEW_SCS:
      return {
        ...state,
        loading: false,
        previewOne: action.payload
      };
    case GET_ONE_CAMPAIGN_PREVIEW_FLR:
      return {
        ...state,
        loading: false,
        previewOne: state.previewOne
      };

    case ACTIVATE_CAMPAIGN_REQ:
      return {
        ...state,
        loading: true,
        activateCampaign: state.activateCampaign
      };
    case ACTIVATE_CAMPAIGN_SCS:
      return {
        ...state,

        activateCampaign: {
          ...state.data,
          data: state.data.data.filter(item => {
            if (item.id === action.payload.id) {
              item.status = action.payload.status;
              return item;
            }
          })
        }
      };

    case ACTIVATE_CAMPAIGN_FLR:
      return {
        ...state,
        loading: false,
        activateCampaign: state.activateCampaign
      };

    case ALTER_AUDIENCE:
      return {
        ...state,
        loading: false,
        audienceOne: action.payload
      };

    case EMPTY_STATE:
      return {
        ...state,
        loading: false,
        detailsOne: null,
        audienceOne: null,
        allGuests: null
      };

    default:
      return state;
  }
}
