/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import { dataURLtoFile } from "Util/functions";

const changeImagesToFiles = async array => {
  let imageName = "";

  const imageArray = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const item of array) {
    await (async function getImages() {
      const blob = await fetch(item.path).then(r => r.blob());
      const dataUrl = await new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
      imageName = item.path.split("/")[item.path.split("/").length - 1];
      if (imageName !== "default.jpg") {
        if (item.cover) {
          imageArray.unshift(
            dataURLtoFile(
              dataUrl,
              imageName,
              item.id,
              URL.createObjectURL(blob)
            )
          );
        } else {
          imageArray.push(
            dataURLtoFile(
              dataUrl,
              imageName,
              item.id,
              URL.createObjectURL(blob)
            )
          );
        }
      }
    })();
  }

  return imageArray;
};

export default changeImagesToFiles;
