/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, putFunc, postFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_REPORT_DETAILS_APPOINTMENTS_REQ =
  "auth/GET_REPORT_DETAILS_APPOINTMENTS_REQ";
const GET_REPORT_DETAILS_APPOINTMENTS_SCS =
  "auth/GET_REPORT_DETAILS_APPOINTMENTS_SCS";
const GET_REPORT_DETAILS_APPOINTMENTS_FLR =
  "auth/GET_REPORT_DETAILS_APPOINTMENTS_FLR";

const GET_REPORT_DETAILS_ORDERS_REQ = "auth/GET_REPORT_DETAILS_ORDERS_REQ";
const GET_REPORT_DETAILS_ORDERS_SCS = "auth/GET_REPORT_DETAILS_ORDERS_SCS";
const GET_REPORT_DETAILS_ORDERS_FLR = "auth/GET_REPORT_DETAILS_ORDERS_FLR";

const CLEAR_REPORT_DETAILS_REQ = "auth/CLEAR_REPORT_DETAILS_REQ";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/
export const getReportDetailsForAppointments = (body) => async (dispatch) => {
  dispatch({ type: GET_REPORT_DETAILS_APPOINTMENTS_REQ });
  const response = await postFunc(`statistics/appointments`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_REPORT_DETAILS_APPOINTMENTS_SCS,
      payload: response.data,
      date: body.date_from,
      dateTo: body.date_to,
    });
    NotificationManager.success(response.status.description);
  } else {
    dispatch({ type: GET_REPORT_DETAILS_APPOINTMENTS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const getReportDetailsForOrders = (body) => async (dispatch) => {
  dispatch({ type: GET_REPORT_DETAILS_ORDERS_REQ });
  const response = await postFunc(`statistics/orders`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_REPORT_DETAILS_ORDERS_SCS,
      payload: response.data,
      date: body.date_from,
      dateTo: body.date_to,
    });
    NotificationManager.success(response.status.description);
  } else {
    dispatch({ type: GET_REPORT_DETAILS_ORDERS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const clearReportDetails = () => async (dispatch) => {
  dispatch({ type: CLEAR_REPORT_DETAILS_REQ });
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/
const INIT_STATE = {
  loading: false,
  data: null,
  orders: null,
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_REPORT_DETAILS_APPOINTMENTS_REQ:
      return {
        ...state,
        loading: true,
        data: null,
      };
    case GET_REPORT_DETAILS_APPOINTMENTS_SCS:
      //uzimam listu datuma koji su poslani na servis
      const listDate = [];
      const startDate = action.date;
      const endDate = action.dateTo;
      const dateMove = new Date(startDate);
      let strDate = startDate;

      while (strDate < endDate) {
        strDate = dateMove.toISOString().slice(0, 10);
        listDate.push(strDate);
        dateMove.setDate(dateMove.getDate() + 1);
      }

      //datume postavljam u statistics kao objekt (jer se iz objekta čitaju u tablici)
      //pravim kolonu Datum gdje će se ispisivati svi datumi po redu
      const datumi = [
        {
          calendar: "Date",
          services: [{ name: " ", statistics: Object.assign({}, listDate) }],
          brojac: Object.entries(Object.assign({}, listDate)),
        },
      ];

      function getStatisticsSum() {
        const temp = [];
        action.payload.map((i) => {
          return i.services.map((j) => {
            temp.push(j.statistics);
          });
        });

        return temp.reduce((acc, n) => {
          for (var prop in n) {
            if (acc.hasOwnProperty(prop)) {
              acc[prop].quantity += n[prop]?.quantity;
              acc[prop].amount += n[prop]?.amount;
            } else
              acc[prop] = {
                quantity: n[prop]?.quantity,
                amount: n[prop]?.amount,
              };
          }
          return acc;
        }, {});
      }

      const ukupno = [
        {
          calendar: "Total (Total amount)",
          services: [
            {
              name: " ",
              statistics: Object.assign({}, getStatisticsSum()),
            },
          ],
          brojac: Object.entries(Object.assign({}, getStatisticsSum())),
        },
      ];

      //brojac je bitan jer se tablica renderira onoliko puta kolika je duljina arrayja brojaca
      return {
        ...state,
        loading: false,
        data:
          action.payload?.length > 0
            ? datumi
                .concat(
                  action.payload.map((i, index) => {
                    return {
                      ...i,
                      services: i.services,
                      brojac: Object.entries(
                        i.services?.length > 0 && i.services[0]?.statistics
                      ),
                    };
                  })
                )
                .concat(ukupno)
            : null,
      };
    case GET_REPORT_DETAILS_APPOINTMENTS_FLR:
      return {
        ...state,
        loading: false,
        data: state.data,
      };
    case GET_REPORT_DETAILS_ORDERS_REQ:
      return {
        ...state,
        loading: true,
        orders: null,
      };
    case GET_REPORT_DETAILS_ORDERS_SCS:
      //uzimam listu datuma koji su poslani na servis
      const listDate2 = [];
      const startDate2 = action.date;
      const endDate2 = action.dateTo;
      const dateMove2 = new Date(startDate2);
      let strDate2 = startDate2;

      while (strDate2 < endDate2) {
        strDate2 = dateMove2.toISOString().slice(0, 10);
        listDate2.push(strDate2);
        dateMove2.setDate(dateMove2.getDate() + 1);
      }

      //datume postavljam u statistics kao objekt (jer se iz objekta čitaju u tablici)
      //pravim kolonu Datum gdje će se ispisivati svi datumi po redu
      const datumi2 = [
        {
          name: "Date",
          services: [
            { name: "Date", statistics: Object.assign({}, listDate2) },
          ],
          brojac: Object.entries(Object.assign({}, listDate2)),
        },
      ];

      function getStatisticsSum2() {
        const temp = [];
        action.payload.map((i) => {
          temp.push(i.statistics);
        });

        return temp.reduce((acc, n) => {
          for (var prop in n) {
            if (acc.hasOwnProperty(prop)) {
              acc[prop].quantity += n[prop]?.quantity;
              acc[prop].amount += n[prop]?.amount;
            } else
              acc[prop] = {
                quantity: n[prop]?.quantity,
                amount: n[prop]?.amount,
              };
          }
          return acc;
        }, {});
      }

      const ukupno2 = [
        {
          name: "Total (Total amount)",
          services: [
            {
              name:  "Total (Total amount)",
              statistics: Object.assign({}, getStatisticsSum2()),
            },
          ],
          brojac: Object.entries(Object.assign({}, getStatisticsSum2())),
        },
      ];

      //brojac je bitan jer se tablica renderira onoliko puta kolika je duljina arrayja brojaca
      return {
        ...state,
        loading: false,
        orders:
          action.payload?.length > 0
            ? datumi2
                .concat(
                  action.payload.map((i, index) => {
                    return {
                      ...i,
                      services: [
                        {
                          name: i.name,
                          statistics: Object.assign({}, i.statistics),
                        },
                      ],
                      brojac: Object.entries(i.statistics),
                    };
                  })
                )
                .concat(ukupno2)
            : null,
      };
    case GET_REPORT_DETAILS_ORDERS_FLR:
      return {
        ...state,
        loading: false,
        orders: state.orders,
      };
    case CLEAR_REPORT_DETAILS_REQ:
      return {
        ...state,
        loading: false,
        data: null,
        orders: null,
      };
    default:
      return state;
  }
}
