import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material-UI
import {
  Paper,
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Hidden,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Atoms
import Logo from "Components/atoms/UI/Logo";

const style = makeStyles((theme) => ({
  container: {
    position: "fixed",
    height: "100%",
  },
  logo: {
    flexGrow: 1,
    padding: "20px",
    borderBottom: "2px solid #e8e8e8",
    textAlign: "center",
  },
  navLinks: {
    color: "#595959",
    textDecoration: "none",
    padding: "0",
    minHeight: "unset",
    "&:hover": {
      background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
      color: "#fff",
    },
    "&.Mui-expanded": {
      margin: "unset",
      minHeight: "unset",
    },
  },
  navLinksActive: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    color: "#fff",
  },
  listText: {
    fontSize: "13px",
  },
  summaryContent: {
    margin: 0,
    "&.Mui-expanded": {
      margin: "unset",
    },
  },
  accordionContent: {
    margin: 0,
    boxShadow: "unset",
    borderBottom: "2px solid #e8e8e8",
    "&.Mui-expanded": {
      margin: "unset",
    },
  },
  summaryRoot: {
    paddingLeft: 0,
    color: "#595959",
    minHeight: "unset",
  },
  expansionDetails: {
    padding: 0,
    color: "inherit",
    background: "inherit",
  },
  iconRoot: {
    color: "inherit",
    padding: "0 12px",
    margin: "auto",
  },
  panelRoot: {
    boxShadow: "unset",
    "&:before": {
      background: "inherit",
    },
    "&.Mui-expanded": {
      margin: "unset",
    },
  },
}));

const MenuLink = ({ value, classes, style, t }) => (
  <NavLink
    to={value.linkTo}
    exact
    className={classes.navLinks}
    key={value.name}
    activeClassName={classes.navLinksActive}
  >
    <ListItem key={value.name} style={style}>
      <ListItemIcon style={{ color: "inherit" }}>
        <Icon
          style={{
            display: "block",
            margin: "0 auto",
          }}
        >
          {value.icon}
        </Icon>
      </ListItemIcon>
      <ListItemText
        primary={t(value.name).toUpperCase()}
        classes={{ primary: classes.listText }}
      />
    </ListItem>
  </NavLink>
);

const ExpandableMenuLinks = ({ classes, value, index, style, t }) => (
  <Accordion
    key={value.name}
    classes={{ root: classes.accordionContent }}
    data-cy="expendableMenu"
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={index}
      classes={{
        root: classes.navLinks,
        content: classes.summaryContent,
        expandIcon: classes.iconRoot,
      }}
    >
      <ListItem
        key={value.name}
        style={{
          background: "inherit",
          padding: "8px 20px 8px 0px",
        }}
      >
        <ListItemIcon style={{ color: "inherit" }}>
          <Icon
            style={{
              display: "block",
              margin: "0 auto",
            }}
          >
            {value.icon}
          </Icon>
        </ListItemIcon>
        <ListItemText
          primary={t(value.name).toUpperCase()}
          classes={{ primary: classes.listText }}
        />
      </ListItem>
    </AccordionSummary>
    <List style={{ padding: "0" }}>
      {value.childRoutes.map((item) => (
        <MenuLink
          key={item.id}
          classes={classes}
          value={item}
          style={style}
          t={t}
        />
      ))}
    </List>
  </Accordion>
);

const Sidebar = () => {
  const classes = style();
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const { t } = useTranslation();

  return (
    <Hidden mdDown>
      <aside>
        <Paper className={classes.container} elevation={3}>
          <Box className={classes.logo}>
            <Logo
              width="115px"
              goTo="/"
              src={app.theme.logo}
              type="dark"
              // tag="ALPHA"
            />
          </Box>
          <List style={{ padding: "0" }} id="sidebar">
            {auth?.meni.map((item, index) => {
              if (item.childRoutes.length !== 0 && item.show_hide !== 0) {
                return (
                  <ExpandableMenuLinks
                    classes={classes}
                    key={item.id}
                    value={item}
                    index={index}
                    style={{
                      background: "inherit",
                      padding: "8px 20px 8px 10px",
                    }}
                    t={t}
                  />
                );
              }
              if (item?.show_hide !== 0) {
                return (
                  <MenuLink
                    value={item}
                    key={item.id}
                    classes={classes}
                    style={{
                      background: "inherit",
                      borderBottom: "2px solid #e8e8e8",
                      padding: "8px 20px 8px 0",
                    }}
                    t={t}
                  />
                );
              }
            })}
          </List>
        </Paper>
      </aside>
    </Hidden>
  );
};

export default Sidebar;
