/* eslint-disable no-case-declarations */
/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import {
	getFunc,
	postFunc,
	deleteFunc,
	putFunc,
} from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const CREATE_KEYS = "auth/CREATE_KEYS";

const DELETE_KEYS = "auth/DELETE_KEYS";

const CLEAR_STATE = "auth/CLEAR_STATE";

const GET_ALL_KEYS_REQ = "auth/GET_ALL_KEYS_REQ";
const GET_ALL_KEYS_SCS = "auth/GET_ALL_KEYS_SCS";
const GET_ALL_KEYS_FLR = "auth/GET_ALL_KEYS_FLR";

const GET_ONE_KEY_REQ = "auth/GET_ONE_KEY_REQ";
const GET_ONE_KEY_SCS = "auth/GET_ONE_KEY_SCS";
const GET_ONE_KEY_FLR = "auth/GET_ONE_KEY_FLR";

const ALTER_KEYS_REQ = "auth/ALTER_KEYS_REQ";
const ALTER_KEYS_SCS = "auth/ALTER_KEYS_SCS";
const ALTER_KEYS_FLR = "auth/ALTER_KEYS_FLR";

const GET_KEY_CARD_TEMPLATE_REQ = "auth/GET_KEY_CARD_TEMPLATE_REQ";
const GET_KEY_CARD_TEMPLATE_SCS = "auth/GET_KEY_CARD_TEMPLATE_SCS";
const GET_KEY_CARD_TEMPLATE_FLR = "auth/GET_KEY_CARD_TEMPLATE_FLR";

const ALTER_CARD_TEMPLATE = "auth/ALTER_CARD_TEMPLATE";
const CREATE_CARD_TEMPLATE = "auth/CREATE_CARD_TEMPLATE";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/

export const getAllKeys = (body) => async (dispatch) => {
	dispatch({ type: GET_ALL_KEYS_REQ });
	const response = await postFunc("keyGenerators/getAllKeysPagination", body);

	if (response.data.status.errorCode === 200) {
		dispatch({
			type: GET_ALL_KEYS_SCS,
			payload: response.data,
			total: response.data.total,
		});
		NotificationManager.success(response.data.status.description);
	} else {
		dispatch({ type: GET_ALL_KEYS_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const createKeys = (body, setOpen, ifChecked, setPrint) => async (
	dispatch
) => {
	const response = await postFunc("keyGenerators", body);

	if (response.status.errorCode === 200) {
		dispatch({ type: CREATE_KEYS, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		if (ifChecked) {
			setPrint(true);
		}
		setOpen();

		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const getOneKey = (id) => async (dispatch) => {
	dispatch({ type: GET_ONE_KEY_REQ });
	const response = await getFunc(`keyGenerators/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ONE_KEY_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_ONE_KEY_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const deactivateKey = (id, setOpen, handleTotalUpdate) => async (
	dispatch
) => {
	const response = await deleteFunc(`keyGenerators/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({ type: DELETE_KEYS, payload: response.data });
		setOpen();
		handleTotalUpdate();
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const changeStatusToPrinted = (
	body,
	handleSearch,
	handleClearChecks = () => {}
) => async (dispatch) => {
	dispatch({ type: ALTER_KEYS_REQ });
	const response = await postFunc("keyGenerators/setToAlreadyPrinted", body);

	if (response.status.errorCode === 200) {
		dispatch({
			type: ALTER_KEYS_SCS,
			payload: response.data,
		});
		handleSearch();
		handleClearChecks();
	} else {
		dispatch({ type: ALTER_KEYS_FLR });
		NotificationManager.error(response.status.description);
		handleClearChecks();
	}
};

export const clearState = () => async (dispatch) => {
	dispatch({ type: CLEAR_STATE });
};

// CARD TEMPLATES
export const getKeyCardTemplate = () => async (dispatch) => {
	dispatch({ type: GET_KEY_CARD_TEMPLATE_REQ });
	const response = await getFunc(`adminKeyCard`);

	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_KEY_CARD_TEMPLATE_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_KEY_CARD_TEMPLATE_SCS });
		//NotificationManager.error(response.status.description);
	}
};

export const createKeyCardTemplate = (body, func) => async (dispatch) => {
	const response = await postFunc(`adminKeyCard`, body);

	if (response.status.errorCode === 200) {
		dispatch({ type: CREATE_CARD_TEMPLATE, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		func();
		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const alterKeyCardTemplate = (body, func) => async (dispatch) => {
	const response = await putFunc(`adminKeyCard`, body);

	if (response.status.errorCode === 200) {
		dispatch({ type: ALTER_CARD_TEMPLATE, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		func();
		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};
/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/
const INIT_STATE = {
	loading: false,
	data: [],
	keyCardTemplate: {},
	createKeys: null,
	oneKey: null,
};

export default function reducer(state = INIT_STATE, action = {}) {
	switch (action.type) {
		case GET_ALL_KEYS_REQ:
			return {
				...state,
				loading: true,
				data: state.data,
			};
		case GET_ALL_KEYS_SCS:
			return {
				...state,
				loading: false,
				data: action.payload.data,
				total: action.total,
			};
		case GET_ALL_KEYS_FLR:
			return {
				...state,
				loading: false,
				data: state.data,
			};

		case GET_ONE_KEY_REQ:
			return {
				...state,
				loading: true,
				oneKey: state.oneKey,
			};
		case GET_ONE_KEY_SCS:
			return {
				...state,
				loading: false,
				oneKey: action.payload,
			};
		case GET_ONE_KEY_FLR:
			return {
				...state,
				loading: false,
				oneKey: state.oneKey,
			};

		case CREATE_KEYS:
			return {
				...state,
				loading: false,
				createKeys: action.payload,
				data: [...state.data, action.payload],
			};

		case DELETE_KEYS:
			const deletedKey = action.payload.id;
			const allKeys = state.data;
			return {
				...state,
				data: allKeys.filter((r) => !deletedKey.includes(r.id)),
			};
		case CLEAR_STATE:
			return {
				...state,
				oneKey: null,
				createKeys: null,
			};
		//
		case GET_KEY_CARD_TEMPLATE_REQ:
			return {
				...state,
				loading: true,
				keyCardTemplate: state.keyCardTemplate,
			};
		case GET_KEY_CARD_TEMPLATE_SCS:
			return {
				...state,
				loading: false,
				keyCardTemplate: action.payload,
			};
		case GET_KEY_CARD_TEMPLATE_SCS:
			return {
				...state,
				loading: false,
				keyCardTemplate: state.keyCardTemplate,
			};
		case CREATE_CARD_TEMPLATE:
			return {
				...state,
				loading: false,
				keyCardTemplate: action.payload,
			};
		case ALTER_CARD_TEMPLATE:
			return {
				...state,
				loading: false,
				keyCardTemplate: action.payload,
			};
		//
		default:
			return state;
	}
}
