/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_ALL_LISTS = "GET_ALL_LISTS";
/**
 * ACTIONS
 */

export const getAllLists = () => async dispatch => {
  const response = await getFunc("list/getAllWithListItems");

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ALL_LISTS, payload: response.data });

    localStorage.setItem("lists", JSON.stringify(response.data));
  } else {
    NotificationManager.error(response.status.description);
  }
};

/**
 * REDUCERS
 */
const INIT_STATE = {
  data: JSON.parse(localStorage.getItem("lists")) || []
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_LISTS:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
}
