// React
import React from "react";

// Router
import { Router, Route, Switch } from "react-router-dom";
import MainRouter from "Router/MainRouter";
import { createBrowserHistory } from "history";
import { ErrorBoundary } from "react-error-boundary";

// Notification manager
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

// HMR
import { hot } from "react-hot-loader";

// Redux
import { Provider } from "react-redux";
import configureStore from "Modules/configureStore";

// Theme
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "Styles/theme";
import "Styles/App.css";
import CssBaseline from "@material-ui/core/CssBaseline";

// Localization
import "./localization/i18n";

// Date
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Page
import SwwPage from "Components/molecules/SwwPage.js";

require("babel-polyfill");

const history = createBrowserHistory();

function ErrorFallback({}) {
	return (
		<SwwPage
			resetErrorBoundary={() => {
				window.location.reload();
			}}
			goToHome={() => {
				history.push("/");
				window.location.reload();
			}}
		/>
	);
}
const App = () => {
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Provider store={configureStore()}>
				<NotificationContainer />
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Router history={history} key={Math.random()}>
						<Switch>
							<ErrorBoundary FallbackComponent={ErrorFallback}>
								<Route path="/" component={MainRouter} />
							</ErrorBoundary>
						</Switch>
					</Router>
				</ThemeProvider>
			</Provider>
		</MuiPickersUtilsProvider>
	);
};

export default hot(module)(App);
