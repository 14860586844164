import React from "react";
import { useTheme } from "@material-ui/core";

export const ServicesHelpImg = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  return (
    <svg
      width="100%"
      height="200px"
      viewBox="0 0 353 269"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M138.567 55.602L139.856 55.9169C139.274 56.0003 138.693 56.08 138.111 56.1521H116.623C115.011 55.9472 113.408 55.6943 111.815 55.3933C102.215 53.626 93.0534 50.0031 84.8454 44.7281C79.802 41.4538 74.994 37.4283 72.3831 32.0218C68.8371 24.6802 70.076 15.3847 75.1386 8.99171C76.9817 6.68514 79.2512 4.75316 81.8239 3.30059C82.2076 3.08053 82.5953 2.87186 82.9906 2.67457C87.2761 0.524732 92.0887 -0.354765 96.8592 0.140121C104.974 1.02414 112.461 5.97162 116.782 12.8844C121.559 20.5181 122.475 29.8591 123.274 38.8245C124.722 34.173 130.157 31.3312 134.926 32.3594C139.692 33.3838 143.371 37.8267 143.964 42.6604C144.546 47.3878 142.395 52.2404 138.811 55.3933C138.731 55.4616 138.651 55.5337 138.567 55.602Z"
          fill="#F2F2F2"
        />
        <path
          d="M121.997 56.1524H120.921C120.815 56.0676 120.732 55.9564 120.682 55.8299C120.621 55.6858 120.564 55.5378 120.499 55.3936C117.303 47.6642 113.368 40.2606 108.748 33.2855C101.406 22.1827 92.4135 12.2612 82.0786 3.86244C81.9902 3.79968 81.9201 3.71464 81.8754 3.61599C81.8307 3.51735 81.8129 3.40863 81.824 3.30092C81.8458 3.15717 81.9051 3.0217 81.9961 2.90819C82.0871 2.79468 82.2065 2.70714 82.3422 2.65441C82.4779 2.60168 82.6251 2.58563 82.7691 2.60788C82.913 2.63013 83.0485 2.68989 83.1618 2.78113C89.8581 8.2253 96.0005 14.3152 101.5 20.9624C107.002 27.6014 111.847 34.7577 115.965 42.3306C118.263 46.5693 120.327 50.9302 122.149 55.3936C122.153 55.405 122.156 55.4126 122.16 55.424C122.221 55.5456 122.238 55.6845 122.208 55.8172C122.179 55.9499 122.104 56.0683 121.997 56.1524Z"
          fill="white"
        />
        <path
          d="M212.076 55.3936H7.22121C5.30656 55.3953 3.47082 56.1553 2.11696 57.5069C0.763094 58.8584 0.00173293 60.691 0 62.6023V84.6079C0.00173293 86.5193 0.763094 88.3519 2.11696 89.7034C3.47082 91.0549 5.30656 91.815 7.22121 91.8167H212.076C213.99 91.815 215.826 91.0549 217.18 89.7034C218.534 88.3519 219.295 86.5193 219.297 84.6079V62.6023C219.295 60.691 218.534 58.8584 217.18 57.5069C215.826 56.1553 213.99 55.3953 212.076 55.3936ZM218.537 84.6079C218.534 86.3178 217.853 87.957 216.642 89.1661C215.43 90.3751 213.788 91.0555 212.076 91.0579H7.22121C5.50837 91.0555 3.86638 90.3751 2.65522 89.1661C1.44405 87.957 0.762552 86.3178 0.760128 84.6079V62.6023C0.762551 60.8924 1.44405 59.2533 2.65522 58.0442C3.86638 56.8351 5.50837 56.1548 7.22121 56.1524H212.076C213.788 56.1548 215.43 56.8351 216.642 58.0442C217.853 59.2533 218.534 60.8924 218.537 62.6023V84.6079Z"
          fill="#3F3D56"
        />
        <path
          d="M212.076 143.796H7.22121C5.3067 143.794 3.47122 143.033 2.11745 141.682C0.763682 140.33 0.00217481 138.498 0 136.587V114.581C0.00217481 112.67 0.763682 110.838 2.11745 109.486C3.47122 108.135 5.3067 107.375 7.22121 107.373H212.076C213.99 107.375 215.826 108.135 217.179 109.486C218.533 110.838 219.295 112.67 219.297 114.581V136.587C219.295 138.498 218.533 140.33 217.179 141.682C215.826 143.033 213.99 143.794 212.076 143.796ZM7.22121 108.131C5.50822 108.133 3.86593 108.813 2.65466 110.023C1.44339 111.232 0.762047 112.871 0.760128 114.581V136.587C0.762048 138.297 1.44339 139.936 2.65466 141.146C3.86593 142.355 5.50822 143.035 7.22121 143.037H212.076C213.789 143.035 215.431 142.355 216.642 141.146C217.853 139.936 218.535 138.297 218.537 136.587V114.581C218.535 112.871 217.853 111.232 216.642 110.023C215.431 108.813 213.789 108.133 212.076 108.131H7.22121Z"
          fill="#3F3D56"
        />
        <path
          d="M212.076 195.774H7.22121C5.3067 195.772 3.47122 195.012 2.11745 193.66C0.763682 192.309 0.00217481 190.477 0 188.565V166.56C0.00217481 164.649 0.763682 162.816 2.11745 161.465C3.47122 160.113 5.3067 159.353 7.22121 159.351H212.076C213.99 159.353 215.826 160.113 217.179 161.465C218.533 162.816 219.295 164.649 219.297 166.56V188.565C219.295 190.477 218.533 192.309 217.179 193.66C215.826 195.012 213.99 195.772 212.076 195.774ZM7.22121 160.11C5.50822 160.112 3.86593 160.792 2.65466 162.001C1.44339 163.21 0.762047 164.85 0.760128 166.56V188.565C0.762048 190.275 1.44339 191.915 2.65466 193.124C3.86593 194.333 5.50822 195.013 7.22121 195.015H212.076C213.789 195.013 215.431 194.333 216.642 193.124C217.853 191.915 218.535 190.275 218.537 188.565V166.56C218.535 164.85 217.853 163.21 216.642 162.001C215.431 160.792 213.789 160.112 212.076 160.11H7.22121Z"
          fill="#3F3D56"
        />
        <path
          d="M40.9824 85.9359H28.8204C27.1578 85.934 25.5638 85.2739 24.3881 84.1003C23.2125 82.9266 22.5512 81.3354 22.5493 79.6757V67.5346C22.5512 65.8749 23.2125 64.2837 24.3881 63.1101C25.5638 61.9364 27.1578 61.2763 28.8204 61.2744H40.9824C42.645 61.2763 44.239 61.9364 45.4146 63.1101C46.5903 64.2837 47.2516 65.8749 47.2535 67.5346V79.6757C47.2516 81.3354 46.5903 82.9266 45.4146 84.1003C44.239 85.2739 42.645 85.934 40.9824 85.9359Z"
          fill={color}
        />
        <path
          d="M193.517 83.8493H59.3545C58.5481 83.8493 57.7747 83.5295 57.2045 82.9603C56.6343 82.3911 56.314 81.6191 56.314 80.8141C56.314 80.0091 56.6343 79.237 57.2045 78.6678C57.7747 78.0986 58.5481 77.7788 59.3545 77.7788H193.517C194.323 77.7788 195.097 78.0986 195.667 78.6678C196.237 79.237 196.558 80.0091 196.558 80.8141C196.558 81.6191 196.237 82.3911 195.667 82.9603C195.097 83.5295 194.323 83.8493 193.517 83.8493Z"
          fill="#CCCCCC"
        />
        <path
          d="M149.81 70.9489H59.3545C58.5481 70.9489 57.7747 70.6292 57.2045 70.0599C56.6343 69.4907 56.314 68.7187 56.314 67.9137C56.314 67.1087 56.6343 66.3366 57.2045 65.7674C57.7747 65.1982 58.5481 64.8784 59.3545 64.8784H149.81C150.616 64.8784 151.389 65.1982 151.96 65.7674C152.53 66.3366 152.85 67.1087 152.85 67.9137C152.85 68.7187 152.53 69.4907 151.96 70.0599C151.389 70.6292 150.616 70.9489 149.81 70.9489Z"
          fill="#CCCCCC"
        />
        <path
          d="M40.9824 137.915H28.8204C27.1578 137.913 25.5638 137.253 24.3881 136.079C23.2125 134.906 22.5512 133.314 22.5493 131.655V119.514C22.5512 117.854 23.2125 116.263 24.3881 115.089C25.5638 113.915 27.1578 113.255 28.8204 113.253H40.9824C42.645 113.255 44.239 113.915 45.4146 115.089C46.5903 116.263 47.2516 117.854 47.2535 119.514V131.655C47.2516 133.314 46.5903 134.906 45.4146 136.079C44.239 137.253 42.645 137.913 40.9824 137.915Z"
          fill={color}
        />
        <path
          d="M193.517 135.828H59.3545C58.5481 135.828 57.7747 135.508 57.2045 134.939C56.6343 134.37 56.314 133.598 56.314 132.793C56.314 131.988 56.6343 131.216 57.2045 130.646C57.7747 130.077 58.5481 129.757 59.3545 129.757H193.517C194.323 129.757 195.097 130.077 195.667 130.646C196.237 131.216 196.558 131.988 196.558 132.793C196.558 133.598 196.237 134.37 195.667 134.939C195.097 135.508 194.323 135.828 193.517 135.828Z"
          fill="#CCCCCC"
        />
        <path
          d="M149.81 122.928H59.3545C58.5481 122.928 57.7747 122.608 57.2045 122.039C56.6343 121.47 56.314 120.698 56.314 119.893C56.314 119.088 56.6343 118.316 57.2045 117.746C57.7747 117.177 58.5481 116.857 59.3545 116.857H149.81C150.616 116.857 151.389 117.177 151.96 117.746C152.53 118.316 152.85 119.088 152.85 119.893C152.85 120.698 152.53 121.47 151.96 122.039C151.389 122.608 150.616 122.928 149.81 122.928Z"
          fill="#CCCCCC"
        />
        <path
          d="M40.9824 189.893H28.8204C27.1578 189.892 25.5638 189.231 24.3881 188.058C23.2125 186.884 22.5512 185.293 22.5493 183.633V171.492C22.5512 169.832 23.2125 168.241 24.3881 167.068C25.5638 165.894 27.1578 165.234 28.8204 165.232H40.9824C42.645 165.234 44.239 165.894 45.4146 167.068C46.5903 168.241 47.2516 169.832 47.2535 171.492V183.633C47.2516 185.293 46.5903 186.884 45.4146 188.058C44.239 189.231 42.645 189.892 40.9824 189.893Z"
          fill={color}
        />
        <path
          d="M193.517 187.807H59.3545C58.5481 187.807 57.7747 187.487 57.2045 186.918C56.6343 186.349 56.314 185.577 56.314 184.772C56.314 183.967 56.6343 183.195 57.2045 182.625C57.7747 182.056 58.5481 181.736 59.3545 181.736H193.517C194.323 181.736 195.097 182.056 195.667 182.625C196.237 183.195 196.558 183.967 196.558 184.772C196.558 185.577 196.237 186.349 195.667 186.918C195.097 187.487 194.323 187.807 193.517 187.807Z"
          fill="#CCCCCC"
        />
        <path
          d="M149.81 174.907H59.3545C58.5481 174.907 57.7747 174.587 57.2045 174.018C56.6343 173.449 56.314 172.677 56.314 171.872C56.314 171.067 56.6343 170.295 57.2045 169.725C57.7747 169.156 58.5481 168.836 59.3545 168.836H149.81C150.616 168.836 151.389 169.156 151.96 169.725C152.53 170.295 152.85 171.067 152.85 171.872C152.85 172.677 152.53 173.449 151.96 174.018C151.389 174.587 150.616 174.907 149.81 174.907Z"
          fill="#CCCCCC"
        />
        <path
          d="M216.636 66.3966C221.464 66.3966 225.378 62.4897 225.378 57.6702C225.378 52.8508 221.464 48.9438 216.636 48.9438C211.809 48.9438 207.895 52.8508 207.895 57.6702C207.895 62.4897 211.809 66.3966 216.636 66.3966Z"
          fill="#3F3D56"
        />
        <path
          d="M220.057 61.2746C220.007 61.2747 219.958 61.2649 219.912 61.2458C219.866 61.2268 219.824 61.1988 219.788 61.1635L212.947 54.3341C212.876 54.263 212.836 54.1665 212.836 54.0659C212.836 53.9653 212.876 53.8688 212.947 53.7976C213.019 53.7265 213.115 53.6865 213.216 53.6865C213.317 53.6865 213.413 53.7265 213.485 53.7976L220.326 60.627C220.379 60.68 220.415 60.7476 220.43 60.8212C220.445 60.8948 220.437 60.9711 220.408 61.0404C220.379 61.1097 220.331 61.169 220.268 61.2106C220.206 61.2523 220.132 61.2746 220.057 61.2746L220.057 61.2746Z"
          fill="white"
        />
        <path
          d="M213.216 61.2741C213.141 61.2741 213.067 61.2519 213.005 61.2102C212.942 61.1685 212.894 61.1092 212.865 61.0399C212.836 60.9706 212.829 60.8943 212.843 60.8207C212.858 60.7471 212.894 60.6796 212.947 60.6265L219.788 53.7971C219.824 53.7619 219.866 53.734 219.912 53.7149C219.958 53.6958 220.007 53.686 220.057 53.686C220.107 53.686 220.156 53.6958 220.203 53.7149C220.249 53.734 220.291 53.7619 220.326 53.7971C220.361 53.8324 220.389 53.8742 220.408 53.9202C220.427 53.9663 220.437 54.0156 220.437 54.0654C220.437 54.1152 220.427 54.1645 220.408 54.2106C220.389 54.2566 220.361 54.2984 220.326 54.3336L213.485 61.163C213.449 61.1983 213.408 61.2263 213.361 61.2454C213.315 61.2644 213.266 61.2742 213.216 61.2741Z"
          fill="white"
        />
        <path
          d="M304.625 161.054C315.859 161.054 324.966 151.962 324.966 140.748C324.966 129.533 315.859 120.442 304.625 120.442C293.391 120.442 284.284 129.533 284.284 140.748C284.284 151.962 293.391 161.054 304.625 161.054Z"
          fill={color}
        />
        <path
          d="M306.12 266.274V219.601C306.12 218.947 306.381 218.32 306.844 217.858C307.307 217.396 307.935 217.136 308.59 217.135H314.2C314.854 217.136 315.482 217.396 315.946 217.858C316.409 218.32 316.669 218.947 316.67 219.601V266.274C316.669 266.927 316.409 267.554 315.946 268.017C315.482 268.479 314.854 268.739 314.2 268.74H308.59C307.935 268.739 307.307 268.479 306.844 268.017C306.381 267.554 306.12 266.927 306.12 266.274Z"
          fill="#2F2E41"
        />
        <path
          d="M294.265 266.274V219.601C294.265 218.947 294.526 218.32 294.989 217.858C295.452 217.396 296.08 217.136 296.735 217.135H302.345C303 217.136 303.628 217.396 304.091 217.858C304.554 218.32 304.814 218.947 304.815 219.601V266.274C304.814 266.927 304.554 267.554 304.091 268.017C303.628 268.479 303 268.739 302.345 268.74H296.735C296.08 268.739 295.452 268.479 294.989 268.017C294.526 267.554 294.265 266.927 294.265 266.274Z"
          fill="#2F2E41"
        />
        <path
          d="M317.6 225.087H292.112C291.407 225.086 290.731 224.806 290.232 224.308C289.733 223.811 289.453 223.136 289.452 222.431V181.097C289.452 177.019 291.075 173.107 293.964 170.223C296.852 167.339 300.771 165.719 304.856 165.719C308.942 165.719 312.86 167.339 315.749 170.223C318.638 173.107 320.261 177.019 320.261 181.097V222.431C320.26 223.136 319.979 223.811 319.48 224.308C318.982 224.806 318.305 225.086 317.6 225.087Z"
          fill="#2F2E41"
        />
        <path
          d="M287.448 149.724L287.469 148.965C286.055 148.926 284.808 148.838 283.871 148.154C283.605 147.95 283.387 147.69 283.231 147.394C283.075 147.097 282.984 146.771 282.966 146.436C282.95 146.232 282.981 146.027 283.057 145.836C283.133 145.646 283.251 145.475 283.404 145.338C284.026 144.814 285.027 144.984 285.756 145.317L286.385 145.605L285.179 136.807L284.426 136.91L285.452 144.394C284.461 144.103 283.542 144.228 282.913 144.759C282.672 144.969 282.483 145.232 282.36 145.528C282.237 145.824 282.185 146.144 282.206 146.463C282.229 146.912 282.349 147.351 282.559 147.75C282.769 148.148 283.064 148.495 283.423 148.767C284.626 149.645 286.191 149.689 287.448 149.724Z"
          fill="#2F2E41"
        />
        <path
          d="M293.624 137.555H289.53V138.314H293.624V137.555Z"
          fill="#2F2E41"
        />
        <path
          d="M312.672 222.403C312.432 222.185 312.238 221.921 312.102 221.627C311.965 221.333 311.889 221.014 311.876 220.691L309.94 174.059C309.926 173.735 309.977 173.412 310.088 173.108C310.2 172.804 310.37 172.525 310.59 172.286C310.81 172.048 311.074 171.855 311.369 171.719C311.663 171.583 311.981 171.506 312.305 171.492L317.91 171.26C318.565 171.234 319.203 171.468 319.685 171.911C320.167 172.353 320.453 172.969 320.481 173.622L322.418 220.255C322.444 220.908 322.21 221.545 321.766 222.026C321.322 222.507 320.706 222.793 320.051 222.821L314.447 223.053C314.268 223.06 314.089 223.049 313.913 223.018C313.449 222.936 313.018 222.723 312.672 222.403Z"
          fill={color}
        />
        <path
          d="M319.824 190.842H310.702C310.047 190.841 309.419 190.581 308.956 190.119C308.493 189.657 308.233 189.03 308.232 188.376V175.476C308.232 173.615 308.973 171.829 310.291 170.513C311.61 169.197 313.398 168.457 315.263 168.457C317.128 168.457 318.916 169.197 320.235 170.513C321.554 171.829 322.294 173.615 322.294 175.476V188.376C322.294 189.03 322.033 189.657 321.57 190.119C321.107 190.581 320.479 190.841 319.824 190.842Z"
          fill="#2F2E41"
        />
        <path
          d="M261.248 208.124L257.672 203.809C257.255 203.305 257.055 202.656 257.117 202.005C257.179 201.355 257.496 200.755 258 200.337L294.023 170.585C294.528 170.169 295.178 169.969 295.83 170.031C296.482 170.092 297.083 170.41 297.501 170.913L301.077 175.228C301.284 175.478 301.439 175.765 301.535 176.075C301.63 176.384 301.664 176.71 301.633 177.032C301.603 177.355 301.509 177.668 301.357 177.954C301.205 178.24 300.998 178.494 300.748 178.7L264.726 208.452C264.292 208.81 263.749 209.009 263.186 209.018C262.623 209.026 262.075 208.842 261.631 208.497C261.49 208.387 261.362 208.262 261.248 208.124Z"
          fill={color}
        />
        <path
          d="M287.813 188.744L282.035 181.699C281.621 181.192 281.425 180.543 281.49 179.892C281.555 179.241 281.876 178.643 282.382 178.228L292.381 170.057C293.095 169.473 293.918 169.035 294.802 168.768C295.686 168.501 296.614 168.411 297.533 168.502C298.452 168.594 299.343 168.865 300.157 169.3C300.971 169.736 301.691 170.327 302.276 171.04C302.861 171.754 303.3 172.575 303.567 173.458C303.834 174.34 303.924 175.267 303.832 176.184C303.741 177.101 303.469 177.992 303.032 178.804C302.596 179.616 302.003 180.335 301.289 180.919L291.29 189.091C290.783 189.504 290.132 189.7 289.48 189.635C288.828 189.57 288.229 189.25 287.813 188.744Z"
          fill="#2F2E41"
        />
        <path
          d="M263.265 190.458L263.162 190.547L260.706 187.746C260.519 187.533 260.292 187.358 260.038 187.233C259.784 187.108 259.507 187.033 259.224 187.015C258.942 186.996 258.658 187.033 258.389 187.123C258.121 187.214 257.873 187.357 257.659 187.543L251.713 192.739C251.282 193.116 251.018 193.648 250.98 194.218C250.942 194.788 251.133 195.35 251.51 195.78L264.988 211.152C265.174 211.365 265.401 211.539 265.655 211.665C265.91 211.79 266.186 211.864 266.469 211.883C266.752 211.902 267.036 211.865 267.304 211.774C267.573 211.683 267.821 211.541 268.034 211.354L273.981 206.158C274.412 205.782 274.675 205.25 274.713 204.68C274.751 204.109 274.56 203.547 274.183 203.117L264.911 192.542L265.014 192.452L263.265 190.458Z"
          fill="#3F3D56"
        />
        <path
          d="M258.317 188.439L257.576 189.085C257.684 189.139 257.776 189.218 257.845 189.316C257.914 189.413 257.959 189.526 257.974 189.645C257.989 189.764 257.975 189.884 257.932 189.996C257.89 190.108 257.82 190.208 257.73 190.286L254.481 193.126C254.391 193.204 254.282 193.26 254.166 193.287C254.049 193.314 253.927 193.312 253.812 193.282C253.696 193.251 253.589 193.192 253.502 193.111C253.414 193.029 253.348 192.927 253.309 192.814L252.617 193.419C252.465 193.551 252.341 193.713 252.252 193.893C252.162 194.074 252.109 194.271 252.096 194.472C252.082 194.673 252.109 194.875 252.173 195.066C252.238 195.257 252.34 195.433 252.473 195.585L265.31 210.226C265.578 210.532 265.958 210.719 266.365 210.746C266.772 210.773 267.173 210.638 267.48 210.37L273.179 205.39C273.486 205.122 273.674 204.743 273.701 204.336C273.728 203.93 273.592 203.53 273.323 203.223L260.487 188.583C260.218 188.276 259.839 188.089 259.432 188.062C259.025 188.035 258.624 188.17 258.317 188.439Z"
          fill="white"
        />
        <path
          d="M309.724 144.186C310.783 142.419 311.612 140.473 311.219 138.38C310.867 136.511 309.52 135.056 307.583 134.865C306.416 134.75 305.255 135.06 304.115 135.263C303.181 135.427 302.232 135.488 301.285 135.446C299.378 135.36 297.51 134.887 295.792 134.056C294.049 133.242 292.501 132.064 291.252 130.603C290.003 129.141 289.081 127.43 288.55 125.583C287.394 121.382 288.252 116.611 290.998 113.191L290.045 112.94L291.285 117.008C291.31 117.092 291.352 117.169 291.411 117.233C291.469 117.298 291.541 117.348 291.621 117.381C291.702 117.414 291.789 117.428 291.876 117.422C291.963 117.417 292.047 117.391 292.123 117.349C294.371 115.93 296.885 114.983 299.511 114.564C302.138 114.144 304.822 114.262 307.402 114.91C308.859 115.276 310.267 115.816 311.594 116.52C312.242 116.863 312.818 115.881 312.169 115.537C309.677 114.239 306.946 113.459 304.142 113.244C301.339 113.03 298.521 113.386 295.859 114.29C294.343 114.805 292.895 115.503 291.547 116.366L292.385 116.706L291.144 112.637C291.116 112.542 291.065 112.455 290.994 112.386C290.923 112.317 290.835 112.267 290.739 112.242C290.643 112.217 290.542 112.217 290.446 112.242C290.35 112.267 290.262 112.317 290.191 112.386C288.914 114.003 287.979 115.861 287.44 117.849C286.902 119.836 286.772 121.912 287.059 123.951C287.336 125.961 288.036 127.889 289.112 129.61C290.188 131.331 291.616 132.806 293.303 133.937C294.988 135.072 296.882 135.863 298.875 136.263C300.869 136.663 302.922 136.664 304.915 136.267C306.031 136.053 307.297 135.746 308.391 136.213C308.788 136.404 309.139 136.68 309.417 137.022C309.695 137.363 309.894 137.762 310.001 138.189C310.621 140.287 309.565 142.28 308.487 144.025L309.724 144.186Z"
          fill="#2F2E41"
        />
        <path
          d="M299.534 184.108L299.52 184.108C299.308 184.107 299.102 184.035 298.936 183.902C298.77 183.769 298.655 183.584 298.608 183.377L298.683 167.568C298.635 167.354 298.661 167.13 298.758 166.933C298.855 166.736 299.016 166.578 299.215 166.485C301.972 165.192 304.82 165.019 307.923 165.955C308.161 166.025 308.361 166.185 308.483 166.401C308.604 166.616 308.636 166.87 308.572 167.109L300.452 183.402C300.4 183.606 300.282 183.786 300.115 183.914C299.949 184.042 299.744 184.11 299.534 184.108Z"
          fill="#CCCCCC"
        />
        <path
          d="M352.62 269H258.364C258.263 269 258.166 268.96 258.095 268.889C258.024 268.818 257.984 268.721 257.984 268.621C257.984 268.52 258.024 268.423 258.095 268.352C258.166 268.281 258.263 268.241 258.364 268.241H352.62C352.721 268.241 352.817 268.281 352.889 268.352C352.96 268.423 353 268.52 353 268.621C353 268.721 352.96 268.818 352.889 268.889C352.817 268.96 352.721 269 352.62 269Z"
          fill="#3F3D56"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="353" height="269" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
