// React, Redux, Router
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

// MUI
import {
  AppBar,
  Toolbar,
  Box,
  Container,
  LinearProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Atoms
import Logo from "Components/atoms/UI/Logo";

// Molecules
import ChangeLanguage from "Components/molecules/ChangeLanguage";

const style = makeStyles(() => ({
  title: {
    flexGrow: 1
  },
  loadingBar: {
    zIndex: 9999,
    height: "5px",
    position: "fixed",
    top: 0,
    overflow: "hidden",
    width: "100%"
  }
}));

const Header = () => {
  const app = useSelector(state => state.app);
  const classes = style();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Loading bar axios interceptors
    axios.interceptors.request.use(
      config => {
        setLoading(true);
        return config;
      },
      error => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      response => {
        setLoading(false);
        return response;
      },
      error => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      {loading && (
        <LinearProgress color="secondary" className={classes.loadingBar} />
      )}
      <AppBar position="fixed" style={{ backgroundColor: "#fff" }}>
        <Container fixed maxWidth="xl">
          <Toolbar disableGutters>
            <Box className={classes.title}>
              <Logo
                width="115px"
                goTo="/"
                src={app.theme.logo}
                type="dark"
                // tag="ALPHA"
              />
            </Box>
            <Box>
              <ChangeLanguage color="primary" />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
