/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import {
  getFunc,
  putFunc,
  deleteFunc,
  postFunc
} from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_ALL_ADMINS_FOR_LOGGED_USER_REQ =
  "auth/GET_ALL_ADMINS_FOR_LOGGED_USER_REQ";
const GET_ALL_ADMINS_FOR_LOGGED_USER_SCS =
  "auth/GET_ALL_ADMINS_FOR_LOGGED_USER_SCS";
const GET_ALL_ADMINS_FOR_LOGGED_USER_FLR =
  "auth/GET_ALL_ADMINS_FOR_LOGGED_USER_FLR";

const GET_ALL_ROLES_REQ = "auth/GET_ALL_ROLES_REQ";
const GET_ALL_ROLES_SCS = "auth/GET_ALL_ROLES_SCS";
const GET_ALL_ROLES_FLR = "auth/GET_ALL_ROLES_FLR";

const GET_ONE_USER_REQ = "auth/GET_ONE_USER_REQ";
const GET_ONE_USER_SCS = "auth/GET_ONE_USER_SCS";
const GET_ONE_USER_FLR = "auth/GET_ONE_USER_FLR";

const GET_ALL_CHILDREN_BY_LOGGED_USER_REQ =
  "auth/GET_ALL_CHILDREN_BY_LOGGED_USER_REQ";
const GET_ALL_CHILDREN_BY_LOGGED_USER_SCS =
  "auth/GET_ALL_CHILDREN_BY_LOGGED_USER_SCS";
const GET_ALL_CHILDREN_BY_LOGGED_USER_FLR =
  "auth/GET_ALL_CHILDREN_BY_LOGGED_USER_FLR";

const CREATE_USER = "auth/CREATE_USER";
const ALTER_USER = "auth/ALTER_USER";
const CHANGE_USER_STATE = "auth/CHANGE_USER_STATE";

/**
 * ACTIONS
 */

export const getAllAdminsforLoggedUser = () => async dispatch => {
  dispatch({ type: GET_ALL_ADMINS_FOR_LOGGED_USER_REQ });
  const response = await getFunc(`admins/getAll`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_ADMINS_FOR_LOGGED_USER_SCS,
      payload: response.data
    });
  } else {
    dispatch({ type: GET_ALL_ADMINS_FOR_LOGGED_USER_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const getAllChildrenByLoggedUser = () => async dispatch => {
  dispatch({ type: GET_ALL_CHILDREN_BY_LOGGED_USER_REQ });
  const response = await getFunc(`organisations/getAllChildrenByLoggedUser`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_CHILDREN_BY_LOGGED_USER_SCS,
      payload: response.data
    });
  } else {
    dispatch({ type: GET_ALL_CHILDREN_BY_LOGGED_USER_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const getAllRoles = () => async dispatch => {
  dispatch({ type: GET_ALL_ROLES_REQ });
  const response = await getFunc(`roles`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_ROLES_SCS,
      payload: response.data
    });
  } else {
    dispatch({ type: GET_ALL_ROLES_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const getOneUser = id => async dispatch => {
  dispatch({ type: GET_ONE_USER_REQ });
  const response = await getFunc(`admins/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ONE_USER_SCS,
      payload: response.data
    });
  } else {
    dispatch({ type: GET_ONE_USER_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const createUser = (body, handleOpen) => async dispatch => {
  const response = await postFunc("admins", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: CREATE_USER, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });
    handleOpen();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const alterUser = (body, id, handleOpen) => async dispatch => {
  const response = await putFunc(`admins/${id}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: ALTER_USER, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });
    handleOpen();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const activateUser = id => async dispatch => {
  const response = await putFunc(`admins/activate/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: CHANGE_USER_STATE, payload: response.data });
    NotificationManager.success(response.status.description);
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const deactivateUser = id => async dispatch => {
  const response = await deleteFunc(`admins/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: CHANGE_USER_STATE, payload: response.data });
    NotificationManager.success(response.status.description);
  } else {
    NotificationManager.error(response.status.description);
  }
};

/**
 * REDUCERS
 */
const INIT_STATE = {
  loading: false,
  allAdmins: [],
  roles: [],
  oneUser: null,
  organisationList: []
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_ADMINS_FOR_LOGGED_USER_REQ:
      return {
        ...state,
        loading: true,
        allAdmins: state.allAdmins
      };
    case GET_ALL_ADMINS_FOR_LOGGED_USER_SCS:
      return {
        ...state,
        loading: false,
        allAdmins: action.payload.map(item => {
          return {
            ...item,
            rolesData: [],
            name: `${item.first_name} ${item.last_name}`
          };
        })
      };
    case GET_ALL_ADMINS_FOR_LOGGED_USER_FLR:
      return {
        ...state,
        loading: false,
        allAdmins: state.allAdmins
      };

    case GET_ALL_ROLES_REQ:
      return {
        ...state,
        loading: true,
        roles: state.data
      };
    case GET_ALL_ROLES_SCS:
      return {
        ...state,
        loading: false,
        roles: action.payload
      };
    case GET_ALL_ROLES_FLR:
      return {
        ...state,
        loading: false,
        roles: state.data
      };

    case GET_ONE_USER_REQ:
      return {
        ...state,
        loading: true,
        oneUser: state.oneUser
      };
    case GET_ONE_USER_SCS:
      return {
        ...state,
        loading: false,
        oneUser: action.payload
      };
    case GET_ONE_USER_FLR:
      return {
        ...state,
        loading: false,
        oneUser: state.oneUser
      };

    case GET_ALL_CHILDREN_BY_LOGGED_USER_REQ:
      return {
        ...state,
        loading: true,
        organisationList: state.data
      };
    case GET_ALL_CHILDREN_BY_LOGGED_USER_SCS:
      return {
        ...state,
        loading: false,
        organisationList: action.payload
      };
    case GET_ALL_CHILDREN_BY_LOGGED_USER_FLR:
      return {
        ...state,
        loading: false,
        organisationList: state.data
      };

    case CREATE_USER:
      return {
        ...state,
        allAdmins: [...state.allAdmins, action.payload]
      };

    case ALTER_USER:
      return {
        ...state,
        allAdmins: state.allAdmins.map(item => {
          if (item.id === action.payload.id) {
            return { ...action.payload };
          }
          return item;
        })
      };

    case CHANGE_USER_STATE:
      return {
        ...state,
        allAdmins: state.allAdmins.map(item => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
          return item;
        })
      };

    default:
      return state;
  }
}
