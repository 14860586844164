import React from "react";
import { useTheme } from "@material-ui/core";

export const OrdersHelpImg = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  return (
    <svg
      width="100%"
      height="200px"
      viewBox="0 0 361 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M350.327 75.6045H152.783C150.003 75.607 147.337 76.7108 145.37 78.6735C143.404 80.6362 142.298 83.2975 142.296 86.0732V118.03C142.298 120.806 143.404 123.467 145.37 125.43C147.337 127.393 150.003 128.496 152.783 128.499H350.327C353.108 128.496 355.774 127.393 357.74 125.43C359.706 123.467 360.812 120.806 360.815 118.03V86.0732C360.812 83.2975 359.706 80.6362 357.74 78.6735C355.774 76.7108 353.108 75.607 350.327 75.6045Z"
          fill="#F2F2F2"
        />
        <path
          d="M79.2203 257.999H0.628733C0.461982 257.999 0.302052 257.933 0.184142 257.816C0.0662312 257.698 0 257.538 0 257.372C0 257.205 0.0662312 257.046 0.184142 256.928C0.302052 256.81 0.461982 256.744 0.628733 256.744H79.2203C79.3871 256.744 79.547 256.81 79.6649 256.928C79.7828 257.046 79.8491 257.205 79.8491 257.372C79.8491 257.538 79.7828 257.698 79.6649 257.816C79.547 257.933 79.3871 257.999 79.2203 257.999Z"
          fill="#3F3D56"
        />
        <path
          d="M67.6809 61.4921C66.952 61.9513 66.3329 62.5645 65.8671 63.2884C65.4014 64.0123 65.1003 64.8294 64.985 65.682C64.8698 66.5346 64.9433 67.4021 65.2003 68.2233C65.4572 69.0446 65.8915 69.7996 66.4724 70.4352L60.7588 82.6143L67.3264 87.8853L75.1531 70.5729C76.1581 69.528 76.7442 68.1514 76.8003 66.704C76.8565 65.2565 76.3788 63.8388 75.4577 62.7195C74.5366 61.6001 73.2362 60.8569 71.8029 60.6307C70.3696 60.4045 68.9029 60.711 67.6809 61.4921V61.4921Z"
          fill="#FFB8B8"
        />
        <path
          d="M34.8473 66.4342C36.6555 65.35 38.8124 65.0021 40.8707 65.4627C42.929 65.9232 44.7307 67.157 45.9022 68.908L57.5934 86.382L61.6521 77.4503C61.9056 76.8925 62.3545 76.4465 62.9145 76.1962C63.4744 75.9458 64.1067 75.9085 64.6923 76.0911L70.8183 78.0012C71.1383 78.101 71.4351 78.2637 71.6913 78.4797C71.9474 78.6956 72.1577 78.9606 72.3098 79.2588C72.4619 79.5571 72.5526 79.8828 72.5768 80.2166C72.601 80.5504 72.558 80.8857 72.4505 81.2027L68.257 93.563C67.7266 95.1266 66.8403 96.5461 65.6678 97.7102C64.4953 98.8743 63.0684 99.7512 61.4992 100.272C59.93 100.793 58.2612 100.944 56.6237 100.713C54.9863 100.482 53.4248 99.875 52.0619 98.9401C51.425 98.5033 50.8374 97.9988 50.3093 97.4356L33.0805 79.0596C32.2438 78.1673 31.6177 77.0994 31.248 75.9343C30.8783 74.7691 30.7743 73.5362 30.9437 72.3258C31.1131 71.1153 31.5515 69.958 32.227 68.9386C32.9024 67.9192 33.7977 67.0635 34.8473 66.4342Z"
          fill="#CCCCCC"
        />
        <path
          d="M72.6674 48.3793C72.9812 48.3871 73.2791 48.5189 73.4955 48.7459C73.7119 48.9729 73.829 49.2764 73.8213 49.5897L73.7204 53.6457L73.9241 53.6642L73.8509 56.419L73.6519 56.3942L73.2904 70.9249C73.278 71.4222 73.0848 71.8981 72.7467 72.2636C72.4087 72.6292 71.949 72.8595 71.4534 72.9116L62.1973 73.8843C61.9719 73.908 61.7441 73.8822 61.5298 73.8087C61.3155 73.7352 61.1199 73.6158 60.9567 73.4589C60.7935 73.3019 60.6667 73.1113 60.5851 72.9002C60.5035 72.6892 60.4692 72.4629 60.4845 72.2372L61.8884 51.5466C61.9178 51.112 62.0946 50.7004 62.3895 50.3793C62.6844 50.0583 63.08 49.847 63.5112 49.7801L72.4467 48.3944C72.5196 48.3825 72.5935 48.3774 72.6674 48.3793Z"
          fill="#3F3D56"
        />
        <path
          d="M65.5039 50.5325L70.2014 49.8041C70.2859 49.791 70.3638 49.7504 70.4229 49.6886C70.482 49.6269 70.5191 49.5474 70.5284 49.4625C70.539 49.3663 70.581 49.2763 70.6479 49.2064C70.7149 49.1364 70.8031 49.0905 70.8988 49.0756L71.8883 48.9222C72.0233 48.9012 72.1613 48.9101 72.2925 48.9482C72.4237 48.9863 72.5449 49.0527 72.6476 49.1427C72.7503 49.2327 72.832 49.344 72.8869 49.469C72.9417 49.5939 72.9684 49.7293 72.9652 49.8656L72.4566 70.9326C72.4503 71.1941 72.3477 71.444 72.1686 71.6348C71.9894 71.8256 71.7461 71.9439 71.4852 71.967L62.2359 72.7879C62.1179 72.7983 61.9991 72.7833 61.8874 72.7437C61.7758 72.7041 61.6741 72.6409 61.5891 72.5585C61.5042 72.4761 61.4381 72.3763 61.3954 72.266C61.3526 72.1558 61.3342 72.0376 61.3414 71.9196L62.5893 51.4201C62.6077 51.1181 62.7291 50.8314 62.9332 50.6077C63.1373 50.384 63.4119 50.2366 63.7114 50.1902L64.7545 50.0284C64.7928 50.1912 64.8913 50.3336 65.0303 50.4271C65.1692 50.5205 65.3384 50.5582 65.5039 50.5325Z"
          fill="white"
        />
        <path
          d="M43.7105 250.382L50.8982 250.381L54.3166 222.706L43.708 222.707L43.7105 250.382Z"
          fill="#FFB8B8"
        />
        <path
          d="M64.7612 257.043L42.171 257.045L42.1702 248.333L56.0324 248.331C57.1786 248.331 58.3136 248.556 59.3725 248.994C60.4315 249.432 61.3937 250.074 62.2043 250.883C63.0148 251.692 63.6578 252.652 64.0965 253.709C64.5353 254.766 64.7611 255.899 64.7612 257.043L64.7612 257.043Z"
          fill="#2F2E41"
        />
        <path
          d="M21.6197 250.382L28.8074 250.381L32.2258 222.706L21.6172 222.707L21.6197 250.382Z"
          fill="#FFB8B8"
        />
        <path
          d="M42.6699 257.043L20.0797 257.045L20.0789 248.333L33.9411 248.331C36.2559 248.331 38.476 249.249 40.113 250.883C41.75 252.516 42.6697 254.732 42.6699 257.043L42.6699 257.043Z"
          fill="#2F2E41"
        />
        <path
          d="M42.6699 57.0286C51.1984 57.0286 58.1122 50.1272 58.1122 41.6139C58.1122 33.1006 51.1984 26.1992 42.6699 26.1992C34.1413 26.1992 27.2275 33.1006 27.2275 41.6139C27.2275 50.1272 34.1413 57.0286 42.6699 57.0286Z"
          fill="#FFB8B8"
        />
        <path
          d="M50.6176 82.8557C50.4914 78.8926 49.3737 75.0239 47.3663 71.6024C45.3589 68.1809 42.5256 65.3152 39.1246 63.2666C35.1351 60.9199 30.7682 60.0048 27.2941 63.4727C25.208 65.6073 23.5373 68.1103 22.3673 70.8541C19.1536 78.3313 18.9433 86.7557 21.7801 94.3834L30.0489 117.187L49.187 119.256C49.5465 119.295 49.9101 119.256 50.2532 119.142C50.5962 119.028 50.9105 118.841 51.1747 118.595C51.4389 118.349 51.6468 118.048 51.7842 117.714C51.9216 117.381 51.9852 117.021 51.9708 116.66L50.6176 82.8557Z"
          fill="#2F2E41"
        />
        <path
          d="M31.2624 114.379C31.2624 114.379 16.9641 119.707 20.7024 142.269C24.0569 162.515 21.3911 229.713 20.8084 243.297C20.7807 243.945 21.0051 244.578 21.4346 245.064C21.8641 245.549 22.4653 245.851 23.1123 245.904L31.4587 246.598C32.1125 246.653 32.7616 246.45 33.2677 246.033C33.7738 245.617 34.0968 245.019 34.1677 244.368L38.4783 204.845C38.4952 204.69 38.5694 204.547 38.6864 204.444C38.8034 204.34 38.9547 204.284 39.111 204.286C39.2673 204.288 39.4172 204.347 39.5317 204.454C39.6461 204.56 39.7167 204.705 39.7299 204.861L42.9419 242.891C42.996 243.531 43.2943 244.127 43.7754 244.555C44.2565 244.982 44.8836 245.209 45.5275 245.189L52.5379 244.967C52.8683 244.957 53.1934 244.882 53.4945 244.746C53.7957 244.61 54.0671 244.416 54.2931 244.175C54.5192 243.935 54.6955 243.652 54.812 243.343C54.9285 243.034 54.9828 242.706 54.972 242.376L50.8423 117.014L31.2624 114.379Z"
          fill="#2F2E41"
        />
        <path
          d="M45.7697 40.5985C50.9343 42.7129 57.5297 40.243 60.0267 35.2595C62.5237 30.2759 60.5476 23.5266 55.755 20.6697C50.9624 17.8129 44.0725 19.2772 40.8615 23.8351C38.2495 19.1377 31.4067 17.9401 26.7085 20.5612C22.0104 23.1823 19.4513 28.6724 19.1478 34.0363C18.8443 39.4003 20.478 44.6845 22.4709 49.6752C25.6945 57.7478 36.0722 62.0944 44.1013 58.7349C40.4256 53.2701 41.1512 45.273 45.7697 40.5985Z"
          fill="#2F2E41"
        />
        <path
          d="M66.5814 63.3613C66.444 63.3615 66.3102 63.3172 66.2002 63.2348L66.1934 63.2297L64.7577 62.1334C64.6912 62.0825 64.6354 62.019 64.5934 61.9466C64.5515 61.8742 64.5243 61.7943 64.5133 61.7113C64.5023 61.6284 64.5078 61.5441 64.5295 61.4633C64.5511 61.3825 64.5885 61.3068 64.6395 61.2404C64.6905 61.174 64.7541 61.1183 64.8266 61.0765C64.8991 61.0346 64.9792 61.0075 65.0623 60.9965C65.1454 60.9856 65.2298 60.991 65.3107 61.0126C65.3917 61.0343 65.4676 61.0716 65.5341 61.1225L66.464 61.8343L68.6616 58.9724C68.7126 58.9061 68.7761 58.8504 68.8486 58.8086C68.9212 58.7667 69.0012 58.7396 69.0843 58.7286C69.1673 58.7177 69.2517 58.7232 69.3326 58.7448C69.4135 58.7664 69.4893 58.8037 69.5558 58.8546L69.556 58.8547L69.5424 58.8736L69.5564 58.8547C69.6905 58.9576 69.7782 59.1093 69.8003 59.2767C69.8224 59.4441 69.7771 59.6134 69.6743 59.7475L67.0895 63.1121C67.0297 63.1897 66.9528 63.2524 66.8648 63.2955C66.7768 63.3385 66.68 63.3608 66.582 63.3605L66.5814 63.3613Z"
          fill={color}
        />
        <path
          d="M350.513 0H152.969C150.188 0.0025143 147.522 1.10628 145.556 3.06899C143.59 5.03171 142.484 7.69299 142.481 10.4687V42.4257C142.484 45.2014 143.59 47.8627 145.556 49.8254C147.522 51.7882 150.188 52.8919 152.969 52.8944H350.513C353.293 52.8919 355.959 51.7882 357.926 49.8254C359.892 47.8627 360.998 45.2014 361 42.4257V10.4687C360.998 7.69299 359.892 5.0317 357.926 3.06898C355.959 1.10627 353.293 0.0025143 350.513 0ZM359.896 42.4257C359.893 44.9089 358.903 47.2893 357.144 49.0451C355.385 50.801 353 51.7889 350.513 51.7924H152.969C150.481 51.7889 148.097 50.801 146.338 49.0451C144.579 47.2893 143.589 44.9089 143.585 42.4257V10.4687C143.589 7.98556 144.579 5.60514 146.338 3.8493C148.097 2.09346 150.481 1.10548 152.969 1.10196H350.513C353 1.10548 355.385 2.09346 357.144 3.8493C358.903 5.60514 359.893 7.98556 359.896 10.4687V42.4257Z"
          fill="#3F3D56"
        />
        <path
          d="M193.169 44.6678C189.559 44.6678 186.03 43.5992 183.028 41.5971C180.026 39.595 177.687 36.7493 176.305 33.4199C174.923 30.0904 174.562 26.4268 175.266 22.8923C175.971 19.3578 177.709 16.1111 180.262 13.5629C182.815 11.0146 186.067 9.27927 189.608 8.5762C193.149 7.87313 196.819 8.23396 200.154 9.61305C203.49 10.9921 206.341 13.3276 208.346 16.324C210.352 19.3204 211.423 22.8432 211.423 26.4469C211.417 31.2777 209.492 35.909 206.07 39.3249C202.648 42.7408 198.009 44.6623 193.169 44.6678Z"
          fill={color}
        />
        <path
          d="M323.56 41.3236H228.682C227.511 41.3236 226.388 40.8592 225.56 40.0325C224.732 39.2059 224.267 38.0847 224.267 36.9157C224.267 35.7466 224.732 34.6255 225.56 33.7989C226.388 32.9722 227.511 32.5078 228.682 32.5078H323.56C324.731 32.5078 325.854 32.9722 326.682 33.7989C327.51 34.6255 327.975 35.7466 327.975 36.9157C327.975 38.0847 327.51 39.2059 326.682 40.0325C325.854 40.8592 324.731 41.3236 323.56 41.3236Z"
          fill="#CCCCCC"
        />
        <path
          d="M260.083 22.5902H228.682C227.511 22.5902 226.388 22.1258 225.56 21.2991C224.732 20.4725 224.267 19.3513 224.267 18.1823C224.267 17.0132 224.732 15.8921 225.56 15.0655C226.388 14.2388 227.511 13.7744 228.682 13.7744H260.083C261.254 13.7744 262.377 14.2388 263.205 15.0655C264.033 15.8921 264.499 17.0132 264.499 18.1823C264.499 19.3513 264.033 20.4725 263.205 21.2991C262.377 22.1258 261.254 22.5902 260.083 22.5902Z"
          fill="#CCCCCC"
        />
        <path
          d="M192.237 32.8565C191.784 32.8572 191.343 32.711 190.981 32.4398L190.958 32.423L186.227 28.8103C186.008 28.6428 185.823 28.4337 185.685 28.195C185.546 27.9563 185.456 27.6927 185.42 27.4192C185.383 27.1457 185.401 26.8678 185.473 26.6012C185.544 26.3347 185.667 26.0848 185.835 25.8658C186.003 25.6469 186.213 25.4632 186.453 25.3252C186.692 25.1873 186.956 25.0978 187.23 25.0619C187.504 25.026 187.783 25.0444 188.05 25.116C188.316 25.1876 188.567 25.311 188.786 25.4792L191.85 27.8249L199.091 18.3947C199.259 18.1761 199.469 17.9926 199.708 17.8548C199.947 17.7169 200.21 17.6274 200.484 17.5913C200.758 17.5552 201.036 17.5733 201.302 17.6445C201.569 17.7157 201.819 17.8386 202.038 18.0062L202.039 18.0067L201.994 18.069L202.04 18.0067C202.482 18.3457 202.771 18.8458 202.844 19.3973C202.917 19.9489 202.767 20.5067 202.428 20.9485L193.911 32.0356C193.714 32.291 193.461 32.4977 193.171 32.6397C192.881 32.7816 192.562 32.8549 192.239 32.854L192.237 32.8565Z"
          fill="white"
        />
        <path
          d="M265.634 85.9824H68.09C65.3093 85.9849 62.6432 87.0887 60.677 89.0514C58.7108 91.0141 57.6051 93.6754 57.6025 96.4511V128.408C57.6051 131.184 58.7108 133.845 60.677 135.808C62.6432 137.771 65.3093 138.874 68.09 138.877H265.634C268.414 138.874 271.081 137.771 273.047 135.808C275.013 133.845 276.119 131.184 276.121 128.408V96.4511C276.119 93.6754 275.013 91.0141 273.047 89.0514C271.081 87.0887 268.414 85.9849 265.634 85.9824Z"
          fill="white"
        />
        <path
          d="M265.634 85.9824H68.09C65.3093 85.9849 62.6432 87.0887 60.677 89.0514C58.7108 91.0141 57.6051 93.6754 57.6025 96.4511V128.408C57.6051 131.184 58.7108 133.845 60.677 135.808C62.6432 137.771 65.3093 138.874 68.09 138.877H265.634C268.414 138.874 271.081 137.771 273.047 135.808C275.013 133.845 276.119 131.184 276.121 128.408V96.4511C276.119 93.6754 275.013 91.0141 273.047 89.0514C271.081 87.0887 268.414 85.9849 265.634 85.9824ZM275.017 128.408C275.014 130.891 274.024 133.272 272.265 135.028C270.506 136.783 268.121 137.771 265.634 137.775H68.09C65.6024 137.771 63.2177 136.783 61.4587 135.028C59.6997 133.272 58.71 130.891 58.7064 128.408V96.4511C58.71 93.968 59.6997 91.5876 61.4587 89.8317C63.2177 88.0759 65.6024 87.0879 68.09 87.0844H265.634C268.121 87.0879 270.506 88.0759 272.265 89.8317C274.024 91.5876 275.014 93.968 275.017 96.4511V128.408Z"
          fill="#3F3D56"
        />
        <path
          d="M108.29 130.65C104.68 130.65 101.151 129.582 98.1492 127.58C95.1474 125.577 92.8077 122.732 91.4261 119.402C90.0445 116.073 89.683 112.409 90.3873 108.875C91.0916 105.34 92.8301 102.094 95.3829 99.5453C97.9357 96.9971 101.188 95.2617 104.729 94.5586C108.27 93.8556 111.94 94.2164 115.275 95.5955C118.611 96.9746 121.462 99.31 123.467 102.306C125.473 105.303 126.544 108.826 126.544 112.429C126.538 117.26 124.613 121.891 121.191 125.307C117.769 128.723 113.13 130.645 108.29 130.65Z"
          fill={color}
        />
        <path
          d="M238.681 127.306H143.803C142.632 127.306 141.509 126.842 140.681 126.015C139.853 125.188 139.388 124.067 139.388 122.898C139.388 121.729 139.853 120.608 140.681 119.781C141.509 118.955 142.632 118.49 143.803 118.49H238.681C239.852 118.49 240.975 118.955 241.803 119.781C242.631 120.608 243.096 121.729 243.096 122.898C243.096 124.067 242.631 125.188 241.803 126.015C240.975 126.842 239.852 127.306 238.681 127.306Z"
          fill="#CCCCCC"
        />
        <path
          d="M175.204 108.573H143.803C143.224 108.573 142.649 108.459 142.114 108.237C141.578 108.016 141.091 107.691 140.681 107.282C140.271 106.872 139.946 106.386 139.724 105.852C139.502 105.317 139.388 104.744 139.388 104.165C139.388 103.586 139.502 103.013 139.724 102.478C139.946 101.943 140.271 101.457 140.681 101.048C141.091 100.639 141.578 100.314 142.114 100.092C142.649 99.8709 143.224 99.7568 143.803 99.7568H175.204C176.375 99.7568 177.498 100.221 178.326 101.048C179.155 101.875 179.62 102.996 179.62 104.165C179.62 105.334 179.155 106.455 178.326 107.282C177.498 108.108 176.375 108.573 175.204 108.573Z"
          fill="#CCCCCC"
        />
        <path
          d="M107.358 118.839C106.905 118.84 106.464 118.693 106.102 118.422L106.079 118.405L101.348 114.793C101.129 114.625 100.944 114.416 100.806 114.177C100.667 113.939 100.577 113.675 100.541 113.402C100.504 113.128 100.522 112.85 100.594 112.584C100.665 112.317 100.788 112.067 100.956 111.848C101.125 111.629 101.334 111.446 101.574 111.308C101.813 111.17 102.077 111.08 102.351 111.044C102.625 111.008 102.904 111.027 103.171 111.098C103.438 111.17 103.688 111.293 103.907 111.462L106.971 113.807L114.213 104.377C114.38 104.159 114.59 103.975 114.829 103.837C115.068 103.699 115.332 103.61 115.605 103.574C115.879 103.538 116.157 103.556 116.423 103.627C116.69 103.698 116.94 103.821 117.159 103.989L117.16 103.989L117.115 104.051L117.161 103.989C117.603 104.328 117.892 104.828 117.965 105.38C118.038 105.931 117.888 106.489 117.55 106.931L109.032 118.018C108.835 118.273 108.582 118.48 108.292 118.622C108.002 118.764 107.683 118.837 107.36 118.836L107.358 118.839Z"
          fill="white"
        />
        <path
          d="M61.6841 93.1909C60.8903 92.8558 60.0325 92.6985 59.1712 92.73C58.3099 92.7616 57.466 92.9813 56.6989 93.3737C55.9319 93.766 55.2603 94.3215 54.7316 95.001C54.2029 95.6804 53.8298 96.4673 53.6387 97.3063L40.3597 99.577L39.7739 107.969L58.4814 104.499C59.9145 104.726 61.3814 104.421 62.6039 103.64C63.8264 102.86 64.7197 101.659 65.1145 100.265C65.5093 98.8712 65.3781 97.3811 64.7459 96.0773C64.1137 94.7735 63.0243 93.7465 61.6842 93.1909L61.6841 93.1909Z"
          fill="#FFB8B8"
        />
        <path
          d="M38.8738 69.1046C40.7979 69.9675 42.3155 71.5366 43.1118 73.4864C43.9081 75.4362 43.922 77.6171 43.1507 79.5769L35.4533 99.1345L45.1167 97.3615C45.7201 97.2508 46.3433 97.3641 46.8688 97.6801C47.3944 97.996 47.7861 98.4929 47.9703 99.0771L49.8966 105.188C49.9973 105.507 50.0329 105.843 50.0015 106.177C49.9701 106.51 49.8723 106.833 49.7138 107.128C49.5553 107.423 49.3393 107.684 49.0785 107.894C48.8177 108.104 48.5174 108.261 48.1953 108.353L35.6351 111.974C34.0463 112.431 32.3728 112.516 30.7459 112.219C29.119 111.923 27.5831 111.254 26.2587 110.266C24.9343 109.277 23.8575 107.996 23.1128 106.522C22.368 105.048 21.9757 103.422 21.9665 101.771C21.9622 100.999 22.0416 100.23 22.2033 99.4756L27.4809 74.8647C27.7372 73.6696 28.258 72.5468 29.0053 71.5786C29.7525 70.6104 30.7072 69.8213 31.7995 69.2691C32.8917 68.7168 34.0939 68.4154 35.318 68.387C36.542 68.3585 37.757 68.6037 38.8738 69.1046Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="361" height="258" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
