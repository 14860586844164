/* eslint-disable camelcase */
// React
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material-UI
import {
  Container,
  Box,
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline
} from "@material-ui/core";

// Layout components
import Header from "Layouts/sections/Header";

// Redux actions
import { getTheme } from "Modules/units/App";

// Loading spinner
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

const PublicLayout = state => {
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);

  useEffect(() => {
    if (app.theme === null) dispatch(getTheme());
    return () => {};
  }, []);

  return (
    <>
      {app.theme?.mui_theme ? (
        <MuiThemeProvider theme={createMuiTheme(app.theme.mui_theme)}>
          <CssBaseline />
          <Header />
          <Container fixed maxWidth="xl">
            <Box mt={11}>{state.children}</Box>
          </Container>
        </MuiThemeProvider>
      ) : (
        <FullScreenSpinner />
      )}
    </>
  );
};

export default PublicLayout;
