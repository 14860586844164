/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, putFunc, postFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_ALL_PRICES_BY_ACC_TYPE_REQ = "auth/GET_ALL_PRICES_BY_ACC_TYPE_REQ";
const GET_ALL_PRICES_BY_ACC_TYPE_SCS = "auth/GET_ALL_PRICES_BY_ACC_TYPE_SCS";
const GET_ALL_PRICES_BY_ACC_TYPE_FLR = "auth/GET_ALL_PRICES_BY_ACC_TYPE_FLR";

const SET_PRICES = "auth/SET_PRICES";

const DELETE_PRICES = "auth/DELETE_PRICES";

const PRICES_SUCCESSFULLY_SETTED = "auth/PRICES_SUCCESSFULLY_SETTED";
const CONTROL_ACTION_FOR_SETTING_PRICES =
  "auth/CONTROL_ACTION_FOR_SETTING_PRICES";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/
export const getAllPricesByAccType = id => async dispatch => {
  dispatch({ type: GET_ALL_PRICES_BY_ACC_TYPE_REQ });
  const response = await getFunc(
    `accommodationTypePrices/getAllByAccommodationType/${id}`
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ALL_PRICES_BY_ACC_TYPE_SCS, payload: response.data });
  } else {
    dispatch({ type: GET_ALL_PRICES_BY_ACC_TYPE_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const setPrices = (body, control) => async dispatch => {
  const response = await postFunc(`accommodationTypePrices/interval`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({
      type: SET_PRICES,
      payload: { data: response.data }
    });
    dispatch(getAllPricesByAccType(body.accommodation_type?.id));
    NotificationManager.success(response.status.description);
    if (control) {
      dispatch({ type: PRICES_SUCCESSFULLY_SETTED });
    }
  } else if (typeof response.status.description === "string") {
    NotificationManager.error(response.status.description);
  } else {
    dispatch({ type: VALIDATION_MESSAGE, message: response.status });
  }
};

export const controlActionForSettingPrices = () => async dispatch => {
  dispatch({ type: CONTROL_ACTION_FOR_SETTING_PRICES });
};

export const deletePrices = body => async dispatch => {
  const response = await putFunc(`accommodationTypePrices/interval`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: DELETE_PRICES });
    dispatch(getAllPricesByAccType(body.accommodation_type?.id));
  } else {
    NotificationManager.error(response.status.description);
  }
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/
const INIT_STATE = {
  loading: false,
  prices: [],
  successfullySetted: false
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_PRICES_BY_ACC_TYPE_REQ:
      return {
        ...state,
        loading: true,
        prices: state.prices
      };
    case GET_ALL_PRICES_BY_ACC_TYPE_SCS:
      return {
        ...state,
        loading: false,
        prices: action.payload.map(i => {
          return {
            title: i.price.toFixed(2),
            start: i.date,
            end: i.date,
            allDay: true
          };
        })
      };
    case GET_ALL_PRICES_BY_ACC_TYPE_FLR:
      return {
        ...state,
        loading: false,
        prices: state.prices
      };
    case PRICES_SUCCESSFULLY_SETTED:
      return {
        ...state,
        successfullySetted: true
      };
    case CONTROL_ACTION_FOR_SETTING_PRICES:
      return {
        ...state,
        successfullySetted: false
      };
    default:
      return state;
  }
}
