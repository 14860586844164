/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, putFunc, postFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";

// import { changeImagesToFiles } from "Util/functions";
import changeImagesToFiles from "../../pages/services-items/functions";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_ONE_ADMIN_REQ = "auth/GET_ONE_ADMIN_REQ";
const GET_ONE_ADMIN_SCS = "auth/GET_ONE_ADMIN_SCS";
const GET_ONE_ADMIN_FLR = "auth/GET_ONE_ADMIN_FLR";
const UPDATE_USER = "auth/UPDATE_USER";
const UPDATE_APP_SETTINGS = "auth/UPDATE_APP_SETTINGS";
const GET_ONE_APP_SETTINGS_REQ = "auth/GET_ONE_APP_SETTINGS_REQ,";
const GET_ONE_APP_SETTINGS_SCS = "auth/GET_ONE_APP_SETTINGS_SCS,";
const GET_ONE_APP_SETTINGS_FLR = "auth/GET_ONE_APP_SETTINGS_FLR";
const SAVE_ICON_IMAGE = "auth/SAVE_ICON_IMAGE";
const SAVE_LOGO_IMAGE = "auth/SAVE_LOGO_IMAGE";
const CREATE_LOGIN_MSG = "auth/CREATE_LOGIN_MSG";
const UPDATE_LOGIN_MSG = "auth/UPDATE_LOGIN_MSG";

/**
 * ACTIONS
 */

export const getOneAdmin = (id) => async (dispatch) => {
  dispatch({ type: GET_ONE_ADMIN_REQ });
  const response = await getFunc(`admins/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ONE_ADMIN_SCS, payload: response.data });
  } else {
    dispatch({ type: GET_ONE_ADMIN_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const updateUser = (body) => async (dispatch) => {
  const response = await putFunc("admins/updateUserData", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: UPDATE_USER, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

/* App Settings */

export const getOneAppSettings = (lang) => async (dispatch) => {
  // let lang_temp;
  // const value = `; ${lang}`;
  // const parts = value.split(`; lang=`);
  // if (parts.length === 2) {
  //   lang_temp = parts
  //     .pop()
  //     .split(";")
  //     .shift();
  // }
  dispatch({ type: GET_ONE_APP_SETTINGS_REQ });
  const response = await getFunc(
    `tenants/details${lang ? `?lang=${lang}` : ``}`
  );

  if (response.status.errorCode === 200) {
    const iconRaw = [];
    const logoRaw = [];
    iconRaw.push({
      gallery: [{ name: response.data.icon_name, path: response.data.icon }],
    });
    logoRaw.push({
      gallery: [{ name: response.data.logo_name, path: response.data.logo }],
    });
    dispatch({ type: GET_ONE_APP_SETTINGS_SCS, payload: response.data });

    changeImagesToFiles(iconRaw[0].gallery).then((result) => {
      dispatch({
        type: SAVE_ICON_IMAGE,
        payload: result,
      });
    });

    changeImagesToFiles(logoRaw[0].gallery).then((result) => {
      dispatch({
        type: SAVE_LOGO_IMAGE,
        payload: result,
      });
    });
  } else {
    dispatch({ type: GET_ONE_APP_SETTINGS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const updateAppSettings = (body, id, logo, icon) => async (dispatch) => {
  const formData = new FormData();

  logo?.map((image) => {
    return formData.append("logo", image, image.name);
  });
  icon?.map((image) => {
    return formData.append("icon", image, image.name);
  });
  formData.append("data", JSON.stringify(body));
  const response = await putFunc(`tenants/basicSettings/${id}`, formData);

  if (response.status.errorCode === 200) {
    dispatch({ type: UPDATE_APP_SETTINGS, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const createLoginMsg = (tenantID, body) => async (dispatch) => {
  const response = await postFunc(`tenants/loginMessage/${tenantID}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({
      type: CREATE_LOGIN_MSG,
      payload: response.data,
    });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const updateLoginMsg = (tenantID, body) => async (dispatch) => {
  const response = await putFunc(`tenants/loginMessage/${tenantID}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({
      type: UPDATE_LOGIN_MSG,
      payload: response.data,
    });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

/**
 * REDUCERS
 */
const INIT_STATE = {
  loading: false,
  myProfile: [],
  appSettings: [],
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ONE_ADMIN_REQ:
      return {
        ...state,
        loading: true,
        myProfile: state.data,
      };
    case GET_ONE_ADMIN_SCS:
      return {
        ...state,
        loading: false,
        myProfile: action.payload,
      };
    case GET_ONE_ADMIN_FLR:
      return {
        ...state,
        loading: false,
        myProfile: state.data,
      };

    case UPDATE_USER:
      return {
        ...state,
        myProfile: action.payload,
      };

    case GET_ONE_APP_SETTINGS_REQ:
      return {
        ...state,
        loading: true,
        appSettings: state.data,
      };
    case GET_ONE_APP_SETTINGS_SCS:
      return {
        ...state,
        loading: false,
        appSettings: action.payload,
      };
    case GET_ONE_APP_SETTINGS_FLR:
      return {
        ...state,
        loading: false,
        appSettings: state.data,
      };

    case UPDATE_APP_SETTINGS:
      return {
        ...state,
        appSettings: action.payload,
      };

    case SAVE_ICON_IMAGE:
      return {
        ...state,

        appSettings: { ...state.appSettings, iconFormated: action.payload },
      };

    case SAVE_LOGO_IMAGE:
      return {
        ...state,

        appSettings: { ...state.appSettings, logoFormated: action.payload },
      };

    default:
      return state;
  }
}
