// eslint-disable-next-line import/no-cycle
import { authHeader, checkToken } from "Util/authUtil";
import { getCookie } from "Util/functions";

function handleResponse(response) {
  if (!response.ok) {
    checkToken();
    return Promise.reject(response.statusText);
  }
  checkToken();
  return response.json();
}

export async function onGetOrgsAndNumbers() {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/organisations/getNumberOfPendingOrdersAndItemsForAppointmentReservationType`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onGetCalendars(organisation, date, auth) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(
    `${
      process.env.MAIN_API_URL
    }/appointmentCalendars?org_core_id=${organisation}&date=${date}&lang=${getCookie(
      "lang",
      auth
    )}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onGetGroupCalendars() {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/visibility/calendars`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onCreateCalendar(data) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify(data)
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/appointmentCalendars`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onDeleteCalendar(calendarId) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/appointmentCalendars/${calendarId}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onGetWorkDaysForCalendar(calendarId, fromDate, toDate) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(
    `${
      process.env.MAIN_API_URL
    }/appointmentCalendarWorkingDays?appointment_calendar_id=${calendarId}&org_core_id=${JSON.parse(
      localStorage.getItem("org")
    )}&from=${fromDate}&to=${toDate}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onAddWorkDays(days) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify(days)
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/appointmentCalendarWorkingDays`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onDeleteWorkDays(days) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify(days)
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/appointmentCalendarWorkingDays`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onGetPendingReservations(organisation, date, auth) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(
    `${
      process.env.MAIN_API_URL
    }/orderItems/appointments?org_core_id=${organisation}&lang=${getCookie(
      "lang",
      auth
    )}&date=${date}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onAssignReservationToCalendar(reservationId, calendarId) {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify({
      appointment_calendar: { id: calendarId }
    })
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/orderItems/assign/${reservationId}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onAddNewCalendarReservation(data) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify(data)
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/ordersByAdmin`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onGetWorkingHoursForOrg(organisation) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/workingHours/${organisation}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onGetOneCalendarReservation(reservationId) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/orderItems/${reservationId}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onEditCalendarReservation(data, reservationId) {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify(data)
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/orderItems/appointments/${reservationId}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onDeleteCalendarReservation(reservationId) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/orderItems/delete/${reservationId}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onGetOneCalendar(calendarId) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/appointmentCalendars/${calendarId}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onEditCalendar(data, calendarId) {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify(data)
  };

  const request = new Request(
    `${process.env.MAIN_API_URL}/appointmentCalendars/${calendarId}`,
    options
  );
  const response = await fetch(request);
  return handleResponse(response);
}
