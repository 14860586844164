/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, postFunc, putFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "Modules/units/Validation";

// Actions
import { updateChatData } from "Modules/units/Auth";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const GET_ALL_ORGANISATIONS = "auth/GET_ALL_ORGANISATIONS";

const GET_ORGANISATION_AMENITIES = "auth/GET_ORGANISATION_AMENITIES";

const GET_ORGANISATION_RULES = "auth/GET_ORGANISATION_RULES";

const ADD_NEW_ORGANISATION = "auth/ADD_NEW_ORGANISATION";

const EDIT_ORGANISATION = "auth/EDIT_ORGANISATION";

const GET_ORGANISATION_TYPES = "auth/GET_ORGANISATION_TYPES";

const GET_ORGANISATION = "auth/GET_ORGANISATION";

const GET_ORGS_FOR_PICKUP = "auth/GET_ORGS_FOR_PICKUP";


/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/

export const getAllOrganisations = setLoading => async dispatch => {
  setLoading(true);
  const response = await getFunc("organisations");

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ALL_ORGANISATIONS, payload: response.data });
    setLoading(false);
  } else {
    NotificationManager.error(response.status.description);
    setLoading(false);
  }
};

export const getOrganisationAmenities = () => async dispatch => {
  const response = await getFunc("amenitiesOrganisations");

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ORGANISATION_AMENITIES, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const getOrganisationRules = () => async dispatch => {
  const response = await getFunc("amenitiesOrganisationRules");

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ORGANISATION_RULES, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const addNewOrganisation = (url, body) => async dispatch => {
  const response = await postFunc(`organisations/${url}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({
      type: ADD_NEW_ORGANISATION,
      payload: { data: response.data, parentId: body.parent_id }
    });
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const editOrganisation = (
  url,
  body,
  parentId,
  callback,
  fail
) => async dispatch => {
  const response = await putFunc(`organisations/${url}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({
      type: EDIT_ORGANISATION,
      payload: { data: response.data, parentId }
    });
    if (callback) {
      callback();
    }
    dispatch(updateChatData(response.data));
    NotificationManager.success(response.status.description);
  } else {
    if (fail) {
      fail();
    }
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const getOrganisation = (id, lang) => async dispatch => {
  // let lang_temp;
  // const value = `; ${lang}`;
  // const parts = value.split(`; lang=`);
  // if (parts.length === 2) {
  //   lang_temp = parts
  //     .pop()
  //     .split(";")
  //     .shift();
  // }
  const response = await getFunc(
    `organisations/${id}${lang ? `?lang=${lang}` : ``}`
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({
      type: GET_ORGANISATION,
      payload: { data: response.data }
    });
   // NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const getOrganisationTypes = url => async dispatch => {
  const response = await getFunc(`organisationTypes/${url}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ORGANISATION_TYPES, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const getHomeScreenRows = async (lang, orgId) => {
  const response = await getFunc(
    `homeScreenRow/getAllForAdmin/${lang}/${orgId}`
  );

  return response;
};

export const addHomeScreenRows = async (orgId, body) => {
  const response = await postFunc(`homeScreenRow/${orgId}`, body);

  return response;
};

export const updatePriorityHomeScreenRows = async body => {
  const response = await putFunc(`homeScreenRow/updatePriority`, body);
  return response
};

export const getOrgsForPickup = () => async (dispatch) => {
  const response = await getFunc(`organisations/pickupPermission`);
  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ORGS_FOR_PICKUP,
      payload: response.data,
    });
  } else {
    NotificationManager.error(i18n.t(response.status.description));
  }
};


/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/

const INIT_STATE = {
  data: [],
  amenities: [],
  rules: [],
  loading: false,
  organisationTypes: [],
  changedOrganisation: "",
  pickupOrgs: [],
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_ORGANISATIONS:
      return {
        ...state,
        data: action.payload.map(item => ({
          ...item,
          sub_organisations: item.sub_organisations.map(subOrg => ({
            ...subOrg,
            parentId: item.org_core_id
          }))
        }))
      };
    case GET_ORGANISATION_AMENITIES:
      return {
        ...state,
        amenities: action.payload
      };
    case GET_ORGANISATION_RULES:
      return {
        ...state,
        rules: action.payload
      };
    case ADD_NEW_ORGANISATION:
      if (
        state.data.some(item => item.org_core_id === action.payload.parentId)
      ) {
        return {
          ...state,
          data: state.data.map(item => {
            if (item.org_core_id === action.payload.parentId) {
              // eslint-disable-next-line no-param-reassign
              item.sub_organisations = [
                ...item.sub_organisations,
                { ...action.payload.data, parentId: item.org_core_id }
              ];
            }
            return item;
          }),
          changedOrganisation: action.payload.data
        };
      }
      return {
        ...state,
        data: state.data.concat(action.payload.data),
        changedOrganisation: action.payload.data
      };
    case EDIT_ORGANISATION:
      return {
        ...state,
        data: state.data.map(item => {
          if (item.org_core_id === action.payload.data.org_core_id) {
            return {
              ...action.payload.data,
              sub_organisations: item.sub_organisations
            };
          }
          if (item.org_core_id === action.payload.parentId) {
            // eslint-disable-next-line no-param-reassign
            item.sub_organisations = item.sub_organisations.map(subOrg => {
              if (subOrg.org_core_id === action.payload.data.org_core_id) {
                return { ...action.payload.data, parentId: item.org_core_id };
              }
              return subOrg;
            });
          }
          return item;
        }),
        changedOrganisation: {
          ...action.payload.data,
          parentId: action.payload.parentId
        }
      };
    case GET_ORGANISATION_TYPES:
      return {
        ...state,
        organisationTypes: action.payload
      };
    case GET_ORGANISATION:
      return {
        ...state,
        data: state.data?.map(i => {
          if (i.org_core_id === action.payload.data.org_core_id) {
            return {
              ...i,
              welcome_message: action.payload.data.welcome_message
            };
          }
          return i;
        }),
        changedOrganisation: {
          ...action.payload.data,
          welcome_message: action.payload.data.welcome_message
        }
      };
    case GET_ORGS_FOR_PICKUP:
      return {
        ...state,
        pickupOrgs: action.payload,
      };
    default:
      return state;
  }
}
