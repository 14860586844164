// eslint-disable-next-line import/prefer-default-export
import React from "react";
import { OrganisationsHelpImg } from "Components/atoms/helpImgs/OrganisationsHelpImg";
import { ServicesHelpImg } from "Components/atoms/helpImgs/ServicesHelpImg";
import { UsersHelpImg } from "Components/atoms/helpImgs/UsersHelpImg";
import { OrdersHelpImg } from "Components/atoms/helpImgs/OrdersHelpImg";
import { AppointmentsHelpImg } from "Components/atoms/helpImgs/AppointmentsHelpImg";
import { AccomodationTypesHelpImg } from "Components/atoms/helpImgs/AccomodationTypesHelpImg";
import { ReservationsHelpImg } from "Components/atoms/helpImgs/ReservationsHelpImg";
import { CampaignsHelpImg } from "Components/atoms/helpImgs/CampaignsHelpImg";
import { MenuPromotionsHelpImg } from "Components/atoms/helpImgs/MenuPromotionsHelpImg";
import { GuestPromotionsHelpImg } from "Components/atoms/helpImgs/GuestPromotionsHelpImg";
import { ReservationReviewHelpImg } from "Components/atoms/helpImgs/ReservationReviewHelpImg";
import { ChatHelpImg } from "Components/atoms/helpImgs/ChatHelpImg";

export const helpInfo = [
  {
    id: "bccd4ab0-7895-46db-b83a-45b0869c983d",
    name: "Organisations",
    text: "organisations_helptext",
    img: <OrganisationsHelpImg />,
  },
  {
    id: "93a00f2f-3a1f-41b0-9136-5c7ca1fec971",
    name: "Services",
    text: "services_helptext",
    img: <ServicesHelpImg />,
  },
  {
    id: "28cfa670-3872-4bd5-b8dd-5d20fe1885ec",
    name: "Users",
    text: "users_helptext",
    img: <UsersHelpImg />,
  },
  {
    id: "e3461783-785a-46f1-9da6-b9375c64c405",
    name: "Orders",
    text: "orders_helptext",
    img: <OrdersHelpImg />,
  },
  {
    id: "23b17823-56d6-4102-bfcd-5c899481e6b7",
    name: "Appointments",
    text: "appointments_helptext",
    img: <AppointmentsHelpImg />,
  },
  {
    id: "2ba7a2ca-58dd-4347-8381-1e39da07c1ac",
    name: "Accommodation Types",
    text: "accomodation_helptext",
    img: <AccomodationTypesHelpImg />,
  },
  {
    id: "11152e7f-06ba-4970-b7ef-240ab6304397",
    name: "Reservations",
    text: "reservations_helptext",
    img: <ReservationsHelpImg />,
  },
  {
    id: "d72defb4-314b-4cac-8246-42588aa8638f",
    name: "My Campaigns",
    text: "campaign_helptext",
    img: <CampaignsHelpImg />,
  },
  {
    id: "37e66419-2fe2-469a-91b2-da9eef608924",
    name: "Contactless App promotions",
    text: "menupromotion_helptext",
    img: <MenuPromotionsHelpImg />,
  },
  {
    id: "eb72867b-dcb4-4d0d-9dbb-a9452dd8fe8e",
    name: "GuestApp promotions",
    text: "guestapppromotion_helptext",
    img: <GuestPromotionsHelpImg />,
  },
  {
    id: "46744ab0-7895-46db-b83a-45b0869c436435",
    name: "Reservations review",
    text: "reservationreview_helptext",
    img: <ReservationReviewHelpImg />,
  },
  {
    id: "bb32ff3f-c365-43a5-9801-74d259077cb2",
    name: "Chat",
    text: "chat_helptext",
    img: <ChatHelpImg />,
  },
];
