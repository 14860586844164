import React from "react";
import { useTheme } from "@material-ui/core";

export const CampaignsHelpImg = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  return (
    <svg
      id="a2e620a1-ac76-48f9-b1a7-7c9a2eef61f8"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="200px"
      viewBox="0 0 1061 507.3972"
    >
      <path
        id="f8c762bc-b7e7-4c8e-b73e-f411c620c2bd"
        data-name="Path 765"
        d="M898.293,272.5326H638.123v-5.362H520.152v5.362H258.91a17.6,17.6,0,0,0-17.6,17.6h0v356.25a17.6,17.6,0,0,0,17.6,17.6H898.293a17.6,17.6,0,0,0,17.6-17.6v-356.252A17.6,17.6,0,0,0,898.293,272.5326Z"
        transform="translate(-69.5 -196.3014)"
        fill="#3f3d56"
      />
      <rect
        id="febd0196-f53d-4e2b-8506-d3dc9ab4b723"
        data-name="Rectangle 125"
        x="195.40601"
        y="107.3322"
        width="627.39099"
        height="353.91299"
        fill="#e6e6e6"
      />
      <rect
        id="a687e43e-dc25-402c-aff7-aaf80cd82cec"
        data-name="Rectangle 130"
        x="222.013"
        y="126.04821"
        width="574.39099"
        height="305.91299"
        fill="#fff"
      />
      <circle
        id="fb392c0f-c18b-4dca-9f8d-a13ba02e8cd8"
        data-name="Ellipse 120"
        cx="508.565"
        cy="91.24621"
        r="6.435"
        fill={color}
      />
      <path
        id="a3b325e1-b438-4eea-a78d-99975bd1014d"
        data-name="Path 766"
        d="M933.507,651.11158H872.278v-4.412a.875.875,0,0,0-.875-.875H850.41a.875.875,0,0,0-.875.875h0v4.412h-13.12v-4.412a.875.875,0,0,0-.875-.875H814.547a.875.875,0,0,0-.875.875h0v4.412h-13.12v-4.412a.875.875,0,0,0-.875-.875H778.684a.875.875,0,0,0-.875.875h0v4.412h-13.12v-4.412a.875.875,0,0,0-.875-.875H742.822a.875.875,0,0,0-.875.875h0v4.412H728.826v-4.412a.875.875,0,0,0-.875-.875H706.959a.875.875,0,0,0-.875.875h0v4.412H692.963v-4.412a.875.875,0,0,0-.875-.875H671.1a.875.875,0,0,0-.875.875h0v4.412H657.1v-4.412a.875.875,0,0,0-.875-.875H491.781a.875.875,0,0,0-.875.875h0v4.412h-13.12v-4.412a.875.875,0,0,0-.875-.875H455.918a.875.875,0,0,0-.875.875h0v4.412h-13.12v-4.412a.875.875,0,0,0-.875-.875H420.056a.875.875,0,0,0-.875.875h0v4.412H406.06v-4.412a.875.875,0,0,0-.875-.875H384.193a.875.875,0,0,0-.875.875h0v4.412H370.2v-4.412a.875.875,0,0,0-.875-.875H348.33a.875.875,0,0,0-.875.875h0v4.412h-13.12v-4.412a.875.875,0,0,0-.875-.875H312.467a.875.875,0,0,0-.875.875h0v4.412h-13.12v-4.412a.875.875,0,0,0-.875-.875H276.6a.875.875,0,0,0-.875.875h0v4.412H235.493a20.993,20.993,0,0,0-20.993,20.993v9.49207a20.993,20.993,0,0,0,20.993,20.993H933.507a20.993,20.993,0,0,0,20.993-20.992v-9.492a20.993,20.993,0,0,0-20.993-20.993Z"
        transform="translate(-69.5 -196.3014)"
        fill="#3f3d56"
      />
      <rect
        id="e8772b6c-5a9b-4fa1-81c9-662ac65893c6"
        data-name="Rectangle 126"
        x="382.20901"
        y="147.5042"
        width="254"
        height="267"
        fill="#e6e6e6"
      />
      <rect
        id="a87049f2-d9c9-408d-98dd-ac42069ee321"
        data-name="Rectangle 128"
        y="505.3972"
        width="1061"
        height="2"
        fill="#e6e6e6"
      />
      <path
        id="b307d008-1640-4a06-af2e-4466e39d32c3"
        data-name="Path 776"
        d="M1027.457,631.83357c14.376,15.426,15.119,38.1,15.119,38.1s-22.565-2.338-36.941-17.764-15.119-38.1-15.119-38.1S1013.081,616.4076,1027.457,631.83357Z"
        transform="translate(-69.5 -196.3014)"
        fill={color}
      />
      <path
        id="e6bb151c-e5ac-48bf-b05a-35e8b71544cc"
        data-name="Path 777"
        d="M1072.714,655.91261c-15.426,14.376-38.1,15.119-38.1,15.119s2.338-22.565,17.764-36.941,38.1-15.119,38.1-15.119S1088.141,641.53657,1072.714,655.91261Z"
        transform="translate(-69.5 -196.3014)"
        fill={color}
      />
      <path
        id="e2659c84-c871-46e0-a841-faed920e56d0"
        data-name="Path 778"
        d="M1068.88,701.69758h-56.739a5.8,5.8,0,0,1-5.219-3.265l-12.353-25.393a5.8,5.8,0,0,1,5.219-8.342h81.446a5.80007,5.80007,0,0,1,5.219,8.342l-12.353,25.394a5.8,5.8,0,0,1-5.219,3.265Z"
        transform="translate(-69.5 -196.3014)"
        fill="#e5e5e5"
      />
      <path
        id="b4ca1fea-0850-4eee-815e-097d11075e0d"
        data-name="Path 779"
        d="M130.457,631.83357c14.376,15.426,15.119,38.1,15.119,38.1s-22.565-2.338-36.941-17.764-15.119-38.1-15.119-38.1S116.081,616.4076,130.457,631.83357Z"
        transform="translate(-69.5 -196.3014)"
        fill="#e5e5e5"
      />
      <path
        id="f6595469-42b3-4592-aaf8-acdcc6d1b7a1"
        data-name="Path 780"
        d="M162.88,701.69758H106.141a5.7999,5.7999,0,0,1-5.219-3.265l-12.353-25.393a5.8,5.8,0,0,1,5.219-8.342h81.446a5.8,5.8,0,0,1,5.219,8.342L168.1,698.4336a5.8,5.8,0,0,1-5.219,3.265Z"
        transform="translate(-69.5 -196.3014)"
        fill="#e5e5e5"
      />
      <circle
        id="b9fa2d1d-eaff-4f39-9574-6fdb852a47dd"
        data-name="Ellipse 127"
        cx="539.856"
        cy="189.3302"
        r="33.757"
        fill="#a0616a"
      />
      <path
        id="a3ede095-a03b-477f-a348-b0bb518c525f"
        data-name="Path 803"
        d="M531.686,611.35563l21.8-137.237a52.032,52.032,0,0,1,65.97894-41.781h0a51.87505,51.87505,0,0,1,36.561,59.46l-22.8,122.559Z"
        transform="translate(-69.5 -196.3014)"
        fill={color}
      />
      <path
        id="a14f3686-8e71-4892-a260-94847064fbe5"
        data-name="Path 804"
        d="M618.676,635.91058a13.821,13.821,0,0,0,2.4-21.056l22.535-43.639-25.378,2.708-17.509,41.026a13.9,13.9,0,0,0,17.951,20.962Z"
        transform="translate(-69.5 -196.3014)"
        fill="#a0616a"
      />
      <path
        id="aefe090c-eadc-4acf-b060-d0ae14889076"
        data-name="Path 805"
        d="M623.502,607.1576a6.19321,6.19321,0,0,1-3.335-1.582l-8.622-7.967a6.205,6.205,0,0,1-1.5-6.946l17.232-40.845-2.445-80.705a19.92432,19.92432,0,1,1,39.483,5.386h0l4.18,50.733a63.73711,63.73711,0,0,1-12.284,43.152l-26.873,36.329a6.19082,6.19082,0,0,1-4.391,2.479A6.12051,6.12051,0,0,1,623.502,607.1576Z"
        transform="translate(-69.5 -196.3014)"
        fill={color}
      />
      <path
        id="b24d83c7-6fd4-4d89-b0e2-e143511102b2"
        data-name="Path 806"
        d="M414.959,367.2396a13.821,13.821,0,0,0,18.453,10.421l31.4,37.766,7.4-24.426-30.952-32.119a13.9,13.9,0,0,0-26.3,8.358Z"
        transform="translate(-69.5 -196.3014)"
        fill="#a0616a"
      />
      <path
        id="a479cb15-9c48-424e-90d4-4868fd6ffd90"
        data-name="Path 807"
        d="M439.556,382.89359a6.19406,6.19406,0,0,1,2.757-2.454l10.7-4.833a6.205,6.205,0,0,1,6.982,1.327l30.894,31.793,75.272,29.215a19.92421,19.92421,0,1,1-20.354,34.258h0l-48.347-15.932a63.73686,63.73686,0,0,1-34.948-28.137l-22.976-38.91a6.19212,6.19212,0,0,1-.571-5.01,6.12558,6.12558,0,0,1,.594-1.316Z"
        transform="translate(-69.5 -196.3014)"
        fill={color}
      />
      <path
        id="fbb91ec3-afe3-4815-be57-4aca5997223d"
        data-name="Path 808"
        d="M636.737,502.68461l-1.374,43.981-25.319,44,21.141-49.03Z"
        transform="translate(-69.5 -196.3014)"
        opacity="0.1"
        // style="isolation:isolate"
      />
      <path
        id="b3c0f84b-28f9-45ec-afed-9c3bdf0aec76"
        data-name="Path 696"
        d="M586.495,368.7116c3.482,4.121,9.374,5.283,14.81,5.76,11.039.968,27.091-.6,37.879-3.093.77,7.463-1.329,15.509,3.066,21.648a100.49878,100.49878,0,0,0,3.571-22.783c.133-3.3.081-6.724-1.262-9.758s-4.272-5.609-7.645-5.636a19.16113,19.16113,0,0,1,8.547-3.654l-10.68-5.367,2.743-2.816-19.336-1.183,5.6-3.558a121.439,121.439,0,0,0-25.441-.8c-3.94.29-8.06.845-11.252,3.128s-5.037,6.82-3.055,10.172a14.77,14.77,0,0,0-11.256,8.931c-1.423,3.608-1.293,7.616-.854,11.461a81.23381,81.23381,0,0,0,4.26,18.02"
        transform="translate(-69.5 -196.3014)"
        fill="#2f2e41"
      />
      <path
        id="a138b9cb-cdfc-4f68-9e32-04b91bb430b4"
        data-name="Path 138"
        d="M730.805,284.14538,423.2071,355.16a5.853,5.853,0,0,1-7.01124-4.38111L398.58018,274.477a5.853,5.853,0,0,1,4.38111-7.01124l307.59794-71.01459a5.853,5.853,0,0,1,7.01124,4.38111l17.61569,76.302A5.853,5.853,0,0,1,730.805,284.14538Z"
        transform="translate(-69.5 -196.3014)"
        fill="#fff"
      />
      <path
        id="ae469d8c-20b4-445b-a76f-3c35ff3d2f47"
        data-name="Path 138"
        d="M730.805,284.14538,423.2071,355.16a5.853,5.853,0,0,1-7.01124-4.38111L398.58018,274.477a5.853,5.853,0,0,1,4.38111-7.01124l307.59794-71.01459a5.853,5.853,0,0,1,7.01124,4.38111l17.61569,76.302A5.853,5.853,0,0,1,730.805,284.14538ZM403.48745,269.74475a3.512,3.512,0,0,0-2.629,4.20721l17.6157,76.302a3.511,3.511,0,0,0,4.20721,2.62895L730.27934,281.8683a3.511,3.511,0,0,0,2.62895-4.20721l-17.61591-76.30292a3.511,3.511,0,0,0-4.20722-2.629Z"
        transform="translate(-69.5 -196.3014)"
        fill="#e6e6e6"
      />
      <path
        id="b1e356c5-36d3-4e1f-8361-52645d018384"
        data-name="Path 139"
        d="M510.221,269.50883a3.969,3.969,0,1,0,1.77222,7.73764l.01344-.0031,182.31241-42.09014a3.969,3.969,0,0,0-1.78566-7.73454Z"
        transform="translate(-69.5 -196.3014)"
        fill="#e6e6e6"
      />
      <path
        id="f47b3ab2-4a63-40b6-b578-ee0e1882dc1b"
        data-name="Path 140"
        d="M515.57821,292.71348a3.969,3.969,0,1,0,1.77222,7.73764l.01344-.0031,78.44946-18.11148a3.969,3.969,0,0,0-1.77222-7.73764l-.01344.0031Z"
        transform="translate(-69.5 -196.3014)"
        fill="#e6e6e6"
      />
      <g id="ebccd953-b296-4a3a-b6cb-ab9b47d83c6a" data-name="Group 45">
        <circle
          id="f502afc0-c552-46f8-b839-9472032a2f9d"
          data-name="Ellipse 18"
          cx="390.88069"
          cy="100.82287"
          r="23.814"
          fill={color}
        />
        <path
          id="e70683fc-6315-4eed-926a-207d48de8bbe"
          data-name="Path 395"
          d="M459.71085,310.86205a3.689,3.689,0,0,1-2.32814-.21993l-.04573-.02023-9.58212-4.35a3.71619,3.71619,0,1,1,3.07693-6.76555l6.209,2.82673,8.71069-19.14a3.713,3.713,0,0,1,4.91681-1.84124h0l-.05224.125.05516-.12563a3.717,3.717,0,0,1,1.84049,4.918l-10.24462,22.51563a3.715,3.715,0,0,1-2.55257,2.07539Z"
          transform="translate(-69.5 -196.3014)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
