// Redux
import { combineReducers } from "redux";

// Units
import auth from "Modules/units/Auth";
import validation from "Modules/units/Validation";
import app from "Modules/units/App";
import appointments from "Modules/units/Appointments";
import verification from "Modules/units/Verification";
import settings from "Modules/units/Settings";
import customization from "Modules/units/Customization";
import users from "Modules/units/Users";
import lists from "Modules/units/List";
import services from "Modules/units/Services";
import accommodationTypes from "Modules/units/AccommodationTypes";
import organisations from "Modules/units/Organisations";
import pricing from "Modules/units/Pricing";
import reservations from "Modules/units/Reservations";
import tenants from "Modules/units/Tenants";
import orders from "Modules/units/Orders";
import superAdmin from "Modules/units/SuperAdmin";
import calendars from "Modules/units/Calendars";
import guests from "Modules/units/Guests";
import campaign from "Modules/units/Campaign";
import notifications from "Modules/units/Notification";
import chat from "Modules/units/Chat";
import keyAdministration from "Modules/units/KeyAdministration";
import reports from "Modules/units/Reports";
import gallery from "Modules/units/Gallery";
import integrations from "Modules/units/Integrations";
import tutorials from "Modules/units/Tutorials";

export default combineReducers({
	accommodationTypes,
	app,
	appointments,
	auth,
	calendars,
	campaign,
	chat,
	customization,
	gallery,
	guests,
	keyAdministration,
	lists,
	notifications,
	orders,
	organisations,
	pricing,
	reports,
	reservations,
	services,
	settings,
	superAdmin,
	tenants,
	users,
	validation,
	verification,
	integrations,
	tutorials,
});
