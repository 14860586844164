/* eslint-disable no-param-reassign */
/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import {
  postFunc,
  getFunc,
  putFunc,
  deleteFunc
} from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "Modules/units/Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const CREATE_ACCOMMODATION_TYPE = "auth/CREATE_ACCOMMODATION_TYPE";

const CREATE_ACCOMMODATION_UNIT = "auth/CREATE_ACCOMMODATION_UNIT";

const EDIT_ACCOMMODATION_TYPE = "auth/EDIT_ACCOMMODATION_TYPE";

const GET_ALL_ACCOMMODATION_TYPES = "auth/GET_ALL_ACCOMMODATION_TYPES";

const ACTIVATE_DEACTIVATE_ACCOMMODATION_TYPE =
  "auth/ACTIVATE_DEACTIVATE_ACCOMMODATION_TYPE";

const GET_ACCOMMODATION_AMENITIES = "auth/GET_ACCOMMODATION_AMENITIES";

const EDIT_ACCOMMODATION_UNIT = "auth/EDIT_ACCOMMODATION_UNIT";

/**
 * ACTIONS
 */

export const createAccommodationType = (
  body,
  closeDialog
) => async dispatch => {
  const response = await postFunc("accomodationType", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({
      type: CREATE_ACCOMMODATION_TYPE,
      payload: {
        ...response.data,
        amenities: response.amenities,
        images: response.images
      }
    });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const getAccommodationAmenities = () => async dispatch => {
  const response = await getFunc("amenitiesAccomodationType");

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ACCOMMODATION_AMENITIES, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const editAccommodationType = (
  body,
  id,
  closeDialog
) => async dispatch => {
  const response = await putFunc(`accomodationType/${id}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({
      type: EDIT_ACCOMMODATION_TYPE,
      payload: response.data
    });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const activateAccommodationType = id => async dispatch => {
  const response = await putFunc(`accomodationType/activate/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: ACTIVATE_DEACTIVATE_ACCOMMODATION_TYPE,
      payload: response.data
    });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const deactivateAccommodationType = id => async dispatch => {
  const response = await deleteFunc(`accomodationType/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: ACTIVATE_DEACTIVATE_ACCOMMODATION_TYPE,
      payload: response.data
    });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const getAllAccommodationTypes = () => async dispatch => {
  const response = await getFunc(
    "accommodationUnits/getAllGroupedByAccommodationType"
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ALL_ACCOMMODATION_TYPES, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const createAccommodationUnit = (
  body,
  closeDialog
) => async dispatch => {
  const response = await postFunc(`accommodationUnits`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({
      type: CREATE_ACCOMMODATION_UNIT,
      payload: response.data
    });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const deactivateAccommodationUnit = id => async dispatch => {
  const response = await deleteFunc(`accommodationUnits/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: EDIT_ACCOMMODATION_UNIT, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const activateAccommodationUnit = id => async dispatch => {
  const response = await putFunc(`accommodationUnits/activate/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: EDIT_ACCOMMODATION_UNIT, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const editAccommodationUnit = (
  body,
  closeDialog,
  id
) => async dispatch => {
  const response = await putFunc(`accommodationUnits/${id}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({ type: EDIT_ACCOMMODATION_UNIT, payload: response.data });
    closeDialog();
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

/**
 * REDUCERS
 */

const INIT_STATE = {
  loading: false,
  data: [],
  amenities: []
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case CREATE_ACCOMMODATION_TYPE:
      return {
        ...state,
        data: state.data.concat({ ...action.payload, accommodation_unit: [] })
      };
    case GET_ACCOMMODATION_AMENITIES:
      return {
        ...state,
        amenities: action.payload
      };
    case EDIT_ACCOMMODATION_TYPE:
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...action.payload,
              accommodation_unit: item.accommodation_unit
            };
          }
          return item;
        })
      };
    case ACTIVATE_DEACTIVATE_ACCOMMODATION_TYPE:
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...action.payload,
              accommodation_unit: item.accommodation_unit,
              images: item.images,
              amenities: item.amenities
            };
          }
          return item;
        })
      };
    case GET_ALL_ACCOMMODATION_TYPES:
      return {
        ...state,
        data: action.payload.map(item => ({
          ...item,
          amenities: item.amenities.map(amenity => amenity.amenities?.id)
        }))
      };
    case CREATE_ACCOMMODATION_UNIT:
      return {
        ...state,
        data: state.data.map(item => {
          if (
            item.id === action.payload.accommodation_unit.accommodation_type_id
          ) {
            item.accommodation_unit = item.accommodation_unit.concat(
              action.payload
            );
          }
          return item;
        })
      };
    case EDIT_ACCOMMODATION_UNIT:
      return {
        ...state,
        data: state.data.map(item => {
          if (
            item.id === action.payload.accommodation_unit.accommodation_type_id
          ) {
            item.accommodation_unit = item.accommodation_unit.map(unit => {
              if (
                unit.accommodation_unit.id ===
                action.payload.accommodation_unit.id
              ) {
                return { ...action.payload };
              }
              return unit;
            });
          }
          return item;
        })
      };
    default:
      return state;
  }
}
