/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import {
	getFunc,
	postFunc,
	putFunc,
	deleteFunc,
} from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";

import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

import changeImagesToFiles from "../../pages/services-items/functions";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_ALL_BY_ORG_ID_REQ = "auth/GET_ALL_BY_ORG_ID_REQ";
const GET_ALL_BY_ORG_ID_SCS = "auth/GET_ALL_BY_ORG_ID_SCS";
const GET_ALL_BY_ORG_ID_FLR = "auth/GET_ALL_BY_ORG_ID_FLR";
const CREATE_CATEGORY = "auth/CREATE_CATEGORY";
const ALTER_CATEGORY = "auth/ALTER_CATEGORY";
const UPDATE_PRIORITY_CATEGORY = "auth/UPDATE_PRIORITY_CATEGORY";
const ACTIVATE_CATEGORY = "auth/ACTIVATE_CATEGORY";
const DEACTIVATE_CATEGORY = "auth/DEACTIVATE_CATEGORY";

const GET_ALL_BY_ORG_REQ = "auth/GET_ALL_BY_ORG_REQ";
const GET_ALL_BY_ORG_SCS = "auth/GET_ALL_BY_ORG_SCS";
const GET_ALL_BY_ORG_FLR = "auth/GET_ALL_BY_ORG_FLR";
const GET_ALL_BY_ORG_AND_CAT_REQ = "auth/GET_ALL_BY_ORG_AND_CAT_REQ";
const GET_ALL_BY_ORG_AND_CAT_SCS = "auth/GET_ALL_BY_ORG_AND_CAT_SCS";
const GET_ALL_BY_ORG_AND_CAT_FLR = "auth/GET_ALL_BY_ORG_AND_CAT_FLR";
const GET_ALL_CHILDREN_BY_LOGGED_USER_REQ =
	"auth/GET_ALL_CHILDREN_BY_LOGGED_USER_REQ";
const GET_ALL_CHILDREN_BY_LOGGED_USER_SCS =
	"auth/GET_ALL_CHILDREN_BY_LOGGED_USER_SCS";
const GET_ALL_CHILDREN_BY_LOGGED_USER_FLR =
	"auth/GET_ALL_CHILDREN_BY_LOGGED_USER_FLR";
const CREATE_SUB_CATEGORY = "auth/CREATE_SUB_CATEGORY";
const ALTER_SUB_CATEGORY = "auth/ALTER_SUB_CATEGORY";
const UPDATE_PRIORITY_SUB_CATEGORY = "auth/UPDATE_PRIORITY_SUB_CATEGORY";
const ACTIVATE_SUB_CATEGORY = "auth/ACTIVATE_SUB_CATEGORY";
const DEACTIVATE_SUB_CATEGORY = "auth/DEACTIVATE_SUB_CATEGORY";
const CLEAR_SUBCATEGORIES = "auth/CLEAR_SUBCATEGORIES";
const CLEAR_TEMP_SUBCATEGORIES = "auth/CLEAR_TEMP_SUBCATEGORIES";

const GET_ALL_ARTICLES_BY_ORG_REQ = "auth/GET_ALL_ARTICLES_BY_ORG_REQ";
const GET_ALL_ARTICLES_BY_ORG_SCS = "auth/GET_ALL_ARTICLES_BY_ORG_SCS";
const GET_ALL_ARTICLES_BY_ORG_FLR = "auth/GET_ALL_ARTICLES_BY_ORG_FLR";
const CREATE_ARTICLE = "auth/CREATE_ARTICLE";
const ALTER_ARTICLE = "auth/ALTER_ARTICLE";
const CREATE_EXTRAS_ARTICLE = "auth/CREATE_EXTRAS_ARTICLE";
const ALTER_EXTRAS_ARTICLE = "auth/ALTER_EXTRAS_ARTICLE";
const ACTIVATE_ARTICLE = "auth/ACTIVATE_ARTICLE";
const DEACTIVATE_ARTICLE = "auth/DEACTIVATE_ARTICLE";
const CLEAR_ARTICLE = "auth/CLEAR_ARTICLE";

const GET_ALL_REVIEWS_BY_SERVICE_REQ = "service/GET_ALL_REVIEWS_BY_SERVICE_REQ";
const GET_ALL_REVIEWS_BY_SERVICE_SCS = "service/GET_ALL_REVIEWS_BY_SERVICE_SCS";
const GET_ALL_REVIEWS_BY_SERVICE_FLR = "service/GET_ALL_REVIEWS_BY_SERVICE_FLR";

const ACTIVATE_REVIEW = "service/ACTIVATE_ARTICLE";
const DEACTIVATE_REVIEW = "service/DEACTIVATE_ARTICLE";

const DELETE_CATEGORY = "service/DELETE_CATEGORY";
const DELETE_SUB_CATEGORY = "service/DELETE_SUB_CATEGORY";
const DELETE_SERVICE = "service/DELETE_SERVICE";

const OPEN_DELETE_DIALOG = "service/OPEN_DELETE_DIALOG";
const CLOSE_DELETE_DIALOG = "service/CLOSE_DELETE_DIALOG";

const ALTER_MOVE_BY_IDS = "service/ALTER_MOVE_BY_IDS";
const ALTER_DUPLICATE_ITEMS = "service/ALTER_DUPLICATE_ITEMS";

/**
 * ACTIONS
 */

/** **************** All Children By Logged User ******************** */
export const getAllChildrenByloggeduser = () => async (dispatch) => {
	dispatch({ type: GET_ALL_CHILDREN_BY_LOGGED_USER_REQ });
	const response = await getFunc(`organisations/getAllChildrenByLoggedUser`);

	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ALL_CHILDREN_BY_LOGGED_USER_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_ALL_CHILDREN_BY_LOGGED_USER_FLR });
		NotificationManager.error(response.status.description);
	}
};

/** ************ Category Actions ***************** */
export const getAllByOrgId = (lang, id) => async (dispatch) => {
	dispatch({ type: GET_ALL_BY_ORG_ID_REQ });
	const response = await getFunc(`category/getAllByOrganisation/${lang}/${id}`);
	// const response = await getFunc(
	// 	`category/getAllCategoriesByOrganisation/${lang}/${id}`
	// );
	if (response.status.errorCode === 200) {
		dispatch({ type: GET_ALL_BY_ORG_ID_SCS, payload: response.data });
	} else {
		dispatch({ type: GET_ALL_BY_ORG_ID_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const createCategory = (body, successFunc) => async (dispatch) => {
	const response = await postFunc("category", body);
	//const response = await postFunc("newCategory", body);
	if (response.status.errorCode === 200) {
		dispatch({ type: CREATE_CATEGORY, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		successFunc(response.data);
		/* localStorage.setItem("categoryId", JSON.stringify(response.data.id));
    localStorage.setItem("path_name", JSON.stringify(response.data.path_name)); */
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const alterCategory = (id, body, successFunc) => async (dispatch) => {
	const response = await putFunc(`category/${id}`, body);

	if (response.status.errorCode === 200) {
		dispatch({ type: ALTER_CATEGORY, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		successFunc(response.data);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const updatePriorityCategory = (body, successFunc) => async (
	dispatch
) => {
	const response = await putFunc(`category/updatePriority`, body);

	if (response.status.errorCode === 200) {
		console.log(response);
		dispatch({ type: UPDATE_PRIORITY_CATEGORY, payload: response.data });
		//dispatch({ type: VALIDATION_CLEAR });
		successFunc(response.data);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ /*type: VALIDATION_MESSAGE, */ message: response.status });
		}
	}
};

export const activateCategory = (id) => async (dispatch) => {
	const response = await putFunc(`category/activate/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({ type: ACTIVATE_CATEGORY, payload: response.data });
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const deactivateCategory = (id) => async (dispatch) => {
	const response = await deleteFunc(`category/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({ type: DEACTIVATE_CATEGORY, payload: response.data });
	} else {
		NotificationManager.error(response.status.description);
	}
};

/** ************ Sub-category Actions ***************** */
export const getAllByOrg = (lang, org) => async (dispatch) => {
	dispatch({ type: GET_ALL_BY_ORG_REQ });
	const response = await getFunc(
		`subCategory/getAllActiveByOrganisation/${lang}/${org}`
	);

	if (response.status.errorCode === 200) {
		dispatch({ type: GET_ALL_BY_ORG_SCS, payload: response.data });
	} else {
		dispatch({ type: GET_ALL_BY_ORG_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const getAllByOrgAndCat = (lang, id, catId) => async (dispatch) => {
	dispatch({ type: GET_ALL_BY_ORG_AND_CAT_REQ });
	const response = await getFunc(
		`subCategory/getAllByOrganisationAndCategory/${lang}/${id}/${catId}`
	);

	if (response.status.errorCode === 200) {
		dispatch({ type: GET_ALL_BY_ORG_AND_CAT_SCS, payload: response.data });
	} else {
		dispatch({ type: GET_ALL_BY_ORG_AND_CAT_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const createSubCategory = (body, successFunc) => async (dispatch) => {
	const response = await postFunc("subCategory", body);

	if (response.status.errorCode === 200) {
		dispatch({ type: CREATE_SUB_CATEGORY, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		successFunc(response.data);
		/* localStorage.setItem("subCategoryId", JSON.stringify(response.data.id));
    localStorage.setItem("path_name", JSON.stringify(response.data.path_name));
    localStorage.setItem(
      "subCategoryPath",
      JSON.stringify(response.data.path_name)
    ); */
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const alterSubCategory = (id, body, successFunc) => async (dispatch) => {
	const response = await putFunc(`subCategory/${id}`, body);

	if (response.status.errorCode === 200) {
		dispatch({ type: ALTER_SUB_CATEGORY, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		successFunc(response.data);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const updatePrioritySubCategory = (body, successFunc) => async (
	dispatch
) => {
	const response = await putFunc(`subCategory/updatePriority`, body);

	if (response.status.errorCode === 200) {
		dispatch({ type: UPDATE_PRIORITY_SUB_CATEGORY, payload: response.data });
		successFunc(response.data);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ message: response.status });
		}
	}
};

export const activateSubCategory = (id) => async (dispatch) => {
	const response = await putFunc(`subCategory/activate/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({ type: ACTIVATE_SUB_CATEGORY, payload: response.data });
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const deactivateSubCategory = (id) => async (dispatch) => {
	const response = await deleteFunc(`subCategory/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({ type: DEACTIVATE_SUB_CATEGORY, payload: response.data });
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const clearSubCategories = () => async (dispatch) => {
	dispatch({ type: CLEAR_SUBCATEGORIES });
};

export const clearTempSubCategories = () => async (dispatch) => {
	console.log("ciscenje potkategorija");
	dispatch({ type: CLEAR_TEMP_SUBCATEGORIES });
};

/** ************ Article Actions ***************** */

export const getAllArticlesByOrgId = (
	lang,
	orgId,
	categoryId,
	subCategoryId
) => async (dispatch) => {
	dispatch({ type: GET_ALL_ARTICLES_BY_ORG_REQ });
	const response = await getFunc(
		`service/getAllWithExtrasByOrganisation/${lang}/${orgId}?category=${categoryId}&&sub_category=${subCategoryId}`
	);

	if (response.status.errorCode === 200) {
		/* response.data.forEach(item => {
      changeImagesToFiles(item.gallery).then(result => {
        item.gallery = result;
      });
    }); */
		dispatch({
			type: GET_ALL_ARTICLES_BY_ORG_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_ALL_ARTICLES_BY_ORG_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const createArticle = (body, propertyImages, successFunc) => async (
	dispatch
) => {
	const formData = new FormData();

	propertyImages?.map((image) => {
		return formData.append("images", image, image.name);
	});

	formData.append("data", JSON.stringify(body));
	const response = await postFunc("service", formData);

	if (response.status.errorCode === 200) {
		dispatch({ type: CREATE_ARTICLE, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		successFunc(response.data);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const alterArticle = (id, body, propertyImages, successFunc) => async (
	dispatch
) => {
	const formData = new FormData();

	propertyImages?.map((image) => {
		return formData.append("images", image, image.name);
	});

	formData.append("data", JSON.stringify(body));
	const response = await putFunc(`service/${id}`, formData);

	if (response.status.errorCode === 200) {
		changeImagesToFiles(response.data.gallery).then((result) => {
			dispatch({
				type: ALTER_ARTICLE,
				payload: { ...response.data, files: result },
			});
		});
		dispatch({ type: VALIDATION_CLEAR });
		successFunc(response.data);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const activateArticle = (id) => async (dispatch) => {
	const response = await putFunc(`service/activate/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({ type: ACTIVATE_ARTICLE, payload: response.data });
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const deactivateArticle = (id) => async (dispatch) => {
	const response = await deleteFunc(`service/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({ type: DEACTIVATE_ARTICLE, payload: response.data });
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const clearArticle = () => async (dispatch) => {
	dispatch({ type: CLEAR_ARTICLE });
};

// Create Extras Service
export const createExtrasArticle = (
	body,
	propertyImages,
	successFunc
) => async (dispatch) => {
	const formData = new FormData();

	propertyImages?.map((image) => {
		return formData.append("images", image, image.name);
	});

	formData.append("data", JSON.stringify(body));
	const response = await postFunc("extrasSubCategories", formData);

	if (response.status.errorCode === 200) {
		dispatch({ type: CREATE_EXTRAS_ARTICLE, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		successFunc(response.data);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

// Alter Extras Service
export const alterExtrasArticle = (
	id,
	body,
	propertyImages,
	successFunc
) => async (dispatch) => {
	const formData = new FormData();

	propertyImages?.map((image) => {
		return formData.append("images", image, image.name);
	});

	formData.append("data", JSON.stringify(body));
	const response = await putFunc(`extrasSubCategories/${id}`, formData);

	if (response.status.errorCode === 200) {
		changeImagesToFiles(response.data.gallery).then((result) => {
			dispatch({
				type: ALTER_EXTRAS_ARTICLE,
				payload: { ...response.data, files: result },
			});
		});

		dispatch({ type: VALIDATION_CLEAR });
		successFunc(response.data);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

/** ************ Reviews Actions ***************** */

export const getAllReviewsByService = (service) => async (dispatch) => {
	dispatch({ type: GET_ALL_REVIEWS_BY_SERVICE_REQ });
	const response = await getFunc(
		`orderItems/review/getAllByService/${service}`
	);

	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ALL_REVIEWS_BY_SERVICE_SCS,
			payload: response,
		});
	} else {
		dispatch({ type: GET_ALL_REVIEWS_BY_SERVICE_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const activateReview = (id) => async (dispatch) => {
	const response = await putFunc(`orderItems/review/administration/${id}`, {
		review_show: true,
	});

	if (response.status.errorCode === 200) {
		dispatch({ type: ACTIVATE_REVIEW, payload: response.data });
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const deactivateReview = (id) => async (dispatch) => {
	const response = await putFunc(`orderItems/review/administration/${id}`, {
		review_show: false,
	});

	if (response.status.errorCode === 200) {
		dispatch({ type: DEACTIVATE_REVIEW, payload: response.data });
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const deleteCategory = (id, func) => async (dispatch) => {
	const response = await deleteFunc(`category/fullInactivate/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({ type: DELETE_CATEGORY, payload: response.data });
		func();
		dispatch(deleteDialog(false));
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const deleteSubcategory = (id, func) => async (dispatch) => {
	const response = await deleteFunc(`subCategory/fullInactivate/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({ type: DELETE_SUB_CATEGORY, payload: response.data });
		func();
		dispatch(deleteDialog(false));
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const deleteService = (id, func) => async (dispatch) => {
	const response = await deleteFunc(`service/fullInactivate/${id}`);

	if (response.status.errorCode === 200) {
		dispatch({ type: DELETE_SERVICE, payload: response.data });
		func();
		dispatch(deleteDialog(false));
	} else {
		NotificationManager.error(response.status.description);
	}
};

export const deleteDialog = (control) => async (dispatch) => {
	control
		? dispatch({ type: OPEN_DELETE_DIALOG })
		: dispatch({ type: CLOSE_DELETE_DIALOG });
};

export const moveByIds = (body) => async (dispatch) => {
	const response = await putFunc("service/moveByIds", body);

	if (response.status.errorCode === 200) {
		dispatch({ type: ALTER_MOVE_BY_IDS, payload: response.data });
		//dispatch({ type: VALIDATION_CLEAR });
		//successFunc(response.data);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} /*else {
      dispatch({ type: VALIDATION_MESSAGE,  message: response.status });
    }*/
	}
};

export const duplicateItems = (body) => async (dispatch) => {
	const response = await putFunc("service/duplicate", body);

	if (response.status.errorCode === 200) {
		dispatch({ type: ALTER_DUPLICATE_ITEMS, payload: response.data });
		//dispatch({ type: VALIDATION_CLEAR });
		//console.log(response.data)
		//successFunc(response.status);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} /*else {
      dispatch({ type: VALIDATION_MESSAGE,  message: response.status });
    }*/
	}
};

/**
 * REDUCERS
 */
const INIT_STATE = {
	loading: false,
	childrenByLoggeduser: [],
	serviceCategory: [],
	createCategory: [],
	updateCategory: [],
	activateCategory: [],
	deactivateCategory: [],
	serviceSubCategory: [],
	createSubCategory: [],
	updateSubCategory: [],
	tempSubcategory: [],
	activateSubCategory: [],
	deactivateSubCategory: [],
	serviceItems: [],
	createArticle: [],
	updateArticle: [],
	createExtras: [],
	alterExtras: [],
	activateItems: [],
	deactivateItems: [],
	reviews: [],
	deleteDialog: false,
};

export default function reducer(state = INIT_STATE, action = {}) {
	switch (action.type) {
		case GET_ALL_CHILDREN_BY_LOGGED_USER_REQ:
			return {
				...state,
				// loading: true,
				childrenByLoggeduser: [],
			};
		case GET_ALL_CHILDREN_BY_LOGGED_USER_SCS:
			return {
				...state,
				// loading: false,
				childrenByLoggeduser: action.payload,
			};
		case GET_ALL_CHILDREN_BY_LOGGED_USER_FLR:
			return {
				...state,
				// loading: false,
				childrenByLoggeduser: state.data,
			};

		case GET_ALL_BY_ORG_ID_REQ:
			return {
				...state,
				loading: true,
				serviceCategory: [],
			};
		case GET_ALL_BY_ORG_ID_SCS:
			return {
				...state,
				loading: false,
				serviceCategory: action.payload,
			};
		case GET_ALL_BY_ORG_ID_FLR:
			return {
				...state,
				loading: false,
				serviceCategory: state.data,
			};

		case CREATE_CATEGORY:
			return {
				...state,
				loading: false,
				serviceCategory: [...state.serviceCategory, action.payload],
			};

		case ALTER_CATEGORY:
			return {
				...state,
				loading: false,
				serviceCategory: state.serviceCategory
					.map((item) => {
						return item.id === action.payload.id ? action.payload : item;
					})
					.sort((a, b) => a.position - b.position),
			};
		case UPDATE_PRIORITY_CATEGORY:
			return {
				...state,
				loading: false,
				serviceCategory: action.payload.sort((a, b) => a.position - b.position),
			};
		case ACTIVATE_CATEGORY:
			return {
				...state,
				activateCategory: {
					...state.serviceCategory,
					data: state.serviceCategory.filter((item) => {
						if (item.id === action.payload.id) {
							item.status = action.payload.status;
							return item;
						}
					}),
				},
			};
		case DEACTIVATE_CATEGORY:
			return {
				...state,
				deactivateCategory: {
					...state.serviceCategory,
					data: state.serviceCategory.filter((item) => {
						if (item.id === action.payload.id) {
							item.status = action.payload.status;
							return item;
						}
					}),
				},
			};
		case GET_ALL_BY_ORG_REQ:
			return {
				...state,
				loading: true,
				tempSubCategory: [],
			};
		case GET_ALL_BY_ORG_SCS:
			return {
				...state,
				loading: false,
				tempSubCategory: action.payload,
			};
		case GET_ALL_BY_ORG_FLR:
			return {
				...state,
				loading: false,
				tempSubCategory: state.data,
			};
		case GET_ALL_BY_ORG_AND_CAT_REQ:
			return {
				...state,
				loading: true,
				serviceSubCategory: [],
				serviceItems: [],
			};
		case GET_ALL_BY_ORG_AND_CAT_SCS:
			return {
				...state,
				loading: false,
				serviceSubCategory: action.payload,
			};
		case GET_ALL_BY_ORG_AND_CAT_FLR:
			return {
				...state,
				loading: false,
				serviceSubCategory: state.data,
			};

		case CREATE_SUB_CATEGORY:
			return {
				...state,
				loading: false,
				serviceSubCategory: [...state.serviceSubCategory, action.payload],
			};

		case ALTER_SUB_CATEGORY:
			return {
				...state,
				loading: false,
				serviceSubCategory: state.serviceSubCategory
					.map((item) => {
						return item.id === action.payload.id ? action.payload : item;
					})
					.sort((a, b) => a.position - b.position),
			};
		case UPDATE_PRIORITY_SUB_CATEGORY:
			return {
				...state,
				loading: false,
				serviceSubCategory: action.payload.sort(
					(a, b) => a.position - b.position
				),
			};
		case ACTIVATE_SUB_CATEGORY:
			return {
				...state,
				activateSubCategory: {
					...state.serviceSubCategory,
					data: state.serviceSubCategory.filter((item) => {
						if (item.id === action.payload.id) {
							item.status = action.payload.status;
							return item;
						}
					}),
				},
			};
		case DEACTIVATE_SUB_CATEGORY:
			return {
				...state,
				deactivateSubCategory: {
					...state.serviceSubCategory,
					data: state.serviceSubCategory.filter((item) => {
						if (item.id === action.payload.id) {
							item.status = action.payload.status;
							return item;
						}
					}),
				},
			};

		case CLEAR_SUBCATEGORIES:
			return {
				...state,
				serviceSubCategory: [],
			};
		case CLEAR_TEMP_SUBCATEGORIES:
			return {
				...state,
				tempSubCategory: [],
			};
		case GET_ALL_ARTICLES_BY_ORG_REQ:
			return {
				...state,
				loading: true,
				serviceItems: [],
			};
		case GET_ALL_ARTICLES_BY_ORG_SCS:
			return {
				...state,
				loading: false,
				serviceItems: action.payload,
			};
		case GET_ALL_ARTICLES_BY_ORG_FLR:
			return {
				...state,
				loading: false,
				serviceItems: state.data,
			};

		case CREATE_ARTICLE:
			return {
				...state,
				loading: false,
				serviceItems: [...state.serviceItems, action.payload],
			};

		case ALTER_ARTICLE:
			return {
				...state,
				loading: false,
				serviceItems: state.serviceItems
					.map((item) => {
						if (item.id === action.payload.id) {
							return { ...action.payload };
						}
						return item;
					})
					.sort((a, b) => a.position - b.position),
			};

		case ACTIVATE_ARTICLE:
			return {
				...state,
				activateItems: {
					...state.serviceItems,
					data: state.serviceItems.filter((item) => {
						if (item.id === action.payload.id) {
							item.status = action.payload.status;
							return item;
						}
					}),
				},
			};
		case DEACTIVATE_ARTICLE:
			return {
				...state,
				deactivateItems: {
					...state.serviceItems,
					data: state.serviceItems.filter((item) => {
						if (item.id === action.payload.id) {
							item.status = action.payload.status;
							return item;
						}
					}),
				},
			};

		case CLEAR_ARTICLE:
			return {
				...state,
				serviceItems: [],
			};

		case CREATE_EXTRAS_ARTICLE:
			return {
				...state,
				serviceItems: [...state.serviceItems, action.payload],
			};

		case ALTER_EXTRAS_ARTICLE:
			return {
				...state,
				serviceItems: state.serviceItems
					.map((item) => {
						if (item.id === action.payload.id) {
							return { ...action.payload };
						}
						return item;
					})
					.sort((a, b) => a.position - b.position),
			};

		case GET_ALL_REVIEWS_BY_SERVICE_REQ:
			return {
				...state,
				loading: true,
				reviews: [],
			};
		case GET_ALL_REVIEWS_BY_SERVICE_SCS:
			return {
				...state,
				loading: false,
				reviews: action.payload,
			};
		case GET_ALL_REVIEWS_BY_SERVICE_FLR:
			return {
				...state,
				loading: false,
				reviews: state.data,
			};

		case ACTIVATE_REVIEW:
			return {
				...state,
				reviews: {
					...state.reviews,
					data: state.reviews.data.filter((item) => {
						if (item.id === action.payload.id) {
							item.review_show = action.payload.review_show;
							return item;
						}
						return item;
					}),
				},
			};
		case DEACTIVATE_REVIEW:
			return {
				...state,
				reviews: {
					...state.reviews,
					data: state.reviews.data.filter((item) => {
						if (item.id === action.payload.id) {
							item.review_show = action.payload.review_show;
							return item;
						}
						return item;
					}),
				},
			};
		case DELETE_CATEGORY:
			return {
				...state,
				loading: false,
				serviceCategory: state.serviceCategory
					?.filter((item) => {
						return item.id === action.payload.id ? null : item;
					})
					.sort((a, b) => a.position - b.position),
				serviceSubCategory: state.serviceSubCategory?.find(
					(item) => item.category_id === action.payload.id
				)
					? []
					: state.serviceSubCategory,
				serviceItems: state.serviceItems?.find(
					(item) => item.category_id === action.payload.id
				)
					? []
					: state.serviceItems,
			};
		case DELETE_SUB_CATEGORY:
			return {
				...state,
				loading: false,
				serviceSubCategory: state.serviceSubCategory
					?.filter((item) => {
						return item.id === action.payload.id ? null : item;
					})
					.sort((a, b) => a.position - b.position),
				serviceItems: state.serviceItems?.find(
					(item) => item.category_id === action.payload.category_id
				)
					? []
					: state.serviceItems,
			};
		case DELETE_SERVICE:
			return {
				...state,
				loading: false,
				serviceItems: state.serviceItems
					?.filter((item) => {
						return item.id === action.payload.id ? null : item;
					})
					.sort((a, b) => a.position - b.position),
			};
		case OPEN_DELETE_DIALOG:
			return {
				...state,
				deleteDialog: true,
			};
		case CLOSE_DELETE_DIALOG:
			return {
				...state,
				deleteDialog: false,
			};
		case ALTER_MOVE_BY_IDS:
			return {
				...state,
				serviceItems: action.payload,
			};
		case ALTER_DUPLICATE_ITEMS:
			return {
				...state,
				serviceItems: action.payload,
			};
		default:
			return state;
	}
}
