/* eslint-disable camelcase */
// React
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material-UI
import {
  Box,
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline,
  Container,
  makeStyles
} from "@material-ui/core";

// Layout components
import Header from "Layouts/sections/PrivateHeader";
import Sidebar from "Layouts/sections/Sidebar";

// Redux actions
import { getTheme } from "Modules/units/App";
import { getOrgsForLoggedUser } from "Modules/units/Auth";

// Loading spinner
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const style = makeStyles(() => ({
  container: {
    "@media (min-width:1280px)": {
      marginLeft: "234px"
    }
  }
}));

const ChatLayout = state => {
  const [, windowHeight] = useWindowSize();

  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const auth = useSelector(state => state.auth);

  const classes = style();

  useEffect(() => {
    if (app.theme === null) dispatch(getTheme());
    return () => {};
  }, []);

  useEffect(() => {
    if (auth.user?.organisationID)
      dispatch(getOrgsForLoggedUser(auth.user.organisationID));
    return () => {};
  }, []);

  return (
    <>
      {app.theme?.mui_theme ? (
        <MuiThemeProvider theme={createMuiTheme(app.theme.mui_theme)}>
          <CssBaseline />
          <Sidebar />
          <Header title={state.title} routeId={state.id} />
          <Box className={classes.container}>
            <Container style={{ height: windowHeight - 68, padding: 0 }}>
              {state.children}
            </Container>
          </Box>
        </MuiThemeProvider>
      ) : (
        <FullScreenSpinner />
      )}
    </>
  );
};

export default ChatLayout;
