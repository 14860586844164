/* eslint-disable no-lone-blocks */
/**
 * IMPORTS
 */

import { getFunc, postFunc, putFunc } from "Services/mainApiServices";
import { postChatFunc } from "Services/rocketApiServices";

import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
 * TYPES
 */

const GET_CHAT_ROOMS = "chat/GET_CHAT_ROOMS";
const UPDATE_CHAT_ROOMS = "chat/UPDATE_CHAT_ROOMS";
const GET_CHAT_USERS = "chat/GET_CHAT_USERS";
const CREATE_CHAT_ROOM = "chat/CREATE_CHAT_ROOM";
const DELETE_CHAT_ROOM = "chat/DELETE_CHAT_ROOM";
const LOAD_HISTORY = "chat/LOAD_HISTORY";
const SEND_MESSAGE = "chat/SEND_MESSAGE";
const APPEND_MESSAGE = "chat/APPEND_MESSAGE";
const READ_MESSAGE = "chat/READ_MESSAGE";
const CLEAR_MESSAGES = "chat/CLEAR_MESSAGES";

const GET_CHAT_TEMPLATE_BY_ORG_REQ = "auth/GET_CHAT_TEMPLATE_BY_ORG_REQ";
const GET_CHAT_TEMPLATE_BY_ORG_SCS = "auth/GET_CHAT_TEMPLATE_BY_ORG_SCS";
const GET_CHAT_TEMPLATE_BY_ORG_FLR = "auth/GET_CHAT_TEMPLATE_BY_ORG_FLR";

const ALTER_CHAT_TEMPLATE = "auth/ALTER_CHAT_TEMPLATE";

const CREATE_CHAT_TEMPLATE = "auth/CREATE_CHAT_TEMPLATE";

/**
 * ACTIONS
 */

export const getChatRooms = (callback) => async (dispatch) => {
	const response = await getFunc("guest/getAllRocketChatRoomsForAdmin");

	if (response?.success) {
		callback(response?.update);
		dispatch({ type: GET_CHAT_ROOMS, payload: response });
	}
};

export const updateChatRooms = (data) => async (dispatch) => {
	dispatch({ type: UPDATE_CHAT_ROOMS, payload: data });
};

export const getChatUsers = () => async (dispatch) => {
	const response = await getFunc(`guest/getRocketChatUsersForAdmin`);

	if (response?.success) {
		dispatch({
			type: GET_CHAT_USERS,
			payload: response,
		});
	}
};

export const createChatRoom = (body, callback) => async (dispatch) => {
	const response = await postChatFunc(
		`api/v1/method.call/createDirectMessage`,
		body
	);

	if (response?.success) {
		callback();
		dispatch({
			type: CREATE_CHAT_ROOM,
			payload: response,
		});
	}
};

export const deleteChatRoom = (body, room, callback) => async (dispatch) => {
	const response = await postChatFunc(
		`api/v1/method.call/createDirectMessage`,
		body
	);

	if (response?.success) {
		callback();
		dispatch({
			type: DELETE_CHAT_ROOM,
			payload: room,
		});
	}
};

export const getChatHistory = (body) => async (dispatch) => {
	const response = await postChatFunc(`api/v1/method.call/loadHistory`, body);

	if (response?.success) {
		dispatch({
			type: LOAD_HISTORY,
			payload: response,
		});
	}
};

export const sendMessage = (body, callback) => async (dispatch) => {
	const response = await postChatFunc(`api/v1/method.call/sendMessage`, body);

	if (response?.success) {
		callback();
		dispatch({
			type: SEND_MESSAGE,
			payload: response,
		});
	}
};

export const appendMessage = (data) => async (dispatch) => {
	dispatch({
		type: APPEND_MESSAGE,
		payload: data,
	});
};

export const readMessages = (body) => async (dispatch) => {
	const response = await postChatFunc(`api/v1/method.call/readMessages`, body);

	if (response?.success) {
		dispatch({
			type: READ_MESSAGE,
			payload: response,
		});
	}
};

export const clearMessages = () => async (dispatch) => {
	dispatch({
		type: CLEAR_MESSAGES,
	});
};

// CHAT TEMPLATES
export const getChatTemplatesByOrg = (org) => async (dispatch) => {
	dispatch({ type: GET_CHAT_TEMPLATE_BY_ORG_REQ });
	const response = await getFunc(`chatTemplate/${org}`);

	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_CHAT_TEMPLATE_BY_ORG_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_CHAT_TEMPLATE_BY_ORG_FLR });
		NotificationManager.error(response.status.description);
	}
};

export const createChatTemplate = (body) => async (dispatch) => {
	const response = await postFunc(`chatTemplate`, body);

	if (response.status.errorCode === 200) {
		dispatch({ type: CREATE_CHAT_TEMPLATE, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

export const alterChatTemplate = (body, func) => async (dispatch) => {
	const response = await putFunc(`chatTemplate`, body);

	if (response.status.errorCode === 200) {
		dispatch({ type: ALTER_CHAT_TEMPLATE, payload: response.data });
		dispatch({ type: VALIDATION_CLEAR });
		func();
		NotificationManager.success(response.status.description);
	} else {
		// eslint-disable-next-line no-lonely-if
		if (typeof response.status.description === "string") {
			NotificationManager.error(response.status.description);
		} else {
			dispatch({ type: VALIDATION_MESSAGE, message: response.status });
		}
	}
};

/**
 * REDUCERS
 */

const INIT_STATE = {
	rooms: [],
	users: [],
	messages: [],
	templates: [],
};

export default function reducer(state = INIT_STATE, action = {}) {
	switch (action.type) {
		case GET_CHAT_ROOMS:
			return {
				...state,
				rooms: action.payload.update?.sort(
					(a, b) => b._updatedAt?.$date - a._updatedAt?.$date
				),
			};
		case UPDATE_CHAT_ROOMS:
			if (action.payload[0] === "updated") {
				{
					return {
						...state,
						rooms: state.rooms
							.map((room) => {
								if (room._id === action.payload[1]._id) {
									return action.payload[1];
								}
								return room;
							})
							?.sort((a, b) => b._updatedAt?.$date - a._updatedAt?.$date),
					};
				}
			} else if (action.payload[0] === "removed") {
				return {
					...state,
					rooms: state.rooms.filter(
						(room) => room._id !== action.payload[1]._id
					),
				};
			} else if (action.payload[0] === "inserted") {
				return {
					...state,
					rooms: [...state.rooms, action.payload[1]],
				};
			} else {
				return { ...state };
			}
		case GET_CHAT_USERS:
			return {
				...state,
				users: action.payload.users,
			};
		case CREATE_CHAT_ROOM:
			return {
				...state,
			};
		case DELETE_CHAT_ROOM:
			return {
				...state,
			};
		case LOAD_HISTORY:
			return {
				...state,
				messages: JSON.parse(action.payload.message)?.result?.messages.sort(
					(a, b) => a.ts.$date - b.ts.$date
				),
			};
		case APPEND_MESSAGE:
			return {
				...state,
				messages: [...state.messages, action.payload],
			};
		case READ_MESSAGE:
			return {
				...state,
			};
		case CLEAR_MESSAGES:
			return {
				...state,
				messages: [],
			};
		//
		case GET_CHAT_TEMPLATE_BY_ORG_REQ:
			return {
				...state,
				loading: true,
				templates: state.templates,
			};
		case GET_CHAT_TEMPLATE_BY_ORG_SCS:
			return {
				...state,
				loading: false,
				templates: action.payload,
			};
		case GET_CHAT_TEMPLATE_BY_ORG_FLR:
			return {
				...state,
				loading: false,
				templates: state.templates,
			};
		case CREATE_CHAT_TEMPLATE:
			return {
				...state,
				loading: false,
				templates: [...state.templates, action.payload],
			};
		case ALTER_CHAT_TEMPLATE:
			return {
				...state,
				loading: false,
				templates: state.templates.map((item) => {
					return item.id === action.payload.id ? action.payload : item;
				}),
			};
		//
		default:
			return state;
	}
}
