import React from "react";
import { useTheme } from "@material-ui/core";

export const ReservationsHelpImg = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  return (
    <svg
      width="100%"
      height="200px"
      viewBox="0 0 350 269"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M203.024 268.218C202.973 268.134 201.758 266.103 201.336 261.886C200.95 258.017 201.199 251.495 204.576 242.398C210.974 225.164 203.101 211.259 203.021 211.12L203.41 210.895C204.925 213.8 206.02 216.906 206.662 220.12C208.247 227.605 207.67 235.387 204.997 242.555C198.61 259.759 203.358 267.904 203.407 267.984L203.024 268.218Z"
          fill="#F2F2F2"
        />
        <path
          d="M200.522 210.109C202.134 210.109 203.44 208.8 203.44 207.186C203.44 205.572 202.134 204.264 200.522 204.264C198.91 204.264 197.604 205.572 197.604 207.186C197.604 208.8 198.91 210.109 200.522 210.109Z"
          fill="#F2F2F2"
        />
        <path
          d="M209.725 220.898C211.336 220.898 212.643 219.59 212.643 217.976C212.643 216.362 211.336 215.054 209.725 215.054C208.113 215.054 206.807 216.362 206.807 217.976C206.807 219.59 208.113 220.898 209.725 220.898Z"
          fill="#F2F2F2"
        />
        <path
          d="M203.44 228.091C205.052 228.091 206.358 226.783 206.358 225.169C206.358 223.555 205.052 222.247 203.44 222.247C201.828 222.247 200.522 223.555 200.522 225.169C200.522 226.783 201.828 228.091 203.44 228.091Z"
          fill="#F2F2F2"
        />
        <path
          d="M211.071 234.16C212.683 234.16 213.989 232.852 213.989 231.238C213.989 229.624 212.683 228.315 211.071 228.315C209.46 228.315 208.153 229.624 208.153 231.238C208.153 232.852 209.46 234.16 211.071 234.16Z"
          fill="#F2F2F2"
        />
        <path
          d="M201.195 243.6C202.807 243.6 204.113 242.292 204.113 240.678C204.113 239.064 202.807 237.756 201.195 237.756C199.584 237.756 198.277 239.064 198.277 240.678C198.277 242.292 199.584 243.6 201.195 243.6Z"
          fill="#F2F2F2"
        />
        <path
          d="M204.786 268.324C204.786 268.324 201.868 261.132 210.622 255.737L204.786 268.324Z"
          fill="#F2F2F2"
        />
        <path
          d="M201.198 268.195C201.198 268.195 199.87 260.546 189.592 260.612L201.198 268.195Z"
          fill="#F2F2F2"
        />
        <path
          d="M49.9752 55.6345C47.6421 55.6345 45.3614 54.9416 43.4214 53.6435C41.4815 52.3454 39.9695 50.5004 39.0767 48.3418C38.1838 46.1831 37.9502 43.8078 38.4054 41.5162C38.8606 39.2246 39.9841 37.1196 41.6338 35.4674C43.2836 33.8153 45.3855 32.6902 47.6738 32.2343C49.9621 31.7785 52.334 32.0124 54.4895 32.9066C56.6451 33.8007 58.4874 35.3149 59.7836 37.2576C61.0798 39.2004 61.7717 41.4844 61.7717 43.8209C61.7717 46.9541 60.5289 49.9589 58.3166 52.1744C56.1043 54.3898 53.1038 55.6345 49.9752 55.6345ZM49.9752 32.415C47.7225 32.415 45.5204 33.084 43.6474 34.3373C41.7743 35.5907 40.3145 37.3721 39.4524 39.4564C38.5903 41.5406 38.3647 43.834 38.8042 46.0466C39.2437 48.2592 40.3285 50.2916 41.9214 51.8868C43.5143 53.482 45.5438 54.5684 47.7532 55.0085C49.9626 55.4486 52.2527 55.2227 54.3339 54.3594C56.4151 53.4961 58.194 52.0341 59.4455 50.1584C60.697 48.2826 61.365 46.0773 61.365 43.8214C61.365 42.3235 61.0704 40.8402 60.498 39.4563C59.9257 38.0725 59.0867 36.815 58.029 35.7559C56.9714 34.6967 55.7158 33.8565 54.3339 33.2833C52.952 32.7101 51.4709 32.415 49.9752 32.415Z"
          fill="#3F3D56"
        />
        <path
          d="M169.556 55.6345C167.223 55.6345 164.942 54.9416 163.002 53.6435C161.062 52.3454 159.55 50.5004 158.657 48.3418C157.764 46.1831 157.531 43.8078 157.986 41.5162C158.441 39.2246 159.565 37.1196 161.214 35.4674C162.864 33.8153 164.966 32.6902 167.254 32.2343C169.543 31.7785 171.915 32.0124 174.07 32.9066C176.226 33.8007 178.068 35.3149 179.364 37.2576C180.66 39.2004 181.352 41.4844 181.352 43.8209C181.352 46.9541 180.109 49.9589 177.897 52.1744C175.685 54.3898 172.684 55.6345 169.556 55.6345ZM169.556 32.415C167.303 32.415 165.101 33.084 163.228 34.3373C161.355 35.5907 159.895 37.3721 159.033 39.4564C158.171 41.5406 157.945 43.834 158.385 46.0466C158.824 48.2592 159.909 50.2916 161.502 51.8868C163.095 53.482 165.124 54.5684 167.334 55.0085C169.543 55.4486 171.833 55.2227 173.914 54.3594C175.996 53.4961 177.775 52.0341 179.026 50.1584C180.278 48.2826 180.946 46.0773 180.946 43.8214C180.946 42.3235 180.651 40.8402 180.079 39.4563C179.506 38.0725 178.667 36.815 177.61 35.7559C176.552 34.6967 175.296 33.8565 173.914 33.2833C172.533 32.7101 171.052 32.415 169.556 32.415Z"
          fill="#3F3D56"
        />
        <path
          d="M212.746 28.2158H7.24786C3.24738 28.2226 0.00547697 32.2335 0 37.1836V135.298C0.00547697 140.248 3.24738 144.257 7.24786 144.266H212.746C216.747 144.259 219.989 140.248 219.994 135.298V37.1832C219.989 32.2335 216.747 28.2245 212.746 28.2153V28.2158ZM219.142 135.298C219.138 139.666 216.278 143.205 212.747 143.211H7.24786C3.71749 143.205 0.858058 139.666 0.852581 135.298V37.1832C0.856688 32.8153 3.71749 29.2757 7.24786 29.2707H212.746C216.277 29.2762 219.136 32.8153 219.142 37.1832L219.142 135.298Z"
          fill="#3F3D56"
        />
        <path
          d="M51.5747 67.6782H15.0615V93.2744H51.5747V67.6782Z"
          fill="#F2F2F2"
        />
        <path
          d="M102.693 67.6782H66.1802V93.2744H102.693V67.6782Z"
          fill="#F2F2F2"
        />
        <path
          d="M153.811 67.6782H117.298V93.2744H153.811V67.6782Z"
          fill="#F2F2F2"
        />
        <path
          d="M204.93 67.6782H168.417V93.2744H204.93V67.6782Z"
          fill="#F2F2F2"
        />
        <path
          d="M51.5747 105.158H15.0615V130.754H51.5747V105.158Z"
          fill="#F2F2F2"
        />
        <path
          d="M102.693 105.158H66.1802V130.754H102.693V105.158Z"
          fill="#F2F2F2"
        />
        <path
          d="M153.811 105.158H117.298V130.754H153.811V105.158Z"
          fill="#F2F2F2"
        />
        <path
          d="M204.93 105.158H168.417V130.754H204.93V105.158Z"
          fill="#F2F2F2"
        />
        <path
          d="M78.343 130.605L69.7236 118.684L72.0714 116.982L78.6694 126.107L103.917 100.13L105.994 102.155L78.343 130.605Z"
          fill={color}
        />
        <path
          d="M129.918 93.1255L121.298 81.2045L123.646 79.5023L130.244 88.6269L155.492 62.6504L157.568 64.6752L129.918 93.1255Z"
          fill={color}
        />
        <path d="M50.1308 0H49.5068V43.8747H50.1308V0Z" fill="#2F2E41" />
        <path d="M170.168 0H169.544V43.8747H170.168V0Z" fill="#2F2E41" />
        <path
          d="M349.331 268.998H176.235C176.15 269.004 176.064 268.992 175.983 268.963C175.902 268.934 175.828 268.889 175.765 268.83C175.702 268.771 175.652 268.7 175.618 268.621C175.584 268.542 175.566 268.457 175.566 268.371C175.566 268.285 175.584 268.2 175.618 268.121C175.652 268.042 175.702 267.971 175.765 267.912C175.828 267.854 175.902 267.808 175.983 267.779C176.064 267.75 176.15 267.738 176.235 267.744H349.331C349.417 267.738 349.503 267.75 349.584 267.779C349.665 267.808 349.739 267.854 349.801 267.912C349.864 267.971 349.914 268.042 349.948 268.121C349.982 268.2 350 268.285 350 268.371C350 268.457 349.982 268.542 349.948 268.621C349.914 268.7 349.864 268.771 349.801 268.83C349.739 268.889 349.665 268.934 349.584 268.963C349.503 268.992 349.417 269.004 349.331 268.998Z"
          fill="#3F3D56"
        />
        <path
          d="M27.6809 93.1255L19.0615 81.2045L21.4093 79.5023L28.0072 88.6269L53.2547 62.6504L55.3314 64.6752L27.6809 93.1255Z"
          fill={color}
        />
        <path
          d="M299.522 170.391C299.764 170.318 300.011 170.265 300.262 170.232L310.257 149.892L307.205 145.311L313.507 139.795L321.429 149.504L305.294 173.019C305.692 173.909 305.81 174.899 305.633 175.857C305.455 176.815 304.99 177.697 304.3 178.384C303.61 179.071 302.727 179.532 301.769 179.704C300.811 179.877 299.823 179.753 298.937 179.35C298.051 178.946 297.309 178.282 296.809 177.446C296.309 176.609 296.075 175.641 296.139 174.668C296.202 173.695 296.56 172.765 297.164 172.001C297.769 171.237 298.591 170.675 299.522 170.391Z"
          fill="#FFB8B8"
        />
        <path
          d="M316.584 142.609C316.448 142.753 316.292 142.876 316.12 142.975L305.894 148.912C305.423 149.185 304.862 149.26 304.336 149.12C303.809 148.98 303.36 148.636 303.087 148.164C303.059 148.114 303.032 148.064 303.008 148.012L298.033 137.489C297.821 137.046 297.775 136.542 297.904 136.068C298.033 135.595 298.328 135.183 298.734 134.909L305.073 130.591C305.469 130.321 305.947 130.198 306.423 130.244C306.9 130.289 307.345 130.5 307.683 130.839L316.544 139.743C316.921 140.122 317.136 140.633 317.143 141.168C317.151 141.703 316.95 142.22 316.583 142.609L316.584 142.609Z"
          fill="#CCCCCC"
        />
        <path
          d="M330.943 259.15L325.543 260.621L317.307 240.464L325.276 238.292L330.943 259.15Z"
          fill="#FFB8B8"
        />
        <path
          d="M321.268 260.124L331.681 257.287L333.464 263.851L316.495 268.475C316.022 266.733 316.259 264.875 317.154 263.309C318.049 261.743 319.529 260.596 321.268 260.123H321.268L321.268 260.124Z"
          fill="#2F2E41"
        />
        <path
          d="M284.785 263.276H279.189L276.527 241.662H284.785V263.276Z"
          fill="#FFB8B8"
        />
        <path
          d="M275.193 261.675H285.984V268.48H268.398C268.398 267.586 268.574 266.701 268.915 265.876C269.256 265.05 269.757 264.3 270.388 263.668C271.019 263.036 271.768 262.535 272.592 262.193C273.416 261.851 274.3 261.675 275.192 261.675H275.193Z"
          fill="#2F2E41"
        />
        <path
          d="M290.68 123.415C296.871 123.415 301.89 118.389 301.89 112.189C301.89 105.989 296.871 100.963 290.68 100.963C284.489 100.963 279.47 105.989 279.47 112.189C279.47 118.389 284.489 123.415 290.68 123.415Z"
          fill="#FFB8B8"
        />
        <path
          d="M284.442 247.865H276.796C276.273 247.865 275.769 247.665 275.388 247.307C275.007 246.948 274.776 246.458 274.742 245.935C272.464 209.661 273.796 183.641 278.936 164.049C278.959 163.963 278.987 163.879 279.02 163.796L279.43 162.771C279.582 162.389 279.845 162.061 280.185 161.83C280.525 161.599 280.927 161.477 281.338 161.478H297.062C297.358 161.478 297.651 161.542 297.92 161.666C298.19 161.79 298.429 161.971 298.622 162.196L300.757 164.692C300.799 164.74 300.838 164.79 300.875 164.842C310.506 178.292 319.137 204.587 329.133 239.93C329.281 240.443 329.222 240.994 328.97 241.465C328.718 241.936 328.292 242.29 327.783 242.451L319.567 245.085C319.096 245.234 318.586 245.208 318.132 245.012C317.677 244.817 317.308 244.464 317.091 244.019L295.766 199.725C295.617 199.416 295.372 199.162 295.068 199.003C294.764 198.844 294.417 198.786 294.078 198.84C293.739 198.894 293.426 199.055 293.186 199.301C292.946 199.546 292.791 199.863 292.745 200.203L286.477 246.087C286.409 246.579 286.165 247.03 285.791 247.356C285.417 247.683 284.938 247.864 284.442 247.865Z"
          fill="#2F2E41"
        />
        <path
          d="M299.896 164.874H299.831L280.73 164.269C280.239 164.256 279.769 164.066 279.406 163.734C279.044 163.402 278.812 162.951 278.754 162.462C276.335 142.835 282.973 137.577 285.941 136.226C286.189 136.114 286.404 135.939 286.564 135.72C286.725 135.5 286.827 135.243 286.859 134.972L287.116 132.915C287.16 132.552 287.3 132.207 287.522 131.916C292.174 125.88 298.527 123.947 301.899 123.333C302.434 123.237 302.986 123.357 303.432 123.668C303.879 123.979 304.184 124.455 304.28 124.991C304.286 125.025 304.291 125.059 304.295 125.093L304.68 128.047C304.719 128.329 304.832 128.595 305.006 128.82C314.709 141.523 303.899 160.279 301.634 163.91C301.449 164.205 301.193 164.448 300.889 164.616C300.585 164.785 300.243 164.874 299.896 164.874Z"
          fill="#CCCCCC"
        />
        <path
          d="M293.885 189.407L279.454 192.192L278.906 189.342L289.952 187.209L277.113 153.316L279.823 152.287L293.885 189.407Z"
          fill={color}
        />
        <path
          d="M313.297 113.453C310.796 109.927 307.798 106.783 304.395 104.12C301.565 101.907 298.416 100.016 294.943 99.1119C291.47 98.2078 287.632 98.3614 284.462 100.048C281.293 101.736 278.015 105.088 277.995 108.682C277.989 109.837 278.282 111.399 279.418 111.2L279.746 111.245L287.965 109.583L291.465 121.749C291.711 122.603 292.041 123.559 292.852 123.923C293.615 124.267 294.495 123.943 295.271 123.62C295.397 123.567 296.488 121.115 296.488 121.115C296.516 121.038 296.563 120.97 296.625 120.918C296.688 120.866 296.763 120.831 296.843 120.817C296.923 120.804 297.005 120.812 297.081 120.841C297.157 120.871 297.224 120.919 297.275 120.983L297.923 121.794C298.046 121.95 298.217 122.06 298.409 122.11C298.601 122.159 298.804 122.145 298.987 122.069L312.39 116.465C313.008 116.207 313.698 115.871 313.877 115.224C314.046 114.602 313.668 113.977 313.297 113.453Z"
          fill="#2F2E41"
        />
        <path
          d="M298.114 192.772C298.701 192.36 299.19 191.824 299.548 191.202C299.905 190.579 300.122 189.886 300.183 189.171C300.245 188.456 300.148 187.736 299.902 187.062C299.655 186.388 299.264 185.777 298.756 185.27L302.303 142.364L291.74 143.82L291.539 185.505C290.745 186.416 290.313 187.587 290.324 188.796C290.335 190.005 290.789 191.168 291.599 192.065C292.41 192.961 293.521 193.528 294.721 193.658C295.921 193.789 297.128 193.474 298.112 192.772L298.114 192.772Z"
          fill="#FFB8B8"
        />
        <path
          d="M302.994 148.543C302.797 148.543 302.6 148.514 302.41 148.458L291.07 145.118C290.811 145.041 290.57 144.915 290.36 144.745C290.15 144.576 289.976 144.366 289.846 144.129C289.717 143.892 289.636 143.632 289.607 143.363C289.579 143.095 289.603 142.823 289.679 142.564C289.695 142.509 289.714 142.455 289.734 142.402L293.931 131.545C294.106 131.086 294.44 130.706 294.871 130.473C295.303 130.241 295.804 130.171 296.283 130.278L303.775 131.909C304.242 132.011 304.66 132.273 304.955 132.65C305.25 133.027 305.405 133.496 305.391 133.975L305.043 146.541C305.028 147.076 304.806 147.584 304.424 147.958C304.041 148.331 303.529 148.541 302.994 148.542V148.543Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="350" height="269" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
