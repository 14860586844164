// React
import React from "react";

// MUI
import {
  CircularProgress,
  Box,
  Container,
  makeStyles
} from "@material-ui/core";

const style = makeStyles(() => ({
  container: {
    height: "100vh"
  }
}));

const FullScreenSpinner = () => {
  const classes = style();

  return (
    <Container maxWidth="sm">
      <Box
        className={classes.container}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Box>
    </Container>
  );
};

export default FullScreenSpinner;
