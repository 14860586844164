import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

// Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// MUI
import { Typography, Grid } from "@material-ui/core";

// Atoms
import IconButton from "Components/atoms/buttons/IconButton";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "500",
    fontFamily: "Rubik"
  },
  tabs: {
    backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    color: "white",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.47)"
  },
  text: {
    fontWeight: "400",
    fontFamily: "Rubik",
    lineHeight: "normal"
  }
}));

const HelpDialog = ({ open, handleClose, data }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle id="customized-dialog-title">
        <Typography className={classes.title} variant="h5">
          {t("Help").toUpperCase()}
          {" | "}
          {t(data?.name)}
        </Typography>
        {handleClose ? (
          <IconButton
            styleProp={{
              position: "absolute",
              right: 7,
              top: 7,
              color: "grey"
            }}
            onClick={handleClose}
            icon="closeIcon"
          />
        ) : null}
      </DialogTitle>
      <DialogContent dividers style={{ paddingBottom: "20px" }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <Typography className={classes.text} variant="body1">
              {ReactHtmlParser(t(data?.text))}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {data?.img}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default HelpDialog;
