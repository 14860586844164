// React
import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// MUI
import Typography from "@material-ui/core/Typography";

// Molecules
import ChangeLanguageNew from "Components/molecules/ChangeLanguageNew";

const style = makeStyles(() => ({
  title: {
    fontFamily: "Rubik",
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "normal",
    alignItems: "center",
    letterSpacing: "0.4px",
    color: "#000000",
  },
}));

const Text = (props) => {
  const {
    title,
    fontSize,
    lineHeight,
    fontWeight,
    letterSpacing,
    variant,
    color,
    dataCy,
    lang,
    setAdministrationLang,
    getData,
    fontFamily,
  } = props;
  const classes = style();
  const { t } = useTranslation();

  return (
    <Typography
      className={classes.title}
      variant={variant}
      color={color}
      data-cy={dataCy}
      style={{
        fontSize: `${fontSize}`,
        lineHeight: `${lineHeight}`,
        fontWeight: `${fontWeight}`,
        letterSpacing: `${letterSpacing}`,
        fontFamily: fontFamily ? `${fontFamily}` : "Rubik",
      }}
    >
      {t(title)}{" "}
      {lang && (
        <ChangeLanguageNew setLang={setAdministrationLang} getData={getData} />
      )}
    </Typography>
  );
};

export default Text;
