import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChatContext } from "Router/MainRouter";

// Material UI
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  makeStyles,
  Box
} from "@material-ui/core";

// Actions
import { logOut } from "Modules/units/Auth";

const style = makeStyles(theme => ({
  container: {
    position: "fixed",
    height: "100%"
  },
  logo: {
    flexGrow: 1,
    padding: "20px",
    borderBottom: "2px solid #e8e8e8",
    textAlign: "center"
  },
  navLinks: {
    color: "#595959",
    textDecoration: "none",
    padding: "0",
    minHeight: "unset",
    "&.Mui-expanded": {
      margin: "unset",
      minHeight: "unset"
    }
  },
  navLinksActive: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    color: "#fff"
  },
  listText: {
    fontSize: "13px"
  },
  organisationName: {
    padding: "10px",
    textAlign: "center",
    fontSize: "17px",
    fontWeight: "600",
    backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    backgroundSize: "100%",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundColor: "#fff"
  },
  organisationLine: {
    height: "2px",
    width: "60%",
    margin: "0 auto",
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`
  }
}));

const MenuLink = ({ value, classes, style, t }) => (
  <NavLink
    to={value.linkTo}
    exact
    className={classes.navLinks}
    key={value.name}
    activeClassName={classes.navLinksActive}
  >
    <ListItem key={value.name} style={style}>
      <ListItemIcon style={{ color: "inherit" }}>
        <Icon
          style={{
            display: "block",
            margin: "0 auto"
          }}
        >
          {value.icon}
        </Icon>
      </ListItemIcon>
      <ListItemText
        primary={t(value.name).toUpperCase()}
        classes={{ primary: classes.listText }}
      />
    </ListItem>
  </NavLink>
);

const LogoutMenu = ({ classes, t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const realTimeAPI = useContext(ChatContext);

  return (
    <Box
      className={classes.navLinks}
      onClick={() => dispatch(logOut(history, realTimeAPI))}
    >
      <ListItem
        style={{
          background: "inherit",
          padding: "8px 30px 8px 0"
        }}
      >
        <ListItemIcon style={{ color: "inherit" }}>
          <Icon
            style={{
              display: "block",
              margin: "0 auto"
            }}
          >
            power_settings_new
          </Icon>
        </ListItemIcon>
        <ListItemText
          primary={t("Log out").toUpperCase()}
          classes={{ primary: classes.listText }}
        />
      </ListItem>
    </Box>
  );
};

const MobileSidebar = ({ open, setOpen, organisation }) => {
  const auth = useSelector(state => state.auth);
  const { t } = useTranslation();

  const classes = style();

  const history = useHistory();

  useEffect(() => {
    setOpen(false);
  }, [history.location.pathname]);

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <Box className={classes.organisationName}>{organisation}</Box>
      <Box width="100%">
        <Box className={classes.organisationLine} />
      </Box>
      <List>
        {auth?.meni?.map(item => {
          if (item?.childRoutes.length !== 0 && item?.show_hide !== 0) {
            return item?.childRoutes.map(child => (
              <MenuLink
                value={child}
                key={item?.id}
                classes={classes}
                style={{
                  background: "inherit",
                  padding: "8px 30px 8px 0"
                }}
                t={t}
              />
            ));
          }
          if (item?.show_hide !== 0) {
            return (
              <MenuLink
                value={item}
                key={item?.id}
                classes={classes}
                style={{
                  background: "inherit",
                  padding: "8px 30px 8px 0"
                }}
                t={t}
              />
            );
          }
        })}
        <LogoutMenu classes={classes} t={t} />
      </List>
    </Drawer>
  );
};

export default MobileSidebar;
