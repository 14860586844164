/* eslint-disable consistent-return */
/* eslint-disable no-bitwise */
/* eslint-disable no-unused-expressions */
import { useState, useEffect, useLayoutEffect } from "react";

export const getCookie = (name, auth) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts
			.pop()
			.split(";")
			.shift();
	}
	return auth.user ? auth.user.default_language : "en";
};

export const getCookieWithPromise = async (name, auth) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	return new Promise((resolve) => {
		if (parts.length === 2) {
			resolve(
				parts
					.pop()
					.split(";")
					.shift()
			);
		} else {
			resolve(auth.user ? auth.user.default_language : "en");
		}
	});
};

export const dataURLtoFile = (dataurl, filename, id, preview) => {
	const arr = dataurl.split(",");
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	// eslint-disable-next-line no-plusplus
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	const file = new File([u8arr], filename, { type: mime });
	file.id = id;
	file.preview = preview;
	/*   console.log(file); */
	return file;
};

export const changeImagesToFiles = (array, setFiles) => {
	let imageName = "";
	array.forEach((item) => {
		(async function getImages() {
			const blob = await fetch(item.path).then((r) => r.blob());
			const dataUrl = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result);
				reader.readAsDataURL(blob);
			});
			imageName = item.path.split("/")[item.path.split("/").length - 1];
			if (imageName !== "default.jpg") {
				if (item.cover) {
					setFiles((prevFiles) => {
						const state = [...prevFiles];
						state.unshift(
							dataURLtoFile(
								dataUrl,
								imageName,
								item.id,
								URL.createObjectURL(blob)
							)
						);
						return state;
					});
				} else {
					setFiles((prevFiles) => {
						const state = [...prevFiles];
						state.push(
							dataURLtoFile(
								dataUrl,
								imageName,
								item.id,
								URL.createObjectURL(blob)
							)
						);
						return state;
					});
				}
			}
		})();
	});
};

export function usePersistedState(key, defaultValue) {
	const [state, setState] = useState(
		() => JSON.parse(localStorage.getItem(key)) || defaultValue
	);
	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(state));
	}, [key, state]);
	return [state, setState];
}

export const getNameFromList = (lists, listName, id) => {
	let finalName = "";
	lists?.data?.forEach((listItem) => {
		if (Object.keys(listItem)[0] === listName) {
			const name = Object.values(listItem)[0].filter(
				(item) => item.id === id
			)[0];
			finalName = name?.text;
		}
	});
	return finalName;
};

export const createID = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
		const r = (Math.random() * 16) | 0;
		const v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

export const createColorFromString = (str) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 3) - hash);
	}
	const color = Math.abs(hash)
		.toString(16)
		.substring(0, 6);
	console.log(`#${"000000".substring(0, 6 - color.length)}${color}`);
	return `#${"000000".substring(0, 6 - color.length)}${color}`;
};
