/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, putFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const SAVE_NOTIFICATIONS_TO_REDUX = "notifications/SAVE_NOTIFICATIONS_TO_REDUX";
const SET_RECIEVED_NOTIFICATIONS = "notifications/SET_RECIEVED_NOTIFICATIONS";
const SET_READ_NOTIFICATIONS = "notifications/SET_READ_NOTIFICATIONS";

/**
 * ACTIONS
 */

export const getNotifications = () => async dispatch => {
  const response = await getFunc("notifications/unreadForAdmin");

  if (response.status.errorCode === 200) {
    dispatch({
      type: SAVE_NOTIFICATIONS_TO_REDUX,
      payload: response.data
    });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const markNotificationsAsRecieved = body => async dispatch => {
  const response = await putFunc("notifications/recieved", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: SET_RECIEVED_NOTIFICATIONS, payload: body });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const markNotificationsAsRead = body => async dispatch => {
  const response = await putFunc("notifications/read", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: SET_READ_NOTIFICATIONS, payload: body });
  } else {
    NotificationManager.error(response.status.description);
  }
};

/**
 * REDUCERS
 */
const INIT_STATE = {
  list: []
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case SAVE_NOTIFICATIONS_TO_REDUX:
      return {
        ...state,
        list: action.payload
      };
    case SET_RECIEVED_NOTIFICATIONS:
      return {
        ...state,
        list: state.list.map(item => {
          if (action.payload.list_id?.find(bodyItem => bodyItem === item.id)) {
            return { ...item, state: "397fdf4a-be04-48dc-9c2c-abd4725e88b0" };
          }
          return item;
        })
      };
    case SET_READ_NOTIFICATIONS:
      return {
        ...state,
        list: state.list.map(item => {
          if (action.payload.list_id?.find(bodyItem => bodyItem === item.id)) {
            return { ...item, state: "efcab6b6-bf38-4d63-95af-35f3bc73ccb6" };
          }
          return item;
        })
      };
    default:
      return state;
  }
}
