/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import { NotificationManager } from "react-notifications";
import {
  getFunc,
  postFunc,
  putFunc,
  deleteFunc,
} from "Services/mainApiServices";
import { getCookie } from "Util/functions";
import { VALIDATION_MESSAGE /* VALIDATION_CLEAR */ } from "./Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const GET_WORKING_HOURS_FOR_ORG_REQ =
  "appointments/GET_WORKING_HOURS_FOR_ORG_REQ";
const GET_WORKING_HOURS_FOR_ORG_SCS =
  "appointments/GET_WORKING_HOURS_FOR_ORG_SCS";
const GET_WORKING_HOURS_FOR_ORG_FLR =
  "appointments/GET_WORKING_HOURS_FOR_ORG_FLR";

const GET_ORGS_AND_NUMBERS_REQ = "appointments/GET_ORGS_AND_NUMBERS_REQ";
const GET_ORGS_AND_NUMBERS_SCS = "appointments/GET_ORGS_AND_NUMBERS_SCS";
const GET_ORGS_AND_NUMBERS_FLR = "appointments/GET_ORGS_AND_NUMBERS_FLR";

const GET_PENDING_APPOINTMENTS_REQ =
  "appointments/GET_PENDING_APPOINTMENTS_REQ";
const GET_PENDING_APPOINTMENTS_SCS =
  "appointments/GET_PENDING_APPOINTMENTS_SCS";
const GET_PENDING_APPOINTMENTS_FLR =
  "appointments/GET_PENDING_APPOINTMENTS_FLR";

const ADD_APPOINTMENT_TO_CALENDAR = "appointments/ADD_APPOINTMENT_TO_CALENDAR";

const GET_ALL_CALENDARS_BY_ORG_REQ =
  "appointments/GET_ALL_CALENDARS_BY_ORG_REQ";
const GET_ALL_CALENDARS_BY_ORG_SCS =
  "appointments/GET_ALL_CALENDARS_BY_ORG_SCS";
const GET_ALL_CALENDARS_BY_ORG_FLR =
  "appointments/GET_ALL_CALENDARS_BY_ORG_FLR";

const GET_CALENDARS_REQ = "appointments/GET_CALENDARS_REQ";
const GET_CALENDARS_SCS = "appointments/GET_CALENDARS_SCS";
const GET_CALENDARS_FLR = "appointments/GET_CALENDARS_FLR";

const CREATE_CALENDAR = "appointments/CREATE_CALENDAR";
const EDIT_CALENDAR = "appointments/EDIT_CALENDAR";
const DELETE_CALENDAR = "appointments/DELETE_CALENDAR";

const GET_WORKING_DAYS_REQ = "appointments/GET_WORKING_DAYS_REQ";
const GET_WORKING_DAYS_SCS = "appointments/GET_WORKING_DAYS_SCS";
const GET_WORKING_DAYS_FLR = "appointments/GET_WORKING_DAYS_FLR";

const ADD_WORK_DAYS = "appointments/ADD_WORK_DAYS";
const EDIT_WORK_DAYS = "appointments/EDIT_WORK_DAYS";
const DELETE_WORK_DAY = "appointments/DELETE_WORK_DAY";

const CREATE_PAUSE = "appointments/CREATE_PAUSE";
const DELETE_PAUSE = "appointments/DELETE_PAUSE";

const CREATE_QUICK_GUEST = "appointments/CREATE_QUICK_GUEST";

const QUICK_EDIT_GUEST = "appointments/QUICK_EDIT_GUEST";

const GET_INTERVALS_BY_CALENDAR_REQ =
  "appointments/GET_INTERVALS_BY_CALENDAR_REQ";
const GET_INTERVALS_BY_CALENDAR_SCS =
  "appointments/GET_INTERVALS_BY_CALENDAR_SCS";
const GET_INTERVALS_BY_CALENDAR_FLR =
  "appointments/GET_INTERVALS_BY_CALENDAR_FLR";
const CLEAR_INTERVALS_BY_CALENDAR = "appointments/CLEAR_INTERVALS_BY_CALENDAR";

const CREATE_RESERVATION = "appointments/CREATE_RESERVATION";
const EDIT_RESERVATION = "appointments/EDIT_RESERVATION";
const CANCEL_RESERVATION = "appointments/CANCEL_RESERVATION";

const GET_RESERVATIONS_BY_CAL_REQ = "appointments/GET_RESERVATIONS_BY_CAL_REQ";
const GET_RESERVATIONS_BY_CAL_SCS = "appointments/GET_RESERVATIONS_BY_CAL_SCS";
const GET_RESERVATIONS_BY_CAL_FLR = "appointments/GET_RESERVATIONS_BY_CAL_FLR";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/
export const getWorkingHoursForOrg = (org) => async (dispatch) => {
  dispatch({ type: GET_WORKING_HOURS_FOR_ORG_REQ });
  const response = await getFunc(`workingHours/${org}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_WORKING_HOURS_FOR_ORG_SCS,
      payload: { data: response.data },
    });
  } else {
    dispatch({ type: GET_WORKING_HOURS_FOR_ORG_FLR });
    NotificationManager.error(response?.status?.description);
  }
};

export const getOrgsAndNumbers = () => async (dispatch) => {
  dispatch({ type: GET_ORGS_AND_NUMBERS_REQ });
  const response = await getFunc(
    `organisations/getNumberOfPendingOrdersAndItemsForAppointmentReservationType`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ORGS_AND_NUMBERS_SCS,
      payload: { data: response.data },
    });
  } else {
    dispatch({ type: GET_ORGS_AND_NUMBERS_FLR });
    NotificationManager.error(response?.status?.description);
  }
};

export const getPendingReservations = (organisation, date, auth) => async (
  dispatch
) => {
  dispatch({ type: GET_PENDING_APPOINTMENTS_REQ });
  const response = await getFunc(
    `orderItems/appointments?org_core_id=${organisation}&lang=${getCookie(
      "lang",
      auth
    )}&date=${date}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_PENDING_APPOINTMENTS_SCS,
      payload: { data: response.data },
    });
  } else {
    dispatch({ type: GET_PENDING_APPOINTMENTS_FLR });
    NotificationManager.error(response?.status?.description);
  }
};

export const addAppointmentToCalendar = (
  body,
  reservationId,
  successFunc
) => async (dispatch) => {
  const response = await putFunc(`orderItems/assign/${reservationId}`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: ADD_APPOINTMENT_TO_CALENDAR,
      payload: {
        data: response.data,
        appointment_calendar_id: body.appointment_calendar?.id,
        reservationId,
      },
    });
    successFunc();
  } else {
    dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    if (typeof response?.status?.description === "string")
      NotificationManager.error(response?.status?.description);
  }
};

export const getAllCalendarsByOrganisation = (org) => async (dispatch) => {
  dispatch({ type: GET_ALL_CALENDARS_BY_ORG_REQ });
  const response = await getFunc(
    `appointmentCalendars/getAllByOrganisation/${org}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_CALENDARS_BY_ORG_SCS,
      payload: { data: response.data },
    });
  } else {
    dispatch({ type: GET_ALL_CALENDARS_BY_ORG_FLR });
    NotificationManager.error(response?.status?.description);
  }
};

export const getCalendars = (organisation, date, auth) => async (dispatch) => {
  dispatch({ type: GET_CALENDARS_REQ });
  const response = await getFunc(
    `appointmentCalendars?org_core_id=${organisation}&date=${date}&lang=${getCookie(
      "lang",
      auth
    )}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_CALENDARS_SCS,
      payload: { data: response.data },
    });
  } else {
    dispatch({ type: GET_CALENDARS_FLR });
    NotificationManager.error(response?.status?.description);
  }
};

export const createCalendar = (body, successFunc) => async (dispatch) => {
  const response = await postFunc(`appointmentCalendars`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: CREATE_CALENDAR,
      payload: { data: response.data },
    });
    successFunc();
  } else {
    dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    if (typeof response?.status?.description === "string")
      NotificationManager.error(response?.status?.description);
  }
};

export const editCalendar = (body, successFunc) => async (dispatch) => {
  const response = await putFunc(`appointmentCalendars/${body.id}`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: EDIT_CALENDAR,
      payload: { data: response.data },
    });
    successFunc();
  } else {
    dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    if (typeof response?.status?.description === "string")
      NotificationManager.error(response?.status?.description);
  }
};

export const deleteCalendar = (id, successFunc) => async (dispatch) => {
  const response = await deleteFunc(`appointmentCalendars/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: DELETE_CALENDAR,
      payload: { data: response.data },
    });
    successFunc();
    NotificationManager.success(response?.status?.description);
  } else {
    NotificationManager.error(response?.status?.description);
  }
};

// WORKING DAYS

export const getWorkDaysForCalendar = (id, fromDate, toDate) => async (
  dispatch
) => {
  dispatch({ type: GET_WORKING_DAYS_REQ });
  const response = await getFunc(
    `appointmentCalendarWorkingDays?appointment_calendar_id=${id}&org_core_id=${JSON.parse(
      localStorage.getItem("org")
    )}&from=${fromDate}&to=${toDate}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_WORKING_DAYS_SCS,
      payload: { data: response.data },
    });
  } else {
    dispatch({ type: GET_WORKING_DAYS_FLR });
    NotificationManager.error(response?.status?.description);
  }
};

export const addWorkDays = (body, successFunc) => async (dispatch) => {
  const response = await postFunc(`appointmentCalendarWorkingDays`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: ADD_WORK_DAYS,
      payload: { ...response },
    });
    successFunc(response);
  } else {
    NotificationManager.error(response?.status?.description);
  }
};

export const editWorkDays = (body, successFunc) => async (dispatch) => {
  const response = await postFunc(
    `appointmentCalendarWorkingDays/getAndCheckOrderItemsForBreak`,
    body
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: EDIT_WORK_DAYS,
      payload: { ...response },
    });
    successFunc(response);
  } else {
    NotificationManager.error(response?.status?.description);
  }
};

export const deleteWorkDays = (body) => async (dispatch) => {
  const response = await putFunc(`appointmentCalendarWorkingDays/delete`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: DELETE_WORK_DAY,
      payload: { data: response.data },
    });
    NotificationManager.success(response?.status?.description);
  } else {
    NotificationManager.error(response?.status?.description);
  }
};

// PAUSE ADMINISTRATION

export const createPause = (body, successFunc) => async (dispatch) => {
  const response = await postFunc(`orderItems/appointments/createBreak`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: CREATE_PAUSE,
      payload: { ...response },
    });
    successFunc();
    NotificationManager.success(response?.status?.description);
  } else {
    NotificationManager.error(response?.status?.description);
  }
};

export const deletePause = (body, successFunc) => async (dispatch) => {
  const response = await putFunc(
    `orderItems/appointments/inactivateBreak`,
    body
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: DELETE_PAUSE,
      payload: { ...response },
    });
    successFunc();
    NotificationManager.success(response?.status?.description);
  } else {
    NotificationManager.error(response?.status?.description);
  }
};

// GUESTS

export const createQuickGuest = (body, successFunc) => async (dispatch) => {
  const response = await postFunc(`guests/createNotPropertyGuest`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: CREATE_QUICK_GUEST,
      payload: { ...response },
    });
    successFunc();
    NotificationManager.success(response?.status?.description);
  } else {
    dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    if (typeof response?.status?.description === "string")
      NotificationManager.error(response?.status?.description);
  }
};

export const quickEditGuest = (id, body, successFunc) => async (dispatch) => {
  const response = await putFunc(`guests/personalInfo/${id}`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: QUICK_EDIT_GUEST,
      payload: { ...response },
    });
    successFunc();
    NotificationManager.success(response?.status?.description);
  } else {
    dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    if (typeof response?.status?.description === "string")
      NotificationManager.error(response?.status?.description);
  }
};

export const getIntervalsByService = (
  service,
  date,
  calendar,
  quantity
) => async (dispatch) => {
  dispatch({ type: GET_INTERVALS_BY_CALENDAR_REQ });
  const response = await getFunc(
    `orderItems/intervalsByCalendar?service_id=${service}&date=${date}&calendar_id=${calendar}&quantity=${quantity}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_INTERVALS_BY_CALENDAR_SCS,
      payload: { ...response.data },
    });
  } else {
    dispatch({ type: GET_INTERVALS_BY_CALENDAR_FLR });
    NotificationManager.error(response?.status?.description);
  }
};

export const getIntervalsByOrder = (order, date, calendar, quantity) => async (
  dispatch
) => {
  dispatch({ type: GET_INTERVALS_BY_CALENDAR_REQ });
  const response = await getFunc(
    `orderItems/intervalsByCalendar/${order}?date=${date}&calendar_id=${calendar}&quantity=${quantity}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_INTERVALS_BY_CALENDAR_SCS,
      payload: { ...response.data },
    });
  } else {
    dispatch({ type: GET_INTERVALS_BY_CALENDAR_FLR });
    NotificationManager.error(response?.status?.description);
  }
};

export const clearIntervalsByCalendar = () => async (dispatch) => {
  dispatch({ type: CLEAR_INTERVALS_BY_CALENDAR });
};

// RESERVATIONS

export const createReservation = (body, successFunc) => async (dispatch) => {
  const response = await postFunc(`orders/appointment`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: CREATE_RESERVATION,
      payload: {
        data: response.data,
        appointment_calendar_id: body.service?.appointment_calendar?.id,
      },
    });
    successFunc();
  } else {
    dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    if (typeof response?.status?.description === "string")
      NotificationManager.error(response?.status?.description);
  }
};

export const editReservation = (id, body, prevCal, successFunc) => async (
  dispatch
) => {
  const response = await putFunc(`orders/appointment/${id}`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: EDIT_RESERVATION,
      payload: {
        data: response.data,
        appointment_calendar_id: body.appointment_calendar?.id,
        prevCal,
      },
    });
    successFunc();
  } else {
    dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    if (typeof response?.status?.description === "string")
      NotificationManager.error(response?.status?.description);
  }
};

export const cancelReservation = (id, successFunc) => async (dispatch) => {
  const response = await putFunc(`orderItems/cancel/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: CANCEL_RESERVATION,
      payload: { ...response },
      reservationId: id,
    });
    successFunc();
    NotificationManager.success(response?.status?.description);
  } else {
    NotificationManager.error(response?.status?.description);
  }
};

export const getReservationsByCalendar = (calendar, date, auth) => async (
  dispatch
) => {
  dispatch({ type: GET_RESERVATIONS_BY_CAL_REQ });
  const response = await getFunc(
    `orderItems/getAllByAppointmentCalendar?appointment_calendar=${calendar}&date=${date}&lang=${getCookie(
      "lang",
      auth
    )}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_RESERVATIONS_BY_CAL_SCS,
      payload: { data: response.data },
    });
  } else {
    dispatch({ type: GET_RESERVATIONS_BY_CAL_FLR });
    NotificationManager.error(response?.status?.description);
  }
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/
const INIT_STATE = {
  loading: false,
  calsByOrg: [],
  workHours: {
    data: [],
    loading: false,
  },
  orgs: {
    data: [],
    loading: false,
  },
  calendars: {
    data: [],
    loading: false,
  },
  pendingAppointments: {
    data: [],
    loading: false,
  },
  workDays: {
    data: [],
    loading: false,
  },
  pause: {
    data: [],
  },
  quickGuest: {
    data: "",
  },
  intervalsByCal: {
    data: [],
    loading: false,
  },
  reservations: {
    data: [],
    loading: false,
  },
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    // WORK HOURS
    case GET_WORKING_HOURS_FOR_ORG_REQ:
      return {
        ...state,
        workHours: {
          loading: true,
        },
      };
    case GET_WORKING_HOURS_FOR_ORG_SCS:
      return {
        ...state,
        workHours: {
          loading: true,
          ...action.payload,
        },
      };
    case GET_WORKING_HOURS_FOR_ORG_FLR:
      return {
        ...state,
        workHours: {
          loading: false,
        },
      };

    // CALENDARS
    case GET_ORGS_AND_NUMBERS_REQ:
      return {
        ...state,
        orgs: {
          loading: true,
        },
      };
    case GET_ORGS_AND_NUMBERS_SCS:
      return {
        ...state,
        orgs: {
          loading: true,
          ...action.payload,
        },
      };
    case GET_ORGS_AND_NUMBERS_FLR:
      return {
        ...state,
        orgs: {
          loading: false,
        },
      };
    case GET_PENDING_APPOINTMENTS_REQ:
      return {
        ...state,
        pendingAppointments: {
          loading: true,
        },
      };
    case GET_PENDING_APPOINTMENTS_SCS:
      return {
        ...state,
        pendingAppointments: {
          loading: false,
          ...action.payload,
        },
      };
    case GET_PENDING_APPOINTMENTS_FLR:
      return {
        ...state,
        pendingAppointments: {
          loading: false,
        },
      };
    case ADD_APPOINTMENT_TO_CALENDAR:
      return {
        ...state,
        /* calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            if (item.id === action.payload.appointment_calendar_id) {
              Array.prototype.push.apply(item.order_items, action.payload.data);
            }
            return item;
          })
        }, */
        pendingAppointments: {
          ...state.pendingAppointments,
          data: state.pendingAppointments.data.filter(
            (item) => item.id !== action.payload.reservationId
          ),
        },
      };
    case GET_ALL_CALENDARS_BY_ORG_REQ:
      return {
        ...state,
        calsByOrg: [],
      };
    case GET_ALL_CALENDARS_BY_ORG_SCS:
      return {
        ...state,
        loading: false,
        calsByOrg: action.payload.data,
      };
    case GET_ALL_CALENDARS_BY_ORG_FLR:
      return {
        ...state,
        loading: false,
        calsByOrg: state.prices,
      };
    case GET_CALENDARS_REQ:
      return {
        ...state,
        calendars: {
          loading: true,
        },
      };
    case GET_CALENDARS_SCS:
      return {
        ...state,
        calendars: {
          loading: false,
          data: action.payload.data.map((item) => {
            return {
              ...item,
              users: item.users?.map((user) => {
                return {
                  ...user,
                  name: `${user.first_name} ${user.last_name}`,
                };
              }),
            };
          }),
        },
      };
    case GET_CALENDARS_FLR:
      return {
        ...state,
        calendars: {
          loading: false,
        },
      };

    case CREATE_CALENDAR:
      return {
        ...state,
        calendars: {
          loading: false,
          ...state.calendars,
          data: state.calendars.data
            .concat({
              ...action.payload.data,
              users: action.payload.data.users?.map((user) => {
                return {
                  ...user,
                  name: `${user.first_name} ${user.last_name}`,
                };
              }),
            })
            .sort((a, b) => a.priority - b.priority),
        },
      };
    case DELETE_CALENDAR:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          data: state.calendars.data
            .filter((calendar) => calendar.id !== action.payload.data.id)
            .sort((a, b) => a.priority - b.priority),
        },
      };
    case EDIT_CALENDAR:
      return {
        ...state,
        /* calendars: {
          ...state.calendars,
          data: state.calendars.data
            .map(item => {
              if (item.id === action.payload.data.id) {
                return action.payload.data;
              }
              return item;
            })
            .sort((a, b) => a.priority - b.priority)
        } */
      };

    // WORKING DAYS

    case GET_WORKING_DAYS_REQ:
      return {
        ...state,
        workDays: {
          loading: true,
        },
      };
    case GET_WORKING_DAYS_SCS:
      return {
        ...state,
        workDays: {
          loading: true,
          data: action.payload.data,
        },
      };
    case GET_WORKING_DAYS_FLR:
      return {
        ...state,
        workDays: {
          loading: false,
        },
      };

    case ADD_WORK_DAYS:
      return {
        ...state,
        workDays: {
          ...state.workDays,
          data: state.workDays.data.concat(action.payload.data),
        },
        pause: {
          ...state.pause,
          data: action.payload.data_modal?.order_items,
        },
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map((i) => {
            if (i.id === action?.payload.data_modal?.appointment_calendar?.id) {
              return { ...i, working: true };
            } else return i;
          }),
        },
      };
    case EDIT_WORK_DAYS:
      return {
        ...state,
        pause: {
          ...state.pause,
          data: action.payload.data_modal?.order_items,
        },
      };
    case DELETE_WORK_DAY:
      const deletedDays = action.payload.data.map((day) => day.id);
      const workingDays = state.workDays.data;
      return {
        ...state,
        workDays: {
          ...state.workDays,
          data: workingDays.filter((day) => !deletedDays.includes(day.id)),
        },
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map((i) => {
            if (i.id === action?.payload.data[0].appointment_calendar_id) {
              return { ...i, working: false };
            } else return i;
          }),
        },
      };

    // PAUSE

    case CREATE_PAUSE:
      return {
        ...state,
        pause: {
          ...state.pause,
          data: state.pause.data?.concat(action.payload.data),
        },
      };
    case DELETE_PAUSE:
      return {
        ...state,
        pause: {
          ...state.pause,
          data: state.pause.data?.filter(
            (item) =>
              item.start_time !== action.payload.data.start_time &&
              item.stop_time !== action.payload.data.stop_time
          ),
        },
      };

    // GUEST

    case CREATE_QUICK_GUEST:
      return {
        ...state,
        quickGuest: {
          ...state.pause,
          data: action.payload.data,
        },
      };
    case QUICK_EDIT_GUEST:
      return {
        ...state,
        quickGuest: {
          ...state.pause,
          data: action.payload.data,
        },
      };

    // INTERVALS

    case GET_INTERVALS_BY_CALENDAR_REQ:
      return {
        ...state,
        intervalsByCal: {
          loading: true,
          data: state.intervalsByCal.data,
        },
      };
    case GET_INTERVALS_BY_CALENDAR_SCS:
      return {
        ...state,
        intervalsByCal: {
          loading: false,
          data: Object.entries(action.payload),
        },
      };
    case GET_INTERVALS_BY_CALENDAR_FLR:
      return {
        ...state,
        intervalsByCal: {
          loading: false,
          data: state.intervalsByCal.data,
        },
      };
    case CLEAR_INTERVALS_BY_CALENDAR:
      return {
        ...state,
        intervalsByCal: {
          loading: false,
          data: [],
        },
      };

    // RESERVATIONS

    case CREATE_RESERVATION:
      return {
        ...state,
        /* calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            if (item.id === action.payload.appointment_calendar_id) {
              Array.prototype.push.apply(item.order_items, action.payload.data);
            }
            return item;
          })
        } */
      };

    case EDIT_RESERVATION:
      return {
        ...state,
        /*         calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            if (item.id === action.payload.appointment_calendar_id) {
              Array.prototype.push.apply(item.order_items, action.payload.data);
            }
            if (item.id === action.payload.prevCal) {
              item.order_items.filter(
                firstArrayItem =>
                  !action.payload.data.some(
                    secondArrayItem =>
                      firstArrayItem.start_date === secondArrayItem.start_date
                  )
              );
            }
            return item;
          })
        } */
      };
    case CANCEL_RESERVATION:
      return {
        ...state,
        pendingAppointments: {
          ...state.pendingAppointments,
          data: state.pendingAppointments.data.filter(
            (item) => item.id !== action.reservationId
          ),
        },
      };

    case GET_RESERVATIONS_BY_CAL_REQ:
      return {
        ...state,
        reservations: {
          loading: true,
        },
      };
    case GET_RESERVATIONS_BY_CAL_SCS:
      return {
        ...state,
        reservations: {
          loading: false,
          ...action.payload,
        },
      };
    case GET_RESERVATIONS_BY_CAL_FLR:
      return {
        ...state,
        reservations: {
          loading: false,
        },
      };
    default:
      return state;
  }
}
