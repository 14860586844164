// React
import React, { useState, useEffect, Suspense, createContext } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

// Router
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import { publicRoutes, privateRoutes } from "Router/Routes";

// Misc
import NotFoundPage from "Layouts/NotFoundPage";

// Loaders
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

// Utils
import registerSW from "Util/registerSW";
import { getCookieWithPromise, createID } from "Util/functions";

// Actions
import { getNotifications } from "Modules/units/Notification";

// Chat Socket Connection
import { RealTimeAPI } from "rocket.chat.realtime.api.rxjs";

export const ChatContext = createContext();

const realTimeAPI = new RealTimeAPI(process.env.CHAT_SOCKET);

const PublicRoutes = ({
  component: CustomComp,
  layout: Layout,
  title,
  description,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Helmet>
          <title>TripleHost Admin | {title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <CustomComp {...props} />
      </Layout>
    )}
  />
);

const PrivateRoutes = ({
  component: CustomComp,
  layout: Layout,
  id,
  user,
  title,
  description,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      user ? (
        <Layout title={title} id={id}>
          <Helmet>
            <title>TripleHost Admin | {title}</title>
            <meta name="description" content={description} />
          </Helmet>
          <CustomComp {...props} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const AppRouter = () => {
  const history = useHistory();
  const app = useSelector(state => state.app);
  const auth = useSelector(state => state.auth);
  const loggedChatUser = useSelector(
    state => state.auth.org?.th_org?.chat_user_id
  );
  const chatToken = useSelector(
    state => state.auth.org?.th_org?.chat_auth_token
  );
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    getCookieWithPromise("lang", auth).then(response => {
      i18n.changeLanguage(response);
      moment.locale(response);
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (realTimeAPI?.webSocket?.closed === true) {
      window.location.reload();
    }
    if (auth.user && realTimeAPI?.webSocket?.closed === false) {
      realTimeAPI.keepAlive().subscribe();
      realTimeAPI.sendMessage({
        msg: "connect",
        version: "1",
        support: ["1", "pre2", "pre1"]
      });
      realTimeAPI.loginWithAuthToken(chatToken);
      realTimeAPI.sendMessage({
        msg: "sub",
        id: loggedChatUser,
        name: "stream-notify-user",
        params: [`${loggedChatUser}/notification`, false]
      });
    }

    if (auth.user) registerSW();
    if (auth.user !== null && history.location.pathname === "/login") {
      history.push("/");
    }
  }, [auth.user]);

  useEffect(() => {
    if (auth.user) dispatch(getNotifications());
  }, [history.location]);

  return (
    <>
      <Helmet>
        <link
          rel="manifest"
          href={`${process.env.MAIN_API_URL}/static/Manifest/manifest-${
            window.location.hostname.split(".")[0]
          }.json`}
        />
        <link rel="icon" type="image/png" href={app.theme?.favicon} />
        <link rel="apple-touch-icon" href={app.theme?.icon} />
      </Helmet>
      <Suspense fallback={<FullScreenSpinner />}>
        <ChatContext.Provider value={realTimeAPI}>
          <Switch>
            {privateRoutes &&
              privateRoutes.map(route => {
                return (
                  <PrivateRoutes
                    id={route.id}
                    path={`/${route.path}`}
                    exact={route.exact}
                    component={route.component}
                    layout={route.layout}
                    title={route.title}
                    description={route.description}
                    user={auth.user}
                  />
                );
              })}
            {publicRoutes &&
              publicRoutes.map(route => {
                return (
                  <PublicRoutes
                    key={route.id}
                    path={`/${route.path}`}
                    exact={route.exact}
                    component={route.component}
                    layout={route.layout}
                    title={route.title}
                    description={route.description}
                  />
                );
              })}
            <Route component={NotFoundPage} />
          </Switch>
        </ChatContext.Provider>
      </Suspense>
    </>
  );
};

export default AppRouter;
