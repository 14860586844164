/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
// React
import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Image
import { SwwImage } from "Components/atoms/helpImgs/SwwImage";

// Atoms
import Button from "Components/atoms/buttons/ChipButton";

// MUI
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Sww = (props) => {
  const { resetErrorBoundary, goToHome } = props;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Box
        mt={20}
        mb={20}
        display="block"
        style={{ textAlign: "-webkit-center" }}
      >
        <SwwImage />
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          {t("Something went wrong")}
        </Typography>
        <Button
          variant="text"
          text={t("Refresh")}
          onClick={resetErrorBoundary}
          light
          customStyle={{ maxInlineSize: "fit-content", marginTop: "20px" }}
        />
        <Button
          variant="text"
          text={t("Go to homepage")}
          onClick={goToHome}
          customStyle={{ maxInlineSize: "fit-content", marginTop: "20px" }}
        />
      </Box>
    </>
  );
};

export default Sww;
