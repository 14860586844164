import React from "react";
import { useTheme } from "@material-ui/core";

export const ChatHelpImg = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  return (
    <svg
      id="e0099732-1b76-45ab-8d98-76d91fa64768"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="200px"
      viewBox="0 0 752.01596 682"
    >
      <path
        d="M797.30743,380.21969c-1.58743,1.09066-3.14446,2.19878-4.69731,3.34063A410.08183,410.08183,0,0,0,770.1,518.2929q-.00859,7.95519.34587,15.86937a398.183,398.183,0,0,0,22.39435,116.016q1.04394,2.94866,2.12606,5.87153c.51713,1.39838,1.043,2.8099,1.60693,4.19485.56416.69747,1.1199,1.38246,1.6753,2.06678.03847.02939.073.08051.1119.11053.22393.27634.44783.55282.65016.82458.96022,1.17347,1.93348,2.33905,2.89336,3.51185l-.7597.08653-1.90467.20821.00842.01246c.64075-.04237,1.29416-.09353,1.93921-.15708.23505-.0099.4913-.016.7302-.04754.21385-.01374.44005-.03673.65389-.05047,27.50812-1.99882,55.6319-4.655,80.18275-17.05816,25.69644-12.99193,46.78894-39.5139,44.35138-68.19452a51.62262,51.62262,0,0,0-2.20916-11.05868c-.47507-1.50125-1.02272-2.97253-1.60444-4.43895a54.35386,54.35386,0,0,0-93.93075-12.8133c6.17432-29.88766,37.76361-46.43234,66.416-56.92074,28.67393-10.48389,60.96776-21.5549,74.61918-48.85216,7.62406-15.19172,7.03894-32.46108.8996-48.15334-.18648-.4687-.38189-.95044-.58992-1.42365a79.31248,79.31248,0,0,0-40.17884-41.63573c-33.99251-14.9475-74.12379-7.303-107.51816,8.93685A196.59862,196.59862,0,0,0,797.30743,380.21969Z"
        transform="translate(-223.99202 -109)"
        fill="#f2f2f2"
      />
      <path
        d="M901.29053,567.1828a122.38028,122.38028,0,0,0-68.951,33.84789,125.23085,125.23085,0,0,0-33.03367,57.424c-.33009,1.24534-.62577,2.48651-.89991,3.73218-.7173-1.22683-1.41712-2.48272-2.10887-3.72681-.445-.79617-.89874-1.60542-1.33091-2.41022.51713,1.39838,1.043,2.80991,1.60692,4.19486.56417.69746,1.11991,1.38246,1.67531,2.06677.03847.0294.073.08051.11189.11053.22393.27635.44784.55282.65017.82458.96021,1.17348,1.93348,2.339,2.89335,3.51185l-.75969.08654-1.90468.2082.00843.01247c.64074-.04237,1.29416-.09354,1.93921-.15709.235-.0099.49129-.016.7302-.04754.062-.376.14525-.74821.22814-1.12108A118.24477,118.24477,0,0,1,924.89607,570.499c-.47507-1.50125-1.02273-2.97253-1.60444-4.43895A126.49582,126.49582,0,0,0,901.29053,567.1828Z"
        transform="translate(-223.99202 -109)"
        fill="#fff"
      />
      <path
        d="M809.50254,461.84648a134.64545,134.64545,0,0,0-26.28612,28.99026,111.70815,111.70815,0,0,0-9.711,18.35036c-1.264,2.97307-2.39893,6.02694-3.40555,9.10578q-.0086,7.95518.34587,15.86937a115.36652,115.36652,0,0,1,3.01163-11.94567c.62757-2.1536,1.33685-4.26889,2.09764-6.36269,8.53431-23.44965,25.06647-42.41,44.91054-57.1561,21.26539-15.77994,45.49266-28.00172,70.18173-37.39316a331.40408,331.40408,0,0,1,78.57855-19.43276,2.37667,2.37667,0,0,0,2.06954-2.55127,2.04518,2.04518,0,0,0-.58993-1.42366,1.69812,1.69812,0,0,0-1.47679-.39683c-3.31813.40049-6.627.8147-9.93165,1.31777a335.33143,335.33143,0,0,0-80.03053,22.34762C854.50646,431.49747,830.13014,444.54448,809.50254,461.84648Z"
        transform="translate(-223.99202 -109)"
        fill="#fff"
      />
      <circle cx="716.20283" cy="210.1048" r="9.24453" fill="#f2f2f2" />
      <path
        d="M759.29917,789H531.37778a51.59779,51.59779,0,0,1-51.5393-51.53955V160.53955A51.59779,51.59779,0,0,1,531.37778,109H759.29917a51.59779,51.59779,0,0,1,51.53931,51.53955v576.9209A51.59779,51.59779,0,0,1,759.29917,789Z"
        transform="translate(-223.99202 -109)"
        fill="#3f3d56"
      />
      <path
        d="M756.74863,125H731.37852a8.54842,8.54842,0,0,0-8.54,8.54,17.47659,17.47659,0,0,1-17.46,17.46H581.29844a17.47659,17.47659,0,0,1-17.46-17.46,8.54842,8.54842,0,0,0-8.54-8.54H532.92832a39.13978,39.13978,0,0,0-39.08984,39.09V736.91A39.13978,39.13978,0,0,0,532.92832,776H756.74863a39.13979,39.13979,0,0,0,39.08985-39.09V164.09A39.13979,39.13979,0,0,0,756.74863,125Z"
        transform="translate(-223.99202 -109)"
        fill="#fff"
      />
      <path
        d="M674.58848,136.5h-70a2.5,2.5,0,0,1,0-5h70a2.5,2.5,0,0,1,0,5Z"
        transform="translate(-223.99202 -109)"
        fill="#fff"
      />
      <circle cx="461.59646" cy="25" r="2" fill="#fff" />
      <path
        d="M812.83848,336.5a4.50508,4.50508,0,0,1-4.5-4.5V244a4.5,4.5,0,1,1,9,0v88A4.50508,4.50508,0,0,1,812.83848,336.5Z"
        transform="translate(-223.99202 -109)"
        fill="#3f3d56"
      />
      <path
        d="M479.83848,271.5a4.505,4.505,0,0,1-4.5-4.5V247a4.5,4.5,0,0,1,9,0v20A4.505,4.505,0,0,1,479.83848,271.5Z"
        transform="translate(-223.99202 -109)"
        fill="#3f3d56"
      />
      <path
        d="M479.83848,308.5a4.505,4.505,0,0,1-4.5-4.5V284a4.5,4.5,0,0,1,9,0v20A4.505,4.505,0,0,1,479.83848,308.5Z"
        transform="translate(-223.99202 -109)"
        fill="#3f3d56"
      />
      <path
        d="M891.992,791h-667a1,1,0,0,1,0-2h667a1,1,0,1,1,0,2Z"
        transform="translate(-223.99202 -109)"
        fill="#3f3d56"
      />
      <polygon
        points="62.649 669.899 74.909 669.899 80.741 622.611 62.647 622.611 62.649 669.899"
        fill="#ffb6b6"
      />
      <path
        d="M283.51368,774.89639l24.144-.001h.001a15.3873,15.3873,0,0,1,15.38648,15.38623v.5l-39.53076.00147Z"
        transform="translate(-223.99202 -109)"
        fill="#2f2e41"
      />
      <polygon
        points="175.649 669.899 187.909 669.899 193.741 622.611 175.647 622.611 175.649 669.899"
        fill="#ffb6b6"
      />
      <path
        d="M396.51368,774.89639l24.144-.001h.001a15.3873,15.3873,0,0,1,15.38648,15.38623v.5l-39.53076.00147Z"
        transform="translate(-223.99202 -109)"
        fill="#2f2e41"
      />
      <polygon
        points="160.846 290 168.846 341 92.846 342 102.846 293 160.846 290"
        fill="#ffb6b6"
      />
      <path
        d="M395.83848,437l19,65,3,267.10236h-20.5l-49-214.60236-45,209h-20l14-235s-7-58,17-75V438.58268Z"
        transform="translate(-223.99202 -109)"
        fill="#2f2e41"
      />
      <path
        d="M286.80424,487.79147a15.00244,15.00244,0,0,0,9.61222-20.90007L328.748,361.4827l-23.78112-4.7141L275.39652,460.09118a15.08379,15.08379,0,0,0,11.40772,27.70029Z"
        transform="translate(-223.99202 -109)"
        fill="#ffb6b6"
      />
      <circle
        cx="352.75636"
        cy="269.56452"
        r="34.3002"
        transform="translate(-311.28298 219.39004) rotate(-45)"
        fill="#ffb6b6"
      />
      <path
        d="M328.23047,426.49924a6.273,6.273,0,0,1-5.03454-5.69454c-.75419-10.32815-4.485-28.27727-11.0878-53.35114a41.56324,41.56324,0,0,1,34.31009-51.69921h0a41.54443,41.54443,0,0,1,47.21992,36.34049c3.26715,28.77134-2.89557,57.6755-5.69667,68.76569a6.29981,6.29981,0,0,1-5.97206,4.74595l-52.38852,1.01169A6.25327,6.25327,0,0,1,328.23047,426.49924Z"
        transform="translate(-223.99202 -109)"
        fill="#e4e4e4"
      />
      <path
        d="M475.81021,334.57585a14.70071,14.70071,0,0,0-.77318,2.1818l-65.57583,22.2832L396.79,347.97127l-19.20538,16.93955,20.5274,21.77648a11.17225,11.17225,0,0,0,13.21182,2.286L481.494,353.13057a14.65951,14.65951,0,1,0-5.68382-18.55472Z"
        transform="translate(-223.99202 -109)"
        fill="#ffb6b6"
      />
      <path
        d="M407.17518,360.25877,383.27,381.13814a6.28442,6.28442,0,0,1-9.42172-1.33707l-17.09878-26.626A17.453,17.453,0,0,1,383.10653,330.29l24.01108,20.45286a6.28443,6.28443,0,0,1,.05757,9.516Z"
        transform="translate(-223.99202 -109)"
        fill="#e4e4e4"
      />
      <path
        d="M324.97105,388.47169,295.4293,376.866a6.28441,6.28441,0,0,1-3.15154-8.97911l15.76176-27.43866a17.453,17.453,0,0,1,32.45117,12.85859l-7.09908,30.732a6.28443,6.28443,0,0,1-8.42056,4.43292Z"
        transform="translate(-223.99202 -109)"
        fill="#e4e4e4"
      />
      <path
        d="M368.6157,261.88126c7.4744,7.63276,17.62292,15.96748,27.70453,11.4155,6.61976-2.98892,9.9661-10.29029,8.94749-17.48176-1.83178-12.93236-12.39585-20.56-22.698-26.84382-13.3928-8.16891-28.28407-15.50748-43.94889-14.66378s-31.70521,12.66877-32.0167,28.35319c-.2505,12.61309,9.23279,25.02934,5.16961,36.97268-4.08927,12.02-19.26127,15.76619-31.95588,15.5429s-26.55126-2.23327-37.10529,4.82474c-13.46358,9.00379-14.63684,29.63481-6.41368,43.58887S259.4129,366.082,274.1503,372.801c19.52614,8.90231,41.17883,15.83885,62.22794,11.66082s40.47728-22.8056,38.86146-44.20444c-.68251-9.03884-4.85557-17.39076-8.87843-25.51376s-8.05013-16.58168-8.4034-25.63936c-.29438-7.54807,2.63944-15.66243,8.49-20.12082a6.19646,6.19646,0,0,0,2.22261-6.94336Z"
        transform="translate(-223.99202 -109)"
        fill="#2f2e41"
      />
      <path
        d="M788.143,408.88384c-10.81795,6.48778-30.09164,14.61712-49.78736,6.26275L725.62383,409.746l25.51351-28.60034A121.84934,121.84934,0,1,1,788.143,408.88384Z"
        transform="translate(-223.99202 -109)"
        fill="#fff"
      />
      <path
        d="M788.20929,410.42541c-10.39491,6.10291-30.1965,14.53716-50.38472,5.97277l-14.47873-6.14143,25.96643-29.10753a123.20949,123.20949,0,1,1,174.85482,9.40238h0a122.66017,122.66017,0,0,1-135.9578,19.87381Zm142.43522-192.084A120.48949,120.48949,0,1,0,752.1446,380.23223l.82171.90637-25.06437,28.0969,10.98471,4.65936c19.49748,8.2709,38.79128-.32072,48.55687-6.17728l.63131-.37915.66235.322A120.48926,120.48926,0,0,0,930.64451,218.34136Z"
        transform="translate(-223.99202 -109)"
        fill={color}
      />
      <path
        d="M944.26122,298.52736a112.1369,112.1369,0,0,0-102.221-111.69072c3.26626-.2861,6.57014-.44092,9.91061-.44092a112.13164,112.13164,0,0,1,0,224.26325c-3.33544,0-6.63365-.15682-9.89495-.44233A112.13692,112.13692,0,0,0,944.26122,298.52736Z"
        transform="translate(-223.99202 -109)"
        opacity="0.1"
        // style="isolation:isolate"
      />
      <rect
        x="553.10149"
        y="167.1668"
        width="80.12436"
        height="7.44069"
        fill={color}
      />
      <rect
        x="553.10149"
        y="192.90658"
        width="139.65003"
        height="7.44069"
        fill={color}
      />
      <rect
        x="553.10149"
        y="218.64632"
        width="139.5133"
        height="7.44069"
        fill={color}
      />
      <path
        d="M535.68557,598.51606a77.72125,77.72125,0,1,1,109.78383-5.35684l16.27372,18.24265-8.12091,3.44472c-12.56286,5.32881-24.85653.14354-31.75673-3.99467A77.7212,77.7212,0,0,1,535.68557,598.51606Z"
        transform="translate(-223.99202 -109)"
        fill="#fff"
      />
      <path
        d="M587.79845,619.602a78.29487,78.29487,0,0,1-52.69558-20.44327h0a78.58873,78.58873,0,1,1,111.53065-5.99728l16.56261,18.56615-9.23521,3.91729c-12.877,5.46278-25.50739.083-32.13775-3.80971A78.69463,78.69463,0,0,1,587.79845,619.602Zm-51.53022-21.72828a76.50268,76.50268,0,0,0,85.21822,12.19786l.42248-.20542.40268.24184c6.229,3.73559,18.53545,9.21573,30.97186,3.94016l7.00657-2.972L644.3028,593.15469l.52412-.57812a76.85388,76.85388,0,1,0-108.55869,5.29717Z"
        transform="translate(-223.99202 -109)"
        fill={color}
      />
      <path
        d="M522.28588,540.46135a71.5262,71.5262,0,0,1,65.20137-71.2416c-2.08337-.1825-4.19074-.28125-6.32145-.28125a71.52285,71.52285,0,0,0,0,143.04568c2.1275,0,4.23125-.1,6.31147-.28214A71.52623,71.52623,0,0,1,522.28588,540.46135Z"
        transform="translate(-223.99202 -109)"
        opacity="0.1"
        // style="isolation:isolate"
      />
      <rect
        x="353.81421"
        y="417.19873"
        width="51.1071"
        height="4.74602"
        fill={color}
      />
      <rect
        x="315.84594"
        y="433.61678"
        width="89.07537"
        height="4.74602"
        fill={color}
      />
      <rect
        x="315.93315"
        y="450.0348"
        width="88.98816"
        height="4.74602"
        fill={color}
      />
      <circle cx="463.35434" cy="509.13402" r="11" fill={color} />
      <circle cx="479.35434" cy="304.13402" r="13" fill={color} />
    </svg>
  );
};
