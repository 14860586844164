import React from "react";
import { useTheme } from "@material-ui/core";

export const AppointmentsHelpImg = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  return (
    <svg
      width="100%"
      height="200px"
      viewBox="0 0 333 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M319.983 147.229C317.579 149.849 314.597 151.8 311.361 153.315H311.357C311.053 153.459 310.748 153.599 310.44 153.731H310.436L310.432 153.735L310.428 153.731H310.424C310.424 153.732 310.424 153.732 310.424 153.733C310.424 153.733 310.423 153.734 310.423 153.734C310.423 153.734 310.422 153.735 310.422 153.735C310.421 153.735 310.421 153.735 310.42 153.735H310.416L310.412 153.731C310.409 153.735 310.409 153.735 310.405 153.731L310.401 153.735L310.397 153.731H310.393C310.389 153.735 310.389 153.735 310.385 153.731H310.381C308.215 154.666 305.991 155.459 303.722 156.107C290.38 159.882 276.358 160.613 262.694 158.244C262.433 158.197 262.175 158.151 261.914 158.104V126.521C262.171 126.412 262.433 126.303 262.694 126.198C264.4 125.493 266.127 124.846 267.874 124.255C270.388 123.399 272.937 122.664 275.521 122.051C283.951 120.016 292.649 119.314 301.298 119.972C302.871 120.089 304.44 120.258 306.006 120.478C309.281 120.934 312.715 121.728 315.737 123.107H315.74C316.182 123.313 316.615 123.527 317.044 123.757C319.73 125.209 321.994 127.207 323.375 129.944C323.872 130.927 324.229 131.974 324.437 133.055V133.063C324.515 133.437 324.57 133.814 324.613 134.188C325.105 138.799 323.188 143.732 319.983 147.229Z"
          fill="#F2F2F2"
        />
        <path
          d="M324.613 134.188C324.574 134.198 324.535 134.203 324.496 134.204C317.809 134.496 311.132 134.929 304.464 135.504H304.452C304.425 135.508 304.401 135.508 304.374 135.512C297.528 136.104 290.695 136.845 283.878 137.735C283.589 137.774 283.304 137.813 283.019 137.848C278.304 138.475 273.598 139.171 268.9 139.935C266.828 140.274 264.759 140.627 262.694 140.995C262.433 141.041 262.175 141.084 261.914 141.135V139.955C262.175 139.904 262.437 139.861 262.694 139.815C269.572 138.6 276.473 137.539 283.397 136.633C285.138 136.4 286.882 136.179 288.628 135.972C293.671 135.364 298.722 134.84 303.781 134.398C304.151 134.367 304.518 134.336 304.889 134.305C311.365 133.756 317.85 133.339 324.343 133.055C324.375 133.054 324.406 133.056 324.437 133.063C325.062 133.117 325.194 134.052 324.613 134.188Z"
          fill="white"
        />
        <path
          d="M310.727 152.584C302.398 152.645 294.191 149.121 288.299 143.289C286.637 141.638 285.172 139.799 283.934 137.811C283.537 137.176 282.608 137.876 283.001 138.506C287.577 145.827 295.199 151.041 303.603 152.959C305.989 153.498 308.43 153.759 310.877 153.735C311.624 153.73 311.469 152.578 310.727 152.584V152.584Z"
          fill="white"
        />
        <path
          d="M275.521 122.051C275.485 122.161 275.421 122.259 275.335 122.336C275.25 122.413 275.145 122.467 275.033 122.491C273.124 122.99 271.279 123.709 269.537 124.633C266.992 125.974 264.682 127.716 262.694 129.792C262.589 129.897 262.487 130.002 262.39 130.111C262.226 130.283 262.07 130.458 261.914 130.637V128.939C262.163 128.675 262.425 128.414 262.694 128.165C264.263 126.666 266.002 125.354 267.874 124.255C270.388 123.399 272.937 122.664 275.521 122.051Z"
          fill="white"
        />
        <path
          d="M316.427 122.799C311.128 125.071 306.695 128.971 303.771 133.93C303.712 134.072 303.712 134.231 303.769 134.374C303.827 134.516 303.939 134.63 304.081 134.691C304.223 134.747 304.382 134.746 304.524 134.689C304.666 134.631 304.78 134.522 304.844 134.382C307.634 129.698 311.843 126.019 316.864 123.875C317.553 123.582 317.117 122.505 316.427 122.799L316.427 122.799Z"
          fill="white"
        />
        <path
          d="M332.4 89.7287C331.494 93.163 329.757 96.2742 327.599 99.1128L327.595 99.1167C327.396 99.3814 327.189 99.6501 326.974 99.9071V99.911C325.476 101.753 323.858 103.494 322.13 105.125C315.921 111.027 308.908 116.026 301.298 119.972C289.327 126.21 275.946 129.897 262.694 130.107C262.593 130.111 262.491 130.111 262.39 130.111C262.23 130.115 262.07 130.115 261.914 130.115V112.418C262.167 111.935 262.429 111.452 262.694 110.973C266.138 104.792 270.338 99.0604 275.197 93.9106C275.314 93.7821 275.435 93.6537 275.556 93.529C277.382 91.6055 279.298 89.7741 281.301 88.0349C289.01 81.2738 297.918 76.011 307.567 72.5181C310.678 71.4044 314.089 70.5128 317.407 70.3375C317.895 70.3103 318.379 70.2986 318.867 70.3025C321.923 70.3453 324.855 71.0657 327.357 72.849C328.253 73.491 329.057 74.2521 329.746 75.1113C329.988 75.4112 330.21 75.7188 330.425 76.0342V76.0381C333.009 79.8929 333.61 85.1457 332.4 89.7287Z"
          fill="#F2F2F2"
        />
        <path
          d="M330.425 76.0344V76.0383C330.396 76.0635 330.365 76.0857 330.331 76.1045C324.554 79.4571 318.85 82.9317 313.219 86.5283C313.211 86.5321 313.207 86.536 313.199 86.5399C313.177 86.5556 313.153 86.5699 313.129 86.5828C307.34 90.2819 301.634 94.1056 296.009 98.0539C295.775 98.2175 295.537 98.3849 295.299 98.5523C291.415 101.29 287.571 104.085 283.768 106.94C276.586 112.336 269.561 117.933 262.694 123.73C262.433 123.948 262.175 124.17 261.914 124.392V122.877C262.171 122.655 262.433 122.433 262.694 122.215C265.325 119.999 267.979 117.815 270.657 115.662C271.457 115.019 272.261 114.377 273.065 113.742C280.209 108.069 287.512 102.607 294.975 97.3569H294.979C296.446 96.3251 297.917 95.3023 299.389 94.2886C303.574 91.415 307.805 88.6101 312.083 85.8741C312.391 85.6755 312.704 85.4769 313.016 85.2783C318.492 81.7895 324.04 78.4149 329.66 75.1544C329.687 75.1378 329.716 75.1235 329.746 75.1116C330.331 74.878 330.881 75.649 330.425 76.0344Z"
          fill="white"
        />
        <path
          d="M326.696 98.7598C319.348 102.674 310.441 103.356 302.507 100.922C300.266 100.229 298.113 99.2801 296.09 98.0932C295.443 97.7146 294.946 98.7656 295.588 99.1409C303.049 103.503 312.227 104.589 320.562 102.393C322.926 101.765 325.209 100.865 327.365 99.7102C328.024 99.3593 327.351 98.4112 326.696 98.7597V98.7598Z"
          fill="white"
        />
        <path
          d="M271.719 113.007C271.355 110.969 271.23 108.895 271.347 106.828C271.61 102.113 273.057 97.5407 275.555 93.5302C277.384 91.6073 279.3 89.7756 281.302 88.0352C281.321 88.149 281.31 88.2658 281.27 88.374C281.23 88.4822 281.162 88.5781 281.073 88.6523C279.616 89.9789 278.318 91.4695 277.204 93.0941C273.227 98.8843 271.675 105.995 272.879 112.911C272.898 112.989 272.897 113.071 272.876 113.149C272.854 113.227 272.813 113.298 272.756 113.356C272.673 113.428 272.572 113.478 272.463 113.499C272.355 113.521 272.242 113.513 272.137 113.478C272.032 113.443 271.939 113.381 271.865 113.298C271.792 113.215 271.741 113.115 271.719 113.007Z"
          fill="white"
        />
        <path
          d="M317.875 69.7429C314.24 74.2101 312.13 79.7177 311.851 85.4641C311.864 85.6172 311.938 85.7588 312.055 85.858C312.173 85.9573 312.325 86.0064 312.479 85.9945C312.631 85.9782 312.771 85.904 312.87 85.7873C312.969 85.6706 313.02 85.5204 313.011 85.3678C313.3 79.9268 315.314 74.7182 318.763 70.493C319.236 69.9136 318.349 69.1624 317.875 69.7429Z"
          fill="white"
        />
        <path
          d="M277.453 82.5373V82.5412C277.425 82.8761 277.394 83.207 277.359 83.538V83.5458C277.088 85.9017 276.664 88.2377 276.09 90.5391C275.817 91.6683 275.521 92.7897 275.197 93.9111C275.158 94.0552 275.115 94.1992 275.072 94.3394V94.3433C274.118 97.6048 272.979 100.809 271.66 103.942C269.233 109.708 266.229 115.216 262.694 120.381C262.441 120.759 262.179 121.137 261.914 121.51V60.5334C262.175 60.5178 262.433 60.5178 262.694 60.5256C262.819 60.5256 262.944 60.5295 263.069 60.5373C264.17 60.595 265.258 60.8061 266.301 61.1642C266.668 61.2849 267.023 61.4212 267.374 61.5731C271.633 63.4343 274.978 67.5384 276.43 72.0513C277.519 75.4311 277.738 78.9862 277.453 82.5373Z"
          fill="#F2F2F2"
        />
        <path
          d="M267.374 61.5726C267.365 61.6108 267.351 61.6475 267.331 61.6817C265.751 64.7461 264.205 67.8261 262.694 70.9217C262.433 71.4551 262.171 71.9925 261.914 72.5259V69.8781C262.171 69.3486 262.431 68.819 262.694 68.2895C263.861 65.9298 265.048 63.5806 266.254 61.2417C266.267 61.2144 266.283 61.1883 266.301 61.1638C266.66 60.6498 267.55 61.0003 267.374 61.5726Z"
          fill="white"
        />
        <path
          d="M277.578 83.1636C277.507 83.2882 277.437 83.4128 277.359 83.5374C276.213 85.53 274.849 87.3896 273.292 89.0822C270.301 92.3187 266.7 94.9347 262.694 96.7802C262.437 96.9048 262.175 97.0217 261.914 97.1307V95.8652C262.175 95.7484 262.437 95.6277 262.694 95.5031C268.493 92.7441 273.309 88.2857 276.5 82.7236C276.546 82.6442 276.609 82.5756 276.684 82.5224C276.759 82.4692 276.844 82.4327 276.935 82.4154C277.025 82.398 277.118 82.4002 277.207 82.4218C277.297 82.4434 277.38 82.4839 277.453 82.5406C277.502 82.5733 277.544 82.6154 277.577 82.6644C277.61 82.7134 277.633 82.7684 277.645 82.8263C277.656 82.8842 277.656 82.9437 277.645 83.0016C277.633 83.0595 277.61 83.1145 277.578 83.1636Z"
          fill="white"
        />
        <path
          d="M262.694 12.8496V42.4425H261.914V42.1271H0.780666V42.4425H0V12.8496C0.00335233 9.44267 1.36153 6.17628 3.77646 3.76724C6.19138 1.35821 9.46577 0.00334415 12.881 0H249.813C253.228 0.00334415 256.503 1.35821 258.918 3.76724C261.333 6.17628 262.691 9.44267 262.694 12.8496Z"
          fill={color}
        />
        <path
          d="M249.813 0H12.881C9.46577 0.00334415 6.19138 1.35821 3.77646 3.76724C1.36153 6.17628 0.00335233 9.44267 0 12.8496V181.841C0.00335233 185.248 1.36153 188.514 3.77646 190.923C6.19138 193.332 9.46577 194.687 12.881 194.69H249.813C253.228 194.687 256.503 193.332 258.918 190.923C261.333 188.514 262.691 185.248 262.694 181.841V12.8496C262.691 9.44267 261.333 6.17628 258.918 3.76724C256.503 1.35821 253.228 0.00334415 249.813 0ZM261.914 181.841C261.909 185.041 260.633 188.109 258.365 190.372C256.097 192.634 253.021 193.907 249.813 193.912H12.881C9.67304 193.907 6.59765 192.634 4.32929 190.372C2.06092 188.109 0.784759 185.041 0.780666 181.841V12.8496C0.784759 9.64944 2.06092 6.58156 4.32929 4.31873C6.59765 2.0559 9.67304 0.782844 12.881 0.778761H249.813C253.021 0.782845 256.097 2.0559 258.365 4.31873C260.633 6.58156 261.909 9.64944 261.914 12.8496V181.841Z"
          fill="#3F3D56"
        />
        <path
          d="M53.0855 29.0088C57.397 29.0088 60.8921 25.5222 60.8921 21.2212C60.8921 16.9202 57.397 13.4336 53.0855 13.4336C48.774 13.4336 45.2788 16.9202 45.2788 21.2212C45.2788 25.5222 48.774 29.0088 53.0855 29.0088Z"
          fill="white"
        />
        <path
          d="M209.609 29.0088C213.92 29.0088 217.416 25.5222 217.416 21.2212C217.416 16.9202 213.92 13.4336 209.609 13.4336C205.297 13.4336 201.802 16.9202 201.802 21.2212C201.802 25.5222 205.297 29.0088 209.609 29.0088Z"
          fill="white"
        />
        <path
          d="M75.6405 108.054H29.2001C27.2327 108.052 25.3464 107.271 23.9553 105.883C22.5641 104.495 21.7815 102.614 21.7793 100.651V78.7552C21.7815 76.7926 22.5641 74.911 23.9553 73.5232C25.3464 72.1354 27.2327 71.3548 29.2001 71.3525H75.6405C77.6079 71.3548 79.4941 72.1354 80.8853 73.5232C82.2765 74.911 83.059 76.7926 83.0613 78.7552V100.651C83.059 102.614 82.2765 104.495 80.8853 105.883C79.4941 107.271 77.6079 108.052 75.6405 108.054Z"
          fill={color}
        />
        <path
          d="M154.643 108.054H108.203C106.236 108.052 104.349 107.271 102.958 105.883C101.567 104.495 100.784 102.614 100.782 100.651V78.7552C100.784 76.7926 101.567 74.911 102.958 73.5232C104.349 72.1354 106.236 71.3548 108.203 71.3525H154.643C156.611 71.3548 158.497 72.1354 159.888 73.5232C161.279 74.911 162.062 76.7926 162.064 78.7552V100.651C162.062 102.614 161.279 104.495 159.888 105.883C158.497 107.271 156.611 108.052 154.643 108.054Z"
          fill={color}
        />
        <path
          d="M154.643 164.628H108.203C106.176 164.625 104.232 163.821 102.799 162.391C101.366 160.961 100.559 159.023 100.557 157.001V135.105C100.559 133.082 101.366 131.144 102.799 129.714C104.232 128.284 106.176 127.48 108.203 127.478H154.643C156.67 127.48 158.614 128.284 160.047 129.714C161.48 131.144 162.287 133.082 162.289 135.105V157.001C162.287 159.023 161.48 160.961 160.047 162.391C158.614 163.821 156.67 164.625 154.643 164.628Z"
          fill="#F2F2F2"
        />
        <path
          d="M75.4884 164.628H29.048C27.0209 164.625 25.0776 163.821 23.6442 162.391C22.2109 160.961 21.4046 159.023 21.4023 157.001V135.105C21.4046 133.082 22.2109 131.144 23.6442 129.714C25.0776 128.284 27.0209 127.48 29.048 127.478H75.4884C77.5154 127.48 79.4588 128.284 80.8921 129.714C82.3255 131.144 83.1317 133.082 83.134 135.105V157.001C83.1318 159.023 82.3255 160.961 80.8921 162.391C79.4588 163.821 77.5154 164.625 75.4884 164.628Z"
          fill="#F2F2F2"
        />
        <path
          d="M233.646 164.628H187.206C185.179 164.625 183.235 163.821 181.802 162.391C180.369 160.961 179.562 159.023 179.56 157.001V135.105C179.562 133.082 180.369 131.144 181.802 129.714C183.235 128.284 185.179 127.48 187.206 127.478H233.646C235.673 127.48 237.617 128.284 239.05 129.714C240.483 131.144 241.289 133.082 241.292 135.105V157.001C241.289 159.023 240.483 160.961 239.05 162.391C237.617 163.821 235.673 164.625 233.646 164.628Z"
          fill="#F2F2F2"
        />
        <path
          d="M233.646 108.098H187.206C185.179 108.096 183.235 107.291 181.802 105.861C180.369 104.432 179.562 102.493 179.56 100.471V78.5748C179.562 76.5527 180.369 74.614 181.802 73.1842C183.235 71.7543 185.179 70.95 187.206 70.9478H233.646C235.673 70.95 237.617 71.7543 239.05 73.1842C240.483 74.614 241.289 76.5527 241.292 78.5748V100.471C241.289 102.493 240.483 104.432 239.05 105.861C237.617 107.291 235.673 108.096 233.646 108.098Z"
          fill="#F2F2F2"
        />
        <path
          d="M52.4205 102.265C59.3754 102.265 65.0134 96.6411 65.0134 89.7032C65.0134 82.7654 59.3754 77.1411 52.4205 77.1411C45.4657 77.1411 39.8276 82.7654 39.8276 89.7032C39.8276 96.6411 45.4657 102.265 52.4205 102.265Z"
          fill="white"
        />
        <path
          d="M51.1724 94.4953C50.8891 94.4959 50.6133 94.4045 50.3866 94.2349L50.3726 94.2244L47.4131 91.9641C47.2759 91.8594 47.1607 91.7288 47.0741 91.5797C46.9875 91.4305 46.9312 91.2658 46.9084 91.095C46.8857 90.9241 46.8969 90.7505 46.9414 90.584C46.986 90.4174 47.063 90.2613 47.1681 90.1245C47.2732 89.9877 47.4043 89.8729 47.5538 89.7867C47.7034 89.7004 47.8686 89.6444 48.0399 89.6219C48.2112 89.5994 48.3852 89.6107 48.5521 89.6553C48.719 89.7 48.8754 89.7769 49.0125 89.8819L50.9294 91.3481L55.4592 85.4549C55.5643 85.3182 55.6953 85.2035 55.8448 85.1174C55.9943 85.0312 56.1594 84.9752 56.3305 84.9527C56.5017 84.9301 56.6757 84.9415 56.8425 84.986C57.0093 85.0305 57.1656 85.1074 57.3026 85.2122L57.2745 85.2503L57.3034 85.2127C57.5797 85.4246 57.7606 85.7373 57.8062 86.082C57.8518 86.4267 57.7585 86.7755 57.5467 87.0517L52.2185 93.9835C52.0952 94.1432 51.9368 94.2725 51.7554 94.3613C51.574 94.45 51.3745 94.4959 51.1724 94.4953Z"
          fill={color}
        />
        <path
          d="M131.648 102.085C138.603 102.085 144.241 96.4604 144.241 89.5226C144.241 82.5847 138.603 76.9604 131.648 76.9604C124.693 76.9604 119.055 82.5847 119.055 89.5226C119.055 96.4604 124.693 102.085 131.648 102.085Z"
          fill="white"
        />
        <path
          d="M130.4 94.3152C130.117 94.3157 129.841 94.2243 129.614 94.0548L129.6 94.0443L126.641 91.784C126.364 91.5724 126.183 91.2598 126.137 90.915C126.091 90.5701 126.185 90.2213 126.397 89.9451C126.609 89.669 126.922 89.4882 127.268 89.4426C127.613 89.3969 127.963 89.4902 128.24 89.7017L130.157 91.168L134.687 85.2747C134.792 85.1381 134.923 85.0234 135.072 84.9372C135.222 84.851 135.387 84.7951 135.558 84.7725C135.729 84.75 135.903 84.7613 136.07 84.8058C136.237 84.8503 136.393 84.9272 136.53 85.032L136.502 85.0702L136.531 85.0326C136.807 85.2445 136.988 85.5571 137.034 85.9018C137.079 86.2466 136.986 86.5953 136.774 86.8715L131.446 93.8033C131.323 93.9631 131.164 94.0923 130.983 94.1811C130.801 94.2698 130.602 94.3157 130.4 94.3152Z"
          fill={color}
        />
        <path
          d="M216.757 148.802C217.17 148.351 217.48 147.814 217.663 147.231C217.847 146.647 217.9 146.031 217.818 145.425C217.737 144.819 217.524 144.238 217.193 143.723C216.862 143.208 216.423 142.771 215.905 142.444L208.874 104.997L200.324 108.159L209.923 143.975C209.43 144.879 209.287 145.933 209.521 146.936C209.754 147.939 210.348 148.821 211.191 149.416C212.033 150.011 213.065 150.277 214.091 150.164C215.116 150.051 216.065 149.566 216.757 148.802Z"
          fill="#A0616A"
        />
        <path
          d="M148.532 91.6606C148.554 91.8931 148.556 92.1269 148.541 92.3599L165.165 105.124L169.879 103.192L173.731 109.909L165.718 114.137C165.071 114.478 164.33 114.601 163.607 114.487C162.883 114.373 162.217 114.027 161.707 113.503L145.09 96.4135C144.21 96.6114 143.291 96.5374 142.455 96.2015C141.618 95.8656 140.904 95.2835 140.407 94.5329C139.911 93.7822 139.655 92.8985 139.674 91.9992C139.693 91.0999 139.986 90.2277 140.514 89.4986C141.042 88.7694 141.78 88.218 142.63 87.9175C143.48 87.6171 144.401 87.5818 145.272 87.8165C146.142 88.0512 146.92 88.5447 147.503 89.2314C148.085 89.9181 148.444 90.7654 148.532 91.6606H148.532Z"
          fill="#A0616A"
        />
        <path
          d="M187.371 215.269H182.586L180.309 200.75L187.372 200.75L187.371 215.269Z"
          fill="#A0616A"
        />
        <path
          d="M188.591 219.896L173.161 219.896V219.701C173.161 218.914 173.316 218.135 173.618 217.408C173.92 216.681 174.362 216.021 174.92 215.465C175.478 214.908 176.14 214.467 176.869 214.166C177.597 213.865 178.378 213.71 179.167 213.71H179.167L188.591 213.71L188.591 219.896Z"
          fill="#2F2E41"
        />
        <path
          d="M205.326 215.269H200.541L198.264 200.75L205.327 200.75L205.326 215.269Z"
          fill="#A0616A"
        />
        <path
          d="M206.547 219.896L191.117 219.896V219.701C191.117 218.914 191.272 218.135 191.574 217.408C191.876 216.681 192.318 216.021 192.876 215.465C193.433 214.908 194.096 214.467 194.824 214.166C195.553 213.865 196.334 213.71 197.123 213.71H197.123L206.547 213.71L206.547 219.896Z"
          fill="#2F2E41"
        />
        <path
          d="M181.592 212.399C181.155 212.4 180.734 212.237 180.411 211.944C180.088 211.651 179.886 211.247 179.845 210.813L175.675 154.864L175.872 154.848L204.57 152.495L204.578 152.698L206.859 210.658C206.868 210.893 206.829 211.128 206.745 211.348C206.661 211.568 206.533 211.769 206.369 211.939C206.205 212.108 206.008 212.243 205.791 212.336C205.574 212.428 205.34 212.475 205.104 212.475H199.477C199.057 212.477 198.65 212.328 198.331 212.055C198.012 211.782 197.803 211.404 197.741 210.99L190.1 164.168C190.092 164.12 190.067 164.076 190.028 164.046C189.99 164.015 189.941 164 189.893 164.004C189.844 164.007 189.798 164.029 189.764 164.065C189.731 164.101 189.712 164.148 189.712 164.197L189.365 210.425C189.361 210.872 189.187 211.3 188.878 211.623C188.569 211.946 188.148 212.139 187.701 212.163L181.686 212.396C181.655 212.398 181.623 212.399 181.592 212.399Z"
          fill="#2F2E41"
        />
        <path
          d="M186.49 84.155C191.785 84.155 196.077 79.8732 196.077 74.5914C196.077 69.3096 191.785 65.0278 186.49 65.0278C181.196 65.0278 176.903 69.3096 176.903 74.5914C176.903 79.8732 181.196 84.155 186.49 84.155Z"
          fill="#A0616A"
        />
        <path
          d="M188.346 158.432C184.156 158.419 179.986 157.851 175.946 156.741C175.557 156.628 175.219 156.385 174.989 156.053C174.759 155.72 174.651 155.319 174.683 154.916C175.974 135.567 176.277 120.551 173.563 110.161C172.406 105.733 172.932 101.07 175.004 97.3662C178.123 91.793 183.855 88.401 189.969 88.5181C190.262 88.5236 190.557 88.534 190.855 88.5493C200.134 89.0259 207.365 97.4001 206.973 107.217L205.107 154.008C205.1 154.35 204.992 154.681 204.796 154.961C204.601 155.242 204.326 155.458 204.008 155.583C199.001 157.462 193.696 158.427 188.346 158.432Z"
          fill="#3F3D56"
        />
        <path
          d="M175.141 113.754L167.96 104.976C167.755 104.726 167.607 104.435 167.526 104.123C167.444 103.811 167.431 103.485 167.487 103.168C167.543 102.85 167.667 102.549 167.851 102.283C168.034 102.018 168.273 101.795 168.551 101.63L178.278 95.8479C179.565 94.812 181.212 94.3276 182.856 94.5008C184.5 94.6739 186.009 95.4906 187.051 96.7717C188.092 98.0528 188.582 99.6937 188.413 101.335C188.244 102.975 187.429 104.482 186.148 105.525L178.545 113.847C178.327 114.085 178.061 114.274 177.763 114.4C177.466 114.526 177.145 114.587 176.822 114.578C176.498 114.569 176.181 114.491 175.891 114.349C175.601 114.207 175.345 114.004 175.141 113.754Z"
          fill="#3F3D56"
        />
        <path
          d="M197.731 112.652C197.436 112.521 197.173 112.327 196.96 112.085C196.747 111.842 196.588 111.557 196.496 111.248L193.263 100.46C192.561 98.9664 192.482 97.2559 193.043 95.7039C193.604 94.1519 194.758 92.8851 196.254 92.1813C197.75 91.4775 199.464 91.3943 201.022 91.9498C202.579 92.5053 203.851 93.6542 204.561 95.1445L210.812 104.563C210.991 104.832 211.109 105.136 211.159 105.454C211.209 105.773 211.19 106.098 211.102 106.409C211.015 106.719 210.861 107.007 210.652 107.253C210.443 107.499 210.183 107.697 209.891 107.833L199.604 112.637C199.311 112.774 198.992 112.846 198.669 112.848C198.346 112.851 198.026 112.784 197.731 112.652Z"
          fill="#3F3D56"
        />
        <path
          d="M191.228 82.4387L184.121 80.8162C183.388 80.6488 182.508 80.3295 182.406 79.5862C182.269 78.5874 183.71 77.8916 183.577 76.8923C183.449 75.9244 182.136 75.7996 181.2 75.5139C180.329 75.2089 179.61 74.5775 179.197 73.7533C178.784 72.9292 178.708 71.977 178.986 71.0982C177.973 72.5218 175.66 72.6437 174.346 71.4904C173.033 70.337 172.781 68.2444 173.57 66.6863C173.996 65.9209 174.597 65.2667 175.324 64.7768C176.052 64.2869 176.885 63.9752 177.756 63.867C179.507 63.6689 181.277 63.9981 182.839 64.8123C182.943 63.7091 184.324 63.2618 185.435 63.252C187.742 63.2788 189.984 64.0225 191.848 65.3797C193.712 66.7368 195.105 68.6399 195.834 70.824C197.198 75.2053 196.286 80.008 192.472 82.5716L191.228 82.4387Z"
          fill="#2F2E41"
        />
        <path
          d="M262.807 220H114.09C113.986 220 113.887 219.959 113.814 219.886C113.74 219.813 113.699 219.714 113.699 219.611C113.699 219.507 113.74 219.408 113.814 219.335C113.887 219.262 113.986 219.221 114.09 219.221H262.807C262.91 219.221 263.009 219.262 263.083 219.335C263.156 219.408 263.197 219.507 263.197 219.611C263.197 219.714 263.156 219.813 263.083 219.886C263.009 219.959 262.91 220 262.807 220Z"
          fill="#3F3D56"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="333" height="220" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
