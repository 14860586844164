import axios from "axios";
import { checkToken } from "Util/authUtil";
import { NotificationManager } from "react-notifications";

// const org = useSelector(state => state.auth);

const API = process.env.CHAT_API_URL;

export const getChatFunc = async url => {
  return axios
    .get(`${API}/${url}`, {
      headers: {
        "X-Auth-Token": JSON.parse(localStorage.getItem("currentOrg"))?.th_org
          ?.chat_auth_token,
        "X-User-Id": JSON.parse(localStorage.getItem("currentOrg"))?.th_org
          ?.chat_user_id,
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      checkToken();
      return response.data;
    })
    .catch(error => {
      checkToken();
      return NotificationManager.error(error.message);
    });
};

export const postChatFunc = async (url, body) => {
  return axios
    .post(`${API}/${url}`, body, {
      headers: {
        "X-Auth-Token": JSON.parse(localStorage.getItem("currentOrg"))?.th_org
          ?.chat_auth_token,
        "X-User-Id": JSON.parse(localStorage.getItem("currentOrg"))?.th_org
          ?.chat_user_id,
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      checkToken();
      return response.data;
    })
    .catch(error => {
      checkToken();
      return NotificationManager.error(error.message);
    });
};

export const deleteChatFunc = async url => {
  return axios
    .delete(`${API}/${url}`, {
      headers: {
        "X-Auth-Token": JSON.parse(localStorage.getItem("currentOrg"))?.th_org
          ?.chat_auth_token,
        "X-User-Id": JSON.parse(localStorage.getItem("currentOrg"))?.th_org
          ?.chat_user_id,
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      checkToken();
      return response.data;
    })
    .catch(error => {
      checkToken();
      return NotificationManager.error(error.message);
    });
};

export const putChatFunc = async (url, body) => {
  return axios
    .put(`${API}/${url}`, body, {
      headers: {
        "X-Auth-Token": JSON.parse(localStorage.getItem("currentOrg"))?.th_org
          ?.chat_auth_token,
        "X-User-Id": JSON.parse(localStorage.getItem("currentOrg"))?.th_org
          ?.chat_user_id,
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      checkToken();
      return response.data;
    })
    .catch(error => {
      checkToken();
      return NotificationManager.error(error.message);
    });
};
