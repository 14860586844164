module.exports = {
  "Welcome to React": "Welcome to React",
  Username: "Nutzername",
  Password: "Passwort",
  Submit: "Einreichen",
  "Send e-mail": "E-Mail senden",
  ForgotPasswordHeader: "Passwort vergessen?",
  ForgotPasswordSubtitle:
    "Keine Sorge! Das Zurücksetzen Ihres Passworts ist einfach. Geben Sie einfach die E-Mail-Adresse ein, die Sie bei TripleHost registriert haben",
  RememberedPassword: "Hast du dich an dein Passwort erinnert?",
  "Try logging in": "Versuchen Sie sich anzumelden",
  "Log out": "Ausloggen",
  Verification: "Überprüfung",
  Name: "Name",
  Actions: "Aktionen",
  Tenants: "Pächter",
  Search: "Suche",
  rows: "reihen",
  "No records to display": "Keine Datensätze zum Anzeigen",
  Domain: "Domain",
  Verify: "Überprüfen",
  confirmEmailText:
    "Wir haben Ihnen eine E-Mail mit dem neuen Passwort für Ihr Konto gesendet.",
  "Back to login": "Zurück zur Anmeldung",
  "Field may not be null.": "Feld kann nicht null sein.",
  "Not a valid number.": "Keine gültige Nummer.",
  "delete prices for selected dates": "Lösch Preise für ausgewählte Termine",
  "Double room": "Zimmer mit zwei Betten",
  "portal profile": "portalprofil",
  "accomodation informations": "unterkunft Informationen",
  pricing: "preisliste",
  apply: "anwenden",
  "You cannot set prices on the past dates.":
    "Sie können keine Preise für vergangene Daten festlegen.",
  Price: "Preis",
  "Price can not be negative": "Der Preis kann nicht negativ sein",
  "Item Description": "Type Item's Description below:",
  Bedienung: "Bedienung",
  Titel: "Titel",
  Beschreibung: "Beschreibung",
  Einzelheiten: "Einzelheiten",
  EINZELHEITEN: "EINZELHEITEN",
  Bild: "Bild",
  Symbol: "Symbol",
  Publikum: "Publikum",
  Lauf: "Lauf",
  Zurück: "Zurück",
  "Im Folgenden": "Im Folgenden",
  Fertig: "Fertig",
  Nachname: "Nachname",
  Tag: "Tag",
  Kampagnen: "KAMPAGNEN",
  Abonnementstatus: "Abonnementstatus",
  JA: "JA",
  NEIN: "NEIN",
  Blieb: "Blieb",
  "Zur Zeit": "Zur Zeit",
  "Ich war vor x Tagen": "Ich war vor x Tagen",
  Aufträge: "Aufträge",
  "Etwas gekauft": "Etwas gekauft",
  "Er hat nie etwas gekauft": "Er hat nie etwas gekauft",
  "Hatte mehr als x Bestellungen": "Hatte mehr als x Bestellungen",
  "Zulässige Benachrichtigungen": "Zulässige Benachrichtigungen",
  "Die Kampagne wurde nie gesendet": "Die Kampagne wurde nie gesendet",
  "X Kampagne gesendet": "X Kampagne gesendet",
  BELASTUNG: "BELASTUNG",
  "Reinigen Sie den Filter": "Reinigen Sie den Filter",
  Suche: "Suche",
  Kampagnenname: "Kampagnenname",
  Kampagnenübersicht: "Kampagnenübersicht",
  "Die Kampagne wird enthalten": "Die Kampagne wird enthalten",
  "Gast/Gäste": "Gast/Gäste",
  "Wie es auf Android aussehen wird:": "Wie es auf Android aussehen wird:",
  Benachrichtigung: "Benachrichtigung",
  jetzt: "jetzt",
  "Preis anzeigen": "Preis anzeigen",
  Zeilennummer: "Zeilennummer",
  Zeilenname: "Zeilenname",
  Größe: "Größe",
  Etikett: "Etikett",
  Aktiv: "Aktiv",
  Inaktiv: "Inaktiv",
  "Random tag order": "Random tag order",
  service: "service",
  subcategory: "subcategory",
  category: "category",
  "No options": "No options",
  Info: "Info",
  "Edit sub-category": "Edit sub-category",
  "Delete service": "Delete service",
  "Delete sub-category": "Delete sub-category",
  "Delete category": "Delete category",
  deleteServiceText:
    "With this action you will delete chosen item. Are you sure you want to proceed?",
  deleteSubcategoryText:
    "With this action you will delete chosen sub-category. Are you sure you want to proceed?",
  deleteCategoryText:
    "With this action you will delete chosen category. Are you sure you want to proceed?",
  "You have successfully deleted category":
    "You have successfully deleted category!",
  "You have successfully deleted sub-category":
    "You have successfully deleted sub-category!",
  "You have successfully deleted item": "You have successfully deleted item!",
  Remark: "Remark",
  Quantity: "Quantity",
  "Edit guest": "Edit guest",
  organisations_helptext: `<p>
  In this section you can manage your <strong>organisations</strong>.
  You can add new and assign <strong>sub-organisations</strong> to
  them.<br><br> When creating both you will need to choose organisation <strong>group</strong>. After that you need to fill out all needed
  information, just like on first sign-up. <br><br> You can change information
  about organisation clicking on <strong>settings icon</strong> in
  the right corner. <br><br> There you can change basic information,
  location, images, assign amenities and rules. <br><br> You can also define 
    <strong>working hours</strong>
  of your organisation. Guests only can
  order and make appointments in those hours.
</p>`,
  services_helptext: `
<p>
  Here you can add all <strong>items</strong> and
  <strong>services</strong> that will be avaliable in the guest
  application. <br><br> First you will need to add <strong>category</strong>.
  Choose icon that will best represent that category, name, reservation
  type (appointment, order, side dish) and template (how will item or
  service be displayed on guest application). <br><br> After that you should add
  <strong>sub-category</strong>. Click on created category and then
  click on add button. Name your sub-category and then you can add
  <strong>items</strong> there. <br><br> For example: Food (Category) >> Pizza
  (Sub-category) >> Margherita (Item) Here on the first step you should
  choose Reservation type and Template as Order. <br><br> For example: Spa
  (Category) >> Massage (Sub-category) >> Royal massage (Item) Here on
  the first step you should choose Reservation type and Template as
  Appointment.
</p>`,
  users_helptext: `
<p>
  Here you can view, create and edit <strong>users</strong> for the
  system. First data cell in this table is your main user wich you have
  been created by creating TripleHost account. <br><br> You can add user to
  <strong>specific organisation</strong> by clicking edit on the end of
  the row and then edit organisation column. Search the specific
  organisation which you want to assign to selected user. <br><br> Also you can
  specify <strong>roles</strong> to users by the same way as adding
  organisations.
</p>`,
  orders_helptext: `
        <p>
          On this screen you will be able to track your <strong>orders</strong>. <br><br> 
          You have three columns: pending, in progress and finished. <br><br> On the date
          picker you can choose for what day will orders will be displayed. Also
          you can filter them by organisation. <br><br> All
          <strong>incoming orders</strong> will go in the
          <strong>pending</strong> column waiting you to accept them or decline.
          From here you can move them to the in progress or finished column by
          <strong>dragging</strong> or buttons on the bottom of the order. <br><br> Once
          order is finished you can’t move it back to other columns. <br><br> You can
          also edit order if it is inncorrect. Add new item to it or delete
          selected.
        </p>`,
  appointments_helptext: `
  <p>
  Here you will be able to manage <strong>appointments</strong>. <br><br> After
  appointment is made by guest, you will see it in
  <strong>pending</strong> appointments. You can also create reservation by yourself!<br><br>          
  To accept it or create new one, you will need to
  have <strong>calendar</strong>. If you don’t, click
  <strong>Add new calendar</strong>, write some basic infos and define its <strong>working days</strong> and breaks!<br><br>
  You can add more calendars depending on your needs. <br><br> For example: Calendar 1 - Worker
  1 and Calendar - Worker 2
</p>`,
  accomodation_helptext: `
        <p>
          On this screen you can manage your
          <strong>accommodation types</strong>. <br><br> <strong>Add</strong> new ones
          and <strong>edit</strong> existing. <br><br> After adding new accommodation
          unit you can assign <strong>rooms</strong> to it. <br><br> For example: Double
          room (accommodation unit) >> 301, 302... (Rooms) <br><br> For accommodation
          unit you can edit it’s basic information, add images and assign
          amenities.
        </p>`,
  reservations_helptext: `
        <p>
          Here you can manage <strong>reservations</strong> made by guests. <br><br> Add
          <strong>holder</strong> of reservation and assign reservation to
          specific room. Before assigning holder you will need to create new
          guest in the system if he doesn’t exist. After that choose
          accommodation type (if it’s not chosen already) and choose desired
          room to <strong>assign</strong> reservation to it. <br><br> If everything is
          went alright reservation should be visible on
          <strong>schedule</strong> view on left. Clicking on reservation you
          should view it’s details including <strong>guest app key</strong>
          needed for the guest to log in into guest application. <br><br> There is two
          types of reservation, <strong>system</strong> and
          <strong>portal</strong>. System is added with button Add new
          reservation, and portal are reservations made trough booking portal.
        </p>`,
  campaign_helptext: `
        <p>
          Here you can look over <strong>campaigns</strong> made for your guests. <br><br>  
          Campaigns help you advertise your services and after you <strong>activate</strong> campaign, 
          guests will get <strong>notification</strong> on their mobile device with details about your service. <br><br>  
          Creation is simple, through <strong>3 easy steps</strong>. Firstly, you add details about campaign and 
          choose service you want to promote. Then, on second step, you <strong>add guests</strong> that you want your campaign to 
          reach out. And afterwards, on 3rd step, you can activate campaign by clicking at button <strong>'Finish'</strong>. <br><br>  
          If campaign is not activate, you can <strong>edit it</strong> and decide rather you want to activate it right away or later.
        </p>`,
  menupromotion_helptext: `
        <p>
          Create home screen rows for your <strong>digital menu</strong> to promote your services.<br><br>
          By clicking at 'Add new row', you can enter details for row that will <strong>show up</strong> to your guests. 
          <strong>Personalize</strong> your rows, adding them name, size (image size) and categories/sub-categories/items you want to point out. <br><br>
          By checking Random tag order added tags will shuffle each time user enters homescreen on digital menu.<br><br>
          You can even decide rather you want to display items' prices or not, or to make order of showing rows to your guests' digital menu. <br><br>
          Don't forget to <strong>Save changes</strong> when you're done!
        </p>`,
  guestapppromotion_helptext: `
        <p>
          Create home screen rows for <strong>Guest App</strong> to promote anything you want, services, events or special offers!<br><br>
          By clicking at 'Add new row', you can enter details for row that will <strong>show up</strong> to Guest App users. 
          <strong>Personalize</strong> your rows, adding them name, size (image size) and categories/sub-categories/items you want to point out. <br><br>
          By checking Random tag order added tags will shuffle each time user enters homescreen on Guest App.<br><br>
          You can even decide rather you want to display items' prices or not, or to make order of showing rows to your guests. <br><br>
          Don't forget to <strong>Save changes</strong> when you're done!
        </p>`,
  reservationreview_helptext: `<p>Here, you can either <strong>find some reservation</strong> and its details, <strong>create</strong> one reservation or <strong>import reservations</strong> via file!<br>
  <br>
  On the top part of the page, there are various inputs for <strong>your easy research</strong>. Just insert parameters and click Search!<br>
  <br>
  By creating a reservation, you'll need to <strong>insert check-in and check-out</strong>, <strong>assign guests</strong>, and then <strong>assign them to accommodation</strong> of your or their wish! Click on 'Save' and new reservation is created!<br>
  <br>
  Import is even more simpler! Just click on <strong>'Import' button</strong>, and choose file with reservations. And within second, new modal will open and there you'll see reservations that are imported with some basic details. All you need then is assign them room keys!<br>
  <br>
  Also, <strong>you can edit guests</strong> on the bottom part of the main page. Edit their informations, edit or add room keys and delete reservation for good!</p>`,
  chat_helptext: `<p>This is the place for your <strong>communication</strong> with guests!<br>
  <br>
  Simply, click on the plus <strong>to find guest</strong> you want to start the communication with. Choose guest, start writing a message, <strong>and send!</strong> In chat you'll see date and time of communication. You can also delete whole conversation if you need to. <br>
  <br>
  Guest will get <strong>notification</strong> on its mobile phone through Guest App for incoming message, and when he answers, <strong>you'll also get one!</strong> By clicking on it, you'll find yourself on this page again, and see red dot on guest's name from whom you have an unread message!<br>
  <br>
  <strong>Happy chatting!</strong></p>`,
  "You have successfully updated the category":
    "Sie haben die Kategorie erfolgreich aktualisiert",
  "You have successfully created the category":
    "Sie haben die Kategorie erfolgreich erstellt",
  "You have successfully updated the subcategory":
    "Sie haben die Unterkategorie erfolgreich aktualisiert",
  "You have successfully created the subcategory":
    "Sie haben die Unterkategorie erfolgreich erstellt",
  "You have successfully updated the item":
    "Sie haben den Artikel erfolgreich aktualisiert",
  "You have successfully created the item":
    "Sie haben den Artikel erfolgreich erstellt",
  "You have successfully updated the extras":
    "Sie haben die Extras erfolgreich aktualisiert",
  "You have successfully created the extras":
    "Sie haben die Extras erfolgreich erstellt",
  "Create another": "Create another",
  "The delivery is finished!": "The delivery is finished!",
  "Chat is disabled": "Chat ist deaktiviert",
  "Add new conversation": "Neue Unterhaltung hinzufügen",
  "Remove conversation": "Gespräch entfernen",
  "Type message here": "Nachricht hier eingeben",
  "Reservations review": "Reservierungsüberprüfung",
  "Assigned key": "Zugewiesene Taste",
  "GuestApp state": "Zustand",
  "Room key": "Schlüssel",
  "Find reservation": "Reservierung finden",
  "Add room key": "Zimmerschlüssel hinzufügen",
  "Delete reservation": "Reservierung löschen",
  "With this action you'll delete chosen reservation. Are you sure you want to proceed?":
    "Mit dieser Aktion löschen Sie die ausgewählte Reservierung. Sind Sie sicher, dass Sie fortfahren möchten?",
  "Yes I am sure": "Ja, ich bin sicher",
  Review: "Rezension",
  Holder: "Holder",
  "Display this item on these sub-categories":
    "Display this item on these sub-categories",
  "Charge this sidedish": "Charge this sidedish",
  "Create reservation": "Create reservation",
  "Currently staying in hotel": "Currently staying in hotel",
  "Customize homescreen rows": "Customize homescreen rows",
  "Something went wrong": "Something went wrong!",
  "Go to homepage": "Go to homepage",
  Reports: "Reports",
  CloseButton: "Close",
  "Choose parameters you want to make report for":
    "Choose parameters you want to make report for",
  Today: "Today",
  "To the end of this week": "To the end of this week",
  "Next week": "Next week",
  "To the end of this month": "To the end of this month",
  "Next month": "Next month",
  "Total (Total amount)": "Total (Total amount)",
  "Date and time from": "Date and time from",
  "Date and time to": "Date and time to",
  "Room number": "Room number",
  // ACTIVITY STREAM
  "Guest activity": "Gastaktivität",
  "/homeScreenRow/getAllForGuest/<stringlang>":
    "Zugriff auf den Startbildschirm der App.",
  "/category/guest/getAllActiveForLoggedUser/<stringlang>":
    "durchsucht Kategorien.",
  "/service/selectView/<stringlang>/<uuidorg_core_id>": "Elemente durchsuchen.",
  "/service/singleView/<stringlang>/<uuidservice_id>":
    "durchsucht das Element.",
  "/orders/roomBill": "hat eine neue Bestellung auf dem Zimmerkonto erstellt.",
  "/orders/getAllForGuest/<stringlang>":
    "hat begonnen, seine Bestellungen zu überprüfen.",
  "/notifications/recieved": "hat Benachrichtigungen gelesen.",
  "/stayReviews": "hat seinen/ihren Aufenthalt bewertet.",
  "/reservationGuests/<uuid_id>": "hat ein Ausgabenlimit festgelegt.",
  "/saveSubscription": "hat den Empfang von Benachrichtigungen zugelassen.",
  "/guests/login":
    "hat sich bei der Anwendung angemeldet. Das System hat das Gerät registriert.",
  "Moving and copying items": "Elemente verschieben und kopieren",
  "Do you want to move or copy selected items?":
    "Möchten Sie ausgewählte Elemente verschieben oder kopieren?",
    "Steps": "Schritt {{num1}} von {{num2}}",
  Move: "Verschieben",
  Copy: "Kopieren",
  "Select subcategory, you want move items to":
    "Wählen Sie die Unterkategorie aus, in die Sie Elemente verschieben möchten",
  "Select subcategory, you want copy items to":
    "Wählen Sie die Unterkategorie aus, in die Sie Elemente kopieren möchten",
    "Move selected items":"{{length}} Artikel verschieben",
    "Copy selected items":"{{length}} Artikel kopieren",
  Confirm: "Konfirmieren",
  "QR codes and links":"QR-Codes und Links",
  "Integration":"Integration",
  "Other":"Andere",
  "Menu":"Menü",
  "public app":"public app",
  "Invoices":"Rechnungen",
  "Overview":"Überschau",
  "Payment details":"Zahlungsdetails",
  "Number":"Nummer",
  "Date":"Datum",
  "Amount":"Betrag",
  "There are no homescreen rows":"Es gibt keine Startbildschirmzeilen",
  //integrations
  "Activate": "Aktivieren",
  "Deactivate": "Deaktivieren",
  "Api key": "API-Schlüssel",
  "Base url": "Basis-URL",
  "Integration PMS": "Integration PMS",
  "Integration POS": "Integration POS",
  "CHOOSE": "WÄHLEN",
  "External property ID": "Externe Eigenschafts-ID",
  "Ordering process management": "Verwaltung des Bestellvorgangs",
  "Order status": "Bestellstatus",
  "Select status": "Status auswählen",
  "Define ordering processes of organization": "Bestellprozesse der Organisation definieren",
  "connected": "verbunden",
  "unconnected": "unverbunden",
  "inactive": "inaktiv",
  "Agree": "Ja",
  "Disagree": "Nein",
  "Are you sure you want to delete calendar": "Möchten Sie den Kalender wirklich löschen",
  "Confirm deletion": "Löschung bestätigen",

  // Tutorial
  next: "next",
  back: "back",
  skip: "skip tutorial",
  tutTitle1: "Order room service",
  tutText1:
    "BROWSE trough large selection of FOOD and BEVERAGES, add what you want to the CART and click CHECKOUT. Your order will be delivered soon to your room.",
  tutTitle2: "Make an appointment",
  tutText2:
    "Want to make an APPOINTMENT in the WELLNESS? No problem. Pick MASSAGE or some other treatment, check available TERMINS and choose one. Add it to the cart and click CHECKOUT. That's it! You have successfully booked an APPOINTMENT. You only have to be there in time.",
  tutTitle3: "Book sport activity",
  tutText3:
    "Do you want to reserve TENNIS COURT or KAYAKS? It is very simple. Browse trough sport category and pick one you desire. Choose available TERMIN and add it to the CART. That's it. You have successfully reserved an SPORT ACTIVITY.",
  tutTitle4: "Review orders",
  tutText4:
    "After every ORDER you can view all your ordered item on the ORDERS screen. If order is completed you can make an REVIEW and help you, other guests and this property to make better service.",
  tutTitle5: "Get informed",
  tutText5:
    "Click BROWSE tab and then HOTEL MAPPING. There you can find various useful information such as: HOUSE RULES, TRANSPORTATION and CONTACT information.",
  tutTitle6: "Stay up to date",
  tutText6:
    "On HOME SCREEN you can always see what is trending in place you staying. There will be information like when and where are next big events such as CONCERTS, PARTIES and other activities.",
  tutTitle7: "Ask a question",
  tutText7:
    "Open CHAT tab and feel free to ask any question. RECEPTION will answer you in short notice. Chat is available 24/7.",
  tutTitle8: "View your stay information",
  tutText8:
    "Click PROFILE tab where you can see your STAY information and all SPENDINGS in your current reservation. View your subscriptions and use them as tickets for various events like BREAKFAST, SWIMMING POOL...",
};
