import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { ChatContext } from "Router/MainRouter";

// Material-UI
import {
  makeStyles,
  Container,
  Box,
  Menu,
  MenuItem,
  Hidden,
  LinearProgress,
  Divider,
  Typography,
  Badge,
  Button,
} from "@material-ui/core";

// Atoms
import ChipButton from "Components/atoms/buttons/ChipButton";
import IconButton from "Components/atoms/buttons/IconButton";
import Logo from "Components/atoms/UI/Logo";
import Title from "Components/atoms/UI/Title";

// Actions
import { logOut, changeOrganisation } from "Modules/units/Auth";
import {
  markNotificationsAsRecieved,
  markNotificationsAsRead,
} from "Modules/units/Notification";
import { getChatRooms } from "Modules/units/Chat";

// Dialogs
import HelpDialog from "Components/dialogs/HelpDialog";

// Sounds
import msgSound from "Assets/sounds/msg_sound.mp3";

// Utils
import { helpInfo } from "Util/helpInfo";
import { ID } from "Util/idConfig";
import MobileSidebar from "./MobileSidebar";

const messageNotificationSound = new Audio(msgSound);

const style = makeStyles(theme => ({
  container: {
    height: "4.8em",
    backgroundColor: "#fafafa",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (min-width:1280px)"]: {
      // eslint-disable-line no-useless-computed-key
      marginLeft: "16.5em",
    },
  },
  logo: {
    flexGrow: 1,
    padding: "20px",
    borderBottom: "2px solid #e8e8e8",
    textAlign: "center",
  },
  navLinks: {
    color: "#595959",
    textDecoration: "none",
    "&:hover": {
      background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
      color: "#fff",
    },
  },
  navLinksActive: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    color: "#fff",
  },
  listText: {
    fontSize: "13px",
  },
  loadingBar: {
    zIndex: 9999,
    height: "5px",
    position: "fixed",
    top: 0,
    overflow: "hidden",
    width: "100%",
  },
  root: {
    "&:focus": {
      outline: "none",
    },
  },
}));

const usePersistedState = (key, defaultValue) => {
  const [localState, setLocalState] = useState(
    JSON.parse(localStorage.getItem(key)) || defaultValue
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(localState));
  }, [key, localState]);

  return [localState, setLocalState];
};

const OrganisationMenu = ({ selectedOrganisation, auth }) => {
  const [anchorEl, setanchorEl] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOpen = (event) => {
    setanchorEl(event.currentTarget);
  };

  return (
    <>
      {auth.orgs && auth.orgs.length > 1 && (
        <Hidden smDown>
          <ChipButton
            aria-controls="menu"
            text={(function orgName() {
              const org = auth.orgs.find(
                (item) => item.id === selectedOrganisation
              );
              return org.name;
            })()}
            light
            onClick={handleOpen}
          />
        </Hidden>
      )}
      <Hidden mdUp>
        <IconButton icon="business" aria-controls="menu" onClick={handleOpen} />
      </Hidden>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setanchorEl(null)}
      >
        {auth.orgs?.map((item, index) => {
          return item.parentID !== null ? (
            <MenuItem
              key={item.id}
              onClick={() => {
                dispatch(changeOrganisation(item.id, history));
              }}
              style={{ fontWeight: index === 0 ? "600" : "unset" }}
              selected={selectedOrganisation === item.id}
            >
              {item.name}
            </MenuItem>
          ) : null;
        })}
      </Menu>
    </>
  );
};

const NotificationMenu = ({ dispatch }) => {
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const notifications = useSelector((state) => state.notifications);
  const history = useHistory();
  const classes = style();

  const handleOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleReadAll = () => {
    dispatch(
      markNotificationsAsRead({
        list_id: notifications.list.map((item) => item.id),
      })
    );
  };

  return (
    <>
      <IconButton
        notificationDot={notifications?.list?.find(
          (i) => i.state === ID.notification_state.sent
        )}
        gradientColor={false}
        icon="notifications"
        onClick={handleOpen}
      />
      <Menu
        onEnter={() => {
          dispatch(
            markNotificationsAsRecieved({
              list_id: notifications.list
                ?.filter((item) => item.state === ID.notification_state.sent)
                ?.map((item) => item.id),
            })
          );
        }}
        id="menu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
      >
        <Box
          px={2}
          display="flex"
          justifyContent="space-between"
          className={classes.root}
        >
          <Typography variant="h6">{t("Notifications")}</Typography>
          <Button variant="text" color="primary" onClick={handleReadAll}>
            {t("Read all").toUpperCase()}
          </Button>
        </Box>
        <Divider />
        {notifications.list?.map((item, index) => (
          <Box
            key={item.id}
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              history.push(item.link);
              setMenuAnchor(null);

              if (ID.notification_state.recieved === item.state) {
                dispatch(
                  markNotificationsAsRead({
                    list_id: [item.id],
                  })
                );
              }
            }}
          >
            <Box p={2} display="flex" width="500px">
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  {moment(item.created_at).format("LLL")}
                </Typography>
                <Typography
                  variant="button"
                  color="primary"
                  style={{
                    fontWeight: "800",
                    fontSize: "1rem",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography variant="body2">{item.description}</Typography>
              </Box>
              <Box px={1}>
                {ID.notification_state.recieved === item.state && (
                  <Badge variant="dot" color="error" />
                )}
              </Box>
            </Box>
            {notifications.list.length - 1 !== index && <Divider />}
          </Box>
        ))}
      </Menu>
    </>
  );
};

const ChatNotifications = ({ dispatch, realTimeAPI }) => {
  const history = useHistory();
  const loggedChatUser = JSON.parse(localStorage.getItem("currentOrg"))?.th_org
    ?.chat_user_id;

  const [unreadNumber, setUnreadNumber] = useState(null);
  const rooms = useSelector((state) => state.chat.rooms);

  const [socketListener, setSocketListener] = useState("");

  useEffect(() => {
    if (window.location.pathname !== "/chat") {
      dispatch(getChatRooms(() => null));
    }
    if (realTimeAPI?.webSocket?.closed === false) {
      realTimeAPI.onMessage((message) => setSocketListener(message));
    }
  }, []);

  useEffect(() => {
    if (rooms) {
      setUnreadNumber(rooms.reduce((a, b) => a + b.unread, 0));
    }
  }, [rooms]);

  useEffect(() => {
    if (
      window.location.pathname !== "/chat" &&
      socketListener?.fields?.eventName === `${loggedChatUser}/notification`
    ) {
      setUnreadNumber(1);
      messageNotificationSound.play();
    }
  }, [socketListener]);

  return (
    <IconButton
      icon="messages"
      notificationDot={
        window.location.pathname !== "/chat" ? unreadNumber : false
      }
      onClick={() => history.push("/chat")}
    />
  );
};

const LogOutButton = ({ t, realTimeAPI }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Hidden mdDown>
      <Box style={{ paddingLeft: "10px" }}>
        <ChipButton
          text={t("Log out")}
          dataCy="logoutButton"
          onClick={() => dispatch(logOut(history, realTimeAPI))}
        />
      </Box>
    </Hidden>
  );
};

const PrivateHeader = ({ title, routeId }) => {
  const dispatch = useDispatch();
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [helpModal, openHelpModal] = useState(false);

  const realTimeAPI = useContext(ChatContext);

  const auth = useSelector((state) => state.auth);
  const [selectedOrganisation, setSelectedOrganisation] = usePersistedState(
    "selectedOrg",
    auth.user?.organisationID
  );

  const classes = style();
  const app = useSelector(state => state.app);
  const meni = useSelector(state => state.auth.meni);

  const isChatIconVisible = meni?.find(item => item.linkTo === "/chat");

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Loading bar axios interceptors
    axios.interceptors.request.use(
      (config) => {
        setLoading(true);
        return config;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      {loading && (
        <LinearProgress color="secondary" className={classes.loadingBar} />
      )}
      <header className={classes.container}>
        <Container style={{ height: "100%" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
          >
            <Box display="flex" alignItems="center">
              <Hidden lgUp>
                <Box style={{ paddingRight: "10px" }}>
                  <Logo
                    width="115px"
                    goTo="/"
                    src={app.theme.logo}
                    type="dark"
                    // tag="ALPHA"
                  />
                </Box>
              </Hidden>
              <Hidden xsDown>
                {helpInfo.some((i) => i.id === routeId) ? (
                  <ChipButton
                    text={t("Help")}
                    light
                    dataCy="help"
                    onClick={() => openHelpModal(true)}
                  />
                ) : null}
                <Box
                  style={{
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Title title={title} dataCy="title" />
                </Box>
              </Hidden>
            </Box>
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <OrganisationMenu
                  selectedOrganisation={selectedOrganisation}
                  setSelectedOrganisation={setSelectedOrganisation}
                  auth={auth}
                />
                <Hidden lgUp>
                  <IconButton
                    icon="menu"
                    onClick={() => setMobileSidebarOpen(true)}
                    styleProp={{ paddingRight: "0" }}
                  />
                </Hidden>
                {isChatIconVisible && (
                  <ChatNotifications
                    dispatch={dispatch}
                    realTimeAPI={realTimeAPI}
                  />
                )}
                <NotificationMenu dispatch={dispatch} />
                <LogOutButton t={t} realTimeAPI={realTimeAPI} />
              </Box>
            </Box>
            <MobileSidebar
              open={mobileSidebarOpen}
              setOpen={setMobileSidebarOpen}
              organisation={
                auth?.orgs &&
                (function orgName() {
                  const org = auth?.orgs.find(
                    (item) => item?.id === selectedOrganisation
                  );
                  return org?.name;
                })()
              }
            />
          </Box>
        </Container>
      </header>

      {helpModal && (
        <HelpDialog
          open={helpModal}
          handleClose={() => openHelpModal(false)}
          data={helpInfo.find((i) => i.id === routeId)}
        />
      )}
    </>
  );
};

export default PrivateHeader;
