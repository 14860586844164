module.exports = {
  /// validacija:
  "Field must be between 2 and 100 characters long":
    "Field must be between 2 and 100 characters long",
  "Email is not in the correct format": "Email is not in the correct format",
  "Field must be between 2 and 30 characters long":
    "Field must be between 2 and 30 characters long",
  "Not a valid UUID.": "Not a valid UUID.",
  "Field must be between 2 and 40 characters long":
    "Field must be between 2 and 40 characters long",
  "Password to short.": "Password to short.",
  "Password must contain at least one uppercase letter.":
    "Password must contain at least one uppercase letter.",
  "Restriction must be in range of 1-200":
    "Restriction must be in range of 1-200",
  "Stars must be in range of 1-200": "Stars must be in range of 1-200",
  "Deadline must be in range of 1-1000 minutes":
    "Deadline must be in range of 1-1000 minutes",
  "Field is required": "Field is required",
  "Field may not be null.": "Field may not be null.",
  "Field must be between 6 and 128 characters long":
    "Field must be between 6 and 128 characters long",
  "Field must be between 2 and 200 characters long":
    "Field must be between 2 and 200 characters long",
  // general
  "Welcome to React": "Welcome to React",
  Username: "Username",
  Password: "Password",
  Submit: "Submit",
  "Send e-mail": "Send e-mail",
  ForgotPasswordHeader: "Forgot your password?",
  ForgotPasswordSubtitle:
    "Don't worry! Resetting your password is easy. Just type in the email you registered in TripleHost",
  RememberedPassword: "Did you remember your password?",
  "Try logging in": "Try logging in",
  "Log out": "Log out",
  Verification: "Verification",
  Date: "Date",
  "Total pending orders": "Total pending orders",
  Organisation: "Organisation",
  Refresh: "Refresh",
  "Add item": "Add item",
  "Add item | Order": "Add item | Order",
  "What do you want to add": "What do you want to add?",
  "Edit item": "Edit item",
  "Edit item | Order": "Edit item | Order",
  Decline: "Decline",
  Accept: "Accept",
  Delete: "Delete",
  Save: "Save",
  Add: "Add",
  "Add + Add another": "Add + Add another",
  Actions: "Actions",
  Tenants: "Tenants",
  Search: "Search",
  rows: "rows",
  "No records to display": "No records to display",
  Domain: "Domain",
  GuestTable: "Guest",
  Service: "Service",
  service: "service",
  subcategory: "subcategory",
  category: "category",
  "No options": "No options",
  TimeReservation: "Time",
  Verify: "Verify",
  confirmEmailText:
    "We sent you an email containing the new password for your account.",
  "Back to login": "Back to login",
  Help: "Help",
  "Add new organisation": "Add new organisation",
  "New sub-organisation": "New sub-organisation",
  "There are no sub-organisations": "There are no sub-organisations",
  "Not a valid number.": "Not a valid number.",
  "delete prices for selected dates": "delete prices for selected dates",
  "Double room": "Double room",
  "portal profile": "portal profile",
  "accomodation informations": "accomodation informations",
  pricing: "pricing",
  apply: "apply",
  "You cannot set prices on the past dates.":
    "You cannot set prices on the past dates.",
  Price: "Price",
  "Price can not be negative": "Price can not be negative",
  "Item Description": "Type Item's Description below:",
  Title: "Title",
  Description: "Description",
  Details: "Details",
  DETAILS: "DETAILS",
  Picture: "Picture",
  Icon: "Icon",
  AUDIENCE: "AUDIENCE",
  RUN: "RUN",
  Back: "Back",
  Next: "Next",
  Finish: "Finish",
  Name: "Name",
  Lastname: "Surname",
  Tag: "Tag",
  CAMPAIGN: "CAMPAIGN",
  "Subscription status": "Subscription status",
  YES: "YES",
  NO: "NO",
  Stays: "Stays",
  Current: "Current",
  "Who stayed x days ago": "Who stayed x days ago",
  Orders: "Orders",
  "Bought something": "Bought something",
  "Never bought anything": "Never bought anything",
  "Had more than x orders": "Had more than x orders",
  Campaigns: "Campaigns",
  "Has enabled notifications": "Has enabled notifications",
  "Never been sent a campaign": "Never been sent a campaign",
  "Were sent up to x campaigns": "Were sent up to x campaigns",
  UPLOAD: "UPLOAD",
  "Reset filter": "Reset filter",
  "Campaign name": "Campaign name",
  "Campaign preview": "Campaign preview",
  "campaign will include": "campaign will include",
  "guest/s": "guest/s",
  "How it will look on Android:": "How it will look on Android:",
  now: "now",
  "Show price": "Show price",
  Order: "Order",
  "Row name": "Row name",
  Size: "Size",
  Tags: "Tags",
  Active: "Active",
  Inactive: "Inactive",
  "Random tag order": "Random tag order",
  Info: "Info",
  "Edit sub-category": "Edit sub-category",
  "Delete service": "Delete service",
  "Delete sub-category": "Delete sub-category",
  "Delete category": "Delete category",
  deleteServiceText:
    "With this action you will delete chosen item. Are you sure you want to proceed?",
  deleteSubcategoryText:
    "With this action you will delete chosen sub-category. Are you sure you want to proceed?",
  deleteCategoryText:
    "With this action you will delete chosen category. Are you sure you want to proceed?",
  "You have successfully deleted category":
    "You have successfully deleted category!",
  "You have successfully deleted sub-category":
    "You have successfully deleted sub-category!",
  "You have successfully deleted item": "You have successfully deleted item!",
  Remark: "Remark",
  Quantity: "Quantity",
  "Edit guest": "Edit guest",
  organisations_helptext: `<p>
  In this section you can manage your <strong>organisations</strong>.
  You can add new and assign <strong>sub-organisations</strong> to
  them.<br><br> When creating both you will need to choose organisation <strong>group</strong>. After that you need to fill out all needed
  information, just like on first sign-up. <br><br> You can change information
  about organisation clicking on <strong>settings icon</strong> in
  the right corner. <br><br> There you can change basic information,
  location, images, assign amenities and rules. <br><br> You can also define 
    <strong>working hours</strong>
  of your organisation. Guests only can
  order and make appointments in those hours.
</p>`,
  services_helptext: `
<p>
  Here you can add all <strong>items</strong> and
  <strong>services</strong> that will be avaliable in the guest
  application. <br><br> First you will need to add <strong>category</strong>.
  Choose icon that will best represent that category, name, reservation
  type (appointment, order, side dish) and template (how will item or
  service be displayed on guest application). <br><br> After that you should add
  <strong>sub-category</strong>. Click on created category and then
  click on add button. Name your sub-category and then you can add
  <strong>items</strong> there. <br><br> For example: Food (Category) >> Pizza
  (Sub-category) >> Margherita (Item) Here on the first step you should
  choose Reservation type and Template as Order. <br><br> For example: Spa
  (Category) >> Massage (Sub-category) >> Royal massage (Item) Here on
  the first step you should choose Reservation type and Template as
  Appointment.
</p>`,
  users_helptext: `
<p>
  Here you can view, create and edit <strong>users</strong> for the
  system. First data cell in this table is your main user wich you have
  been created by creating TripleHost account. <br><br> You can add user to
  <strong>specific organisation</strong> by clicking edit on the end of
  the row and then edit organisation column. Search the specific
  organisation which you want to assign to selected user. <br><br> Also you can
  specify <strong>roles</strong> to users by the same way as adding
  organisations.
</p>`,
  orders_helptext: `
        <p>
          On this screen you will be able to track your <strong>orders</strong>. <br><br> 
          You have three columns: pending, in progress and finished. <br><br> On the date
          picker you can choose for what day will orders will be displayed. Also
          you can filter them by organisation. <br><br> All
          <strong>incoming orders</strong> will go in the
          <strong>pending</strong> column waiting you to accept them or decline.
          From here you can move them to the in progress or finished column by
          <strong>dragging</strong> or buttons on the bottom of the order. <br><br> Once
          order is finished you can’t move it back to other columns. <br><br> You can
          also edit order if it is inncorrect. Add new item to it or delete
          selected.
        </p>`,
  appointments_helptext: `
        <p>
          Here you will be able to manage <strong>appointments</strong>. <br><br> After
          appointment is made by guest, you will see it in
          <strong>pending</strong> appointments. You can also create reservation by yourself!<br><br>          
          To accept it or create new one, you will need to
          have <strong>calendar</strong>. If you don’t, click
          <strong>Add new calendar</strong>, write some basic infos and define its <strong>working days</strong> and breaks!<br><br>
          You can add more calendars depending on your needs. <br><br> For example: Calendar 1 - Worker
          1 and Calendar - Worker 2
        </p>`,
  accomodation_helptext: `
        <p>
          On this screen you can manage your
          <strong>accommodation types</strong>. <br><br> <strong>Add</strong> new ones
          and <strong>edit</strong> existing. <br><br> After adding new accommodation
          unit you can assign <strong>rooms</strong> to it. <br><br> For example: Double
          room (accommodation unit) >> 301, 302... (Rooms) <br><br> For accommodation
          unit you can edit it’s basic information, add images and assign
          amenities.
        </p>`,
  reservations_helptext: `
        <p>
          Here you can manage <strong>reservations</strong> made by guests. <br><br> Add
          <strong>holder</strong> of reservation and assign reservation to
          specific room. Before assigning holder you will need to create new
          guest in the system if he doesn’t exist. After that choose
          accommodation type (if it’s not chosen already) and choose desired
          room to <strong>assign</strong> reservation to it. <br><br> If everything is
          went alright reservation should be visible on
          <strong>schedule</strong> view on left. Clicking on reservation you
          should view it’s details including <strong>guest app key</strong>
          needed for the guest to log in into guest application. <br><br> There is two
          types of reservation, <strong>system</strong> and
          <strong>portal</strong>. System is added with button Add new
          reservation, and portal are reservations made trough booking portal.
        </p>`,
  campaign_helptext: `
        <p>
          Here you can look over <strong>campaigns</strong> made for your guests. <br><br>  
          Campaigns help you advertise your services and after you <strong>activate</strong> campaign, 
          guests will get <strong>notification</strong> on their mobile device with details about your service. <br><br>  
          Creation is simple, through <strong>3 easy steps</strong>. Firstly, you add details about campaign and 
          choose service you want to promote. Then, on second step, you <strong>add guests</strong> that you want your campaign to 
          reach out. And afterwards, on 3rd step, you can activate campaign by clicking at button <strong>'Finish'</strong>. <br><br>  
          If campaign is not activate, you can <strong>edit it</strong> and decide rather you want to activate it right away or later.
        </p>`,
  menupromotion_helptext: `
        <p>
          Create home screen rows for your <strong>digital menu</strong> to promote your services.<br><br>
          By clicking at 'Add new row', you can enter details for row that will <strong>show up</strong> to your guests. 
          <strong>Personalize</strong> your rows, adding them name, size (image size) and categories/sub-categories/items you want to point out. <br><br>
          By checking Random tag order added tags will shuffle each time user enters homescreen on digital menu.<br><br>
          You can even decide rather you want to display items' prices or not, or to make order of showing rows to your guests' digital menu. <br><br>
          Don't forget to <strong>Save changes</strong> when you're done!
        </p>`,
  guestapppromotion_helptext: `
        <p>
          Create home screen rows for <strong>Guest App</strong> to promote anything you want, services, events or special offers!<br><br>
          By clicking at 'Add new row', you can enter details for row that will <strong>show up</strong> to Guest App users. 
          <strong>Personalize</strong> your rows, adding them name, size (image size) and categories/sub-categories/items you want to point out. <br><br>
          By checking Random tag order added tags will shuffle each time user enters homescreen on Guest App.<br><br>
          You can even decide rather you want to display items' prices or not, or to make order of showing rows to your guests. <br><br>
          Don't forget to <strong>Save changes</strong> when you're done!
        </p>`,
  reservationreview_helptext: `<p>Here, you can either <strong>find some reservation</strong> and its details, <strong>create</strong> one reservation or <strong>import reservations</strong> via file!<br>
        <br>
        On the top part of the page, there are various inputs for <strong>your easy research</strong>. Just insert parameters and click Search!<br>
        <br>
        By creating a reservation, you'll need to <strong>insert check-in and check-out</strong>, <strong>assign guests</strong>, and then <strong>assign them to accommodation</strong> of your or their wish! Click on 'Save' and new reservation is created!<br>
        <br>
        Import is even more simpler! Just click on <strong>'Import' button</strong>, and choose file with reservations. And within second, new modal will open and there you'll see reservations that are imported with some basic details. All you need then is assign them room keys!<br>
        <br>
        Also, <strong>you can edit guests</strong> on the bottom part of the main page. Edit their informations, edit or add room keys and delete reservation for good!</p>`,
  chat_helptext: `<p>This is the place for your <strong>communication</strong> with guests!<br>
        <br>
        Simply, click on the plus <strong>to find guest</strong> you want to start the communication with. Choose guest, start writing a message, <strong>and send!</strong> In chat you'll see date and time of communication. You can also delete whole conversation if you need to. <br>
        <br>
        Guest will get <strong>notification</strong> on its mobile phone through Guest App for incoming message, and when he answers, <strong>you'll also get one!</strong> By clicking on it, you'll find yourself on this page again, and see red dot on guest's name from whom you have an unread message!<br>
        <br>
        <strong>Happy chatting!</strong></p>`,
  "Basic Information": "Basic Information",
  "Fill in informations about your property":
    "Fill in informations about your property",
  "Add all amenities": "Add all amenities",
  "Select amenities that your property has":
    "Select amenities that your property has",
  "Create Home screen rows": "Create Home screen rows",
  "Promote your services and items": "Promote your services and items",
  "Import best images": "Import best images",
  "Add images for your property": "Add images for your property",
  "Integrate with 3rd party applications":
    "Integrate with 3rd party applications",
  "Location is important": "Location is important",
  "Choosing location will help users find your property":
    "Choosing location will help users find your property",
  "Get order info": "Get order info",
  "Pick how would you like to receieve notifications":
    "Pick how would you like to receieve notifications",
  "Define your rules": "Define your rules",
  "Select set of rules for your property":
    "Select set of rules for your property",
  "Welcome and Info messages": "Welcome and Info messages",
  "Write welcome and info messages to your Guests":
    "Write welcome and info messages to your Guests",
  "It's about your availability": "It's about your availability",
  "Select a day and set work hours for it":
    "Select a day and set work hours for it",
  Basic: "Basic",
  Location: "Location",
  Images: "Images",
  Amenities: "Amenities",
  "Working hours": "Working hours",
  Rules: "Rules",
  Notification: "Notification",
  Integration: "Integration",
  "Home screen": "Home screen",
  Messages: "Messages",
  "Where to?": "Where to?",
  Address: "Address",
  Email: "Email",
  Phone: "Phone",
  Status: "Status",
  Availability: "Availability",
  Unavailable: "Unavailable",
  Available: "Available",
  "Save changes": "Save changes",
  "Drag & Drop or Click to upload": "Drag & Drop or Click to upload",
  Open: "Open",
  Close: "Close",
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",
  "Check in from": "Check in from",
  "Check in until": "Check in until",
  "Check out from": "Check out from",
  "Check out until": "Check out until",
  "Web notifications": "Web notifications",
  "SMS notifications": "SMS notifications",
  "E-mail notifications": "E-mail notifications",
  "Check in": "Check in",
  "Home screen rows": "Home screen rows",
  OrderHSR: "Order",
  "Add new row": "Add new row",
  "Search...": "Search...",
  Language: "Language",
  "Edit welcome message": "Edit welcome message",
  "Edit info message": "Edit info message",
  "Select Property Group": "Select Property Group",
  "Property Group": "Property Group",
  Continue: "Continue",
  Organisations: "Organisations",
  Services: "Services",
  Users: "Users",
  Appointments: "Appointments",
  "Accommodation Types": "Accommodation Types",
  Reservations: "Reservations",
  "My Campaigns": "My Campaigns",
  "Menu Promotions": "Menu Promotions",
  "Menu promotions": "Menu promotions",
  Dashboard: "Dashboard",
  "Administration language": "Administration language",
  Category: "Category",
  "Add new category": "Add new category",
  "There is no categories here. Add new by clicking on the plus icon.":
    "There is no categories here. Add new by clicking on the plus icon.",
  "Activate/deactivate": "Activate/deactivate",
  "Edit this item": "Edit this item",
  "Sub-category": "Sub-category",
  "Add new sub-category": "Add new sub-category",
  "There is no sub-categories for selected category. Add new by clicking on the plus icon.":
    "There is no sub-categories for selected category. Add new by clicking on the plus icon.",
  Expand: "Expand",
  Items: "Items",
  "Add new item": "Add new item",
  Collapse: "Collapse",
  "Edit category": "Edit category",
  Template: "Template",
  "External ID": "External ID",
  Position: "Position",
  "Age restriction": "Age restriction",
  Visibility: "Visibility",
  "Guest app": "Guest app",
  "Contactless menu": "Contactless menu",
  "Public site": "Public site",
  "Create category": "Create category",
  "Create sub-category": "Create sub-category",
  "Create item": "Create item",
  "Delivery time": "Delivery time",
  "Cancellation deadline (min)": "Cancellation deadline (min)",
  Image: "Image",
  "View reviews": "View reviews",
  Reviews: "Reviews",
  "average rating": "average rating",
  Guest: "Guest",
  Rating: "Rating",
  "users overview": "users overview",
  "First name": "First name",
  "Last name": "Last name",
  Roles: "Roles",
  "Phone number": "Phone number",
  "Edit User": "Edit User",
  "Add new User": "Add new User",
  Pending: "Pending",
  "In progress": "In progress",
  Finished: "Finished",
  "No pending appointments": "No pending appointments",
  "No calendars to display": "No calendars to display",
  "Please add a new calendar": "Please add a new calendar",
  Total: "Total",
  "Ready for delivery": "Ready for delivery",
  "New calendar": "New calendar",
  "Add new calendar": "Add new calendar",
  "Capacity of timeslot": "Capacity of timeslot",
  "Timeslot interval": "Timeslot interval",
  Priority: "Priority",
  "Who has access to this calendar?": "Who has access to this calendar?",
  "Create calendar": "Create calendar",
  "Add new reservation": "Add new reservation",
  "Payment method": "Payment method",
  "Create New": "Create New",
  "Add new guest": "Add new guest",
  "Create guest": "Create guest",
  "Check for new appointments": "Check for new appointments",
  "Edit working days and hours": "Edit working days and hours",
  "Delete by clicking on working day or dragging on group of working days":
    "Delete by clicking on working day or dragging on group of working days",
  "Add working days by clicking on empty slot or dragging on group of empty slots":
    "Add working days by clicking on empty slot or dragging on group of empty slots",
  "Edit working days by clicking on working day or dragging on group of working days":
    "Edit working days by clicking on working day or dragging on group of working days",
  "Enable delete": "Enable delete",
  "Edit calendar": "Edit calendar",
  "Search appointments": "Search appointments",
  "Search reservation": "Search reservation",
  "Search this calendar": "Search this calendar",
  "Transfer to calendar": "Transfer to calendar",
  All: "All",
  From: "From",
  To: "To",
  "Edit reservation": "Edit reservation",
  "Edit breaks": "Edit breaks",
  "Delete by clicking on break event": "Delete by clicking on break event",
  "Add break by dragging on empty time slots":
    "Add break by dragging on empty time slots",
  "Working days": "Working days",
  "Working day": "Working day",
  BackCalendar: "BackCalendar",
  NextCalendar: "NextCalendar",
  "Breaks:": "Breaks:",
  "Reserv.:": "Reserv.:",
  Calendars: "Calendars",
  "COVER IMAGE": "COVER IMAGE",
  "Change reservation time": "Change reservation time",
  "Choose time slot": "Choose time slot",
  Break: "Break",
  Reservation: "Reservation",
  "Add new accommodation": "Add new accommodation",
  Information: "Information",
  "Size (sqm)": "Size (sqm)",
  "Max capacity": "Max capacity",
  Cancel: "Cancel",
  "New accommodation unit": "New accommodation unit",
  Number: "Number",
  Floor: "Floor",
  "Accommodation state": "Accommodation state",
  Pool: "Pool",
  "Total to pay": "Total to pay",
  "Date from": "Date from",
  "Date to": "Date to",
  Duration: "Duration",
  Guests: "Guests",
  "Date of birth": "Date of birth",
  "Document ID": "Document ID",
  "Document type": "Document type",
  Country: "Country",
  "Assign guest to the reservation": "Assign guest to the reservation",
  Holder: "Holder",
  "Assign guest": "Assign guest",
  "Accommodation type": "Accommodation type",
  Room: "Room",
  Accommodation: "Accommodation",
  "Pricing by single day": "Pricing by single day",
  "Bulk edit": "Bulk edit",
  "campaigns overview": "campaigns overview",
  Created: "Created",
  Sent: "Sent",
  Read: "Read",
  "Edit this campaign": "Edit this campaign",
  "Add new campaign": "Add new campaign",
  Enter: "Enter",
  "more characters to start the search": "more characters to start the search",
  Audience: "Audience",
  "Search Campaigns": "Search Campaigns",
  Overview: "Overview",
  Settings: "Settings",
  "My profile": "My profile",
  "Last logged in": "Last logged in",
  "Number of total logins": "Number of total logins",
  "Working place": "Working place",
  "App settings": "App settings",
  "Login message": "Login message",
  Billing: "Billing",
  "Guest APP Overview": "Guest APP Overview",
  "Apply changes": "Apply changes",
  "Primary color": "Primary color",
  "Secundary color": "Secundary color",
  "Write login message": "Write login message",
  Notifications: "Notifications",
  "Read all": "Read all",
  "You have successfully updated the category":
    "You have successfully updated the category",
  "You have successfully created the category":
    "You have successfully created the category",
  "You have successfully updated the subcategory":
    "You have successfully updated the subcategory",
  "You have successfully created the subcategory":
    "You have successfully created the subcategory",
  "You have successfully updated the item":
    "You have successfully updated the item",
  "You have successfully created the item":
    "You have successfully created the item",
  "You have successfully updated the extras":
    "You have successfully updated the extras",
  "You have successfully created the extras":
    "You have successfully created the extras",
  "Create another": "Create another",
  "The delivery is finished!": "The delivery is finished!",
  "Chat is disabled": "Chat is disabled",
  "Add new conversation": "Add new conversation",
  "Remove conversation": "Remove conversation",
  "Type message here": "Type message here",
  "Reservations review": "Reservations review",
  "Assigned key": "Assigned key",
  "GuestApp state": "GuestApp state",
  "Room key": "Room key",
  "Find reservation": "Find reservation",
  "Add room key": "Add room key",
  "Delete reservation": "Delete reservation",
  "With this action you'll delete chosen reservation. Are you sure you want to proceed?":
    "With this action you'll delete chosen reservation. Are you sure you want to proceed?",
  "Yes I am sure": "Yes, I am sure",
  Review: "Review",
  "Import reservations": "Import reservations",
  "Reservations Review": "Reservations Review",
  "Add new": "Add new",
  "Delete this item": "Delete this item",
  "Find key": "Find key",
  Key: "Key",
  "Key list": "Key list",
  "Card preview": "Card preview",
  "Delete key": "Delete key",
  "With this action you will delete chosen key. Are you sure you want to proceed":
    "With this action you will delete chosen key. Are you sure you want to proceed",
  "Generate new key/s": "Generate new key/s",
  "Number of keys": "Number of keys",
  Generate: "Generate",
  "Please scan the code and enjoy your stay!":
    "Please scan the code and enjoy your stay!",
  "or visit": "or visit",
  "and login with guest code": "and login with guest code",
  "No key": "No key",
  "Creating document please wait...": "Creating document please wait...",
  "Downloading document": "Downloading document",
  "Download document": "Download document",
  "Create document": "Create document",
  "Display this item on these sub-categories":
    "Display this item on these sub-categories",
  "Charge this sidedish": "Charge this sidedish",
  "Create reservation": "Create reservation",
  "Currently staying in hotel": "Currently staying in hotel",
  "Customize homescreen rows": "Customize homescreen rows",
  "Something went wrong": "Something went wrong!",
  "Go to homepage": "Go to homepage",
  Reports: "Reports",
  CloseButton: "Close",
  "Choose parameters you want to make report for":
    "Choose parameters you want to make report for",
  Today: "Today",
  "To the end of this week": "To the end of this week",
  "Next week": "Next week",
  "To the end of this month": "To the end of this month",
  "Next month": "Next month",
  "Total (Total amount)": "Total (Total amount)",
  "Date and time from": "Date and time from",
  "Date and time to": "Date and time to",
  "Room number": "Room number",
  // ACTIVITY STREAM
  "Guest activity": "Guest activity",
  "/homeScreenRow/getAllForGuest/<stringlang>":
    "accessed the home screen on the guest app.",
  "/category/guest/getAllActiveForLoggedUser/<stringlang>":
    "browses categories.",
  "/service/selectView/<stringlang>/<uuidorg_core_id>": "browse items.",
  "/service/singleView/<stringlang>/<uuidservice_id>": "browses the item.",
  "/orders/roomBill": "has created a new order on the room bill.",
  "/orders/getAllForGuest/<stringlang>": "has started viewing his orders.",
  "/notifications/recieved": "has read notifications.",
  "/stayReviews": "has rated his / her stay.",
  "/reservationGuests/<uuid_id>": "has set a spending limit.",
  "/saveSubscription": "has allowed notifications to be received.",
  "/guests/login":
    "has logged in to the application. The system has registered the device.",
  "Moving and copying items": "Moving and copying items",
  "Do you want to move or copy selected items?":
    "Do you want to move or copy selected items?",
    "Steps": "Step {{num1}} of {{num2}}",
  Move: "Move",
  Copy: "Copy",
  "Select subcategory for moving/copying":
    "Select subcategory, you want move items to",
  "Select subcategory, you want copy items to":
    "Select subcategory, you want copy items to",
    "Move selected items":"Move selected {{length}} items",
    "Copy selected items":"Copy selected {{length}} items",
  Confirm: "Confirm",
  "QR codes and links":"QR codes and links",
  "Other":"Other",
  "Menu":"Menu",
  "public app":"public app",
  "Invoices":"Invoices",
  "Payment details":"Payment details",
  "Amount":"Amount",
  "There are no homescreen rows":"There are no homescreen rows",
  //integrations
  "Activate": "Activate",
  "Deactivate": "Deactivate",
  "Api key": "Api key",
  "Base url": "Base url",
  "Integration PMS": "Integration PMS",
  "Integration POS": "Integration POS",
  "CHOOSE": "CHOOSE",
  "External property ID": "External property ID",
  "Ordering process management": "Ordering process management",
  "Order status": "Order status",
  "Select status": "Select status",
  "Define ordering processes of the organization": "Define ordering processes of the organization",
  "connected": "connected",
  "unconnected": "unconnected",
  "inactive": "inactive",
  "Agree": "Agree",
  "Disagree": "Disagree",
  "Are you sure you want to delete calendar": "Are you sure you want to delete calendar",
  "Confirm deletion": "Confirm deletion",

  // Tutorial
  next: "next",
  back: "back",
  skip: "skip tutorial",
  tutTitle1: "Order room service",
  tutText1:
    "BROWSE trough large selection of FOOD and BEVERAGES, add what you want to the CART and click CHECKOUT. Your order will be delivered soon to your room.",
  tutTitle2: "Make an appointment",
  tutText2:
    "Want to make an APPOINTMENT in the WELLNESS? No problem. Pick MASSAGE or some other treatment, check available TERMINS and choose one. Add it to the cart and click CHECKOUT. That's it! You have successfully booked an APPOINTMENT. You only have to be there in time.",
  tutTitle3: "Book sport activity",
  tutText3:
    "Do you want to reserve TENNIS COURT or KAYAKS? It is very simple. Browse trough sport category and pick one you desire. Choose available TERMIN and add it to the CART. That's it. You have successfully reserved an SPORT ACTIVITY.",
  tutTitle4: "Review orders",
  tutText4:
    "After every ORDER you can view all your ordered item on the ORDERS screen. If order is completed you can make an REVIEW and help you, other guests and this property to make better service.",
  tutTitle5: "Get informed",
  tutText5:
    "Click BROWSE tab and then HOTEL MAPPING. There you can find various useful information such as: HOUSE RULES, TRANSPORTATION and CONTACT information.",
  tutTitle6: "Stay up to date",
  tutText6:
    "On HOME SCREEN you can always see what is trending in place you staying. There will be information like when and where are next big events such as CONCERTS, PARTIES and other activities.",
  tutTitle7: "Ask a question",
  tutText7:
    "Open CHAT tab and feel free to ask any question. RECEPTION will answer you in short notice. Chat is available 24/7.",
  tutTitle8: "View your stay information",
  tutText8:
    "Click PROFILE tab where you can see your STAY information and all SPENDINGS in your current reservation. View your subscriptions and use them as tickets for various events like BREAKFAST, SWIMMING POOL...",
};
