/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, putFunc, deleteFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_ALL_ORGANISATIONS_FOR_TENANT_REQ =
  "auth/GET_ALL_ORGANISATIONS_FOR_TENANT_REQ";
const GET_ALL_ORGANISATIONS_FOR_TENANT_SCS =
  "auth/GET_ALL_ORGANISATIONS_FOR_TENANT_SCS";
const GET_ALL_ORGANISATIONS_FOR_TENANT_FLR =
  "auth/GET_ALL_ORGANISATIONS_FOR_TENANT_FLR";

const ALTER_ORGANISATION = "auth/ALTER_ORGANISATION";

/**
 * ACTIONS
 */

export const getAllOrganisationsForTenant = id => async dispatch => {
  dispatch({ type: GET_ALL_ORGANISATIONS_FOR_TENANT_REQ });
  const response = await getFunc(`organisations/allForTenant/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_ORGANISATIONS_FOR_TENANT_SCS,
      payload: response.data
    });
  } else {
    dispatch({ type: GET_ALL_ORGANISATIONS_FOR_TENANT_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const activateOrganisation = id => async dispatch => {
  const response = await putFunc(`organisation/activate/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: ALTER_ORGANISATION, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const deactivateOrganisation = id => async dispatch => {
  const response = await deleteFunc(`organisation/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: ALTER_ORGANISATION, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

/**
 * REDUCERS
 */
const INIT_STATE = {
  loading: false,
  organisations: []
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_ORGANISATIONS_FOR_TENANT_REQ:
      return {
        loading: true,
        organisations: state.organisations
      };
    case GET_ALL_ORGANISATIONS_FOR_TENANT_SCS:
      return {
        loading: false,
        organisations: action.payload
      };
    case GET_ALL_ORGANISATIONS_FOR_TENANT_FLR:
      return {
        loading: false,
        organisations: state.organisations
      };

    case ALTER_ORGANISATION:
      return {
        ...state,

        organisations: state.organisations.map(item => {
          item?.sub_organisations?.map(org => {
            if (org.org_core_id === action.payload.org_core_id) {
              org.status = action.payload.status;
            }
          });
          return item;
        })
      };

    default:
      return state;
  }
}
