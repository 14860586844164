// eslint-disable-next-line import/prefer-default-export
export const ID = {
  settings: {
    myProfile: "1",
    appSettings: "2",
    loginMessage: "5",
    billing: "4",
    guestFrame: "6",
  },
  sideDish: {
    template: "587c9b58-816e-4e30-bc55-4ff9e01e95da",
  },
  appointment: {
    template: "f8a588e7-9949-4ce3-973c-303785b955f7",
  },
  dateRange: {
    template: "9a1e86ed-03bc-45d2-b416-6309f540bda4",
  },
  orderStates: {
    pending: "0af38e1d-9f92-4be3-ad51-0455d325d96c",
    in_progress: "1b56be7b-68ca-4963-8403-04f623b8f099",
    cancelled: "40af6e71-74ee-475f-adfe-0f35b0c2f2c4",
    finished: "cbebc670-50da-4d88-a566-017f98348389",
  },
  digitalMenuUrl: "https://kantun-menu.triple-host.com/",
  notification_state: {
    sent: "6ba51ba1-daf8-41e0-991c-603b305e91cd",
    read: "efcab6b6-bf38-4d63-95af-35f3bc73ccb6",
    recieved: "397fdf4a-be04-48dc-9c2c-abd4725e88b0",
    successful: "a519a848-a596-4deb-9f58-da86f18f555b",
  },
  org_types: [
    { id: "fe1e1254-64b4-437c-9b1d-37a55330ed4a", name: "Hotel" },
    { id: "23c5ffc1-2738-435c-a9e5-d672dd8507fc", name: "Camp" },
  ],
  deliveryTypes: {
    roomService: "eac6d0e7-924b-4d11-b136-84688b559552",
    orgPickup: "775ff8f5-7486-4d4c-8e3c-3fd41b8b4a37"
  },
  integrationTypes: {
    pms: "c344483f-9be9-4b75-a7ba-38d9100f3b48",
    pos: "6d2690a4-8b1d-11ec-a8a3-0242ac120002"
  }
};
