/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import {
  postFunc,
  getFunc,
  putFunc,
  deleteFunc
} from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";

import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const CREATE_GUEST = "auth/CREATE_GUEST";

const ALTER_GUEST = "auth/ALTER_GUEST";

const CLEAN_REDUCER = "auth/CLEAN_REDUCER";

const GET_ALL_GUESTS_REQ = "auth/GET_ALL_GUESTS_REQ";
const GET_ALL_GUESTS_SCS = "auth/GET_ALL_GUESTS_SCS";
const GET_ALL_GUESTS_FLR = "auth/GET_ALL_GUESTS_FLR";

const GET_ONE_GUEST_REQ = "auth/GET_ONE_GUEST_REQ";
const GET_ONE_GUEST_SCS = "auth/GET_ONE_GUEST_SCS";
const GET_ONE_GUEST_FLR = "auth/GET_ONE_GUEST_FLR";

/**
 * ACTIONS
 */

export const getAllGuests = body => async dispatch => {
  dispatch({ type: GET_ALL_GUESTS_REQ });
  const response = await postFunc(`guest/pagination`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_GUESTS_SCS,
      payload: response.data,
      total: response.total
    });
  } else {
    dispatch({ type: GET_ALL_GUESTS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const createGuest = (body, setOpen) => async dispatch => {
  const response = await postFunc("guests", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: CREATE_GUEST, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });
    setOpen();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const alterGuest = (body, id, setOpen) => async dispatch => {
  const response = await putFunc(`guests/${id}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: ALTER_GUEST, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });
    setOpen();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const getOneGuest = id => async dispatch => {
  dispatch({ type: GET_ONE_GUEST_REQ });
  const response = await getFunc(`guest/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ONE_GUEST_SCS,
      payload: response.data
    });
  } else {
    dispatch({ type: GET_ONE_GUEST_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const activateGuest = id => async dispatch => {
  const response = await putFunc(`guest/activate/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: ALTER_GUEST, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const deactivateGuest = id => async dispatch => {
  const response = await deleteFunc(`guest/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: ALTER_GUEST, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const cleanReducer = () => async dispatch => {
  dispatch({ type: CLEAN_REDUCER });
};

/**
 * REDUCERS
 */
const INIT_STATE = {
  loading: false,
  data: [],
  createGuest: null,
  oneGuest: null
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_GUESTS_REQ:
      return {
        ...state,
        loading: true,
        data: state.data
      };
    case GET_ALL_GUESTS_SCS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        total: action.total
      };
    case GET_ALL_GUESTS_FLR:
      return {
        ...state,
        loading: false,
        data: state.data
      };

    case GET_ONE_GUEST_REQ:
      return {
        ...state,
        loading: true,
        oneGuest: state.oneGuest
      };
    case GET_ONE_GUEST_SCS:
      return {
        ...state,
        loading: false,
        oneGuest: action.payload
      };
    case GET_ONE_GUEST_FLR:
      return {
        ...state,
        loading: false,
        oneGuest: state.oneGuest
      };

    case CREATE_GUEST:
      return {
        ...state,
        loading: false,
        createGuest: action.payload,
        data: [...state.data, action.payload]
      };

    case ALTER_GUEST:
      return {
        ...state,
        loading: false,
        data: state.data.map(item => {
          if (item.user_core_id === action.payload.user_core_id) {
            return { ...action.payload };
          }
          return item;
        })
      };

    case CLEAN_REDUCER:
      return {
        ...state,
        oneGuest: null
      };

    default:
      return state;
  }
}
