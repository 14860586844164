// React
import React from "react";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import IconButtonMUI from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Badge from "@material-ui/core/Badge";

const style = makeStyles(theme => ({
  gradientColor: {
    backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    backgroundSize: "100%",
    WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    backgroundColor: "#fff"
  }
}));

const IconButton = props => {
  const {
    onClick,
    color,
    icon,
    dataCy,
    gradientColor,
    styleProp,
    notificationDot
  } = props;
  const classes = style();

  return (
    <IconButtonMUI
      color={color}
      data-cy={dataCy}
      classes={{ label: gradientColor && classes.gradientColor }}
      style={styleProp}
      onClick={e => {
        onClick(e);
      }}
    >
      <Badge color="error" variant="dot" invisible={!notificationDot}>
        <Icon>{icon}</Icon>
      </Badge>
    </IconButtonMUI>
  );
};

IconButton.defaultProps = {
  onClick: () => console.log("Clicked!"),
  color: "primary",
  icon: "mood",
  styleProp: null,
  gradientColor: true,
  notificationDot: false
};

export default IconButton;
