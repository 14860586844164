/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
/**
 * IMPORTS
 */

import {
  getFunc,
  postFunc,
  putFunc,
  deleteFunc
} from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "Modules/units/Validation";

/**
 * TYPES
 */

const GET_ALL_ACCOMMODATION_TYPES = "reservations/GET_ALL_ACCOMMODATION_TYPES";
const GET_PRICING_TABLE = "reservations/GET_PRICING_TABLE";
const GET_ONE_RESERVATION = "reservations/GET_ONE_RESERVATION";
const CLEAR_PRICING_TABLE = "reservations/CLEAR_PRICING_TABLE";
const CLEAR_ROOMS = "reservations/CLEAR_ROOMS";
const CHANGE_PRICE = "reservations/CHANGE_PRICE";
const REMOVE_PRICING_TABLE = "reservations/REMOVE_PRICING_TABLE";
const ADD_POOL_RESERVATION = "reservations/ADD_POOL_RESERVATION";
const CLEAR_ONE_RESERVATION = "reservations/CLEAR_ONE_RESERVATION";
const EDIT_ONE_RESERVATION = "reservations/EDIT_ONE_RESERVATION";
const ADD_CALENDAR_RESERVATION = "reservations/ADD_CALENDAR_RESERVATION";
const UNASSIGN_GUEST = "reservations/UNASSIGN_GUEST";
const EDIT_RESERVATION = "reservations/EDIT_RESERVATION";
const ADD_NEW_ACC_TYPE = "reservations/ADD_NEW_ACC_TYPE";
const DELETE_POOL_RESERVATION = "reservations/DELETE_POOL_RESERVATION";
const DELETE_ACCOMMODATION_TYPE = "reservations/DELETE_ACCOMMODATION_TYPE";
const ASSIGN_GUEST_TO_THE_RESERVATION =
  "reservations/ASSIGN_GUEST_TO_THE_RESERVATION";
const EDIT_ASSIGNED_GUEST = "reservations/EDIT_ASSIGNED_GUEST";
const EDIT_ASSIGNED_GUEST_IMPORT = "reservations/EDIT_ASSIGNED_GUEST_IMPORT";
const EDIT_ASSIGNED_GUEST_IMPORT_MODE =
  "reservations/EDIT_ASSIGNED_GUEST_IMPORT_MODE";

const GET_ALL_AVAILABLE_ACCOMMODATION_TYPES =
  "reservations/GET_ALL_AVAILABLE_ACCOMMODATION_TYPES";
const GET_ALL_AVAILABLE_ROOMS = "reservations/GET_ALL_AVAILABLE_ROOMS";

const GET_ALL_UNASSIGNED_RESERVATIONS =
  "reservations/GET_ALL_UNASSIGNED_RESERVATIONS";
const REFUND_TRANSACTION = "reservations/REFUND_TRANSACTION";

const IMPORT_FILE_REQ = "auth/IMPORT_FILE_REQ";
const IMPORT_FILE_SCS = "auth/IMPORT_FILE_SCS";
const IMPORT_FILE_FLR = "auth/IMPORT_FILE_FLR";

const GET_ALL_RESERVATIONS_REQ = "auth/GET_ALL_RESERVATIONS_REQ";
const GET_ALL_RESERVATIONS_SCS = "auth/GET_ALL_RESERVATIONS_SCS";
const GET_ALL_RESERVATIONS_FLR = "auth/GET_ALL_RESERVATIONS_FLR";

const DELETE_ONE_RESERVATION = "auth/DELETE_ONE_RESERVATION";

const CLEAN_IMPORT_STATE = "auth/CLEAN_IMPORT_STATE";

/**
 * ACTIONS
 */

export const getAllAccommodationTypes = () => async dispatch => {
  const response = await getFunc(
    "accommodationUnits/getAllActiveGroupedByAccommodationTypeWithReservationDetails"
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ALL_ACCOMMODATION_TYPES, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const getAllUnassignedReservations = () => async dispatch => {
  const response = await getFunc(
    "reservations/getAllWithoutAssignedAccommodationUnits"
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_ALL_UNASSIGNED_RESERVATIONS, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const addNewGuest = (body, setGuest) => async dispatch => {
  const response = await postFunc("guests", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    setGuest({
      label: `${response.data.first_name} ${response.data.last_name} ${response.data.email}`,
      id: response.data.user_core_id
    });
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const getAvailableAccommodationTypes = (
  dateFrom,
  dateTo
) => async dispatch => {
  const response = await getFunc(
    `accomodationType/getAllAvailableByInterval?date_from=${dateFrom}&date_to=${dateTo}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_AVAILABLE_ACCOMMODATION_TYPES,
      payload: response.data
    });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const getAvailableRooms = (
  dateFrom,
  dateTo,
  accommodationType
) => async dispatch => {
  const response = await getFunc(
    `accommodationUnits/getAllAvailableByAccommodationTypeAndInterval/${accommodationType}?date_from=${dateFrom}&date_to=${dateTo}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_AVAILABLE_ROOMS,
      payload: response.data
    });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const getAvailableRoomsByReservation = (
  dateFrom,
  dateTo,
  accommodationType,
  resAccTypeId
) => async dispatch => {
  const response = await getFunc(
    `accommodationUnits/getAllAvailableByAndAccommodationTypeAndIntervalExcludeReservation/${resAccTypeId}/${accommodationType}?date_from=${dateFrom}&date_to=${dateTo}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_AVAILABLE_ROOMS,
      payload: response.data
    });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const getPricingTables = (
  dateFrom,
  dateTo,
  accommodationType
) => async dispatch => {
  const response = await getFunc(
    `accommodationTypePrices/getAllByAccommodationTypeAndDates/${accommodationType}?date_from=${dateFrom}&date_to=${dateTo}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_PRICING_TABLE,
      payload: response.data
    });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const clearPricingTable = () => dispatch => {
  dispatch({ type: CLEAR_PRICING_TABLE });
};

export const clearRooms = () => dispatch => {
  dispatch({ type: CLEAR_ROOMS });
};

export const removePricingTable = index => (dispatch, getState) => {
  const state = getState();
  const prices = [...state.reservations.pricingTables];
  prices.splice(index, 1);

  dispatch({
    type: REMOVE_PRICING_TABLE,
    payload: prices
  });
};

export const handleChangePrices = (firstIndex, secondIndex, value) => (
  dispatch,
  getState
) => {
  const state = getState();
  const prices = [...state.reservations.pricingTables];
  prices[firstIndex][secondIndex].price = value;

  dispatch({ type: CHANGE_PRICE, payload: prices });
};

export const addReservation = (body, closeDialog) => async dispatch => {
  const response = await postFunc("reservations/internal", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    if (
      response.data.reservation_accommodation_types.some(
        item =>
          item.reservation_accommodation_type.accommodation_unit_id === null
      )
    ) {
      const unassigned = response.data.reservation_accommodation_types.filter(
        item =>
          item.reservation_accommodation_type.accommodation_unit_id === null
      );
      const assigned = response.data.reservation_accommodation_types.filter(
        item =>
          item.reservation_accommodation_type.accommodation_unit_id !== null
      );
      assigned.forEach(item => {
        dispatch({
          type: ADD_CALENDAR_RESERVATION,
          payload: { ...response.data, item }
        });
      });
      dispatch({
        type: ADD_POOL_RESERVATION,
        payload: {
          ...response.data.reservation,
          guest: response.data.guest,
          accommodation_types: unassigned
        }
      });
    } else {
      response.data.reservation_accommodation_types.forEach(item => {
        dispatch({
          type: ADD_CALENDAR_RESERVATION,
          payload: { ...response.data, item }
        });
      });
    }
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const createOneReservation = (body, closeDialog) => async dispatch => {
  const response = await postFunc(`reservations/internal/createOne`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });

    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const editOneReservation = (body, closeDialog) => async dispatch => {
  const response = await putFunc(
    `reservations/alterAccommodation/${body.id}`,
    body
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });

    dispatch({
      type: EDIT_ONE_RESERVATION,
      payload: {
        data: response.data
      }
    });

    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const getOnePoolReservation = id => async dispatch => {
  const response = await getFunc(
    `reservations/getDetailsAboutReservationAccommodationTypeByReservation/${id}`
  );

  dispatch({
    type: GET_ONE_RESERVATION,
    payload: response.data
  });
};

export const editReservation = (body, id, closeDialog) => async dispatch => {
  const response = await putFunc(
    `reservations/reservationAccommodationType/${id}`,
    body
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });

    dispatch({ type: EDIT_RESERVATION, payload: response.data });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const addNewAccType = (body, id, closeDialog) => async dispatch => {
  const response = await putFunc(
    `reservations/addNewAccommodationType/${id}`,
    body
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });

    dispatch({ type: ADD_NEW_ACC_TYPE, payload: response.data });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const assignToRoom = (body, closeDialog) => async dispatch => {
  const response = await postFunc(
    `reservations/assignAccommodationToUnit`,
    body
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });

    dispatch({ type: EDIT_RESERVATION, payload: response.data });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const assignGuest = (body, closeDialog) => async dispatch => {
  const response = await postFunc("reservations/assignGuest", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({ type: ASSIGN_GUEST_TO_THE_RESERVATION, payload: response.data });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};
export const editAssignedGuest = (body, closeDialog) => async dispatch => {
  const response = await putFunc(
    `reservations/assignGuest/${body.reservationGuest}`,
    body
  );

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    dispatch({ type: EDIT_ASSIGNED_GUEST, payload: response.data });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const editAssignedGuestImport = (
  body,
  closeDialog,
  importMode,
  id
) => async dispatch => {
  const response = await putFunc(`reservations/import/assignGuest/${id}`, body);

  if (response.status.errorCode === 200) {
    dispatch({ type: VALIDATION_CLEAR });
    if (!importMode) {
      dispatch({ type: EDIT_ASSIGNED_GUEST_IMPORT, payload: response.data });
      closeDialog();
    } else {
      dispatch({
        type: EDIT_ASSIGNED_GUEST_IMPORT_MODE,
        payload: response.data
      });
      closeDialog();
    }

    NotificationManager.success(response.status.description);
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      NotificationManager.error(response.status.description);
    } else {
      dispatch({ type: VALIDATION_MESSAGE, message: response.status });
    }
  }
};

export const unassignGuest = id => async dispatch => {
  const response = await deleteFunc(`reservations/unAssignGuest/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: UNASSIGN_GUEST,
      payload: response.data
    });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const clearOneReservation = () => dispatch => {
  dispatch({ type: CLEAR_ONE_RESERVATION });
};

export const getAvailableRoomsForEdit = id => async dispatch => {
  const response = await getFunc(
    `accommodationUnits/getAllAvailableByReservationAccommodationType/${id}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_AVAILABLE_ROOMS,
      payload: response.data
    });
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const deletePoolReservation = (id, callback) => async dispatch => {
  const response = await deleteFunc(`reservations/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: DELETE_POOL_RESERVATION,
      payload: response.data
    });
    callback();
    NotificationManager.success(response.status.description);
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const deleteReservationAccommodationType = (
  id,
  closeDialog
) => async dispatch => {
  const response = await deleteFunc(
    `reservations/reservationAccommodationType/${id}`
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: DELETE_ACCOMMODATION_TYPE,
      payload: response.data
    });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const refundTransaction = (
  reservationId,
  amount,
  closeDialog
) => async dispatch => {
  const response = await putFunc(`reservations/refund/${reservationId}`, {
    amount: parseFloat(amount)
  });

  if (response.status.errorCode === 200) {
    dispatch({
      type: REFUND_TRANSACTION,
      payload: response.data
    });
    closeDialog();
    NotificationManager.success(response.status.description);
  } else if (response.status.errorCode === -2) {
    dispatch({
      type: REFUND_TRANSACTION,
      payload: response.data
    });
    closeDialog();
    NotificationManager.error(response.status.description);
  } else if (typeof response.status.description === "string") {
    NotificationManager.error(response.status.description);
  } else {
    dispatch({ type: VALIDATION_MESSAGE, message: response.status });
  }
};

export const importFile = (file, handleOpen) => async dispatch => {
  dispatch({ type: IMPORT_FILE_REQ });
  const response = await postFunc(`reservations/import`, file);

  if (response.status.errorCode === 200) {
    dispatch({
      type: IMPORT_FILE_SCS,
      payload: response.data
    });

    handleOpen();
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof response.status.description === "string") {
      dispatch({ type: IMPORT_FILE_FLR });

      NotificationManager.error(response.status.description);
    }
  }
};

export const getAllReservations = body => async dispatch => {
  dispatch({ type: GET_ALL_RESERVATIONS_REQ });
  const response = await postFunc(`reservations/pagination`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_RESERVATIONS_SCS,
      payload: response
    });
  } else {
    dispatch({ type: GET_ALL_RESERVATIONS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const deleteOneReservation = (
  id,
  handleOpen,
  handleTotalUpdate
) => async dispatch => {
  const response = await deleteFunc(`reservations/unAssignGuest/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: DELETE_ONE_RESERVATION,
      payload: response.data
    });

    handleOpen(false);
    handleTotalUpdate();
  } else {
    NotificationManager.error(response.status.description);
  }
};

export const cleanImport = () => async dispatch => {
  dispatch({
    type: CLEAN_IMPORT_STATE
  });
};

/**
 * REDUCERS
 */

const INIT_STATE = {
  accommodationTypes: [],
  unassignedReservations: [],
  availableAccommodationTypes: [],
  availableRooms: [],
  pricingTables: [],
  oneReservation: null,
  importedFile: null,
  allReservations: []
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_ACCOMMODATION_TYPES:
      return {
        ...state,
        accommodationTypes: action.payload.map(item => {
          item.accommodation_units = item.accommodation_units.map(unit => {
            if (!unit.reservations) {
              unit.reservations = [];
            }
            return unit;
          });
          return item;
        })
      };
    case GET_ALL_UNASSIGNED_RESERVATIONS:
      return {
        ...state,
        unassignedReservations: action.payload
      };
    case GET_ALL_AVAILABLE_ACCOMMODATION_TYPES:
      return {
        ...state,
        availableAccommodationTypes: action.payload
      };
    case GET_ALL_AVAILABLE_ROOMS:
      return {
        ...state,
        availableRooms: action.payload
      };
    case GET_PRICING_TABLE:
      return {
        ...state,
        pricingTables: action.payload
      };
    case CLEAR_PRICING_TABLE:
      return {
        ...state,
        pricingTables: []
      };
    case CLEAR_ROOMS:
      return {
        ...state,
        availableRooms: []
      };
    case REMOVE_PRICING_TABLE:
      return {
        ...state,
        pricingTables: action.payload
      };
    case CHANGE_PRICE:
      return {
        ...state,
        pricingTables: action.payload
      };
    case ADD_POOL_RESERVATION:
      return {
        ...state,
        unassignedReservations: [
          ...state.unassignedReservations,
          action.payload
        ]
      };
    case GET_ONE_RESERVATION:
      return {
        ...state,
        oneReservation: action.payload
      };
    case ADD_CALENDAR_RESERVATION:
      return {
        ...state,
        accommodationTypes: state.accommodationTypes.map(type => {
          if (
            action.payload.item.reservation_accommodation_type
              .accommodation_type_id === type.id
          ) {
            type.accommodation_units.map(unit => {
              if (
                unit.accommodation_unit.id ===
                action.payload.item.reservation_accommodation_type
                  .accommodation_unit_id
              ) {
                if (unit.reservations === null) {
                  unit.reservations = [];
                }
                unit.reservations.push({
                  guest: action.payload.guest,
                  reservation: action.payload.reservation,
                  accommodations: action.payload.item.accommodations,
                  reservation_accommodation_type:
                    action.payload.item.reservation_accommodation_type
                });
              }
              return unit;
            });
          }
          return type;
        })
      };

    case ASSIGN_GUEST_TO_THE_RESERVATION:
      return {
        ...state,
        oneReservation: {
          ...state.oneReservation,
          reservation_accommodation_type: state.oneReservation?.reservation_accommodation_type?.map(
            item => {
              if (
                item.reservation_accommodation_type.id ===
                action.payload.reservation_guest?.reservation_guest
                  ?.reservation_accommodation_type_id
              ) {
                item.reservation_guests === null
                  ? (item.reservation_guests = [])
                  : item.reservation_guests;
                return {
                  ...item,
                  reservation_guests: [
                    ...item.reservation_guests,
                    action.payload.reservation_guest
                  ]
                };
              }
              return item;
            }
          )
        }
      };
    case EDIT_ASSIGNED_GUEST:
      return {
        ...state,
        oneReservation: {
          ...state.oneReservation,
          reservation_accommodation_type: state.oneReservation?.reservation_accommodation_type?.map(
            item => {
              if (
                item.reservation_accommodation_type.id ===
                action.payload.reservation_guest?.reservation_guest
                  ?.reservation_accommodation_type_id
              ) {
                return {
                  ...item,
                  reservation_guests: item.reservation_guests.map(guest => {
                    if (
                      guest.reservation_guest.id ===
                      action.payload.reservation_guest.reservation_guest.id
                    ) {
                      return action.payload.reservation_guest;
                    }
                    return guest;
                  })
                };
              }
              return item;
            }
          )
        }
      };
    case UNASSIGN_GUEST:
      return {
        ...state,
        oneReservation: {
          ...state.oneReservation,
          reservation_accommodation_type: state.oneReservation.reservation_accommodation_type.map(
            item => {
              if (
                item.reservation_accommodation_type.id ===
                action.payload.reservation_accommodation_type_id
              ) {
                return {
                  ...item,
                  reservation_guests: item.reservation_guests.filter(
                    item => item.reservation_guest.id !== action.payload.id
                  )
                };
              }
              return item;
            }
          )
        }
      };
    case CLEAR_ONE_RESERVATION:
      return {
        ...state,
        oneReservation: null
      };
    case EDIT_ONE_RESERVATION:
      return {
        ...state,
        allReservations: {
          ...state.allReservations,
          data: state.allReservations?.data?.map(res => {
            const foundItem = action.payload.data.find(
              item => item.reservation_guest.id === res.reservation_guest.id
            );
            if (foundItem) {
              return foundItem;
            }
            return res;
          })
        }
      };
    case EDIT_RESERVATION:
      return {
        ...state,
        accommodationTypes: state.accommodationTypes.map(type => {
          type.accommodation_units = type.accommodation_units.map(unit => {
            if (unit.reservations) {
              unit.reservations = unit.reservations.filter(
                item =>
                  item.reservation_accommodation_type.id !==
                  action.payload.reservation_accommodation_type.id
              );
              if (
                unit.accommodation_unit.id ===
                action.payload.accommodation_unit.id
              ) {
                unit.reservations.push({
                  guest: action.payload.guest,
                  accommodations: action.payload.accommodations,
                  reservation: action.payload.reservation,
                  reservation_accommodation_type:
                    action.payload.reservation_accommodation_type
                });
              }
            }
            return unit;
          });
          return type;
        }),
        unassignedReservations: state.unassignedReservations.map(res => {
          res.accommodation_types = res.accommodation_types.filter(
            item =>
              item.reservation_accommodation_type.id !==
              action.payload.reservation_accommodation_type.id
          );
          return res;
        }),
        oneReservation: {
          ...state.oneReservation,
          reservation_accommodation_type: state.oneReservation?.reservation_accommodation_type?.map(
            item => {
              if (
                item.reservation_accommodation_type.id ===
                action.payload.reservation_accommodation_type.id
              ) {
                return {
                  ...item,
                  accommodation_unit: action.payload.accommodation_unit
                };
              }
              return item;
            }
          )
        }
      };
    case ADD_NEW_ACC_TYPE:
      return {
        ...state,
        oneReservation: {
          ...state.oneReservation,
          reservation_accommodation_type:
            action.payload.reservation_accommodation_type
        }
      };
    case DELETE_POOL_RESERVATION:
      return {
        ...state,
        unassignedReservations: state.unassignedReservations.filter(
          item => item.id !== action.payload.reservation.id
        )
      };
    case DELETE_ACCOMMODATION_TYPE:
      return {
        ...state,
        accommodationTypes: state.accommodationTypes.map(type => {
          type.accommodation_units = type.accommodation_units.map(unit => {
            if (unit.reservations) {
              unit.reservations = unit.reservations.filter(
                item =>
                  item.reservation_accommodation_type.id !==
                  action.payload.reservation_accommodation_type.id
              );
            }
            return unit;
          });
          return type;
        }),
        unassignedReservations: state.unassignedReservations.map(res => {
          res.accommodation_types = res.accommodation_types.filter(
            item =>
              item.reservation_accommodation_type.id !==
              action.payload.reservation_accommodation_type.id
          );
          return res;
        }),
        oneReservation: {
          ...state.oneReservation,
          reservation_accommodation_type: state.oneReservation.reservation_accommodation_type?.filter(
            item =>
              item.reservation_accommodation_type.id !==
              action.payload.reservation_accommodation_type.id
          )
        }
      };
    case REFUND_TRANSACTION:
      return {
        ...state,
        oneReservation: {
          ...state.oneReservation,
          transactions: [
            ...state.oneReservation.transactions,
            action.payload.transaction
          ]
        }
      };

    case IMPORT_FILE_REQ:
      return {
        ...state,
        loading: true,
        importedFile: state.importedFile
      };
    case IMPORT_FILE_SCS:
      return {
        ...state,
        loading: false,
        importedFile: action.payload
      };
    case IMPORT_FILE_FLR:
      return {
        ...state,
        loading: false,
        importedFile: state.importedFile
      };

    case GET_ALL_RESERVATIONS_REQ:
      return {
        ...state,
        loading: true,
        allReservations: state.allReservations
      };
    case GET_ALL_RESERVATIONS_SCS:
      return {
        ...state,
        loading: false,
        allReservations: action.payload
      };
    case GET_ALL_RESERVATIONS_FLR:
      return {
        ...state,
        loading: false,
        allReservations: state.allReservations
      };

    case DELETE_ONE_RESERVATION:
      const deletedReservation = action.payload.id;
      const reservations = state.allReservations.data;
      return {
        ...state,
        allReservations: {
          ...state.allReservations,
          data: reservations.filter(
            r => !deletedReservation.includes(r.reservation_guest.id)
          )
        }
      };

    case EDIT_ASSIGNED_GUEST_IMPORT:
      return {
        ...state,
        allReservations: {
          ...state.allReservations,
          data: state.allReservations.data.map(item => {
            if (
              item.reservation_guest?.id ===
              action.payload.reservation_guest?.id
            ) {
              return { ...action.payload };
            }
            return item;
          })
        }
      };

    case EDIT_ASSIGNED_GUEST_IMPORT_MODE:
      return {
        ...state,
        importedFile: state?.importedFile?.map(item => {
          if (
            item?.reservation_guest?.id === action.payload.reservation_guest.id
          ) {
            item.reservation_guest = action.payload.reservation_guest;
            item.room_key = action.payload.room_key_data;
            return item;
          }
          return item;
        })
      };

    case CLEAN_IMPORT_STATE:
      return {
        ...state,
        importedFile: null
      };

    default:
      return state;
  }
}
