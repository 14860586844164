// React
import React from "react";

// MUI
import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles(() => ({
  logo: { verticalAlign: "middle" },
  tag: {
    padding: "2px 8px",
    display: "inline",
    borderRadius: "5px",
    marginLeft: "5px",
    fontWeight: 500,
    fontSize: "12px",
    verticalAlign: "sub"
  }
}));

const Logo = props => {
  const classes = style();

  const { width, goTo, src, dataCy, tag, light, centered } = props;
  return (
    <a href={goTo} data-cy={dataCy}>
      <img alt="logo" src={src} className={classes.logo} style={{ width }} />
      {tag && (
        <div
          style={{
            textAlign: "center",
            width: "100%",
            display: centered ? "block" : "inline"
          }}
        >
          <div
            className={classes.tag}
            style={{
              backgroundColor: light ? "#fff" : "#0072D4",
              color: light ? "#0072D4" : "#fff"
            }}
          >
            {tag}
          </div>
        </div>
      )}
    </a>
  );
};

Logo.defaultProps = {
  width: "100%",
  goTo: "/"
};

export default Logo;
