/* eslint-disable camelcase */
// React
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material-UI
import {
  Container,
  Box,
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline,
  makeStyles
} from "@material-ui/core";

// Layout components
import Header from "Layouts/sections/PrivateHeader";
import Sidebar from "Layouts/sections/Sidebar";

// Redux actions
import { getTheme } from "Modules/units/App";
import { getOrgsForLoggedUser } from "Modules/units/Auth";

// Loading spinner
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

const style = makeStyles(() => ({
  container: {
    // eslint-disable-next-line no-useless-computed-key
    ["@media (min-width:1280px)"]: {
      // eslint-disable-line no-useless-computed-key
      marginLeft: "17.5em"
    }
  }
}));

const PrivateLayout = state => {
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const auth = useSelector(state => state.auth);

  const classes = style();

  useEffect(() => {
    if (app.theme === null) dispatch(getTheme());
    return () => {};
  }, []);

  useEffect(() => {
    if (auth.user?.organisationID)
      dispatch(getOrgsForLoggedUser(auth.user.organisationID));
    return () => {};
  }, []);

  return (
    <>
      {app.theme?.mui_theme ? (
        <MuiThemeProvider theme={createMuiTheme(app.theme.mui_theme)}>
          <CssBaseline />
          <Sidebar />
          <Header title={state.title} routeId={state.id} />
          <Box className={classes.container}>
            <Container>
              <Box pt={2}>{state.children}</Box>
            </Container>
          </Box>
        </MuiThemeProvider>
      ) : (
        <FullScreenSpinner />
      )}
    </>
  );
};

export default PrivateLayout;
