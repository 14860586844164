/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { postFunc, putFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const GET_TENANTS_REQ = "auth/GET_TENANTS_REQ";
const GET_TENANTS_SCS = "auth/GET_TENANTS_SCS";
const GET_TENANTS_FLR = "auth/GET_TENANTS_FLR";

const VERIFY_TENANT = "auth/VERIFY_TENANT";

/**
 * ACTIONS
 */

export const getTenants = body => async dispatch => {
  dispatch({ type: GET_TENANTS_REQ });
  const response = await postFunc("tenants/pagination", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: GET_TENANTS_SCS, payload: response.data });
  } else {
    dispatch({ type: GET_TENANTS_FLR });
    NotificationManager.error(response.status.description);
  }
};

export const verifyTenant = id => async dispatch => {
  const response = await putFunc(`tenants/${id}`);

  if (response.status.errorCode === 200) {
    dispatch({ type: VERIFY_TENANT, payload: response.data });
  } else {
    NotificationManager.error(response.status.description);
  }
};

/**
 * REDUCERS
 */

const INIT_STATE = {
  loading: false,
  data: []
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_TENANTS_REQ:
      return {
        loading: true,
        data: state.data
      };
    case GET_TENANTS_SCS:
      return {
        loading: false,
        data: action.payload
      };
    case GET_TENANTS_FLR:
      return {
        loading: false,
        data: state.data
      };
    case VERIFY_TENANT:
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id === action.payload.id) {
            return { ...action.payload };
          }
          return item;
        })
      };
    default:
      return state;
  }
}
