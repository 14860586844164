// React
import React from "react";
import { useTranslation } from "react-i18next";

// Material UI
import { Box } from "@material-ui/core";

// Atoms
import Line from "Components/atoms/UI/Line";
import Text from "Components/atoms/UI/Text";

const Title = props => {
  const {
    title,
    variant,
    dataCy,
    lineWidth,
    fontSize,
    lineHeight,
    lang,
    setAdministrationLang,
    getData
  } = props;
  const { t } = useTranslation();

  return (
    <Box width="100%">
      <Text
        title={t(title)}
        variant={variant}
        dataCy={dataCy}
        fontSize={fontSize}
        lineHeight={lineHeight}
        lang={lang}
        setAdministrationLang={setAdministrationLang}
        getData={getData}
      />

      <Line lineWidth={lineWidth} />
    </Box>
  );
};

export default Title;
