import { NotificationManager } from "react-notifications";
import {
  onGetCalendars,
  onGetGroupCalendars,
  onCreateCalendar,
  onDeleteCalendar,
  onGetWorkDaysForCalendar,
  onAddWorkDays,
  onDeleteWorkDays,
  onGetPendingReservations,
  onGetOrgsAndNumbers,
  onAssignReservationToCalendar,
  onAddNewCalendarReservation,
  onGetWorkingHoursForOrg,
  onGetOneCalendarReservation,
  onEditCalendarReservation,
  onDeleteCalendarReservation,
  onGetOneCalendar,
  onEditCalendar
} from "Services/CalendarsServices";

import moment from "moment";
import { VALIDATION_MESSAGE, VALIDATION_CLEAR } from "./Validation";
/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const GET_ORGS_AND_NUMBERS_REQ = "auth/GET_ORGS_AND_NUMBERS_REQ";
const GET_ORGS_AND_NUMBERS_SCS = "auth/GET_ORGS_AND_NUMBERS_SCS";
const GET_ORGS_AND_NUMBERS_FLR = "auth/GET_ORGS_AND_NUMBERS_FLR";

const GET_CALENDAR_GROUPS_REQ = "auth/GET_CALENDAR_GROUPS_REQ";
const GET_CALENDAR_GROUPS_SCS = "auth/GET_CALENDAR_GROUPS_SCS";
const GET_CALENDAR_GROUPS_FLR = "auth/GET_CALENDAR_GROUPS_FLR";

const GET_CALENDARS_REQ = "auth/GET_CALENDARS_REQ";
const GET_CALENDARS_SCS = "auth/GET_CALENDARS_SCS";
const GET_CALENDARS_FLR = "auth/GET_CALENDARS_FLR";

const CREATE_CALENDAR_SCS = "auth/CREATE_CALENDAR_SCS";

const DELETE_CALENDAR_SCS = "auth/DELETE_CALENDAR_SCS";

const GET_WORK_DAYS_FOR_CALENDAR_REQ = "auth/GET_WORK_DAYS_FOR_CALENDAR_REQ";
const GET_WORK_DAYS_FOR_CALENDAR_SCS = "auth/GET_WORK_DAYS_FOR_CALENDAR_SCS";
const GET_WORK_DAYS_FOR_CALENDAR_FLR = "auth/GET_WORK_DAYS_FOR_CALENDAR_FLR";

const ADD_WORK_DAYS_FOR_CALENDAR_SCS = "auth/ADD_WORK_DAYS_FOR_CALENDAR_SCS";

const DELETE_WORK_DAY_FOR_CALENDAR_SCS =
  "auth/DELETE_WORK_DAY_FOR_CALENDAR_SCS";

const ADD_TEMP_EVENT_ON_CALENDARS = "auth/ADD_TEMP_EVENT_ON_CALENDARS";

const REMOVE_TEMP_EVENT_ON_CALENDARS = "auth/REMOVE_TEMP_EVENT_ON_CALENDARS";

const GET_PENDING_RESERVATIONS_REQ = "auth/GET_PENDING_RESERVATIONS_REQ";
const GET_PENDING_RESERVATIONS_SCS = "auth/GET_PENDING_RESERVATIONS_SCS";
const GET_PENDING_RESERVATIONS_FLR = "auth/GET_PENDING_RESERVATIONS_FLR";

const ASSIGN_RESERVATION_TO_CALENDAR = "auth/ASSIGN_RESERVATION_TO_CALENDAR";

const ADD_NEW_RESERVATION_TO_CALENDAR = "auth/ADD_NEW_RESERVATION_TO_CALENDAR";

const GET_WORKING_HOURS_FOR_ORG_REQ = "auth/GET_WORKING_HOURS_FOR_ORG_REQ";
const GET_WORKING_HOURS_FOR_ORG_SCS = "auth/GET_WORKING_HOURS_FOR_ORG_SCS";
const GET_WORKING_HOURS_FOR_ORG_FLR = "auth/GET_WORKING_HOURS_FOR_ORG_FLR";

const GET_ONE_CALENDAR_RESERVATION_REQ =
  "auth/GET_ONE_CALENDAR_RESERVATION_REQ";
const GET_ONE_CALENDAR_RESERVATION_SCS =
  "auth/GET_ONE_CALENDAR_RESERVATION_SCS";
const GET_ONE_CALENDAR_RESERVATION_FLR =
  "auth/GET_ONE_CALENDAR_RESERVATION_FLR";

const EDIT_CALENDAR_RESERVATION_SCS = "auth/EDIT_CALENDAR_RESERVATION_SCS";

const DELETE_CALENDAR_RESERVATION_SCS = "auth/DELETE_CALENDAR_RESERVATION_SCS";

const CLEAR_ONE_RESERVATION = "auth/CLEAR_ONE_RESERVATION";

const CLEAR_CALENDARS = "auth/CLEAR_CALENDARS";

const GET_ONE_CALENDAR_REQ = "auth/GET_ONE_CALENDAR_REQ";
const GET_ONE_CALENDAR_SCS = "auth/GET_ONE_CALENDAR_SCS";
const GET_ONE_CALENDAR_FLR = "auth/GET_ONE_CALENDAR_FLR";

const CLEAR_ONE_CALENDAR = "auth/CLEAR_ONE_CALENDAR";

const EDIT_CALENDAR_SCS = "auth/EDIT_CALENDAR_SCS";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/
export const getOrgsAndNumbers = () => dispatch => {
  dispatch({ type: GET_ORGS_AND_NUMBERS_REQ });
  onGetOrgsAndNumbers()
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: GET_ORGS_AND_NUMBERS_SCS,
          payload: { data: response.data }
        });
      } else {
        dispatch({ type: GET_ORGS_AND_NUMBERS_FLR });
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      dispatch({ type: GET_ORGS_AND_NUMBERS_FLR });
      NotificationManager.error(error.message);
    });
};

export const getCalendarGroups = () => dispatch => {
  dispatch({ type: GET_CALENDAR_GROUPS_REQ });
  onGetGroupCalendars()
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: GET_CALENDAR_GROUPS_SCS,
          payload: { data: response.data }
        });
      } else {
        dispatch({ type: GET_CALENDAR_GROUPS_FLR });
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      dispatch({ type: GET_CALENDAR_GROUPS_FLR });
      NotificationManager.error(error.message);
    });
};

export const getPendingReservations = (
  organisation,
  date,
  auth
) => dispatch => {
  dispatch({ type: GET_PENDING_RESERVATIONS_REQ });
  onGetPendingReservations(organisation, date, auth)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: GET_PENDING_RESERVATIONS_SCS,
          payload: { data: response.data }
        });
      } else {
        dispatch({ type: GET_PENDING_RESERVATIONS_FLR });
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      dispatch({ type: GET_PENDING_RESERVATIONS_FLR });
      NotificationManager.error(error.message);
    });
};

export const getCalendars = (organisation, date, auth) => dispatch => {
  dispatch({ type: GET_CALENDARS_REQ });
  onGetCalendars(organisation, date, auth)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: GET_CALENDARS_SCS,
          payload: { data: response.data }
        });
      } else {
        dispatch({ type: GET_CALENDARS_FLR });
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      dispatch({ type: GET_CALENDARS_FLR });
      NotificationManager.error(error.message);
    });
};

export const createCalendar = (body, closeDialog) => dispatch => {
  onCreateCalendar(body)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({ type: VALIDATION_CLEAR });
        dispatch({
          type: CREATE_CALENDAR_SCS,
          payload: { data: response.data }
        });
        NotificationManager.success(response.status.description);
        closeDialog();
      } else {
        // eslint-disable-next-line no-lonely-if
        if (typeof response.status.description === "string") {
          NotificationManager.error(response.status.description);
        } else {
          dispatch({ type: VALIDATION_MESSAGE, message: response.status });
        }
      }
    })
    .catch(error => {
      NotificationManager.error(error.message);
    });
};

export const deleteCalendar = (calendarId, closeDialog) => dispatch => {
  onDeleteCalendar(calendarId)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: DELETE_CALENDAR_SCS,
          payload: { data: response.data }
        });
        closeDialog();
      } else {
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      NotificationManager.error(error.message);
    });
};

export const getWorkDaysForCalendar = (
  calendarId,
  fromDate,
  toDate
) => dispatch => {
  dispatch({ type: GET_WORK_DAYS_FOR_CALENDAR_REQ });
  onGetWorkDaysForCalendar(calendarId, fromDate, toDate)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: GET_WORK_DAYS_FOR_CALENDAR_SCS,
          payload: { data: response.data }
        });
      } else {
        dispatch({ type: GET_WORK_DAYS_FOR_CALENDAR_FLR });
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      dispatch({ type: GET_WORK_DAYS_FOR_CALENDAR_FLR });
      NotificationManager.error(error.message);
    });
};

export const addWorkDays = (days, date) => dispatch => {
  onAddWorkDays(days)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: ADD_WORK_DAYS_FOR_CALENDAR_SCS,
          payload: { data: response.data, date }
        });
        NotificationManager.success(response.status.description);
      } else {
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      NotificationManager.error(error.message);
    });
};

export const deleteWorkDay = (days, date) => dispatch => {
  onDeleteWorkDays(days)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: DELETE_WORK_DAY_FOR_CALENDAR_SCS,
          payload: { data: response.data, date }
        });
        NotificationManager.success(response.status.description);
      } else {
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      NotificationManager.error(error.message);
    });
};

export const addTempEventOnCalendars = reservation => dispatch => {
  dispatch({
    type: ADD_TEMP_EVENT_ON_CALENDARS,
    payload: { data: reservation }
  });
};

export const removeTempEventOnCalendars = reservation => dispatch => {
  dispatch({
    type: REMOVE_TEMP_EVENT_ON_CALENDARS,
    payload: { data: reservation }
  });
};

export const assignReservationToCalendar = (
  reservationId,
  calendarId
) => dispatch => {
  onAssignReservationToCalendar(reservationId, calendarId)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: ASSIGN_RESERVATION_TO_CALENDAR,
          payload: { data: response.data }
        });
        NotificationManager.success(response.status.description);
      } else {
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      NotificationManager.error(error.message);
    });
};

export const addNewReservationToCalendar = (data, closeDialog) => dispatch => {
  onAddNewCalendarReservation(data)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({ type: VALIDATION_CLEAR });
        dispatch({
          type: ADD_NEW_RESERVATION_TO_CALENDAR,
          payload: { data: response.data }
        });
        NotificationManager.success(response.status.description);
        closeDialog();
      } else {
        // eslint-disable-next-line no-lonely-if
        if (typeof response.status.description === "string") {
          NotificationManager.error(response.status.description);
        } else {
          dispatch({ type: VALIDATION_MESSAGE, message: response.status });
        }
      }
    })
    .catch(error => {
      NotificationManager.error(error.message);
    });
};

export const getWorkingHoursForOrg = organisation => dispatch => {
  dispatch({ type: GET_WORKING_HOURS_FOR_ORG_REQ });
  onGetWorkingHoursForOrg(organisation)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: GET_WORKING_HOURS_FOR_ORG_SCS,
          payload: { data: response.data }
        });
      } else {
        dispatch({ type: GET_WORKING_HOURS_FOR_ORG_FLR });
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      dispatch({ type: GET_WORKING_HOURS_FOR_ORG_FLR });
      NotificationManager.error(error.message);
    });
};

export const getOneCalendarReservation = reservationId => dispatch => {
  dispatch({ type: GET_ONE_CALENDAR_RESERVATION_REQ });
  onGetOneCalendarReservation(reservationId)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: GET_ONE_CALENDAR_RESERVATION_SCS,
          payload: { data: response.data }
        });
      } else {
        dispatch({ type: GET_ONE_CALENDAR_RESERVATION_FLR });
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      dispatch({ type: GET_ONE_CALENDAR_RESERVATION_FLR });
      NotificationManager.error(error.message);
    });
};

export const editCalendarReservation = (
  body,
  reservationId,
  closeDialog
) => dispatch => {
  onEditCalendarReservation(body, reservationId)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({ type: VALIDATION_CLEAR });
        dispatch({
          type: EDIT_CALENDAR_RESERVATION_SCS,
          payload: { data: response.data }
        });
        NotificationManager.success(response.status.description);
        if (closeDialog) closeDialog();
      } else {
        // eslint-disable-next-line no-lonely-if
        if (typeof response.status.description === "string") {
          NotificationManager.error(response.status.description);
        } else {
          dispatch({ type: VALIDATION_MESSAGE, message: response.status });
        }
      }
    })
    .catch(error => {
      NotificationManager.error(error.message);
    });
};

export const deleteCalendarReservation = reservationId => dispatch => {
  onDeleteCalendarReservation(reservationId)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: DELETE_CALENDAR_RESERVATION_SCS,
          payload: { data: response.data }
        });
        NotificationManager.success(response.status.description);
      } else {
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      NotificationManager.error(error.message);
    });
};

export const getOneCalendar = calendarId => dispatch => {
  dispatch({ type: GET_ONE_CALENDAR_REQ });
  onGetOneCalendar(calendarId)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({
          type: GET_ONE_CALENDAR_SCS,
          payload: { data: response.data }
        });
      } else {
        dispatch({ type: GET_ONE_CALENDAR_FLR });
        NotificationManager.error(response.status.description);
      }
    })
    .catch(error => {
      dispatch({ type: GET_ONE_CALENDAR_FLR });
      NotificationManager.error(error.message);
    });
};

export const editCalendar = (body, calendarId, closeDialog) => dispatch => {
  onEditCalendar(body, calendarId)
    .then(response => {
      if (response.status.errorCode === 200) {
        dispatch({ type: VALIDATION_CLEAR });
        dispatch({
          type: EDIT_CALENDAR_SCS,
          payload: { data: response.data }
        });
        NotificationManager.success(response.status.description);
        closeDialog();
      } else {
        // eslint-disable-next-line no-lonely-if
        if (typeof response.status.description === "string") {
          NotificationManager.error(response.status.description);
        } else {
          dispatch({ type: VALIDATION_MESSAGE, message: response.status });
        }
      }
    })
    .catch(error => {
      NotificationManager.error(error.message);
    });
};

export const clearOneReservation = () => dispatch => {
  dispatch({ type: CLEAR_ONE_RESERVATION });
};

export const clearCalendars = () => dispatch => {
  dispatch({ type: CLEAR_CALENDARS });
};

export const clearOneCalendar = () => dispatch => {
  dispatch({ type: CLEAR_ONE_CALENDAR });
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/
const INIT_STATE = {
  calendars: {
    loading: false
  },
  groups: {
    loading: false
  },
  workDays: {
    loading: false
  },
  pendingReservations: {
    loading: false
  },
  workHours: {
    loading: false
  },
  oneReservation: {
    loading: false
  },
  oneCalendar: {
    loading: false
  },
  orgs: {
    data: [],
    loading: false
  }
};
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ORGS_AND_NUMBERS_REQ:
      return {
        ...state,
        orgs: {
          loading: true
        }
      };
    case GET_ORGS_AND_NUMBERS_SCS:
      return {
        ...state,
        orgs: {
          loading: true,
          ...action.payload
        }
      };
    case GET_ORGS_AND_NUMBERS_FLR:
      return {
        ...state,
        orgs: {
          loading: false
        }
      };
    case GET_CALENDAR_GROUPS_REQ:
      return {
        ...state,
        groups: {
          loading: true
        }
      };
    case GET_CALENDAR_GROUPS_SCS:
      return {
        ...state,
        groups: {
          loading: true,
          ...action.payload
        }
      };
    case GET_CALENDAR_GROUPS_FLR:
      return {
        ...state,
        groups: {
          loading: false
        }
      };
    case GET_CALENDARS_REQ:
      return {
        ...state,
        calendars: {
          loading: true
        }
      };
    case GET_CALENDARS_SCS:
      return {
        ...state,
        calendars: {
          loading: true,
          ...action.payload
        }
      };
    case GET_CALENDARS_FLR:
      return {
        ...state,
        calendars: {
          loading: false
        }
      };
    case CREATE_CALENDAR_SCS:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          data: state.calendars.data.concat(action.payload.data)
        }
      };
    case DELETE_CALENDAR_SCS:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          data: state.calendars.data.filter(
            calendar => calendar.id !== action.payload.data.id
          )
        }
      };
    case GET_WORK_DAYS_FOR_CALENDAR_REQ:
      return {
        ...state,
        workDays: {
          loading: true
        }
      };
    case GET_WORK_DAYS_FOR_CALENDAR_SCS:
      return {
        ...state,
        workDays: {
          loading: true,
          ...action.payload
        }
      };
    case GET_WORK_DAYS_FOR_CALENDAR_FLR:
      return {
        ...state,
        workDays: {
          loading: false
        }
      };
    case ADD_WORK_DAYS_FOR_CALENDAR_SCS:
      return {
        ...state,
        workDays: {
          ...state.workDays,
          data: state.workDays.data.concat(action.payload.data)
        },
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            if (
              action.payload.data.some(
                day =>
                  moment(action.payload.date).isSame(day.date) &&
                  day.appointment_calendar_id === item.id
              )
            ) {
              // eslint-disable-next-line no-param-reassign
              item.working = true;
            }
            return item;
          })
        }
      };
    case DELETE_WORK_DAY_FOR_CALENDAR_SCS:
      return {
        ...state,
        workDays: {
          ...state.workDays,
          data: state.workDays.data.filter(
            item => item.id !== action.payload.data[0].id
          )
        },
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            if (
              item.id === action.payload.data[0].appointment_calendar_id &&
              moment(action.payload.date).isSame(action.payload.data[0].date)
            ) {
              // eslint-disable-next-line no-param-reassign
              item.working = false;
            }
            return item;
          })
        }
      };
    case ADD_TEMP_EVENT_ON_CALENDARS:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            const checkStartDate = new Date(
              moment(action.payload.data.start_date).format("MM/DD/YYYY HH:mm")
            );
            const checkStopDate = new Date(
              moment(action.payload.data.stop_date).format("MM/DD/YYYY HH:mm")
            );
            if (
              !item.order_items.some(
                event =>
                  checkStartDate.getTime() >=
                    new Date(
                      moment(event.start_date).format("MM/DD/YYYY HH:mm")
                    ).getTime() &&
                  checkStartDate.getTime() <=
                    new Date(
                      moment(event.stop_date).format("MM/DD/YYYY HH:mm")
                    ).getTime()
              ) &&
              !item.order_items.some(
                event =>
                  checkStopDate.getTime() >=
                    new Date(
                      moment(event.start_date).format("MM/DD/YYYY HH:mm")
                    ).getTime() &&
                  checkStopDate.getTime() <=
                    new Date(
                      moment(event.stop_date).format("MM/DD/YYYY HH:mm")
                    ).getTime()
              ) &&
              item.working !== false
            ) {
              item.order_items.push({
                ...action.payload.data,
                appointment_calendar_id: item.id
              });
            }
            return item;
          })
        }
      };
    case REMOVE_TEMP_EVENT_ON_CALENDARS:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            // eslint-disable-next-line no-param-reassign
            item.order_items = item.order_items.filter(
              event => event.state_obj?.value !== "Pending"
            );
            return item;
          })
        }
      };
    case GET_PENDING_RESERVATIONS_REQ:
      return {
        ...state,
        pendingReservations: {
          loading: true
        }
      };
    case GET_PENDING_RESERVATIONS_SCS:
      return {
        ...state,
        pendingReservations: {
          loading: true,
          ...action.payload
        }
      };
    case GET_PENDING_RESERVATIONS_FLR:
      return {
        ...state,
        pendingReservations: {
          loading: false
        }
      };
    case ASSIGN_RESERVATION_TO_CALENDAR:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            if (item.id === action.payload.data.appointment_calendar_id) {
              item.order_items.push(action.payload.data);
            }
            return item;
          })
        },
        pendingReservations: {
          ...state.pendingReservations,
          data: state.pendingReservations.data.filter(
            item => item.id !== action.payload.data.id
          )
        }
      };
    case ADD_NEW_RESERVATION_TO_CALENDAR:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            if (item.id === action.payload.data.appointment_calendar_id) {
              item.order_items.push(action.payload.data);
            }
            return item;
          })
        }
      };
    case GET_WORKING_HOURS_FOR_ORG_REQ:
      return {
        ...state,
        workHours: {
          loading: true
        }
      };
    case GET_WORKING_HOURS_FOR_ORG_SCS:
      return {
        ...state,
        workHours: {
          loading: true,
          ...action.payload
        }
      };
    case GET_WORKING_HOURS_FOR_ORG_FLR:
      return {
        ...state,
        workHours: {
          loading: false
        }
      };
    case GET_ONE_CALENDAR_RESERVATION_REQ:
      return {
        ...state,
        oneReservation: {
          loading: true
        }
      };
    case GET_ONE_CALENDAR_RESERVATION_SCS:
      return {
        ...state,
        oneReservation: {
          loading: true,
          ...action.payload
        }
      };
    case GET_ONE_CALENDAR_RESERVATION_FLR:
      return {
        ...state,
        oneReservation: {
          loading: false
        }
      };
    case EDIT_CALENDAR_RESERVATION_SCS:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            if (item.id === action.payload.data.appointment_calendar_id) {
              // eslint-disable-next-line no-param-reassign
              item.order_items = item.order_items?.filter(
                order => order.id !== action.payload.data.id
              );
              item.order_items.push(action.payload.data);
            } else {
              // eslint-disable-next-line no-param-reassign
              item.order_items = item.order_items?.filter(
                order => order.id !== action.payload.data.id
              );
            }
            return item;
          })
        }
      };
    case CLEAR_ONE_RESERVATION:
      return {
        ...state,
        oneReservation: {
          loading: false
        }
      };
    case DELETE_CALENDAR_RESERVATION_SCS:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            if (item.id === action.payload.data.appointment_calendar_id) {
              // eslint-disable-next-line no-param-reassign
              item.order_items = item.order_items.filter(
                order => order.id !== action.payload.data.id
              );
            }
            return item;
          })
        }
      };
    case GET_ONE_CALENDAR_REQ:
      return {
        ...state,
        oneCalendar: {
          loading: true
        }
      };
    case GET_ONE_CALENDAR_SCS:
      return {
        ...state,
        oneCalendar: {
          loading: true,
          ...action.payload
        }
      };
    case GET_ONE_CALENDAR_FLR:
      return {
        ...state,
        oneCalendar: {
          loading: false
        }
      };
    case EDIT_CALENDAR_SCS:
      return {
        ...state,
        calendars: {
          ...state.calendars,
          data: state.calendars.data.map(item => {
            if (item.id === action.payload.data.id) {
              return { ...item, name: action.payload.data.name };
            }
            return item;
          })
        }
      };
    case CLEAR_CALENDARS:
      return {
        calendars: {
          loading: false
        },
        groups: {
          loading: false
        },
        workDays: {
          loading: false
        },
        pendingReservations: {
          loading: false
        },
        workHours: {
          loading: false
        },
        oneReservation: {
          loading: false
        },
        oneCalendar: {
          loading: false
        }
      };
    case CLEAR_ONE_CALENDAR:
      return {
        ...state,
        oneCalendar: {
          loading: false
        }
      };
    default:
      return state;
  }
}
